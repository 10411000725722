import {FETCH, VIEW, PUT, POST} from './huddle.types';
import {displayErrorMessage, displayMessage, getRequest, postRequest, putRequest} from "../../config/helper";
import {HUDDLE_LIST_API, HUDDLE_CREATE_API, HUDDLE_UPDATE_API, HUDDLE_DATA_API} from "../../config/URLConstant";

export const fetchHuddles= () => {
    return function (dispatch) {
        return getRequest(HUDDLE_LIST_API)
            .then(
                response => response.data,
                error => {
                    if (error && error.response !== undefined) {
                        displayErrorMessage(error.response.data.message || "Internal server error")
                    } else {
                        displayErrorMessage("Internal server error")
                    }
                }
            )
            .then((data) => {
                    return dispatch({
                        type: FETCH,
                        payload: data
                    })
                },
            );
    };
};

export const createHuddle = (postData) => {
    return function (dispatch) {
        return postRequest(HUDDLE_CREATE_API, postData)
            .then(
                response => {
                    displayMessage('Huddle Created.');
                    return response.data;
                },
                error => {
                    if (error && error.response !== undefined) {
                        displayErrorMessage(error.response.data.message || "Internal server error")
                    } else {
                        displayErrorMessage("Internal server error")
                    }
                }
            ).then((data) => {
                    return dispatch({
                        type: POST,
                        payload: data
                    })
                }
            )
    }
};

export const updateHuddle = (postData) => {
    return function (dispatch) {
        return putRequest(HUDDLE_UPDATE_API, postData)
            .then(
                response => {
                    displayMessage('Huddle Updated.');
                    return response.data;
                },
                error => {
                    if (error && error.response !== undefined) {
                        displayErrorMessage(error.response.data.message || "Internal server error")
                    } else {
                        displayErrorMessage("Internal server error")
                    }
                }
            ).then((data) => {
                    return dispatch({
                        type: PUT,
                        payload: data
                    })
                }
            )
    }
};

export const fetchHuddle = (id) => {
    return function (dispatch) {
        return getRequest(HUDDLE_DATA_API+id)
            .then(
                response => response.data,
                error => {
                    if (error && error.response !== undefined) {
                        displayErrorMessage(error.response.data.message || "Internal server error")
                    } else {
                        displayErrorMessage("Internal server error")
                    }
                }
            )
            .then((data) => {
                    return dispatch({
                        type: VIEW,
                        payload: data
                    })
                },
            );
    };
};