import {connect} from "react-redux";
import {Card, CardBody, CardHeader, Col, Row} from "reactstrap";
import React, {useEffect, useRef, useState} from "react";
import {Link, useHistory, useParams} from "react-router-dom";
import HuddleCreateForm from "./form/Create";
import {createHuddle, updateHuddle} from "../../redux/huddle/huddle.actions";
import {checkAuth} from "../../config/helper";

const Create = (props) => {
    const {createHuddle, updateHuddle} = props;
    const history = useHistory();
    const mounted = useRef(false);
    const [submitBtnText,setSubmitBtnText] = useState('Save');
    const [submitBtnIcon,setSubmitBtnIcon] = useState('');
    const {id} = useParams();

    useEffect(() => {
        mounted.current = true;
        checkAuth(history);
        return () => {
            mounted.current = false;
            handleSubmitBtn(false);
        };
    }, []);

    const handleSubmitBtn = (val) =>{
        if(val){
            setSubmitBtnText('Saving');
            setSubmitBtnIcon('fa fa-spinner fa-spin');
        }else{
            setSubmitBtnText('Save');
            setSubmitBtnIcon('');
        }
    }

    const handleSubmit = (values) => {
        if(mounted.current) {
            let postData = values;
            handleSubmitBtn(true);
            if (id !== undefined) {
                updateHuddle(postData).then((data) => {
                    handleSubmitBtn(false);
                    if (!('payload' in data && data.payload === undefined)) {
                        history.push('/admin/team-hub');
                    }
                });
            } else {
                createHuddle(postData).then((data) => {
                    handleSubmitBtn(false);
                    if (!('payload' in data && data.payload === undefined)) {
                        history.push('/admin/team-hub');
                    }
                });
            }
        }
    }

    return (
        <>
            <Row className={"justify-content-center"}>
                <Col xl="8">
                    <Card className="shadow border-0">
                        <CardHeader>
                            <Row>
                                <Col lg="12">
                                    <span className={"mb-0 h3"}>{id === undefined ? "Create" : "Update"} Team Hub</span>
                                    <Link
                                        to='/admin/team-hub'
                                        className={"d-flex float-right btn btn-sm btn-primary align-items-center"}
                                    >
                                        <i className="fa fa-arrow-left mr-2"/>
                                        Back
                                    </Link>
                                </Col>
                            </Row>
                        </CardHeader>
                        <CardBody>
                            <HuddleCreateForm id={id} onSubmit={handleSubmit} groupList={props.groupList} submitBtnText={submitBtnText} submitBtnIcon={submitBtnIcon} />
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </>
    )
}

const mapStateToProps = () => {
    return {
    };
}

const mapDispatchToProps = {
    updateHuddle,
    createHuddle
}

export default connect(mapStateToProps, mapDispatchToProps)(Create);