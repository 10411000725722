import {FETCH} from './appSetting.types';
import {displayErrorMessage, displayMessage, postRequest} from "../../config/helper";
import {APP_SETTING_FETCH_API, APP_SETTING_POST_API} from "../../config/URLConstant";

export const fetchAppSetting= (formData) => {
    return function (dispatch) {
        return postRequest(APP_SETTING_FETCH_API,formData)
            .then(
                response => response.data,
                error => {
                    if (error && error.response !== undefined) {
                        displayErrorMessage(error.response.data.message || "Internal server error")
                    } else {
                        displayErrorMessage("Internal server error")
                    }
                }
            )
            .then((data) => {
                    return dispatch({
                        type: FETCH,
                        payload: data
                    })
                },
            );
    };
};

export const postAppSetting= (formData) => {
    return function (dispatch) {
        return postRequest(APP_SETTING_POST_API,formData)
            .then(
                response => {
                    displayMessage('App build number updated.');
                    return response.data;
                },
                error => {
                    if (error && error.response !== undefined) {
                        displayErrorMessage(error.response.data.message || "Internal server error")
                    } else {
                        displayErrorMessage("Internal server error")
                    }
                }
            )
            .then((data) => {
                    return dispatch({
                        type: FETCH,
                        payload: data
                    })
                },
            );
    };
};