import {FETCH, FETCH_POST} from './weScreen.types';
import {displayErrorMessage, getRequest, postRequest} from "../../config/helper";
import {POST_FETCH_API, WE_SCREEN_LIST_API} from "../../config/URLConstant";

export const fetchWeScreen = (formData) => {
    return function (dispatch) {
        return postRequest(WE_SCREEN_LIST_API, formData)
            .then(
                response => response.data,
                error => {
                    if (error && error.response !== undefined) {
                        displayErrorMessage(error.response.data.message || "Internal server error")
                    } else {
                        displayErrorMessage("Internal server error")
                    }
                }
            )
            .then((data) => {
                    return dispatch({
                        type: FETCH,
                        payload: data
                    })
                },
            );
    };
};

export const fetchPostById = (id) => {
    return function (dispatch) {
        dispatch({
            type: FETCH_POST,
            payload: []
        })
        return getRequest(POST_FETCH_API + id)
            .then(
                response => response.data,
                error => {
                    if (error && error.response !== undefined) {
                        displayErrorMessage(error.response.data.message || "Internal server error")
                    } else {
                        displayErrorMessage("Internal server error")
                    }
                }
            )
            .then((data) => {
                    return dispatch({
                        type: FETCH_POST,
                        payload: data
                    })
                },
            );
    };
};