import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Col,
} from "reactstrap";
import axios from "axios";
import {LOGIN_ADMIN_API, LOGIN_ADVISOR_API} from "../../config/URLConstant";
import React, {useState} from "react";
import {useHistory, useParams} from "react-router-dom";
import {displayErrorMessage, displayMessage, setSessionData} from "../../config/helper";

const Login = () => {

  const history = useHistory();
  const [formLoader, setFormLoader] = useState(false);
  const [formData, setFormData] = useState({
    emailId: "",
    password: ""
  });
  const [errors,setErrors] = useState({});
  const {role} = useParams();

  const setInputVal = (name) => {
    return ({target: {value}}) => {
      setFormData(oldValues => ({...oldValues, [name]: value}));
    }
  };

  function handleLoginForm(event) {
    event.preventDefault();
    if(checkValidation()) {
      setFormLoader(true);
      if(role === 'admin') {
        axios.post(LOGIN_ADMIN_API, formData)
            .then((response) => {
              setFormLoader(false);
              setSessionData(response.data.data, 'admin');
              displayMessage(response.data.message || 'Login successful.');
              history.push('/admin/dashboard')
            })
            .catch(error => {
              setFormLoader(false);
              if (error && error.response !== undefined) {
                displayErrorMessage(error.response.data.message || "Internal server error")
              } else {
                displayErrorMessage("Internal server error")
              }
            });
      }else{
        axios.post(LOGIN_ADVISOR_API, formData)
            .then((response) => {
              setFormLoader(false);
              setSessionData(response.data.data, 'advisor');
              // displayMessage(response.data.message || 'Login successful.');
              displayMessage('Coach Login successful');
              history.push('/coach/dashboard')
            })
            .catch(error => {
              setFormLoader(false);
              if (error && error.response !== undefined) {
                displayErrorMessage(error.response.data.message || "Internal server error")
              } else {
                displayErrorMessage("Internal server error")
              }
            });
      }
    }else{
      displayErrorMessage("Please fill all the fields");
    }
  }

  function removeError(key){
    if(key in errors) {
      delete errors[key];
      setErrors(errors);
    }
  }

  function checkValidation(){
    let isValid = true;
    if(formData.emailId.length === 0){
      setErrors(oldValues => ({...oldValues, ...{'emailId': 'Email id is required'}}));
      isValid = false;
    }else{
      removeError('emailId');
    }
    if(formData.password.length === 0){
      setErrors(oldValues => ({...oldValues, ...{'password': 'Password is required'}}));
      isValid = false;
    }else{
      removeError('password');
    }
    return isValid;
  }

  return (
    <>
      <Col lg="5" md="7">
        <Card className="bg-secondary shadow border-0">
          <CardHeader className="bg-transparent">
            <div className="btn-wrapper text-center">
              <h1>{role === 'admin' ? 'Admin' : 'Coach'} Login</h1>
            </div>
          </CardHeader>
          <CardBody className="px-lg-5 py-lg-5">
            <Form role="form" onSubmit={handleLoginForm}>
              <FormGroup className="mb-3">
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-email-83"/>
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Email"
                    type="email"
                    autoComplete="new-email"
                    value={formData.emailId}
                    onChange={setInputVal("emailId")}
                    invalid={'emailId' in errors}
                  />
                </InputGroup>
                {errors.emailId && <span className="text-danger">{errors.emailId}</span>}
              </FormGroup>
              <FormGroup>
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-lock-circle-open"/>
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Password"
                    type="password"
                    autoComplete="new-password"
                    value={formData.password}
                    onChange={setInputVal("password")}
                    invalid={'password' in errors}
                  />
                </InputGroup>
                {errors.password && <span className="text-danger">{errors.password}</span>}
              </FormGroup>
              <div className="text-center">
                {!formLoader ? (
                  <Button className="my-4" color="primary" type="submit" block>
                    <i className={"fa fa-sign-in-alt"}/> Sign in
                  </Button>
                ) : (
                  <Button className="my-4" color="primary" type="button" block disabled>
                    <i className={"fa fa-sync-alt fa-spin"}/> Loading
                  </Button>
                )}
                {/*<p className="text-center">*/}
                {/*  <Link*/}
                {/*      to={'/auth/login/'+(role === 'admin' ? '' : 'admin')}*/}
                {/*      className={""}*/}
                {/*  >*/}
                {/*    Login As {role === 'admin' ? 'Advisor' : 'Admin'}*/}
                {/*  </Link>*/}
                {/*</p>*/}
              </div>
            </Form>
          </CardBody>
        </Card>
      </Col>
    </>
  );
};

export default Login;
