import {connect} from "react-redux";
import {Card, CardBody, CardHeader, Col, Row} from "reactstrap";
import React, {useEffect, useRef, useState} from "react";
import {Link, useHistory, useParams} from "react-router-dom";
import UserCreateForm from "./form/Create";
import {registerUser, updateUserProfile} from "../../redux/user/user.actions";
// import ShowPasswordModal from "./Modals/ShowPasswordModal";
import {checkAuth, checkRole, displayMessage} from "../../config/helper";

const Create = (props) => {
    const {registerUser, updateUserProfile} = props;
    const history = useHistory();
    const [submitBtnText, setSubmitBtnText] = useState('Save');
    const [submitBtnIcon, setSubmitBtnIcon] = useState('');
    // const [showPasswordModal,setShowPasswordModal] = useState(false);
    // const [newPassword,setNewPassword] = useState('');
    const {id} = useParams();

    const mounted = useRef(false);
    useEffect(() => {
        mounted.current = true;
        checkAuth(history);
        checkRole('admin', history);
        return () => {
            mounted.current = false;
        };
    }, []);

    const handleSubmitBtn = (val) => {
        if (val) {
            setSubmitBtnText('Saving');
            setSubmitBtnIcon('fa fa-spinner fa-spin');
        } else {
            setSubmitBtnText('Save');
            setSubmitBtnIcon('');
        }
    }

    const handleSubmit = (values) => {
        let data = Object.assign({},values);
        if (mounted.current) {
            handleSubmitBtn(true);
            if ('secondaryTeams' in data) {
                data.secondaryTeams = data.secondaryTeams.map(v => v.value);
            }
            if (id === '' || id === undefined) {
                registerUser(data).then((data) => {
                    handleSubmitBtn(false);
                    if (('payload' in data) && data.payload !== undefined && data.payload.password !== undefined) {
                        // setNewPassword(data.payload.password);
                        // setShowPasswordModal(true);
                        displayMessage('User has been registered');
                        history.push('/admin/user');
                    }
                });
            } else {
                data.userId = id;
                updateUserProfile(data).then((data) => {
                    handleSubmitBtn(false);
                    if (('payload' in data) && data.payload !== undefined && data.payload.user !== undefined) {
                        displayMessage('User updated');
                        // history.push('/admin/user/view/' + id);
                    }
                });
            }
        }
    }

    // const passwordModal = () => {
    //     setShowPasswordModal(false);
    //     history.push('/admin/user');
    // };

    return (
        <>
            <Row className={"justify-content-center"}>
                <Col lg="12">
                    <Card className="shadow border-0">
                        <CardHeader>
                            <Row>
                                <Col lg="12">
                                    <span className={"mb-0 h3"}>{id === undefined ? "Create" : "Update"} User</span>
                                    <Link
                                        to='/admin/user'
                                        className={"d-flex float-right btn btn-sm btn-primary align-items-center"}
                                    >
                                        <i className="fa fa-arrow-left mr-2"/>
                                        Back
                                    </Link>
                                </Col>
                            </Row>
                        </CardHeader>
                        <CardBody>
                            <UserCreateForm id={id} onSubmit={handleSubmit} submitBtnText={submitBtnText}
                                            submitBtnIcon={submitBtnIcon}/>
                        </CardBody>
                    </Card>
                    {/*<ShowPasswordModal handleClose={passwordModal} isModalOpen={showPasswordModal} password={newPassword} />*/}
                </Col>
            </Row>
        </>
    )
}

const mapStateToProps = () => {
    return {};
}

const mapDispatchToProps = {
    registerUser,
    updateUserProfile
}

export default connect(mapStateToProps, mapDispatchToProps)(Create);