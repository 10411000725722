import {FETCH, DELETE, VIEW, PUT, POST, SELECTED} from './team.types';
import collect from "collect.js";

const INITIAL_STATE = {
  teamList: [],
  teamSelect: [],
  team:{},
  selectedTeams:[]
};

const reducer = (state = INITIAL_STATE, action) => {
  let teamList = [];
  let teamSelect = [];
  let team = {};
  let selectedTeams = [];
  try {
    switch (action.type) {
      case FETCH:
        teamList = action.payload.teamList ?? [];
        teamList = teamList.map(val => {
          val.createdAt = window.moment(val.createdAt).format('YYYY-MM-DD h:mm A');
          val.kickOffDate = val.kickOffDate ? window.moment(val.kickOffDate).format('YYYY-MM-DD') : '';
          return val;
        });
        teamSelect = teamList.map((val) => {
          return {value: val.id, label: val.teamName}
        });
        return {
          ...state, teamList: teamList, teamSelect: teamSelect
        };
      case VIEW:
        team = action.payload.team ?? {};
        if ('kickOffDate' in team && team.kickOffDate.length > 0) {
          team.kickOffDate = window.moment(team.kickOffDate).format('YYYY-MM-DD');
        }
        return {
          ...state, team: team
        };
      case DELETE:
        return {
          ...state,
          teamList: state.teamList.filter(item => item.id !== action.payload.id),
          teamSelect: state.teamSelect.filter(item => item.value !== action.payload.id)
        };
      case POST:
      case PUT:
        teamList = state.teamList ?? [];
        if (action.payload.team && action.payload.team.id !== undefined) {
          team = action.payload.team ?? {};
          if ('kickOffDate' in team) {
            team.kickOffDate = window.moment(team.kickOffDate).format('YYYY-MM-DD');
          }
          if ('createdAt' in team) {
            team.createdAt = window.moment(team.createdAt).format('YYYY-MM-DD h:mm A');
          }
          teamList = [team].concat(teamList);
          teamList = collect(teamList).unique('id').all();
          teamSelect = teamList.map((val) => {
            return {value: val.id, label: val.teamName}
          });
        }
        return {...state, team: team, teamList: teamList, teamSelect: teamSelect}
      case SELECTED:
        if(action.payload && action.payload.length > 0){
          selectedTeams = action.payload;
        }
        return {...state, selectedTeams:selectedTeams}
      default:
        return state;
    }
  } catch (e) {
    // console.log({e});
  }
  return state;
};

export default reducer;