import React, {useEffect, useRef, useState} from "react";
import {connect} from "react-redux";
import {Button, ButtonGroup, Tooltip} from "reactstrap";
import {Link, useHistory} from "react-router-dom";
import copy from 'copy-to-clipboard';
import {checkAuth, showConfirmDialog} from "../../config/helper";
import {deleteTeam} from "../../redux/team/team.actions";

const loaderStatus = {
    deleteTeam: false
};

const TableAction = (props) => {
    const {deleteTeam} = props;
    const [editTooltipOpen, setEditTooltipOpen] = useState(false);
    const [copyTooltipOpen, setCopyTooltipOpen] = useState(false);
    const [deleteTooltipOpen, setDeleteTooltipOpen] = useState(false);
    const [loadingBtn, setLoadingBtn] = useState(loaderStatus);
    const mounted = useRef(false);
    const history = useHistory();

    useEffect(() => {
        checkAuth(history);
        mounted.current = true;
        return () => {
            mounted.current = false;
            setLoadingBtn(loaderStatus);
        };
    },[]);

    const editToggleTooltip = () => setEditTooltipOpen(!editTooltipOpen);
    const copyToggleTooltip = () => setCopyTooltipOpen(!copyTooltipOpen);
    const deleteToggleTooltip = () => setDeleteTooltipOpen(!deleteTooltipOpen);

    const handleDelete = (row, status) => {
        if (mounted.current) {
            if (status) {
                deleteTeam(row).then(() => {
                    updateLoaderData('deleteTeam', false);
                });
            }
        }
    }

    const updateLoaderData = (key, val) => {
        let loading = loadingBtn;
        if (key in loading) {
            loading[key] = val;
            setLoadingBtn(loading);
        }
    }

    const clickToCopy = (id) => {
        copy(id);
    }

    return (
        <>
            <div className='d-flex'>
                <ButtonGroup>
                    <Link
                        to={'/admin/team/update/'+props.row.id}
                        className={"btn btn-sm btn-primary"}
                        id={'editTooltip-' + props.row.id}
                    >
                        <i className="fa fa-edit"/>
                    </Link>
                    <Button color="success" size="sm" onClick={() => clickToCopy(props.row.id)}
                            id={'copyTooltip-' + props.row.id}>
                        <i className="fa fa-copy" />
                    </Button>
                    <Button
                        color="danger"
                        size="sm"
                        type="button"
                        onClick={() => showConfirmDialog(props.row, handleDelete, 'Are you sure!')}
                        id={'deleteTooltip-' + props.row.id}
                    >
                        {loadingBtn.deleteTeam ? <i className='fa fa-spinner fa-spin'/> :
                            <i className={'fa fa-trash'}/>}
                    </Button>
                </ButtonGroup>
                <Tooltip placement="top" isOpen={editTooltipOpen} autohide={false}
                         target={'editTooltip-' + props.row.id} toggle={editToggleTooltip}>
                    Edit
                </Tooltip>
                <Tooltip placement="top" isOpen={copyTooltipOpen} autohide={false}
                         target={'copyTooltip-' + props.row.id} toggle={copyToggleTooltip}>
                    Copy ID
                </Tooltip>
                <Tooltip placement="top" isOpen={deleteTooltipOpen} autohide={false}
                         target={'deleteTooltip-' + props.row.id} toggle={deleteToggleTooltip}>
                    Delete
                </Tooltip>
            </div>
        </>
    );
}

const mapStateToProps = () => {
    return {}
}

const mapDispatchToProps = {
    deleteTeam
}

export default connect(mapStateToProps, mapDispatchToProps)(TableAction);