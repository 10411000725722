import React, {useEffect, useRef, useState} from "react";
import {connect} from "react-redux";
import {useHistory} from "react-router-dom";
import {checkAuth} from "../../config/helper";
import {Card, Col, Nav, NavItem, Row, NavLink} from "reactstrap";
import Create from "./Create";
import {fetchMails, viewMailData} from "../../redux/mail/mail.actions";

const Index = (props) => {

    const {fetchMails, viewMailData} = props;
    const mounted = useRef(false);
    const history = useHistory();
    const [selectedTabId, setSelectedTabId] = useState(null);
    const [mailTabTitle, setMailTabTitle] = useState([]);
    const [mailTabContent, setMailTabContent] = useState('');

    useEffect(() => {
        checkAuth(history);
        mounted.current = true;
        fetchMails();
        return () => {
            mounted.current = false;
        };
    }, []);

    useEffect(() => {
        if (props.mailList.length > 0) {
            updateTabs();
            setSelectedTabId(props.mailList[0].id);
        }
    }, [props.mailList]);

    useEffect(() => {
        updateTabs();
        updateContent();
    }, [selectedTabId]);

    const updateContent = () => {
        let mailTemplate = props.mailList.filter((val) => val.id === selectedTabId);
        let content = '';
        if (mailTemplate.length > 0) {
            viewMailData(mailTemplate[0]);
            content = <Create mailTemplate={mailTemplate[0]}/>;
        }
        setMailTabContent(content);
    }

    const updateTabs = () => {
        let title = [];

        for (const mail of props.mailList) {
            title.push(
                <NavItem key={"title-" + mail.id} className="cursor-pointer">
                    <NavLink
                        className={selectedTabId === mail.id ? 'active' : ''}
                        onClick={() => setSelectedTabId(mail.id)}
                    >
                        {mail.name}
                    </NavLink>
                </NavItem>
            );
        }
        setMailTabTitle(title);
    }

    return (
        <>
            {/* Page content */}
            <Row>
                <Col sm={12}>
                    <Card body>
                        <Row>
                            <Col lg="3" md="4">
                                <Nav tabs pills card fill vertical className="m-0">
                                    {mailTabTitle}
                                </Nav>
                            </Col>
                            <Col lg={9} md={8}>
                                <Card body>
                                    {mailTabContent}
                                </Card>
                            </Col>
                        </Row>
                    </Card>
                </Col>
            </Row>
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        mailList: state.mail.mailList,
        mail: state.mail.mail,
        timestamp: state.mail.timestamp,
        viewTimestamp: state.mail.viewTimestamp
    }
};

const mapDispatchToProps = {
    fetchMails,
    viewMailData
};

export default connect(mapStateToProps, mapDispatchToProps)(Index);
