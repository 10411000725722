import React, {useEffect, useRef, useState} from "react";
import {connect} from "react-redux";
import {ButtonGroup, Tooltip} from "reactstrap";
import {Link} from "react-router-dom";

const TableAction = (props) => {
    const [detailTooltipOpen, setDetailTooltipOpen] = useState(false);
    const mounted = useRef(false);

    useEffect(() => {
        mounted.current = true;
        return () => {
            mounted.current = false;
        };
    },[]);

    const detailToggleTooltip = () => {
        setDetailTooltipOpen(!detailTooltipOpen);
    }

    return (
        <>
            <div className='d-flex'>
                <ButtonGroup>
                    <Link
                        to={'/coach/user/view/' + props.row.id}
                        className={"btn btn-sm btn-info align-items-center"}
                        id={'detailTooltip-' + props.row.id}
                    >
                        <i className="fa fa-eye"/>
                    </Link>
                </ButtonGroup>
                <Tooltip placement="top" isOpen={detailTooltipOpen} autohide={false}
                         target={'detailTooltip-' + props.row.id} toggle={detailToggleTooltip}>
                    Details
                </Tooltip>
            </div>
        </>
    );
}

const mapStateToProps = () => {
    return {}
}

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(TableAction);