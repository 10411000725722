import {connect} from "react-redux";
import {Card, CardBody, CardHeader, Col, Row} from "reactstrap";
import React, {useEffect, useRef, useState} from "react";
import {Link, useHistory, useParams} from "react-router-dom";
import AdvisorCreateForm from "./form/Create";
import {createAdvisor} from "../../redux/advisor/advisor.actions";
import {checkAuth} from "../../config/helper";

const Create = (props) => {
    const {createAdvisor} = props;
    const history = useHistory();
    const mounted = useRef(false);
    const [submitBtnText,setSubmitBtnText] = useState('Save');
    const [submitBtnIcon,setSubmitBtnIcon] = useState('');
    const {id} = useParams();

    useEffect(() => {
        mounted.current = true;
        checkAuth(history);
        return () => {
            mounted.current = false;
            handleSubmitBtn(false);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleSubmitBtn = (val) =>{
        if(val){
            setSubmitBtnText('Saving');
            setSubmitBtnIcon('fa fa-spinner fa-spin');
        }else{
            setSubmitBtnText('Save');
            setSubmitBtnIcon('');
        }
    }
    const handleSubmit = (values) => {
        if(mounted.current) {
            createAdvisor(values).then((data) => {
                handleSubmitBtn(false);
                if (!('payload' in data && data.payload === undefined)) {
                    history.push('/admin/coach');
                }
            });
        }
    }
    return (
        <>
            <Row className={"justify-content-center"}>
                <Col lg="6">
                    <Card className="shadow border-0">
                        <CardHeader>
                            <Row>
                                <Col lg="12">
                                    <span className={"mb-0 h3"}>{id === undefined ? "Create" : "Update"} Coach</span>
                                    <Link
                                        to='/admin/coach'
                                        className={"d-flex float-right btn btn-sm btn-primary align-items-center"}
                                    >
                                        <i className="fa fa-arrow-left mr-2"/>
                                        Back
                                    </Link>
                                </Col>
                            </Row>
                        </CardHeader>
                        <CardBody>
                            <AdvisorCreateForm id={id} onSubmit={handleSubmit} submitBtnText={submitBtnText} submitBtnIcon={submitBtnIcon} />
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </>
    )
}

const mapStateToProps = () => {
    return {
    };
}

const mapDispatchToProps = {
    createAdvisor
}

export default connect(mapStateToProps, mapDispatchToProps)(Create);