import {FETCH, DELETE, VIEW, PUT, POST} from './huddle.types';

const INITIAL_STATE = {
  huddleList: [],
  huddle:{}
};

const reducer = (state = INITIAL_STATE, action) => {
  let huddles = [];
  let huddle = {};
  try {
    switch (action.type) {
      case FETCH:
        huddles = action.payload.huddleList ?? [];
        huddles = huddles.map(val => {
          val.createdAt = window.moment(val.createdAt).format('YYYY-MM-DD h:mm A');
          return val;
        });
        return {
          ...state, huddleList: huddles
        };
      case VIEW:
        huddle = action.payload.huddle ?? {};
        if ('createdAt' in huddle && huddle.createdAt.length > 0) {
          huddle.createdAt = window.moment(huddle.createdAt).format('YYYY-MM-DD h:mm A');
        }
        return {
          ...state, huddle: huddle
        };
      case DELETE:
        return {
          ...state,
          huddleList: state.huddleList.filter(item => item.id !== action.payload.id)
        };
      case POST:
      case PUT:
        huddles = state.huddleList ?? [];
        if (action.payload.huddle && action.payload.huddle.id !== undefined) {
          huddle = action.payload.huddle ?? {};
          if ('createdAt' in huddle) {
            huddle.createdAt = window.moment(huddle.createdAt).format('YYYY-MM-DD h:mm A');
          }
          if(action.payload && action.payload.huddle) {
            let index = huddles.findIndex(val => val.id === huddle.id);
            if (index >= 0) {
              huddles[index] = huddle;
            } else {
              huddles.reverse().push(huddle);
              huddles.reverse();
            }
          }
        }
        return {...state, huddle: huddle, huddleList: huddles}
      default:
        return state;
    }
  } catch (e) {
    // console.log({e});
  }
  return state;
};

export default reducer;