import React, {useEffect, useMemo, useRef, useState} from "react";
import {
    ButtonGroup,
    Card,
    CardBody,
    CardHeader,
    Col,
    Row
} from "reactstrap";
import DataTable from "react-data-table-component";
import Loader from "../../components/Loader/Loader";
import {connect} from "react-redux";
import {Link, useHistory} from "react-router-dom";
import Filter from "../../components/filters/Filter";
import {fetchHuddles} from "../../redux/huddle/huddle.actions";
import {checkAuth, checkAuthWithCallback} from "../../config/helper";

const Index = (props) => {

    const {fetchHuddles} = props;
    const mounted = useRef(false);
    const history = useHistory();
    const [listLoader,setListLoader] = useState(true);
    const [filterText, setFilterText] = useState('');
    const [filteredItems,setFilteredItems] = useState([]);

    const subHeaderComponentMemo = useMemo(() => {
        return (
            <Filter onFilter={val => {
                setFilterText(val)
            }} filterText={filterText} />
        );
    }, [filterText]);

    const columns = [
        {
            name: 'Practice',
            selector: row => row.series && row.series.seriesName ? row.series.seriesName : 'N/A',
            sortable: true
        },
        {
            name: 'Heading',
            selector: row => row.heading ?? 'N/A',
            sortable: true
        },
        {
            name: 'Title',
            selector: row => row.title ?? 'N/A',
            sortable: true
        },
        {
            name: 'Hashtag',
            selector: row => row.hashtag ?? 'N/A',
            sortable: true
        },
        {
            name: 'Action',
            cell: (row) => {
                return (
                    <div className='d-flex'>
                        <ButtonGroup>
                            {/*<Button color="danger" outline size="sm" type="button" onClick={() => handleDelete(row)} disabled={true}><i*/}
                            {/*    className={"fa fa-trash"}/></Button>*/}
                            <Link
                                to={'/admin/team-hub/update/'+row.id}
                                className={"btn btn-sm btn-primary"}
                            >
                                <i className="fa fa-edit"/>
                            </Link>
                        </ButtonGroup>
                    </div>
                )
            },
            maxWidth: '150px'
        },
    ];

    useEffect(() => {
        checkAuth(history);
        mounted.current = true;
        setListLoader(true);
        checkAuthWithCallback(history,fetchHuddles);
        setListLoader(false);
        return () => {
            mounted.current = false;
            setFilteredItems([]);
        };
    }, []);

    useEffect(() => {
        if(mounted.current) {
            if (props.huddleList.length > 0) {
                let filteredData = props.huddleList.filter(
                    item => (item.heading && item.heading.toLowerCase().trim().includes(filterText.toLowerCase()))
                        || (item.title && item.title.toLowerCase().trim().includes(filterText.toLowerCase()))
                        || (item.hashtag && item.hashtag.toLowerCase().trim().includes(filterText.toLowerCase()))
                );
                setFilteredItems(filteredData);
            }
        }
    }, [props.huddleList,filterText,fetchHuddles]);

    return (
        <>
            {/* Page content */}
            <Row>
                <Col lg="12" md="12">
                    <Card className="shadow border-0">
                        <CardHeader>
                            <Row>
                                <Col lg="12" md="12">
                                    <span className={"mb-0 h3"}>Team Hub List</span>
                                    <Link
                                        to='/admin/team-hub/create'
                                        className={"d-flex float-right btn btn-sm btn-primary align-items-center"}
                                    >
                                        <i className="ni ni-fat-add mr-2"/>
                                        Add Team Hub
                                    </Link>
                                </Col>
                            </Row>
                        </CardHeader>
                        <CardBody>
                            <DataTable
                                columns={columns}
                                progressPending={listLoader}
                                progressComponent={<Loader/>}
                                className={"react-dataTable"}
                                data={filteredItems}
                                pagination
                                responsive
                                subHeaderWrap
                                subHeader
                                subHeaderComponent={subHeaderComponentMemo}
                            />
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        huddleList: state.huddle.huddleList,
    }
};

const mapDispatchToProps = {
    fetchHuddles
};

export default connect(mapStateToProps, mapDispatchToProps)(Index);
