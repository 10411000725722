import {connect} from "react-redux";
import React, {useEffect, useRef, useState} from "react";
import {
    Button,
    Col, FormGroup, Input, Label,
    Modal,
    ModalBody,
    Row
} from "reactstrap";
import LoaderBtn from "../../../components/Loader/LoaderBtn";
import {checkAuth} from "../../../config/helper";
import {useHistory} from "react-router-dom";

const StrengthScopeWheelModal = (props) => {
        const {handleSubmit, handleClose} = props;
        const [selectedFile, setSelectedFile] = useState();
        const [isSelected, setIsSelected] = useState(false);
        const [error, setError] = useState({});
        const [show, setShow] = useState(false);
        const [loadingBtn,setLoadingBtn] = useState(false);
        const mounted = useRef(false);
        const history = useHistory();

        useEffect(() => {
            checkAuth(history);
            mounted.current = true;
            return () => {
                mounted.current = false;
                setShow(false);
            };
        }, []);

        useEffect(() => {
            if (props.isModalOpen !== undefined && props.isModalOpen !== show) {
                setShow(props.isModalOpen);
            }
        }, [props.isModalOpen]);

        useEffect(() => {
            if(props.loadingBtn !== loadingBtn){
                setLoadingBtn(props.loadingBtn);
            }
        },[props.loadingBtn]);

        const changeHandler = (event) => {
            setSelectedFile(event.target.files[0]);
            setIsSelected(true);
        };

        const saveImage = () => {
            if (isSelected) {
                setLoadingBtn(true);
                handleSubmit(selectedFile);
            } else {
                error.wheelFile = "Team is required";
                setError(error);
            }
        };

        return (
            <>
                <Modal isOpen={show} centered={true} backdrop={true}>
                    <ModalBody>
                        <Row>
                            <Col sm="12">
                                <FormGroup>
                                    <Label>Strength Scope Wheel Image <span className="text-danger">*</span></Label>
                                    <Input className="form-control" type="file" accept="image/*" onChange={changeHandler}/>
                                    {error.wheelFile && <span className="text-danger">{error.wheelFile}</span>}
                                </FormGroup>
                            </Col>
                            <Col sm="12">
                                <div className="float-right">
                                    <Button color="primary" disabled={loadingBtn} onClick={saveImage}>{loadingBtn ?
                                        <LoaderBtn text="Submitting"/> : 'Submit'}</Button>
                                    <Button color="secondary" disabled={loadingBtn} onClick={() => handleClose(false)}>Close</Button>
                                </div>
                            </Col>
                        </Row>
                    </ModalBody>
                </Modal>
            </>
        );
    }
;

const mapStateToProps = () => {
    return {}
}

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(StrengthScopeWheelModal);