import React, {useEffect, useRef, useState} from "react";
import {
    Card,
    CardBody,
    CardHeader,
    Col,
    Row
} from "reactstrap";
import DataTable from "react-data-table-component";
import Loader from "../../components/Loader/Loader";
import {connect} from "react-redux"
import {Link, useHistory} from "react-router-dom";
import {
    checkAuth,
    checkAuthWithCallback,
    getStaticDataValue,
} from "../../config/helper";
import {paginationSchema} from "../../config/schema";
import {fetchAdhocNotifications} from "../../redux/notification/notification.actions";

const AdhocIndex = (props) => {
    const mounted = useRef(false);
    const history = useHistory();
    const {fetchAdhocNotifications} = props;
    const [tableInfo, setTableInfo] = useState(paginationSchema());
    const [listLoader, setListLoader] = useState(true);
    const [timestamp, setTimestamp] = useState('');

    useEffect(() => {
        mounted.current = true;
        checkAuth(history);
        getNotificationData();
        return () => {
            mounted.current = false;
        };
    }, []);

    const columns = [
        {
            name: 'Message',
            selector: row => row.message,
            fieldName: 'message',
            maxWidth: "40%"
        },
        {
            name: "Link",
            fieldName: 'link',
            cell: row => {
                if(row.link) {
                    return (
                        <a target="_blank" href={row.link} rel="noreferrer">{row.link}</a>
                    );
                }else{
                    return "N/A";
                }
            },
            maxWidth: "25%"
        },
        {
            name: 'Audience',
            fieldName: 'audienceType',
            selector: row => getStaticDataValue(row.audienceType, 'audienceType'),
            maxWidth: "10%"
        },
        {
            name: 'Device Reached',
            selector: row => row.deviceCount ?? 0,
            fieldName: 'deviceCount',
            maxWidth: "10%"
        },
        {
            name: 'Created At',
            selector: row => row.createdAt || 'N/A',
            fieldName: 'createdAt',
            maxWidth: "15%"
        }
    ];

    useEffect(() => {
        if (props.timestamp !== timestamp) {
            setListLoader(false);
            setTimestamp(props.timestamp);
        }
    }, [props.timestamp]);

    // useEffect(() => {
    //     if (mounted.current) {
    //         if (props.teamSelect === undefined || props.teamSelect.length === 0) {
    //             checkAuthWithCallback(history, fetchTeams);
    //         }
    //     }
    // }, [props.teamSelect]);

    useEffect(() => {
        if (props.paginationResult !== undefined) {
            let page = tableInfo;
            page.pagination = {...page.pagination, ...props.paginationResult}
            setTableInfo(page)
        }

    }, [props.paginationResult]);

    const handlePageChange = pageNum => {
        let page = tableInfo;
        page.pagination.pageNumber = pageNum;
        setListLoader(true);
        setTableInfo(page);
        getNotificationData();
    };

    const handlePerRowsChange = async (newPerPage, currentPage) => {
        let page = tableInfo;
        page.pagination.pageSize = newPerPage;
        page.pagination.pageNumber = currentPage;
        setListLoader(true);
        setTableInfo(page);
        getNotificationData();
    };

    const getNotificationData = () => {
        if (mounted.current) {
            setListLoader(true);
            checkAuthWithCallback(history, fetchAdhocNotifications, tableInfo);
        }
    }

    const handleSort = async (column, sortDirection) => {
        let page = tableInfo;
        page.sort = {};
        page.sort[column.fieldName] = sortDirection === 'asc' ? 1 : -1;
        setTableInfo(page);
        getNotificationData();
    };

    return (
        <>
            {/* Page content */}
            <Row>
                <Col lg="12" md="12">
                    <Card className="shadow border-0">
                        <CardHeader>
                            <Row>
                                <Col lg="12" md="12">
                                    <span className={"mb-0 h3"}>Important Info List</span>

                                    <div className={"d-flex flex-wrap float-left float-md-right mt-2 mt-md-0"}>
                                        <Link
                                            to='/admin/important-info/create-important-info'
                                            className={"d-flex float-right btn btn-sm btn-primary align-items-center mb-1 ml-0"}
                                        >
                                            <i className="ni ni-send mr-2"/>
                                            Send Important Info
                                        </Link>
                                    </div>
                                </Col>
                            </Row>
                        </CardHeader>
                        <CardBody>
                            <Row>
                                <Col sm="12">
                                    <DataTable
                                        columns={columns}
                                        progressPending={listLoader}
                                        progressComponent={<Loader/>}
                                        className={"react-dataTable"}
                                        data={props.adhocNotificationList || []}
                                        pagination
                                        responsive
                                        paginationServer
                                        paginationTotalRows={tableInfo.pagination.totalRecords}
                                        onChangeRowsPerPage={handlePerRowsChange}
                                        onChangePage={handlePageChange}
                                        onSort={handleSort}
                                        sortServer
                                        subHeaderWrap
                                    />
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </>
    )
};

const mapStateToProps = (state) => {
    return {
        adhocNotificationList: state.notification.adhocNotificationList,
        // userList: state.user.userList,
        paginationResult: state.notification.adhocPaginationResult,
        // teamSelect: state.team.teamSelect,
        timestamp: state.notification.timestamp ?? ''
    }
}

const mapDispatchToProps = {
    // fetchUsers,
    // fetchTeams,
    fetchAdhocNotifications
}

export default connect(mapStateToProps, mapDispatchToProps)(AdhocIndex);