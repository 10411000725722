import React, {useEffect, useRef, useState} from "react";
import {useHistory} from "react-router-dom";
import {checkAuth} from "../../../config/helper";
import {connect} from "react-redux";
import {Col, Modal, ModalBody, ModalHeader, Row, Tooltip} from "reactstrap";
import {fetchPostById} from "../../../redux/we-screen/weScreen.actions";
import Loader from "../../../components/Loader/Loader";
import DataTable from "react-data-table-component";

const ShowCommentsModal = (props) => {
    const {fetchPostById} = props;
    const mounted = useRef(false);
    const [listLoader, setListLoader] = useState(true);
    const history = useHistory();
    const [show, setShow] = useState(false);
    const [updateTooltipOpen, setUpdateTooltipOpen] = useState(false);
    const [tableList,setTableList] = useState([]);

    useEffect(() => {
        checkAuth(history);
        mounted.current = true;
        return () => {
            mounted.current = false;
            setListLoader(true);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if(props.rowPost.feedData.postId === props.post.id) {
            setTableList(props.postComments);
            setListLoader(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.timestamp])

    const columns = [
        {
            name: 'Profile',
            fieldName: 'profilePicId',
            center: true,
            cell: (row) => {
                let url = "https://ui-avatars.com/api/?name=" + (row.postedBy?.userName);
                return (<div className={"d-table-cell"}>
                    <img
                        alt="Card cap"
                        src={row.postedBy?.profilePic || url}
                        height={'50px'}
                    />
                </div>);
            },
            style: () => ({minHeight: '80px', maxWidth: '50px'}),
            maxWidth: '8%'
        },
        {
            name: 'Name',
            selector: row => row.postedBy?.userName || 'N/A',
            sortable: true,
            maxWidth: '22%'
        },
        {
            name: 'Comment Message',
            selector: row => row.commentMessage,
            sortable: true
        }
    ]

    const updateToggleTooltip = () => setUpdateTooltipOpen(!updateTooltipOpen);
    const handleClose = () => setShow(false);
    const handleShow = () => {
        setShow(true);
        fetchPostById(props.rowPost.feedData.postId);
    };

    return (
        <>
            <div className="cursor-pointer" onClick={handleShow} id={'updateTooltip-' + props.rowPost.id}>
                <i className="fa fa-comments mr-1"/>{props.rowPost.feedData.commentCount}
            </div>
            <Tooltip placement="top" isOpen={updateTooltipOpen} autohide={false}
                     target={'updateTooltip-' + props.rowPost.id} toggle={updateToggleTooltip}>
                Tap to see comments.
            </Tooltip>
            <Modal centered={true} isOpen={show} backdrop={true} size="lg">
                <ModalHeader tag="h3" toggle={handleClose}>{props.modalTitleText}</ModalHeader>
                <ModalBody className="pt-0">
                    <Row>
                        <Col lg={12}>
                            <DataTable
                                columns={columns}
                                progressPending={listLoader}
                                progressComponent={<Loader/>}
                                className={"react-dataTable"}
                                data={tableList}
                                pagination
                                responsive
                            />
                        </Col>
                    </Row>
                </ModalBody>
            </Modal>
        </>
    );

}

const mapStateToProps = (state) => {
    return {
        postComments: state.weScreen.postComments,
        post: state.weScreen.post,
        timestamp: state.weScreen.commentsTimestamp
    }
}

const mapDispatchToProps = {
    fetchPostById
}

export default connect(mapStateToProps, mapDispatchToProps)(ShowCommentsModal);