import React, {useEffect, useMemo, useRef, useState} from "react";
import {
    Card,
    CardBody,
    CardHeader,
    Col,
    Row
} from "reactstrap";
import DataTable from "react-data-table-component";
import Loader from "../../components/Loader/Loader";
import {connect} from "react-redux";
import {Link, useHistory} from "react-router-dom";
import {deleteTeam, fetchTeams} from "../../redux/team/team.actions";
import Filter from "../../components/filters/Filter";
import {checkAuth, checkAuthWithCallback} from "../../config/helper";
import TableAction from "./TableAction";

const Index = (props) => {

    const {fetchTeams} = props;
    const mounted = useRef(false);
    const history = useHistory();
    const [listLoader,setListLoader] = useState(true);
    const [filterText, setFilterText] = useState('');
    const [filteredItems,setFilteredItems] = useState([]);

    const subHeaderComponentMemo = useMemo(() => {
        return (
            <Filter onFilter={val => {
                setFilterText(val)
            }} filterText={filterText} />
        );
    }, [filterText]);

    const columns = [
        {
            name: 'Name',
            selector: row => row.teamName,
            sortable: true
        },
        {
            name: 'Organization',
            selector: row => row.groupData.groupName,
            sortable: true
        },
        {
            name: 'Team Kickoff Date',
            selector: row => row.kickOffDate,
            sortable: true
        },
        {
            name: 'Display Time (UTC)',
            selector: row => row.config ? row.config.day + ' ' + row.config.time : '-',
            sortable: true
        },
        // {
        //     name: 'Created at',
        //     selector: row => row.createdAt,
        //     sortable: true
        // },
        {
            name: 'Action',
            cell: (row) => {
                return (
                    <TableAction row={row} />
                )
            },
            maxWidth: '150px'
        },
    ];

    useEffect(() => {
        document.title = 'Team List - ' + process.env.REACT_APP_NAME;
        mounted.current = true;
        checkAuth(history);
        setListLoader(true);
        checkAuthWithCallback(history,fetchTeams);
        setListLoader(false);

        return () => {
            mounted.current = false;
            setFilteredItems([]);
        };
    }, []);

    useEffect(() => {
        if(mounted.current) {
            if (props.teamList.length > 0) {
                let filteredData = props.teamList.filter(
                    item => (item.teamName && item.teamName.toLowerCase().includes(filterText.toLowerCase()))
                        || (item.groupData && item.groupData.groupName && item.groupData.groupName.toLowerCase().includes(filterText.toLowerCase()))
                    // || (item.kickoff_date && item.kickoff_date.toLowerCase().includes(filterText.toLowerCase()))
                    // || (item.createdAt && item.createdAt.toLowerCase().includes(filterText.toLowerCase()))
                );
                setFilteredItems(filteredData);
            }
        }
    }, [props.teamList,filterText,fetchTeams]);

    return (
        <>
            {/* Page content */}
            <Row>
                <Col lg="12" md="12">
                    <Card className="shadow border-0">
                        <CardHeader>
                            <Row>
                                <Col lg="12" md="12">
                                    <span className={"mb-0 h3"}>Team list</span>
                                    <Link
                                        to='/admin/team/create'
                                        className={"d-flex float-right btn btn-sm btn-primary align-items-center"}
                                    >
                                        <i className="ni ni-fat-add mr-2"/>
                                        Add Team
                                    </Link>
                                </Col>
                            </Row>
                        </CardHeader>
                        <CardBody>
                            <DataTable
                                columns={columns}
                                progressPending={listLoader}
                                progressComponent={<Loader/>}
                                className={"react-dataTable"}
                                data={filteredItems}
                                pagination
                                responsive
                                subHeaderWrap
                                subHeader
                                subHeaderComponent={subHeaderComponentMemo}
                            />
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </>
    )
};

const mapStateToProps = (state) => {
    return {
        teamList: state.team.teamList,
    }
}

const mapDispatchToProps = {
    fetchTeams,
    deleteTeam
}

export default connect(mapStateToProps, mapDispatchToProps)(Index);
