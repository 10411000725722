import {connect} from "react-redux";
import React, {useEffect, useRef, useState} from "react";
import {
    Button,
    Col, FormGroup, Input, Label,
    Modal,
    ModalBody,
    Row
} from "reactstrap";
import LoaderBtn from "../../../components/Loader/LoaderBtn";
import {statusList} from "../../../config/staticData";
import {checkAuth} from "../../../config/helper";
import {useHistory} from "react-router-dom";

const ChangeStatusModal = (props) => {
    const {handleSubmit, handleClose} = props;
    const [userStatus, setUserStatus] = useState('');
    const [loadingBtn, setLoadingBtn] = useState(false);
    const [error, setError] = useState({});
    const [show, setShow] = useState(false);
    const mounted = useRef(false);
    const history = useHistory();

    useEffect(() => {
        checkAuth(history);
        mounted.current = true;
        return () => {
            mounted.current = false;
            setShow(false);
            setUserStatus('');
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (props.userStatus !== '' && props.userStatus !== undefined && props.userStatus !== userStatus) {
            setUserStatus(props.userStatus);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.userStatus]);

    useEffect(() => {
        if (props.isModalOpen !== undefined && props.isModalOpen !== show) {
            setShow(props.isModalOpen);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.isModalOpen]);

    useEffect(() => {
        if (props.loadingBtn !== loadingBtn) {
            setLoadingBtn(props.loadingBtn);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.loadingBtn]);

    const saveStatus = () => {
        if (userStatus !== '') {
            setLoadingBtn(true);
            handleSubmit(userStatus);
        } else {
            error.userStatus = "Status is required";
            setError(error);
        }
    };

    const handleChange = (val) => {
        let value = val.target.value ?? '';
        if (value !== '' && 'userStatus' in error) {
            let e = error;
            delete e.userStatus;
            setError(e);
        } else {
            setUserStatus(value);
        }
    }

    let optionList = [];
    for (const status of statusList) {
        optionList.push(<option value={status.value} key={status.value}>{status.label}</option>);
    }

    return (
        <>
            <Modal isOpen={show} centered={true} backdrop={true}>
                <ModalBody>
                    <Row>
                        <Col sm="12">
                            <FormGroup>
                                <Label>Status <span className="text-danger">*</span></Label>
                                <Input placeholder={'Status'} type={'select'} value={userStatus || ''}
                                       invalid={error.userStatus}
                                       onChange={handleChange}>
                                    {optionList}
                                </Input>
                                {error.userStatus && <span className="text-danger">{error.userStatus}</span>}
                            </FormGroup>
                        </Col>
                        <Col sm="12">
                            <div className="float-right">
                                <Button color="primary" disabled={loadingBtn} onClick={saveStatus}>{loadingBtn ?
                                    <LoaderBtn text="Submitting"/> : 'Submit'}</Button>
                                <Button color="secondary" disabled={loadingBtn}
                                        onClick={() => handleClose(false)}>Close</Button>
                            </div>
                        </Col>
                    </Row>
                </ModalBody>
            </Modal>
        </>
    );
};

const mapStateToProps = () => {
    return {}
}

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(ChangeStatusModal);