import React, {useEffect, useMemo, useRef, useState} from "react";
import {connect} from "react-redux"
import {
    Badge, Button,
    Card,
    CardBody, CardHeader,
    Col,
    FormGroup,
    Label,
    Row
} from "reactstrap";
import Select from "react-select";
import {selectedTeamData} from "../../redux/team/team.actions";
import {downloadMembers, fetchMembers} from "../../redux/member/member.actions";
import {useHistory} from "react-router-dom";
import DataTable from "react-data-table-component";
import Loader from "../../components/Loader/Loader";
import Filter from "../../components/filters/Filter";
import {
    checkAuth,
    checkAuthWithCallback,
    checkRole,
    displayErrorMessage,
    exportCSVFile,
    reactSelectStyle
} from "../../config/helper";
import TableAction from "./TableAction";
import {fetchGroup, fetchGroups} from "../../redux/group/group.actions";
import NotificationSection from "./NotificationSection";
import WeScreenSection from "./WeScreenSection";


const TeamSection = (props) => {

    const {fetchGroup, fetchGroups, fetchMembers, downloadMembers} = props;
    const selectTeamInputRef = useRef();
    const selectGroupInputRef = useRef();
    const mounted = useRef(false);
    const filterRef = useRef();
    const history = useHistory();
    const [listLoader, setListLoader] = useState(true);
    const [filterText, setFilterText] = useState('');
    const [filteredItems, setFilteredItems] = useState([]);
    const [items,setItems] = useState([]);
    const [timestamp, setTimestamp] = useState('');
    const [selectedGroup, setSelectedGroup] = useState('');
    const [selectedTeam, setSelectedTeam] = useState([]);
    const [teamOptions, setTeamOptions] = useState([]);
    const [groupOptions, setGroupOptions] = useState([]);
    const [tableInfo, setTableInfo] = useState({
        teamIdList: []
    });
    const [exportLoader, setExportLoader] = useState(false);

    const columns = [
        {
            name: 'Name',
            selector: row => row.userName || 'N/A',
            sortable: true
        },
        {
            name: 'Email',
            selector: row => row.emailId || 'N/A',
            sortable: true
        },
        {
            name: 'Current Practice',
            selector: row => row.currentSeries && row.currentSeries.seriesName ? row.currentSeries.seriesName : 'N/A'
        },
        {
            name: 'Profile Complete',
            cell: (row) => {
                if (row.isProfileComplete) {
                    return (<Badge color="success">
                        <i className="fa fa-check"/>
                    </Badge>);
                } else {
                    return (<Badge color="danger">
                        <i className="fa fa-minus"/>
                    </Badge>);
                }
            },
            sortable: true,
            sortFunction: (rowA, rowB) => customSort('isProfileComplete', rowA, rowB)
        },
        {
            name: 'Assessments Complete',
            cell: (row) => {
                if (row.isAssessmentsComplete) {
                    return (<Badge color="success">
                        <i className="fa fa-check"/>
                    </Badge>);
                } else {
                    return (<Badge color="danger">
                        <i className="fa fa-minus"/>
                    </Badge>);
                }
            },
            sortable: true,
            sortFunction: (rowA, rowB) => customSort('isAssessmentsComplete', rowA, rowB)
        },
        {
            name: 'Energy Wheel Complete',
            cell: (row) => {
                if (row.isEnergyWheelComplete) {
                    return (<Badge color="success">
                        <i className="fa fa-check"/>
                    </Badge>);
                } else {
                    return (<Badge color="danger">
                        <i className="fa fa-minus"/>
                    </Badge>);
                }
            },
            sortable: true,
            sortFunction: (rowA, rowB) => customSort('isEnergyWheelComplete', rowA, rowB)
        },
        {
            name: 'In Warm-Up',
            cell: (row) => {
                if (row.isInWarnUp) {
                    return (<Badge color="success">
                        <i className="fa fa-check"/>
                    </Badge>);
                } else {
                    return (<Badge color="danger">
                        <i className="fa fa-minus"/>
                    </Badge>);
                }
            },
            sortable: true,
            sortFunction: (rowA, rowB) => customSort('isInWarnUp', rowA, rowB)
        },
        {
            name: 'Action',
            center: true,
            cell: (row) => {
                return (
                    <TableAction row={row} onDataUpdated={getMemberData}/>
                )
            }
        }
    ];

    useEffect(() => {
        checkAuth(history);
        checkRole('advisor', history);
        mounted.current = true;
        checkAuthWithCallback(history, fetchGroups);
        return () => {
            mounted.current = false;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() =>{
        setItems(props.memberList ?? []);
    },[props.memberList]);

    useEffect(() => {
        if (mounted.current) {
            if (props.groupList.length > 0) {
                setGroupOptions(props.groupList);
                setSelectedGroup(props.groupList[0])
            }
        }
    }, [props.groupList]);

    useEffect(() => {
        if(selectedGroup && selectedGroup.value !== props.group.id){
            selectTeamInputRef.current.clearValue();
            fetchGroup(selectedGroup.value);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[selectedGroup]);

    useEffect(() => {
        let filterData = tableInfo;
        filterData.teamIdList = selectedTeam.map(info => info.value);
        setTableInfo(filterData);
        getMemberData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[selectedTeam]);

    useEffect(() => {
        if (mounted.current) {
            setTeamOptions(props.teamList);
            setSelectedTeam(props.teamList);
        }
    }, [props.teamList]);

    useEffect(() => {
        if (props.paginationResult !== undefined) {
            let page = tableInfo;
            page.pagination = {...page.pagination, ...props.paginationResult}
            setTableInfo(page)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.paginationResult]);

    useEffect(() => {
        if (timestamp === '') {
            getMemberData();
        }
        if (props.timestamp !== timestamp) {
            setListLoader(false);
            setTimestamp(props.timestamp);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.timestamp]);

    useEffect(() => {
        let filterData = items.filter((item) => {
            return (item.userName && item.userName.toLowerCase().includes(filterText.toLowerCase())) ||
                (item.emailId && item.emailId.toLowerCase().includes(filterText.toLowerCase()))
        });
        setFilteredItems(filterData);
        // let page = tableInfo;
        // if(filterText.length >= 3) {
        //     page.filter = {
        //         emailId: filterText,
        //         fullName: filterText
        //     }
        // }else{
        //     page.filter = {};
        // }
        // setTableInfo(page);
        // getMemberData();
    }, [filterText,items]);

    const customSort = (field, rowA, rowB) => {
        if (rowA[field] > rowB[field]) {
            return 1;
        }
        if (rowB[field] > rowA[field]) {
            return -1;
        }
        return 0;
    }

    const exportMembers = () => {
        if (mounted.current) {
            if (tableInfo.teamIdList.length > 0) {
                setExportLoader(true);
                let page = tableInfo;
                page.downloadCsv = true;
                downloadMembers(page).then(data => {
                    if (data.payload) {
                        exportCSVFile(data.payload, 'members');
                    }
                    setExportLoader(false);
                });
            } else {
                displayErrorMessage("Please select team first");
            }
        }
    }

    const getMemberData = () => {
        if (mounted.current) {
            setListLoader(true);
            if (tableInfo.teamIdList.length > 0) {
                checkAuthWithCallback(history, fetchMembers, tableInfo);
            } else {
                setFilteredItems([]);
                setListLoader(false);
            }
        }
    }

    const changeGroup = (refData) => {
        if (refData !== null) {
            if(refData.value !== props.group.id) {
                filterRef.current.clearFilter();
                selectTeamInputRef.current.clearValue();
                setSelectedGroup(refData.value);
                setItems([]);
            }
        } else {
            filterRef.current.clearFilter();
            selectTeamInputRef.current.clearValue();
            setItems([]);
        }
    }

    const changeTeam = (refData) => {
        setSelectedTeam(refData);
    }

    const subHeaderComponentMemo = useMemo(() => {
        return (
            <>
                <Filter ref={filterRef} id="search" onFilter={val => {
                    setFilterText(val)
                }} filterText={filterText}/>
                <Button className="ml-2" size="md" color="primary" disabled={exportLoader}
                        onClick={exportMembers}>{exportLoader ?
                    <i className="fa fa-spinner fa-spin"/> : <i className="fa fa-file-export"/>}</Button>
            </>
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filterText, exportLoader]);

    return (
        <>
            <Row>
                <Col lg="12" md="12">
                    <Card>
                        <CardBody>
                            <Row className="mb-4 mb-md-0">
                                <Col md="4">
                                    <FormGroup>
                                        <Label for="group">
                                            Organization
                                        </Label>
                                        <Select
                                            id="group"
                                            name="groupIdList"
                                            placeholder="Select Organization"
                                            options={groupOptions}
                                            classNamePrefix="select"
                                            isClearable
                                            onChange={changeGroup}
                                            styles={reactSelectStyle}
                                            ref={selectGroupInputRef}
                                            value={selectedGroup}
                                        >
                                        </Select>
                                    </FormGroup>
                                </Col>
                                <Col md="4">
                                    <FormGroup>
                                        <Label for="team">
                                            Team
                                        </Label>
                                        <Select
                                            id="team"
                                            name="teamIdList"
                                            placeholder="Select Team"
                                            isMulti={true}
                                            options={teamOptions}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            isClearable
                                            onChange={changeTeam}
                                            styles={reactSelectStyle}
                                            ref={selectTeamInputRef}
                                            value={selectedTeam}
                                        >
                                        </Select>
                                    </FormGroup>
                                </Col>
                            </Row>

                            {/*Team Card*/}
                            {/*<Row className="dashboard-cards">*/}
                            {/*    <Col xl="3" md="6">*/}
                            {/*        <Card outline color="primary" className="mb-3">*/}
                            {/*            <CardBody>*/}
                            {/*                <h3>WEEKLY CHECKIN</h3>*/}
                            {/*                <CardTitle>N/A</CardTitle>*/}
                            {/*                <CardText>Total number of practice check ins by week</CardText>*/}
                            {/*            </CardBody>*/}
                            {/*        </Card>*/}
                            {/*    </Col>*/}
                            {/*    <Col xl="3" md="6">*/}
                            {/*        <Card outline color="primary" className="mb-3">*/}
                            {/*            <CardBody>*/}
                            {/*                <h3>ALL-TIME CHECKIN</h3>*/}
                            {/*                <CardTitle>N/A</CardTitle>*/}
                            {/*                <CardText>Total cumulative number of practice check ins</CardText>*/}
                            {/*            </CardBody>*/}
                            {/*        </Card>*/}
                            {/*    </Col>*/}
                            {/*    <Col xl="3" md="6">*/}
                            {/*        <Card outline color="primary" className="mb-3">*/}
                            {/*            <CardBody>*/}
                            {/*                <h3>KICKOFF DATE</h3>*/}
                            {/*                <CardTitle>N/A</CardTitle>*/}
                            {/*                <CardText>Kickoff date</CardText>*/}
                            {/*            </CardBody>*/}
                            {/*        </Card>*/}
                            {/*    </Col>*/}
                            {/*    <Col xl="3" md="6">*/}
                            {/*        <Card outline color="primary" className="mb-3">*/}
                            {/*            <CardBody>*/}
                            {/*                <h3>DAYS IN THE APP</h3>*/}
                            {/*                <CardTitle>N/A</CardTitle>*/}
                            {/*                <CardText>The number of days since Kickoff</CardText>*/}
                            {/*            </CardBody>*/}
                            {/*        </Card>*/}
                            {/*    </Col>*/}
                            {/*</Row>*/}
                        </CardBody>
                    </Card>
                </Col>
                {/*Team Members */}
                <Col lg="12" md="12" className="mt-4">
                    <Card>
                        <CardHeader>
                            <Row>
                                <Col lg="12" md="12">
                                    <span className={"mb-0 h3"}>Member list</span>
                                </Col>
                            </Row>
                        </CardHeader>
                        <CardBody>
                            <DataTable
                                columns={columns}
                                progressPending={listLoader}
                                progressComponent={<Loader/>}
                                className={"react-dataTable"}
                                data={filteredItems}
                                pagination
                                responsive
                                subHeaderWrap
                                subHeader
                                subHeaderComponent={subHeaderComponentMemo}
                            />
                        </CardBody>
                    </Card>
                </Col>
                {/*We Screen Section*/}
                <Col lg={12} md={12} className="mt-4">
                    <WeScreenSection selectedTeam={selectedTeam} />
                </Col>
                {/*Notifications */}
                {/*<Col lg="12" md="12" className="mt-4">*/}
                {/*    <NotificationSection />*/}
                {/*</Col>*/}
            </Row>
        </>
    )
};

const mapStateToProps = (state) => {
    return {
        selectedTeams: state.team.selectedTeams ?? [],
        memberList: state.member.memberList ?? [],
        timestamp: state.member.timestamp ?? '',
        teamList: state.group.teamSelect ?? [],
        groupList: state.group.groupSelect ?? [],
        group: state.group.group ?? null
    }
}

const mapDispatchToProps = {
    selectedTeamData,
    downloadMembers,
    fetchMembers,
    fetchGroups,
    fetchGroup
}

export default connect(mapStateToProps, mapDispatchToProps)(TeamSection);
