import React, {useEffect, useRef} from "react";
import {
    Card,
    CardBody,
    Col,
    Row
} from "reactstrap";
import {connect} from "react-redux";
import {useHistory} from "react-router-dom";
import {checkAuth} from "../../config/helper";

const Index = (props) => {

    const mounted = useRef(false);
    const history = useHistory();

    useEffect(() => {
        checkAuth(history);
        mounted.current = true;
        return () => {
            mounted.current = false;
        };
    }, []);

    return (
        <>
            {/* Page content */}
            <Row>
                <Col lg="12" md="12">
                    <Card className="shadow border-0">
                        <CardBody>
                            <Row>
                                <Col sm={12} className={"min-vh-60 d-flex justify-content-center"}>
                                    <span className={'h1 mt-auto mb-auto'}>Coming Soon...</span>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </>
    );
};

const mapStateToProps = (state) => {
    return {
    }
};

const mapDispatchToProps = {
};

export default connect(mapStateToProps, mapDispatchToProps)(Index);
