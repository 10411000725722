import {Button, Modal, ModalBody, ModalHeader, Tooltip} from "reactstrap";
import React, {useEffect, useRef, useState} from "react";
import {createGroup, fetchGroup, updateGroup} from "../../redux/group/group.actions";
import {connect} from "react-redux";
import GroupCreateForm from "./form/Create";
import {checkAuth} from "../../config/helper";
import {useHistory} from "react-router-dom";

const CreateModal = (props) => {
    const {updateGroup, createGroup} = props;
    const mounted = useRef(false);
    const history = useHistory();
    const [show, setShow] = useState(false);
    const [submitBtnText, setSubmitBtnText] = useState('Save');
    const [submitBtnIcon, setSubmitBtnIcon] = useState('');
    const [updateTooltipOpen, setUpdateTooltipOpen] = useState(false);
    const [tooltipText, setTooltipText] = useState('');
    const [formData, setFormData] = useState({
        groupName: '',
        impactSurveyLink: '',
        id: 0
    });

    useEffect(() => {
        checkAuth(history);
        mounted.current = true;
        setUpdateTooltipOpen(false);
        return () => {
            mounted.current = false;
            setFormData({
                groupName: '',
                impactSurveyLink: '',
                id: 0
            });
            setTooltipText('');
            setUpdateTooltipOpen(false);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (props.formData && props.formData.id.length > 0 && formData.id !== props.formData.id) {
            setFormData({
                id: props.formData.id,
                impactSurveyLink: props.formData.impactSurveyLink,
                groupName: props.formData.groupName
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.formData]);

    useEffect(() => {
        if (props.tooltipText) {
            setTooltipText(props.tooltipText);
        }
    }, [props.tooltipText]);

    const updateToggleTooltip = () => setUpdateTooltipOpen(!updateTooltipOpen);

    const handleSubmitBtn = (val) => {
        if (val) {
            setSubmitBtnText('Saving');
            setSubmitBtnIcon('fa fa-spinner fa-spin');
        } else {
            setSubmitBtnText('Save');
            setSubmitBtnIcon('');
        }
    }
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const handleSubmit = (values) => {
        if (mounted.current) {
            let postData = {...formData, ...values};
            handleSubmitBtn(true);
            if (postData.id.length > 0) {
                updateGroup(postData).then((data) => {
                    if (!('payload' in data && data.payload === undefined)) {
                        // props.updateGroupList();
                        handleClose();
                    }
                    handleSubmitBtn(false);
                });
            } else {
                createGroup(postData).then((data) => {
                    if (!('payload' in data && data.payload === undefined)) {
                        // props.updateGroupList();
                        handleClose();
                    }
                    handleSubmitBtn(false);
                });
            }
        }
    }

    return (
        <>
            <Button color="primary" size="sm" className={"d-flex align-items-center float-right"} onClick={handleShow}
                    id={'updateModalTooltip-' + props.id + '-' + formData.id}>
                {props.modalBtnIcon && <i className={props.modalBtnIcon}/>}{props.modalBtnText}
            </Button>
            {tooltipText ?
            <Tooltip placement="top" isOpen={updateTooltipOpen} autohide={true}
                     target={'updateModalTooltip-' + props.id + '-' + formData.id} toggle={updateToggleTooltip}>
                {tooltipText}
            </Tooltip>
                : ''}
            <Modal centered={true} isOpen={show} backdrop={true}>
                <ModalHeader tag={'h3'}>{props.modalTitleText}</ModalHeader>
                <ModalBody>
                    <GroupCreateForm id={props.id} onSubmit={handleSubmit} formData={formData} showCancleBtn={true}
                                     onCancel={handleClose} submitBtnText={submitBtnText}
                                     submitBtnIcon={submitBtnIcon}/>
                </ModalBody>
            </Modal>
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        // groupInfo: state.group.group,
    }
}

const mapDispatchToProps = {
    fetchGroup,
    updateGroup,
    createGroup
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateModal);