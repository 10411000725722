import {SIGNOUT} from "./auth.types";

export const signOutUser = (data) => {
    return function (dispatch) {
        return dispatch({
            type: SIGNOUT,
            payload: data
        });
    };
};
