import {FETCH, PUT, VIEW} from './mail.types';
import {now} from "moment";

const INITIAL_STATE = {
    mailList: [],
    mail: {},
    timestamp: null,
    viewTimestamp: null
};

const reducer = (state = INITIAL_STATE, action) => {
    let mailList = [];
    let mail = {};
    try {
        switch (action.type) {
            case FETCH:
                mailList = action.payload.templateList ?? [];
                return {...state, mailList: mailList, timestamp: now()};
            case PUT:
                mail = action.payload;
                mailList = state.mailList ?? [];
                let index = mailList.findIndex(val => val.id === mail.id);
                if (index >= 0) {
                    mailList[index] = mail;
                } else {
                    mailList.unshift(mail);
                }
                return {...state, mailList: mailList, viewTimestamp: now()}
            case VIEW:
                if (action.payload) {
                    mail = action.payload;
                }
                return {...state, mail: mail, viewTimestamp: now()};
            default:
                return state;
        }
    } catch (e) {
        // console.log({e});
    }
    return state;
};

export default reducer;