import React, {useEffect, useRef, useState} from 'react'
import {connect} from "react-redux"
import {Field, reduxForm} from 'redux-form'
import {Button, Col, Row, Table} from "reactstrap";
import {renderSelectField, renderTextField} from "../../../config/formElement";
import Loader from "../../../components/Loader/Loader";
import {checkAuth, checkAuthWithCallback} from "../../../config/helper";
import {fetchSeriesList} from "../../../redux/series/series.actions";
import {fetchHuddle} from "../../../redux/huddle/huddle.actions";
import {getVideoInfo} from "youtube-video-exists";
import {useHistory} from "react-router-dom";
import {updateHuddleSchema} from "../../../config/schema";

const validate = (values) => {
    let errors = {};
    if (!values.seriesId) {
        errors.seriesId = 'Practice selection is required.';
    }
    if (!values.heading) {
        errors.heading = 'Heading is required.';
    }
    if (!values.title) {
        errors.title = 'Title is required.';
    }
    if (!values.practiceTitle) {
        errors.practiceTitle = 'Practice Title is required.';
    }
    if (!values.practiceSubTitle) {
        errors.practiceSubTitle = 'Practice Subtitle is required.';
    }
    if (!values.hashtag) {
        errors.hashtag = 'Hashtag is required.';
    }
    if (!values.videoUrl) {
        errors.videoUrl = 'Video URL is required.';
    } else if (values.videoUrl.trim().length !== 11) {
        errors.videoUrl = 'Video URL must be 11 letters long.';
    }
    return errors;
}

const warn = () => {
    return {};
}

let HuddleCreateForm = (props) => {
    const {handleSubmit, fetchSeriesList,fetchHuddle} = props;
    const mounted = useRef(false);
    const history = useHistory();
    const [fields, setFields] = useState(updateHuddleSchema({}));
    const [isSubmitting,setIsSubmitting] = useState({text:'Save',icon: ''});
    const [isLoading,setIsLoading] = useState(true);
    const [seriesList, setSeriesList] = useState([{label: "Select Practice", value: ""}]);
    const [youtubeInfo,setYoutubeInfo] = useState({});
    const [youtubeLoading,setYoutubeLoading] = useState(false);
    const [watchId,setWatchId] = useState('');

    useEffect(() => {
        checkAuth(history);
        mounted.current = true;
        return () => {
            mounted.current = false;
            setFields(updateHuddleSchema({}));
            setIsSubmitting({text:'Save',icon: ''});
        };
    }, []);

    useEffect(() => {
        if(props.submitBtnText !== isSubmitting.text) {
            setIsSubmitting({text:props.submitBtnText,icon: props.submitBtnIcon});
        }else{
            if(isLoading){
                setIsSubmitting({text:'Wait',icon: ''});
            }else{
                setIsSubmitting({text:'Save',icon: ''});
            }
        }
    }, [props.submitBtnText,props.submitBtnIcon,isLoading]);

    useEffect(() => {
        if(mounted.current) {
            if (props.seriesList === undefined || props.seriesList.length === 0) {
                checkAuthWithCallback(history,fetchSeriesList);
            }
            if (props.seriesList !== undefined && props.seriesList.length > 0) {
                setSeriesList([{label: "Select Practice", value: ""}].concat(props.seriesList));
            }
        }
    }, [props.seriesList, fetchSeriesList]);

    useEffect(() => {
        console.log("test");
        if(mounted.current) {
            if (props.id !== undefined) {
                if (props.huddle === {} || props.huddle.id !== props.id) {
                    checkAuthWithCallback(history,fetchHuddle,props.id);
                }
                if (props.huddle !== {} && props.huddle.id === props.id) {
                    setFields(props.huddle);
                    checkYoutubeVideo(props.huddle.videoUrl ? props.huddle.videoUrl.trim() : '');
                    setIsLoading(false);
                }
            } else {
                setIsLoading(false);
            }
        }
        // eslint-disable-next-line
    },[props.id,props.huddle]);

    const handleSelect = (key, val) => {
        let value = val.target.value;
        let data = fields;
        data[key] = value;
        setFields(data);
    }

    const checkYoutubeVideo = (id) => {
        if(id !== '' && id.length === 11) {
            if(id !== watchId) {
                setYoutubeLoading(true);
                getVideoInfo(id).then(value => {
                    value.watchId = id;
                    if (value.existing && value.validId) {
                        setYoutubeInfo(value);
                    } else {
                        setYoutubeInfo({});
                    }
                    setWatchId(id);
                    setYoutubeLoading(false);
                });
            }
        }else {
            setWatchId(id);
            setYoutubeInfo({});
        }
    }

    const validatePostData = (val) => {
        val.preventDefault();
        if(youtubeInfo && youtubeInfo.validId){
            handleSubmit(val);
        }
    }

    return (
        <>
            {
                isLoading ?
                    <div className="text-center"><Loader /></div>
                    :
                    <form onSubmit={(values) => validatePostData(values)}>
                        <Row>
                            <Col xl="6">
                                <Field
                                    name="seriesId"
                                    type="select"
                                    component={renderSelectField}
                                    label="Practice"
                                    options={seriesList ?? []}
                                    hasDefaultValue={fields.seriesId}
                                    isRequired={true}
                                    onChange={(value) => handleSelect('seriesId', value)}
                                />
                            </Col>
                            <Col xl="6">
                                <Field
                                    name="heading"
                                    type="text"
                                    label={"Heading"}
                                    hasDefaultValue={fields.heading}
                                    component={renderTextField}
                                    isRequired={true}
                                />
                            </Col>
                            <Col xl="6">
                                <Field
                                    name="title"
                                    type="text"
                                    label={"Title"}
                                    hasDefaultValue={fields.title}
                                    component={renderTextField}
                                    isRequired={true}
                                />
                            </Col>
                            <Col xl="6">
                                <Field
                                    name="hashtag"
                                    type="text"
                                    label={"Hashtag"}
                                    hasDefaultValue={fields.hashtag}
                                    component={renderTextField}
                                    isRequired={true}
                                />
                            </Col>
                            <Col xl="6">
                                <Field
                                    name="practiceTitle"
                                    type="text"
                                    label={"Practice Title"}
                                    hasDefaultValue={fields.practiceTitle}
                                    component={renderTextField}
                                    isRequired={true}
                                />
                            </Col>
                            <Col xl="6">
                                <Field
                                    name="practiceSubTitle"
                                    type="textarea"
                                    label={"Practice Subtitle"}
                                    hasDefaultValue={fields.practiceSubTitle}
                                    component={renderTextField}
                                    isRequired={true}
                                />
                            </Col>
                            <Col xl="6">
                                <Field
                                    name="videoUrl"
                                    type="text"
                                    label={"Video URL"}
                                    hasDefaultValue={fields.videoUrl}
                                    component={renderTextField}
                                    isRequired={true}
                                    onChange={element => checkYoutubeVideo(element.target.value.trim())}
                                />
                            </Col>
                            <Col xl="6">
                                <div className="mt-2">
                                {!youtubeLoading ? (watchId.length === 11 ? (Object.keys(youtubeInfo).length > 0 ?
                                    <Table striped responsive>
                                        <tbody>
                                        <tr>
                                            <th>Video Title</th>
                                            <td> : </td>
                                            <td>{youtubeInfo.info.title || ""}</td>
                                        </tr>
                                        <tr>
                                            <th>Video Author</th>
                                            <td> : </td>
                                            <td>{youtubeInfo.info.author && youtubeInfo.info.author.name ? youtubeInfo.info.author.name : ''}</td>
                                        </tr>
                                        </tbody>
                                    </Table> : <p className="text-warning">Youtube video not exist. Please enter correct id.</p>) : '')
                                    : <Loader />}
                                </div>
                            </Col>
                            <Col lg="12">
                                <Button color="primary" type="submit" disabled={isSubmitting.text !== 'Save'}>{isSubmitting.icon !== '' ? <i className={isSubmitting.icon} /> : ''} {isSubmitting.text}</Button>
                            </Col>
                        </Row>
                    </form>
            }
        </>
    );
}

const mapStateToProps = (state,ownProps) => {
    let huddle = updateHuddleSchema({});
        if (ownProps.id !== undefined && state.huddle !== undefined && state.huddle.huddle !== undefined && state.huddle.huddle.id !== undefined && ownProps.id === state.huddle.huddle.id) {
            huddle = updateHuddleSchema(state.huddle.huddle);
        }
    return {
        initialValues: huddle,
        huddle: huddle,
        seriesList: state.series.seriesSelect
    };
};

const mapDispatchToProps = {
    fetchSeriesList,
    fetchHuddle
};

HuddleCreateForm = reduxForm({
    form: 'huddleCreateForm',
    enableReinitialize:true,
    validate,
    warn
})(HuddleCreateForm);

export default connect(mapStateToProps,mapDispatchToProps)(HuddleCreateForm)