export const FETCH = 'USER_FETCH';
export const DELETE = 'USER_DELETE';
export const VIEW = 'USER_VIEW';
export const POST = 'USER_POST';
export const PUT = 'USER_PUT';
export const POST_USER = 'USER_POST_1';
export const FETCH_S3_SIGNED = "USER_FETCH_S3_SIGNED";
export const POST_S3_FILE = "USER_POST_S3_FILE";
export const TEAM_FETCH = "MEMBER_FETCH";
export const DOWNLOAD = "USER_DOWNLOAD";
export const POST_BULK = 'USER_BULK_POST';
export const RESEND_PASSWORD = 'USER_RESEND_PASSWORD';