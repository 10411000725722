import React, {useEffect, useRef, useState} from 'react'
import {connect} from "react-redux"
import {Field, reduxForm} from 'redux-form'
import {Button, Col, FormGroup, Label, Row} from "reactstrap";
import {checkAuth, displayErrorMessage} from "../../../config/helper";
import {useHistory} from "react-router-dom";
import {renderTextField} from "../../../config/formElement";
import ReactQuill from "react-quill";
import 'react-quill/dist/quill.snow.css';
import {mailTemplateSchema} from "../../../config/schema";

const validate = (values) => {
    let errors = {};
    // if (!values.subject) {
    //     errors.subject = 'Subject is required.';
    // }
    // if (!values.message) {
    //     errors.message = 'Message is required.';
    // }
    return errors;
}

const warn = () => {
    return {};
}

let MailTemplateCreateForm = (props) => {
    const {saveMail} = props;
    const mounted = useRef(false);
    const history = useHistory();
    const [isSubmitting, setIsSubmitting] = useState({text: 'Save', icon: ''});
    const [isLoading, setIsLoading] = useState(false);
    const [fields, setFields] = useState({});

    const quillModules = {
        toolbar: [
            ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
            ['blockquote', 'code-block'],

            [{'header': 1}, {'header': 2}],               // custom button values
            [{'list': 'ordered'}, {'list': 'bullet'}],
            [{'script': 'sub'}, {'script': 'super'}],      // superscript/subscript
            [{'indent': '-1'}, {'indent': '+1'}],          // outdent/indent
            [{'direction': 'rtl'}],                         // text direction

            [{'size': ['small', false, 'large', 'huge']}],  // custom dropdown
            [{'header': [1, 2, 3, 4, 5, 6, false]}],

            [{'color': []}, {'background': []}],          // dropdown with defaults from theme
            [{'font': []}],
            [{'align': []}],

            ['clean']
        ],
    };

    useEffect(() => {
        checkAuth(history);
        mounted.current = true;
        return () => {
            mounted.current = false;
            setIsSubmitting({text: 'Save', icon: ''});
        };
    }, []);

    useEffect(() => {
        if (props.mailTemplate) {
            setFields(props.mailTemplate);
        }
    }, [props.timestamp, props.mailTemplate]);

    useEffect(() => {
        if (props.submitBtnText !== isSubmitting.text) {
            setIsSubmitting({text: props.submitBtnText, icon: props.submitBtnIcon});
        } else {
            if (isLoading) {
                setIsSubmitting({text: 'Wait', icon: ''});
            } else {
                setIsSubmitting({text: 'Save', icon: ''});
            }
        }
    }, [props.submitBtnText, props.submitBtnIcon, isLoading]);

    const updateSubmit = (values) => {
        values.preventDefault();
        let message = fields.message.replace(/<(.|\n)*?>/g, '');
        if (fields.subject && message) {
            saveMail({...props.mail,...{subject:fields.subject,message:fields.message}});
        } else {
            displayErrorMessage('Please fill all fields.');
        }
    }

    return (
        <form onSubmit={(values) => updateSubmit(values)}>
            <Row>
                <Col xl="12">
                    <Field
                        name="subject"
                        type="text"
                        label="Subject"
                        component={renderTextField}
                        onChange={(val) => setFields({...fields, subject: val.target.value})}
                    />
                </Col>
                <Col xl={12}>
                    <FormGroup>
                        <Label>Message <span className="text-danger">*</span></Label>
                        <ReactQuill theme="snow" value={fields.message} name="message"
                                    modules={quillModules} onChange={(val) => setFields({...fields, message: val})}/>
                    </FormGroup>
                </Col>
                <Col xl={"12"} className="mb-3">
                    <b>Note: Please don't remove {"{####}"}. It will be replaced by user's data</b>
                </Col>
                <Col lg="12">
                    <Button color="primary" type="submit"
                            disabled={isSubmitting.text !== 'Save'}>{isSubmitting.icon !== '' ?
                        <i className={isSubmitting.icon}/> : ''} {isSubmitting.text}</Button>
                </Col>
            </Row>
        </form>
    );
}

const mapStateToProps = (state, ownProps) => {
    return {
        initialValues: ownProps.mailTemplate ? mailTemplateSchema(ownProps.mailTemplate) : {},
        mail: state.mail.mail ? mailTemplateSchema(state.mail.mail) : {},
        timestamp: state.mail.viewTimestamp
    };
};

const mapDispatchToProps = {};

MailTemplateCreateForm = reduxForm({
    form: 'mailTemplateCreateForm',
    enableReinitialize: true,
    validate,
    warn
})(MailTemplateCreateForm);

export default connect(mapStateToProps, mapDispatchToProps)(MailTemplateCreateForm)