import {connect} from "react-redux";
import {Card, CardBody, CardHeader, Col, Row, Tooltip} from "reactstrap";
import React, {useEffect, useMemo, useRef, useState} from "react";
import {Link, useHistory} from "react-router-dom";
import {checkAuth, checkRole, displayMessage, stringRestyle} from "../../config/helper";
import UserUploadForm from "./form/Upload";
import {registerBulkUser} from "../../redux/user/user.actions";
import CsvDownload from 'react-json-to-csv'
import DataTable from "react-data-table-component";

const Upload = (props) => {
    const {registerBulkUser} = props;
    const history = useHistory();
    const [submitBtnText, setSubmitBtnText] = useState('Save');
    const [submitBtnIcon, setSubmitBtnIcon] = useState('');
    const mounted = useRef(false);
    const [exportUserTooltip, setExportUserTooltip] = useState(false);

    useEffect(() => {
        mounted.current = true;
        checkAuth(history);
        checkRole('admin', history);
        return () => {
            mounted.current = false;
        };
    }, []);

    const updateExportUserTooltip = () => setExportUserTooltip(!exportUserTooltip);

    const handleSubmitBtn = (val) => {
        if (val) {
            setSubmitBtnText('Saving');
            setSubmitBtnIcon('fa fa-spinner fa-spin');
        } else {
            setSubmitBtnText('Save');
            setSubmitBtnIcon('');
        }
    }

    const handleSubmit = (values) => {
        if (mounted.current) {
            handleSubmitBtn(true);
            let formData = new FormData();
            if (values.userCsv && values.userCsv[0]) {
                formData.append("userCsv", values.userCsv[0]);
            }
            registerBulkUser(formData).then((data) => {
                handleSubmitBtn(false);
                if(data !== undefined) {
                    if (('payload' in data) && data.payload !== undefined && data.payload.data !== undefined) {
                        displayMessage('Users Registered.');
                        history.push('/admin/user');
                    }
                }
            });
        }
    }

    const columns = [
        {
            name: 'reason',
            selector: row => row.reason || 'N/A',
            sortable: true,
            wrap: true
        }, {
            name: 'first Name',
            selector: row => row.firstName,
            sortable: true
        }, {
            name: 'last Name',
            selector: row => row.lastName,
            sortable: true
        }, {
            name: 'email Id',
            selector: row => row.emailId,
            sortable: true
        }, {
            name: 'team Id',
            selector: row => row.teamId,
            sortable: true
        }, {
            name: 'secondary Teams',
            selector: row => row.secondaryTeams,
            sortable: true
        }, {
            name: 'role',
            selector: row => stringRestyle(row.userRole, 'firstUpperCase'),
            sortable: true
        }, {
            name: 'Team Kickoff Date',
            selector: row => row.teamKickOffDate,
            sortable: true
        }, {
            name: 'Individual Kickoff Date',
            selector: row => row.individualKickOffDate,
            sortable: true
        },
    ];

    return (
        <>
            <Row className={"justify-content-center"}>
                <Col lg="12">
                    <Card className="shadow border-0">
                        <CardHeader>
                            <Row>
                                <Col lg="12">
                                    <span className={"mb-0 h3"}>Upload Users</span>
                                    <Link
                                        to='/admin/user'
                                        className={"d-flex float-right btn btn-sm btn-primary align-items-center"}
                                    >
                                        <i className="fa fa-arrow-left mr-2"/>
                                        Back
                                    </Link>
                                </Col>
                            </Row>
                        </CardHeader>
                        <CardBody>
                            <UserUploadForm onSubmit={handleSubmit} submitBtnText={submitBtnText}
                                            submitBtnIcon={submitBtnIcon}/>
                            <Row className={"mt-3"}>
                                <Col sm={12}>
                                    <div className={"text-warning"}>
                                        <p className={"font-weight-bold mb-0"}>Notice : please follow below
                                            instructions.</p>
                                        <ul>
                                            <li>Please use sample csv for data upload.</li>
                                            <li>Don't change the sequence in sheet.</li>
                                            <li>firstName, lastName, emailId, teamId, userRole are mandatory.</li>
                                            <li>individualKickOffDate, secondaryTeams are Optional.</li>
                                            <li>individualKickOffDate must be YYYY-MM-DD Format.</li>

                                            <li>Please copy teamId from team list using <span
                                                className="font-weight-bold">copy ID</span> action.
                                            </li>
                                        </ul>
                                    </div>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                    {
                        props.failUpload.length > 0 ?
                            <Card className="shadow border-0 mt-3">
                                <CardHeader>
                                    <Row>
                                        <Col lg="12">
                                            <span className={"mb-0 h3"}>Fail To Upload Users</span>
                                            <CsvDownload className="btn btn-sm btn-primary float-right" filename="UserList.csv"
                                                         data={props.failUpload} id={"exportUserTooltip"}>
                                                <i className="fa fa-file-export"/> Export Users
                                            </CsvDownload>
                                            <Tooltip placement="top" isOpen={exportUserTooltip} autohide={false}
                                                     target={'exportUserTooltip'} toggle={updateExportUserTooltip}>
                                                Export Users
                                            </Tooltip>
                                        </Col>
                                    </Row>
                                </CardHeader>
                                <CardBody>
                                    <Row>
                                        <Col sm={12}>
                                            <DataTable
                                                columns={columns}
                                                className={"react-dataTable"}
                                                data={props.failUpload}
                                                pagination
                                                responsive
                                            />
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                            : ''
                    }
                </Col>
            </Row>
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        failUpload: state.user.failUpload
    };
}

const mapDispatchToProps = {
    registerBulkUser
}

export default connect(mapStateToProps, mapDispatchToProps)(Upload);