import React, {useEffect, useRef, useState} from "react";
import {Card, CardBody, CardHeader, Col, Row} from "reactstrap";
import DataTable from "react-data-table-component";
import Loader from "../../../components/Loader/Loader";
import {connect} from "react-redux";
import {useHistory} from "react-router-dom";
import {checkAuth, checkAuthWithCallback, reactSelectStyle} from "../../../config/helper";
import {paginationSchema} from "../../../config/schema";
import {fetchWeScreen} from "../../../redux/we-screen/weScreen.actions";
import {weFeedType} from "../../../config/staticData";
import Select from "react-select";
import ShowCommentsModal from "../../advisor-dashboard/Modal/ShowCommentsModal";

const WeScreen = (props) => {
    const mounted = useRef(false);
    const history = useHistory();
    const {fetchWeScreen} = props;
    const [tableInfo, setTableInfo] = useState(paginationSchema());
    const [listLoader, setListLoader] = useState(true);
    const [timestamp, setTimestamp] = useState('');
    const [selectedWeFeedType, setSelectedWeFeedType] = useState(weFeedType[0]);
    const selectRoleInputRef = useRef();

    useEffect(() => {
        mounted.current = true;
        checkAuth(history);
        tableInfo.userId = props.userData.id;
        tableInfo.filter.weFeedType = weFeedType[0].value;
        getWeScreenItems();
        return () => {
            mounted.current = false;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const weColumns = [
        {
            name: "User",
            fieldName: 'link',
            selector: row => row?.feedData?.postedBy?.userName,
            width: "10%",
            hide: true,
        },
        {
            name: "Post title",
            fieldName: 'link',
            selector: row => row?.feedData?.seriesItem?.view?.title,
            width: "20%"
        },
        {
            name: "Message",
            fieldName: 'link',
            selector: row => row?.feedData.message,
            width: "40%"
        },
        {
            name: 'Comment',
            cell: (row) => {
                return (<ShowCommentsModal rowPost={row} modalTitleText={row?.feedData?.seriesItem?.view?.title} />);
            },
            center: true,
            fieldName: 'createdAt',
            width: "10%"
        },
        {
            name: 'Created At',
            selector: row => row.createdAt ? window.moment(row.createdAt).format('YYYY-MM-DD h:mm A') : 'N/A',
            fieldName: 'createdAt',
            width: "15%"
        }
    ];

    const EncColumns = [
        {
            name: "From",
            fieldName: 'link',
            selector: row => row.feedData.fromUser.userName,
            width: "15%",
            hide: true,
        },
        {
            name: "To",
            fieldName: 'link',
            selector: row => row.feedData.toUser.userName,
            width: "15%",
            hide: true,
        },
        {
            name: "Message",
            fieldName: 'link',
            selector: row => row.feedData.message,
            width: "55%"
        },
        {
            name: 'At',
            selector: row => row.createdAt ? window.moment(row.createdAt).format('YYYY-MM-DD h:mm A') : 'N/A',
            fieldName: 'createdAt',
            width: "15%"
        }
    ];

    useEffect(() => {
        if (props.timestamp !== timestamp) {
            setListLoader(false);
            setTimestamp(props.timestamp);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.timestamp]);

    useEffect(() => {
        if (props.paginationResult !== undefined) {
            let page = tableInfo;
            page.pagination = {...page.pagination, ...props.paginationResult}
            setTableInfo(page)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.paginationResult]);

    const handlePageChange = pageNum => {
        let page = tableInfo;
        page.pagination.pageNumber = pageNum;
        setListLoader(true);
        setTableInfo(page);
        getWeScreenItems();
    };

    const handlePerRowsChange = async (newPerPage, currentPage) => {
        let page = tableInfo;
        page.pagination.pageSize = newPerPage;
        page.pagination.pageNumber = currentPage;
        setListLoader(true);
        setTableInfo(page);
        getWeScreenItems();
    };

    const getWeScreenItems = () => {
        if (mounted.current) {
            setListLoader(true);
            checkAuthWithCallback(history, fetchWeScreen, tableInfo);
        }
    }

    const handleSort = async (column, sortDirection) => {
        let page = tableInfo;
        page.sort = {};
        page.sort[column.fieldName] = sortDirection === 'asc' ? 1 : -1;
        setTableInfo(page);
        getWeScreenItems();
    };

    const handleSelectFilter = (key, val) => {
        let filterData = tableInfo;
        setSelectedWeFeedType(val);
        if (val && val.value !== '') {
            filterData.filter[key] = val.value;
            setTableInfo(filterData);
            getWeScreenItems();
        } else {
            delete filterData.filter[key];
            setTableInfo(filterData);
            getWeScreenItems();
        }
    };

    return (
        <>
            {/* Page content */}
            <Row>
                <Col lg="12" md="12">
                    <Card className="shadow border-0">
                        <CardHeader>
                            <Row>
                                <Col lg="12" md="12">
                                    <span className={"mb-0 h3"}>We Screen</span>
                                    <div className={"float-right weFeedType-wrapper"}>
                                        <Select
                                            id={"weFeedType"}
                                            ref={selectRoleInputRef}
                                            className="basic-single"
                                            classNamePrefix="select"
                                            isSearchable={true}
                                            options={weFeedType}
                                            name="weFeedType"
                                            onChange={(val) => handleSelectFilter('weFeedType', val ?? {})}
                                            styles={reactSelectStyle}
                                            value={selectedWeFeedType}
                                        />
                                    </div>
                                </Col>
                            </Row>
                        </CardHeader>
                        <CardBody>
                            <Row>
                                <Col lg="12">
                                    <DataTable
                                        columns={selectedWeFeedType.value === weFeedType[0].value ? weColumns : EncColumns}
                                        progressPending={listLoader}
                                        progressComponent={<Loader/>}
                                        className={"react-dataTable"}
                                        data={props.weScreenList || []}
                                        pagination
                                        responsive
                                        paginationServer
                                        paginationTotalRows={tableInfo.pagination.totalRecords}
                                        onChangeRowsPerPage={handlePerRowsChange}
                                        onChangePage={handlePageChange}
                                        onSort={handleSort}
                                        sortServer
                                        subHeaderWrap
                                    />
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </>
    )
};

const mapStateToProps = (state) => {
    return {
        weScreenList: state.weScreen.weScreenList,
        paginationResult: state.weScreen.paginationResult,
        timestamp: state.weScreen.timestamp ?? ''
    }
}

const mapDispatchToProps = {
    fetchWeScreen
}

export default connect(mapStateToProps, mapDispatchToProps)(WeScreen);