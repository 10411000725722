import {FETCH, DELETE, VIEW, PUT, POST} from './advisor.types';
import collect from "collect.js";
import {now} from "moment";

const INITIAL_STATE = {
  advisorList: [],
  advisorSelect: [],
  advisor:{}
};

const reducer = (state = INITIAL_STATE, action) => {
  let advisorList = [];
  let advisor = {};
  try {
    switch (action.type) {
      case FETCH:
        let data = action.payload.advisorMappingList.map((val) => {
          val.createdAt = window.moment(val.createdAt).format('YYYY-MM-DD h:mm A');
          val.kickOffDate = val.kickOffDate ? window.moment(val.kickOffDate).format('YYYY-MM-DD') : '';
          return val;
        });
        return {
          ...state, advisorList: data, paginationResult: action.payload.paginationResult, timestamp: now()
        };
      case VIEW:
        advisor = action.payload.advisor ?? {};
        return {
          ...state, advisor: advisor
        };
      case DELETE:
        return {
          ...state,
          advisorList: state.advisorList.filter(item => item.id !== action.payload.id)
        };
      case POST:
      case PUT:
        advisorList = state.advisorList ?? [];
        if (action.payload.advisor && action.payload.advisor.id !== undefined) {
          advisor = action.payload.advisor ?? {};
          if ('createdAt' in advisor) {
            advisor.createdAt = window.moment(advisor.createdAt).format('YYYY-MM-DD h:mm A');
          }
          advisorList = [advisor].concat(advisorList);
          advisorList = collect(advisorList).unique('id').all();
        }
        return {...state, advisor: advisor, advisorList: advisorList}
      default:
        return state;
    }
  } catch (e) {
    // console.log({e});
  }
  return state;
};

export default reducer;