import React, {useEffect, useMemo, useRef, useState} from "react";
import {
    Badge,
    Card,
    CardBody,
    CardHeader,
    Col, FormGroup, Label,
    Row
} from "reactstrap";
import DataTable from "react-data-table-component";
import Loader from "../../components/Loader/Loader";
import {connect} from "react-redux"
import {fetchMembers} from "../../redux/member/member.actions";
import Filter from "../../components/filters/Filter";
import {checkAuth, checkAuthWithCallback, reactSelectStyle} from "../../config/helper";
import {useHistory} from "react-router-dom";
import Select from "react-select";
import {fetchGroup, fetchGroups} from "../../redux/group/group.actions";

const Index = (props) => {
    const mounted = useRef(false);
    const selectTeamInputRef = useRef();
    const filterRef = useRef();
    const history = useHistory();
    const {fetchMembers, fetchGroups, fetchGroup} = props;
    const [tableInfo, setTableInfo] = useState({
        teamIdList: [],
        filter:{}
    });
    const [items,setItems] = useState([]);
    const [filterText, setFilterText] = useState('');
    const [filteredItems, setFilteredItems] = useState([]);
    const [timestamp, setTimestamp] = useState('');
    const [selectedGroup, setSelectedGroup] = useState(null);
    const [selectedTeams, setSelectedTeams] = useState([]);
    const [teamOptions, setTeamOptions] = useState([]);
    const [groupOptions, setGroupOptions] = useState([]);
    const [listLoader, setListLoader] = useState(false);

    const columns = [
        {
            name: 'Name',
            selector: row => row.userName || 'N/A',
            sortable: true
        },
        {
            name: 'Email',
            selector: row => row.emailId || 'N/A',
            sortable: true
        },
        {
            name: 'Current Practice',
            selector: row => row.currentSeries && row.currentSeries.seriesName ? row.currentSeries.seriesName : 'N/A'
        },
        {
            name: 'Welcome Complete',
            cell: (row) => {
                if (row.isWelcomeComplete) {
                    return (<Badge color="success">
                        <i className="fa fa-check"/>
                    </Badge>);
                } else {
                    return (<Badge color="danger">
                        <i className="fa fa-minus"/>
                    </Badge>);
                }
            },
            sortable: true,
            sortFunction: (rowA, rowB) => customSort('isWelcomeComplete', rowA, rowB)
        },
        {
            name: 'Profile Complete',
            cell: (row) => {
                if (row.isProfileComplete) {
                    return (<Badge color="success">
                        <i className="fa fa-check"/>
                    </Badge>);
                } else {
                    return (<Badge color="danger">
                        <i className="fa fa-minus"/>
                    </Badge>);
                }
            },
            sortable: true,
            sortFunction: (rowA, rowB) => customSort('isProfileComplete', rowA, rowB)
        },
        {
            name: 'Assessments Complete',
            cell: (row) => {
                if (row.isAssessmentsComplete) {
                    return (<Badge color="success">
                        <i className="fa fa-check"/>
                    </Badge>);
                } else {
                    return (<Badge color="danger">
                        <i className="fa fa-minus"/>
                    </Badge>);
                }
            },
            sortable: true,
            sortFunction: (rowA, rowB) => customSort('isAssessmentsComplete', rowA, rowB)
        },
        {
            name: 'Energy Wheel Complete',
            cell: (row) => {
                if (row.isEnergyWheelComplete) {
                    return (<Badge color="success">
                        <i className="fa fa-check"/>
                    </Badge>);
                } else {
                    return (<Badge color="danger">
                        <i className="fa fa-minus"/>
                    </Badge>);
                }
            },
            sortable: true,
            sortFunction: (rowA, rowB) => customSort('isEnergyWheelComplete', rowA, rowB)
        },
        {
            name: 'In Warm-Up',
            cell: (row) => {
                if (row.isInWarnUp) {
                    return (<Badge color="success">
                        <i className="fa fa-check"/>
                    </Badge>);
                } else {
                    return (<Badge color="danger">
                        <i className="fa fa-minus"/>
                    </Badge>);
                }
            },
            sortable: true,
            sortFunction: (rowA, rowB) => customSort('isInWarnUp', rowA, rowB)
        },
        {
            name: 'Weekly Practice',
            selector: row => row.weeklyPractiseCount || 0,
            sortable: true,
            sortFunction: (rowA, rowB) => customSort('weeklyPractiseCount', rowA, rowB)
        },
        {
            name: 'All Time Practice',
            selector: row => row.totalPractiseCount || 0,
            sortable: true,
            sortFunction: (rowA, rowB) => customSort('totalPractiseCount', rowA, rowB)
        },
    ];

    useEffect(() => {
        checkAuth(history);
        mounted.current = true;
        checkAuthWithCallback(history, fetchGroups);
        return () => {
            mounted.current = false;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() =>{
        setItems(props.memberList ?? []);
    },[props.memberList]);

    useEffect(() => {
        if (mounted.current) {
            if (props.groupList === undefined || props.groupList.length <= 1) {
            } else {
                setGroupOptions(props.groupList);
                setSelectedGroup(props.groupList[0]);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.groupList, fetchGroups]);

    useEffect(() => {
        if(selectedGroup && selectedGroup !== props.group.id){
            selectTeamInputRef.current.clearValue();
            fetchGroup(selectedGroup.value);
        }else{
            selectTeamInputRef.current.clearValue();
            setItems([]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[selectedGroup]);

    useEffect(() => {
        if (mounted.current) {
            setTeamOptions(props.teamList);
            if(props.teamList.length > 0) {
                setSelectedTeams([props.teamList[0]])
            }
        }
    }, [props.teamList]);

    useEffect(() => {
        if (selectedTeams.length > 0) {
            let filterData = tableInfo;
            filterData.teamIdList = selectedTeams.map(info => info.value);
            setTableInfo(filterData);
            getMemberData();
        }else{
            setItems([]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[selectedTeams]);

    useEffect(() => {
        if (props.paginationResult !== undefined) {
            let page = tableInfo;
            page.pagination = {...page.pagination, ...props.paginationResult}
            setTableInfo(page)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.paginationResult]);

    useEffect(() => {
        if (timestamp === '') {
            getMemberData();
        }
        if (props.timestamp !== timestamp) {
            setListLoader(false);
            setTimestamp(props.timestamp);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.timestamp]);

    useEffect(() => {
        let filterData = items.filter((item) => {
            return (item.userName && item.userName.toLowerCase().includes(filterText.toLowerCase())) ||
                (item.emailId && item.emailId.toLowerCase().includes(filterText.toLowerCase()))
        });
        setFilteredItems(filterData);
        // let page = tableInfo;
        // if(filterText.length >= 3) {
        //     page.filter = {
        //         emailId: filterText,
        //         fullName: filterText
        //     }
        // }else{
        //     page.filter = {};
        // }
        // setTableInfo(page);
        // getMemberData();
    }, [filterText,items]);

    const customSort = (field, rowA, rowB) => {
        if (rowA[field] > rowB[field]) {
            return 1;
        }
        if (rowB[field] > rowA[field]) {
            return -1;
        }
        return 0;
    }

    const getMemberData = () => {
        if (mounted.current) {
            setListLoader(true);
            if (tableInfo.teamIdList.length > 0) {
                checkAuthWithCallback(history, fetchMembers, tableInfo);
            } else {
                setFilteredItems([]);
                setListLoader(false);
            }
        }
    }

    const changeGroup = (refData) => {
        setSelectedGroup(refData)
        // if (refData !== null) {
        //     if(refData.value !== props.group.id) {
        //         filterRef.current.clearFilter();
        //         selectTeamInputRef.current.clearValue();
        //         setSelectedTeams(refData.value);
        //         setItems([]);
        //     }
        // } else {
        //     filterRef.current.clearFilter();
        //     selectTeamInputRef.current.clearValue();
        //     setItems([]);
        // }
    }

    const changeTeam = (refData) => {
        setSelectedTeams(refData);
    }

    const subHeaderComponentMemo = useMemo(() => {
        return (<></>
            // <Button disabled={true} ><i className="fa fa-file-export" /></Button>
        );
    }, []);

    return (
        <>
            {/* Page content */}
            <Row>
                <Col lg="12" md="12">
                    <Card className="shadow border-0">
                        <CardHeader>
                            <Row>
                                <Col lg="12" md="12">
                                    <span className={"mb-0 h3"}>Member list</span>
                                </Col>
                            </Row>
                        </CardHeader>
                        <CardBody>
                            <Row>
                                <Col md="4">
                                    <FormGroup>
                                        <Label for="group">
                                            Organization
                                        </Label>
                                        <Select
                                            id="group"
                                            name="groupIdList"
                                            placeholder="Select Organization"
                                            options={groupOptions}
                                            classNamePrefix="select"
                                            isClearable
                                            onChange={changeGroup}
                                            styles={reactSelectStyle}
                                            value={selectedGroup}
                                        >
                                        </Select>
                                    </FormGroup>
                                </Col>
                                <Col md="4">
                                    <FormGroup>
                                        <Label for="team">
                                            Team
                                        </Label>
                                        <Select
                                            id="team"
                                            name="teamIdList"
                                            placeholder="Select Team"
                                            isMulti={true}
                                            options={teamOptions}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            isClearable
                                            onChange={changeTeam}
                                            styles={reactSelectStyle}
                                            ref={selectTeamInputRef}
                                            value={selectedTeams}
                                        >
                                        </Select>
                                    </FormGroup>
                                </Col>
                                <Col md="4">
                                    <FormGroup>
                                        <Label for="search">
                                            Email
                                        </Label>
                                        <Filter ref={filterRef} id="search" onFilter={val => {
                                            setFilterText(val)
                                        }} filterText={filterText}/>
                                    </FormGroup>
                                </Col>
                                <Col sm="12">
                                    <DataTable
                                        columns={columns}
                                        progressPending={listLoader}
                                        progressComponent={<Loader/>}
                                        className={"react-dataTable"}
                                        data={filteredItems}
                                        pagination
                                        responsive
                                        // paginationTotalRows={tableInfo.pagination.totalRecords}
                                        subHeaderWrap
                                        subHeaderComponent={subHeaderComponentMemo}
                                    />
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </>
    )
};

const mapStateToProps = (state) => {
    return {
        memberList: state.member.memberList ?? [],
        timestamp: state.member.timestamp ?? '',
        teamList: state.group.teamSelect ?? [],
        groupList: state.group.groupSelect ?? [],
        group: state.group.group ?? null
    }
}

const mapDispatchToProps = {
    fetchMembers,
    fetchGroups,
    fetchGroup
}

export default connect(mapStateToProps, mapDispatchToProps)(Index);
