import {FETCH, DELETE, VIEW, PUT, POST, POST_ITEM, PUT_ITEM, VIEW_ITEM, FETCH_ITEM} from './series.types';
import {
    deleteRequest,
    displayErrorMessage,
    displayMessage,
    getRequest,
    postRequest,
    putRequest
} from "../../config/helper";
import {
    SERIES_LIST_API,
    SERIES_CREATE_API,
    SERIES_DATA_API,
    SERIES_DELETE_API,
    SERIES_UPDATE_API,
    SERIES_ITEM_CREATE_API,
    SERIES_ITEM_UPDATE_API,
    SERIES_ITEM_VIEW_API,
    SERIES_ITEM_LIST_API
} from "../../config/URLConstant";

export const fetchSeriesList = () => {
    return function (dispatch) {
        return getRequest(SERIES_LIST_API)
            .then(
                response => response.data,
                error => {
                    if (error && error.response !== undefined) {
                        displayErrorMessage(error.response.data.message || "Internal server error")
                    } else {
                        displayErrorMessage("Internal server error")
                    }
                }
            )
            .then((data) => {
                    return dispatch({
                        type: FETCH,
                        payload: data
                    })
                },
            );
    };
};

export const createSeries = (postData) => {
    return function (dispatch) {
        return postRequest(SERIES_CREATE_API, postData)
            .then(
                response => {
                    displayMessage('Practice Created.');
                    return response.data;
                },
                error => {
                    if (error && error.response !== undefined) {
                        displayErrorMessage(error.response.data.message || "Internal server error")
                    } else {
                        displayErrorMessage("Internal server error")
                    }
                }
            ).then((data) => {
                    return dispatch({
                        type: POST,
                        payload: data
                    })
                }
            )
    }
};

export const updateSeries = (postData) => {
    return function (dispatch) {
        return putRequest(SERIES_UPDATE_API, postData)
            .then(
                response => {
                    displayMessage('Practice Updated.');
                    return response.data;
                },
                error => {
                    if (error && error.response !== undefined) {
                        displayErrorMessage(error.response.data.message || "Internal server error")
                    } else {
                        displayErrorMessage("Internal server error")
                    }
                }
            ).then((data) => {
                    return dispatch({
                        type: PUT,
                        payload: data
                    })
                }
            )
    }
};

export const fetchSeries = (id) => {
    return function (dispatch) {
        return getRequest(SERIES_DATA_API + id)
            .then(
                response => response.data,
                error => {
                    if (error && error.response !== undefined) {
                        displayErrorMessage(error.response.data.message || "Internal server error")
                    } else {
                        displayErrorMessage("Internal server error")
                    }
                }
            )
            .then((data) => {
                    return dispatch({
                        type: VIEW,
                        payload: data
                    })
                },
            );
    };
};

export const deleteSeries = (data) => {
    return function (dispatch) {
        return deleteRequest(SERIES_DELETE_API + data.id)
            .then(
                response => {
                    displayMessage(response.message);
                    return response.data;
                },
                error => {
                    if (error && error.response !== undefined) {
                        displayErrorMessage(error.response.data.message || "Internal server error")
                    } else {
                        displayErrorMessage("Internal server error")
                    }
                }
            )
            .then((response) => {
                    return dispatch({
                        type: DELETE,
                        payload: response !== undefined ? data : response
                    })
                },
            );
    };
};

export const fetchSeriesItems = (postData) => {
    return function (dispatch) {
        return postRequest(SERIES_ITEM_LIST_API, postData)
            .then(
                response => response.data,
                error => {
                    if (error && error.response !== undefined) {
                        displayErrorMessage(error.response.data.message || "Internal server error")
                    } else {
                        displayErrorMessage("Internal server error")
                    }
                }
            )
            .then((data) => {
                    return dispatch({
                        type: FETCH_ITEM,
                        payload: data
                    })
                },
            );
    };
};

export const createSeriesItem = (postData) => {
    return function (dispatch) {
        return postRequest(SERIES_ITEM_CREATE_API, postData)
            .then(
                response => {
                    displayMessage('Practice Item Created.');
                    return response.data;
                },
                error => {
                    if (error && error.response !== undefined) {
                        displayErrorMessage(error.response.data.message || "Internal server error")
                    } else {
                        displayErrorMessage("Internal server error")
                    }
                }
            ).then((data) => {
                    return dispatch({
                        type: POST_ITEM,
                        payload: data
                    })
                }
            )
    }
};

export const updateSeriesItem = (postData) => {
    return function (dispatch) {
        return putRequest(SERIES_ITEM_UPDATE_API, postData)
            .then(
                response => {
                    displayMessage('Practice Item Updated.');
                    return response.data;
                },
                error => {
                    if (error && error.response !== undefined) {
                        displayErrorMessage(error.response.data.message || "Internal server error")
                    } else {
                        displayErrorMessage("Internal server error")
                    }
                }
            ).then((data) => {
                    return dispatch({
                        type: PUT_ITEM,
                        payload: data
                    })
                }
            )
    }
};

export const fetchSeriesItem = (id) => {
    return function (dispatch) {
        return getRequest(SERIES_ITEM_VIEW_API + id)
            .then(
                response => response.data,
                error => {
                    if (error && error.response !== undefined) {
                        displayErrorMessage(error.response.data.message || "Internal server error")
                    } else {
                        displayErrorMessage("Internal server error")
                    }
                }
            )
            .then((data) => {
                    return dispatch({
                        type: VIEW_ITEM,
                        payload: data
                    })
                },
            );
    };
};