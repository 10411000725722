import React, {useEffect, useRef, useState} from 'react'
import {connect} from "react-redux"
import {Field, reduxForm} from 'redux-form'
import {Button, Col, Row} from "reactstrap";
import {renderSelectField, renderTextField} from "../../../config/formElement";
import {fetchTeam} from "../../../redux/team/team.actions";
import {fetchGroups} from "../../../redux/group/group.actions";
import Loader from "../../../components/Loader/Loader";
import {checkAuth, checkAuthWithCallback} from "../../../config/helper";
import {useHistory} from "react-router-dom";

const validate = values => {
    let errors = {};
    if (!values.groupId) {
        errors.groupId = 'Organization selection is required.';
    }
    if (!values.teamName) {
        errors.teamName = 'Team name is required.';
    }
    return errors;
}

const warn = () => {
    return {};
}

let TeamCreateForm = (props) => {
    const {handleSubmit, fetchGroups, fetchTeam} = props;
    const mounted = useRef(false);
    const [fields, setFields] = useState({
        id:'',
        groupId:'',
        teamName:'',
        kickOffDate:''
    });
    const [isSubmitting,setIsSubmitting] = useState({text:'Save',icon: ''});
    const [groupList,setGroupList] = useState([{value:null,label:'Select Organization'}]);
    const [isLoading,setIsLoading] = useState(true);
    const [selectedGroup,setSelectedGroup] = useState('')
    const history = useHistory();

    const handleChange = (value) => {
        setSelectedGroup(value.target.value);
    };

    useEffect(() => {
        checkAuth(history);
        mounted.current = true;
        return () => {
            mounted.current = false;
            setFields({
                id:'',
                groupId:'',
                teamName:'',
                kickOffDate:''
            });
            setIsSubmitting({text:'Save',icon: ''});
        };
    }, []);

    useEffect(() => {
        if(props.submitBtnText !== isSubmitting.text) {
            setIsSubmitting({text:props.submitBtnText,icon: props.submitBtnIcon});
        }else{
            if(isLoading){
                setIsSubmitting({text:'Wait',icon: ''});
            }else{
                setIsSubmitting({text:'Save',icon: ''});
            }
        }
    }, [props.submitBtnText,props.submitBtnIcon,isLoading]);

    useEffect(() => {
        if(mounted.current) {
            if (props.groupList === undefined || props.groupList.length <= 1) {
                setIsLoading(true);
                checkAuthWithCallback(history,fetchGroups);
            } else {
                let groups = [...new Map(props.groupList.map(item => [item.value, item])).values()];
                if (props.id === undefined) {
                    setGroupList([{value: null, label: 'Select Organization'}].concat(groups));
                } else {
                    setGroupList(groups);
                }
                setIsLoading(false);
            }
        }
    }, [props.groupList,fetchGroups]);

    useEffect(() => {
        if(mounted.current) {
            if (props.id !== undefined && (props.team === undefined || props.team.id !== props.id)) {
                setIsLoading(true);
                checkAuthWithCallback(history,fetchTeam,props.id);
            }
            if (props.id !== undefined && props.team !== undefined && props.team.id === props.id) {
                let data = {...props.team, ...{groupId: props.team.groupData.id}}
                setSelectedGroup(props.team.groupData.id);
                setFields(data);
                setIsLoading(false);
            }
        }
    }, [props.team,fetchTeam]);

    let groupField;
    if(mounted.current) {
        if (props.id === undefined) {
            groupField = <Field
                name="groupId"
                type="select"
                component={renderSelectField}
                label="Organization"
                options={groupList ?? []}
                hasDefaultValue={selectedGroup}
                isRequired={true}
                onChange={value => handleChange(value)}
            />
        }
    }

    return (
        <>
            {
                isLoading ?
                    <div className="text-center"><Loader /></div>
                    :
                    <form onSubmit={(values) => handleSubmit(values)}>
                        <Row>
                            <Col lg="12">
                                {groupField}
                            </Col>
                            <Col lg="12">
                                <Field
                                    name="teamName"
                                    type="text"
                                    label={"Name"}
                                    hasDefaultValue={fields.teamName}
                                    component={renderTextField}
                                    isRequired={true}
                                />
                            </Col>
                            <Col lg="12">
                                <Field
                                    name="kickOffDate"
                                    type="date"
                                    component={renderTextField}
                                    label="Team Kickoff Date"
                                    hasDefaultValue={fields.kickOffDate}
                                />
                            </Col>
                            <Col lg="12">
                                <Button color="primary" type="submit" disabled={isSubmitting.text !== 'Save'}>{isSubmitting.icon !== '' ? <i className={isSubmitting.icon} /> : ''} {isSubmitting.text}</Button>
                            </Col>
                        </Row>
                    </form>
            }
        </>)
}

const mapStateToProps = (state,ownProps) => {
    let team = {
        id:'',
        groupId:'',
        teamName:'',
        kickOffDate:''
    };
    if(state.group !== undefined && state.group.groupSelect !== undefined) {
        if (ownProps.id !== undefined && state.team !== undefined && state.team.team !== undefined && state.team.team.id !== undefined && ownProps.id === state.team.team.id) {
            team = state.team.team;
        }
    }
    return {
        initialValues: team,
        team: team,
        groupList: state.group.groupSelect
    };
}

const mapDispatchToProps = {
    fetchGroups,
    fetchTeam
}

TeamCreateForm = reduxForm({
    form: 'teamCreateForm',
    enableReinitialize:true,
    validate,
    warn
})(TeamCreateForm)

export default connect(mapStateToProps,mapDispatchToProps)(TeamCreateForm)