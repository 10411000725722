import {connect} from "react-redux";
import {Button, Card, CardBody, CardHeader, Col, Row} from "reactstrap";
import React, {useEffect, useRef, useState} from "react";
import {checkAuth, deepEqual, displayMessage} from "../../../config/helper";
import {getS3SignedUrl, postS3FileUpload, updateUserProfile} from "../../../redux/user/user.actions";
import TeamModal from "../Modals/TeamModal";
import StrengthScopeWheelModal from "../Modals/StrengthScopeWheelModal";
import {Link, useHistory} from "react-router-dom";

const loaderStatus = {
    removedFromTeam: false,
    updateTeamId: false,
    moveUserToPractice: false,
    uploadStrengthScopeWheel: false,
};

const OtherOperations = (props) => {
    const {updateUserProfile, getS3SignedUrl, postS3FileUpload} = props;
    const mounted = useRef(false);
    const [user, setUser] = useState({});
    const [showTeamModal, setShowTeamModal] = useState(false);
    const [showSeriesModal, setShowSeriesModal] = useState(false);
    const [showUploadStrengthScopeWheelModal, setShowUploadStrengthScopeWheelModal] = useState(false);
    const [loadingBtn, setLoadingBtn] = useState(loaderStatus);
    const history = useHistory();

    useEffect(() => {
        checkAuth(history);
        mounted.current = true;
        return () => {
            mounted.current = false;
            setLoadingBtn(loaderStatus);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (mounted.current) {
            if (props.userData !== undefined && props.userData.id !== undefined && props.userData.id !== '' && !deepEqual(props.userData, user)) {
                setUser(props.userData);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.userData]);

    const updateTeamId = (val) => {
        updateLoaderData(val.length > 0 ? 'updateTeamId' : 'removedFromTeam',true);
        updateProfileInfo({
            userId: props.userData.id,
            teamId: val
        }, val.length > 0 ? 'updateTeamId' : 'removedFromTeam');
    }

    const uploadStrengthScopeWheel = (val) => {
        if(mounted.current) {
            updateLoaderData('uploadStrengthScopeWheel', true);
            let postData = {
                "fileList": [
                    {
                        "fileName": val.name,
                        "fileType": "STRENGTH_SCOPE_WHEEL"
                    }
                ]
            };
            getS3SignedUrl(postData).then((res) => {
                if (res.payload !== undefined && res.payload.uploadList.length > 0) {
                    let data = res.payload.uploadList[0].fields;
                    let formData = new FormData();
                    for (const property in data) {
                        formData.append(property, data[property]);
                    }
                    formData.append('file', val);
                    postS3FileUpload(formData).then((res1) => {
                        if (res1.payload) {
                            let postDate1 = {
                                userId: props.userData.id,
                                strengthScopeWheelPicId: res.payload.uploadList[0].fileUrl ?? ''
                            }
                            updateProfileInfo(postDate1, 'uploadStrengthScopeWheel');
                        } else {
                            updateLoaderData('uploadStrengthScopeWheel', false);
                        }
                    });
                } else {
                    updateLoaderData('uploadStrengthScopeWheel', false);
                }
            });
        }
    }

    const updateProfileInfo = (profileInfo, loaderKey) => {
        if(mounted.current) {
            updateUserProfile(profileInfo).then((data) => {
                if (!('payload' in data && data.payload === undefined)) {
                    if ("password" in profileInfo) {
                        displayMessage("Password has been updated");
                    }
                    if ("userStatus" in profileInfo) {
                        displayMessage("User Status has been updated");
                    }
                    if ("teamId" in profileInfo) {
                        let message = 'User has been remove from the team';
                        if (profileInfo.teamId !== '') {
                            message = 'User team has been changed';
                        }
                        if (showTeamModal) {
                            setShowTeamModal(false);
                        }
                        displayMessage(message);
                    }
                    if ("currentSeriesId" in profileInfo) {
                        if (showSeriesModal) {
                            setShowSeriesModal(false);
                        }
                        displayMessage("User has been move from one to another practice");
                    }
                    if ("strengthScopeWheelPicId" in profileInfo) {
                        if (showUploadStrengthScopeWheelModal) {
                            setShowUploadStrengthScopeWheelModal(false);
                        }
                        displayMessage("User strength scope wheel has been uploaded");
                    }
                    updateLoaderData(loaderKey, false);

                }
            });
        }
    }

    const updateLoaderData = (key,val) => {
        let loading = loadingBtn;
        if (key in loading) {
            loading[key] = val;
            setLoadingBtn(loading);
        }
    }

    return (
        <>
            <Card>
                <CardHeader>
                    <span className="h3">Other Operations</span>
                    <Link
                        to={props.viewerRole ==='advisor' ? '/coach/dashboard' :'/admin/user'}
                        className={"d-flex float-right btn btn-sm btn-primary align-items-center"}
                    >
                        <i className="fa fa-arrow-left mr-2"/>
                        Back
                    </Link>
                </CardHeader>
                <CardBody>
                    <Row>
                        {/*<Col sm="12" className="mt-3">*/}
                        {/*    <Button block color="danger"*/}
                        {/*            disabled={props.userData.teamId === '' || disabledBtn.includes('removedFromTeam')}*/}
                        {/*            onClick={(e) => updateTeamId(null)}>*/}
                        {/*        {loadingBtn.removedFromTeam ? <LoaderBtn text={'Removing'}/> : 'Remove From Team'}*/}
                        {/*    </Button>*/}
                        {/*</Col>*/}
                        <Col md="6" className="mt-3">
                            <Button block color="success" onClick={() => setShowTeamModal(true)}>Change Team
                                Membership</Button>
                            <TeamModal isModalOpen={showTeamModal} teamId={props.userData.teamId ?? ''}
                                       groupId={props.userData.groupId} handleSubmit={updateTeamId}
                                       handleClose={(val) => setShowTeamModal(val)}
                                       loadingBtn={loadingBtn.updateTeamId}/>
                        </Col>
                        {/*<Col md="6" className="mt-3">*/}
                        {/*    <Button block color="success" onClick={() => setShowSeriesModal(true)}>Move User To Another*/}
                        {/*        Practice</Button>*/}
                        {/*    <SeriesModal isModalOpen={showSeriesModal} currentSeriesId={props.userData.currentSeriesId}*/}
                        {/*                 handleSubmit={moveToSeries} handleClose={(val) => setShowSeriesModal(val)}*/}
                        {/*                 loadingBtn={loadingBtn.moveUserToPractice}/>*/}
                        {/*</Col>*/}
                        <Col sm="6" className="mt-3">
                            <Button block color="success" onClick={() => setShowUploadStrengthScopeWheelModal(true)}>Upload
                                Strength Scope Wheel</Button>
                            <StrengthScopeWheelModal isModalOpen={showUploadStrengthScopeWheelModal}
                                                     handleSubmit={uploadStrengthScopeWheel}
                                                     handleClose={(val) => setShowUploadStrengthScopeWheelModal(val)}
                                                     loadingBtn={loadingBtn.uploadStrengthScopeWheel}/>
                        </Col>
                    </Row>
                </CardBody>
            </Card>
        </>
    );
}

const mapStateToProps = () => {
    return {}
}

const mapDispatchToProps = {
    updateUserProfile,
    getS3SignedUrl,
    postS3FileUpload
}

export default connect(mapStateToProps, mapDispatchToProps)(OtherOperations);