import {connect} from "react-redux";
import React, {useEffect, useRef, useState} from "react";
import {useHistory} from "react-router-dom";
import {checkAuth} from "../../config/helper";
import {fetchAppSetting, postAppSetting} from "../../redux/app-setting/appSetting.actions";
import AppSettingFormCreate from "./form/AppCreate";

const App = (props) => {
    const {postAppSetting, fetchAppSetting} = props;
    const history = useHistory();
    const mounted = useRef(false);
    const [submitBtnText, setSubmitBtnText] = useState('Save');
    const [submitBtnIcon, setSubmitBtnIcon] = useState('');

    useEffect(() => {
        mounted.current = true;
        checkAuth(history);
        return () => {
            mounted.current = false;
            handleSubmitBtn(false);
        };
    }, []);

    const handleSubmitBtn = (val) => {
        if (val) {
            setSubmitBtnText('Saving');
            setSubmitBtnIcon('fa fa-spinner fa-spin');
        } else {
            setSubmitBtnText('Save');
            setSubmitBtnIcon('');
        }
    }
    const handleSubmit = (values) => {
        handleSubmitBtn(true);
        const postData = {
            key: "APP_UPDATE_DATA",
            value: {
                IOS: {
                    buildNumber: parseInt(values.ios_app_build),
                    isMandatory: values.is_force_update,
                    description: values.description
                },
                ANDROID: {
                    buildNumber: parseInt(values.android_app_build),
                    isMandatory: values.is_force_update,
                    description: values.description
                }
            }
        };
        postAppSetting(postData).then(() => {
            fetchAppSetting({key: "APP_UPDATE_DATA"}).then(() => {
                handleSubmitBtn(false);
            });
        });
    }
    return (
        <>
            <AppSettingFormCreate onSubmit={handleSubmit} submitBtnText={submitBtnText}
                               submitBtnIcon={submitBtnIcon}/>
        </>
    )
}

const mapStateToProps = () => {
    return {};
}

const mapDispatchToProps = {
    postAppSetting,
    fetchAppSetting
}

export default connect(mapStateToProps, mapDispatchToProps)(App);