import {connect} from "react-redux";
import React, {useEffect, useRef, useState} from "react";
import {
    Button,
    Col, FormGroup, Input, Label,
    Modal,
    ModalBody,
    Row
} from "reactstrap";
import LoaderBtn from "../../../components/Loader/LoaderBtn";
import {fetchTeams} from "../../../redux/team/team.actions";
import {checkAuth, checkAuthWithCallback} from "../../../config/helper";
import {useHistory} from "react-router-dom";

const TeamModal = (props) => {
    const {handleSubmit, fetchTeams, handleClose} = props;
    const [teamId,setTeamId] = useState('');
    const [teamList,setTeamList] = useState([{value:'',label:'Select Team'}]);
    const [loadingBtn,setLoadingBtn] = useState(false);
    const [error,setError] = useState({});
    const [show, setShow] = useState(false);
    const mounted = useRef(false);
    const history = useHistory();

    useEffect(() => {
        checkAuth(history);
        mounted.current = true;
        return () => {
            mounted.current = false;
            setShow(false);
            setTeamId('');
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if(mounted.current) {
            if (props.teamList === undefined || props.teamList.length === 0) {
                checkAuthWithCallback(history,fetchTeams);
            }
            if (props.teamList !== undefined) {
                setTeamList([{value: '', label: 'Select Team'}].concat(props.teamList));
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[props.teamList,fetchTeams]);

    useEffect(() => {
        if(props.teamId !== '' && props.teamId !== undefined && props.teamId !== teamId){
            setTeamId(props.teamId);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[props.teamId]);

    useEffect(() => {
        if(props.isModalOpen !== undefined && props.isModalOpen !== show){
            setShow(props.isModalOpen);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[props.isModalOpen]);

    useEffect(() => {
        if(props.loadingBtn !== loadingBtn){
            setLoadingBtn(props.loadingBtn);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[props.loadingBtn]);

    const saveTeam = () => {
        if(teamId !== '') {
            setLoadingBtn(true);
            handleSubmit(teamId);
        }else{
            error.teamId = "Team is required";
            setError(error);
        }
    };

    const handleChange = (val) => {
        let value = val.target.value ?? '';
        if(value !== '' && 'teamId' in error){
            let e = error;
            delete e.teamId;
            setError(e);
        }else{
            setTeamId(value);
        }
    }

    let optionList=[];
    if(mounted.current) {
        if (teamList.length > 0) {
            for (const key in teamList) {
                optionList.push(<option value={teamList[key].value} key={key}>{teamList[key].label}</option>);
            }
        }
    }

    return (
        <>
            <Modal isOpen={show} centered={true} backdrop={true}>
                <ModalBody>
                    <Row>
                        <Col sm="12">
                            <FormGroup>
                                <Label>Team <span className="text-danger">*</span></Label>
                                <Input placeholder={'Team'} type={'select'} value={teamId || ''} invalid={error.teamId}
                                 onChange={handleChange}>
                                    {optionList}
                                </Input>
                                {error.teamId && <span className="text-danger">{error.teamId}</span>}
                            </FormGroup>
                        </Col>
                        <Col sm="12">
                            <div className="float-right">
                                <Button color="primary" disabled={loadingBtn} onClick={saveTeam}>{loadingBtn ? <LoaderBtn text="Submitting" /> : 'Submit'}</Button>
                                <Button color="secondary" disabled={loadingBtn} onClick={() => handleClose(false)}>Close</Button>
                            </div>
                        </Col>
                    </Row>
                </ModalBody>
            </Modal>
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        teamList : state.team.teamSelect,
    }
}

const mapDispatchToProps = {
    fetchTeams
}

export default connect(mapStateToProps, mapDispatchToProps)(TeamModal);