import React, {useEffect, useRef, useState} from "react";
import {
    Card,
    CardBody,
    CardHeader, Col,
    Row
} from "reactstrap";
import DataTable from "react-data-table-component";
import Loader from "../../../components/Loader/Loader";
import {connect} from "react-redux";
import {Link, useHistory} from "react-router-dom";
import {checkAuth, checkAuthWithCallback, getStaticDataValue, uiAvatarName} from "../../../config/helper";
import {fetchMeFeeds} from "../../../redux/me-feed/meFeed.actions";
import {paginationSchema} from "../../../config/schema";
import ImageModal from "../../../components/Modals/ImageModal";

const MEScreen = (props) => {
    const {fetchMeFeeds} = props;
    const mounted = useRef(false);
    const [listLoader,setListLoader] = useState(true);
    const [tableInfo, setTableInfo] = useState(paginationSchema());
    const [timestamp, setTimestamp] = useState('');
    const [imageModalShow, setImageModalShow] = useState(false);
    const [image, setImage] = useState("");


    const history = useHistory();
    const showImageModal = (img) => {
        if (!imageModalShow) {
            console.log(img)
            setImage(img)
            setImageModalShow(true);
        }
    }
    const handleCloseImageModal = () => {
        if (imageModalShow) {
            setImageModalShow(false);
        }
    }
    const columns = [
        {
            name: 'BG Image',
            center: true,
            cell: (row) => {
                return (<div className={"d-table-cell"}>
                    <img
                        onClick={() => showImageModal(row.feed.bgImg)}
                        alt="Card cap"
                        src={row.feed.bgImg}
                        height={'50px'}
                        className="cursor-pointer"
                    />
                </div>);
            },
        },
        {
            name: 'Title',
            selector: (row) => row.feed && row.feed.view && row.feed.view.title ? row.feed.view.title : '',
            sortable: false
        },
        {
            name: 'Heading',
            selector: (row) => row.series && row.series.seriesHeader ? row.series.seriesHeader : '',
            sortable: false
        },
        {
            name: 'Status',
            selector: (row) => {
                return getStaticDataValue(row.feedStatus, 'feedStatus');
            },
            sortable: false
        }
    ];

    useEffect(() => {
        checkAuth(history);
        mounted.current = true;
        return () => {
            mounted.current = false;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (props.paginationResult !== undefined) {
            let page = tableInfo;
            page.pagination = {...page.pagination, ...props.paginationResult}
            setTableInfo(page)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.paginationResult]);

    useEffect(() => {
        if(props.userData && props.userData.id){
            let page = tableInfo;
            page.userId = props.userData.id;
            setTableInfo(page);
            getMeFeedData();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[props.userData]);

    useEffect(() => {
        if (props.timestamp !== timestamp) {
            setListLoader(false);
            setTimestamp(props.timestamp);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.timestamp]);

    const handlePageChange = pageNum => {
        let page = tableInfo;
        page.pagination.pageNumber = pageNum;
        setListLoader(true);
        setTableInfo(page);
        getMeFeedData();
    };

    const handlePerRowsChange = async (newPerPage, currentPage) => {
        let page = tableInfo;
        page.pagination.pageSize = newPerPage;
        page.pagination.pageNumber = currentPage;
        setListLoader(true);
        setTableInfo(page);
        getMeFeedData();
    };

    const getMeFeedData = () => {
        if (mounted.current && props.userData && props.userData.id) {
            setListLoader(true);
            checkAuthWithCallback(history, fetchMeFeeds, tableInfo);
        }
    }

    return (
        <>
            {/* Page content */}
            <Row>
                <Col lg="12" md="12">
                    <Card className="shadow border-0">
                        <CardHeader>
                            <Row>
                                <Col lg="12" md="12">
                                    <span className={"mb-0 h3"}>ME Screen</span>
                                    <Link
                                        to={props.viewerRole ==='advisor' ? '/coach/dashboard' :'/admin/user'}
                                        className={"d-flex float-right btn btn-sm btn-primary align-items-center"}
                                    >
                                        <i className="fa fa-arrow-left mr-2"/>
                                        Back
                                    </Link>
                                </Col>
                            </Row>
                        </CardHeader>
                        <CardBody>
                            <Row>
                                <Col lg="12">
                                    <ImageModal
                                        image={image}
                                        isModalShow={imageModalShow}
                                        handleCloseModal={handleCloseImageModal}
                                        alt=""/>
                                    <DataTable
                                        columns={columns}
                                        progressPending={listLoader}
                                        progressComponent={<Loader/>}
                                        className={"react-dataTable"}
                                        data={props.meFeedList || []}
                                        pagination
                                        responsive
                                        paginationServer
                                        paginationTotalRows={tableInfo.pagination.totalRecords}
                                        onChangeRowsPerPage={handlePerRowsChange}
                                        onChangePage={handlePageChange}
                                        subHeaderWrap
                                    />
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </>
    )
};

const mapStateToProps = (state) => {
    return {
        meFeedList: state.meFeed.meFeedList ?? [],
        timestamp: state.meFeed.timestamp ?? '',
        paginationResult: state.meFeed.paginationResult,
    }
}

const mapDispatchToProps = {
    fetchMeFeeds
}

export default connect(mapStateToProps, mapDispatchToProps)(MEScreen);