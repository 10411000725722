export const permissionList = [
    {
        value: '',
        label: 'Select Permission'
    }, {
        value: 'admin',
        label: 'Admin'
    }, {
        value: 'leader',
        label: 'Leader'
    }, {
        value: 'member',
        label: 'Member'
    }
];

export const seriesTypeList = [
    {
        value: '',
        label: 'Select Practice Type'
    }, {
        value: 'WELCOME',
        label: 'Welcome'
    }, {
        value: 'STRENGTH',
        label: 'Strength'
    }, {
        value: 'WARMUP',
        label: 'Warm Up'
    }, {
        value: 'PRACTICE',
        label: 'Practice'
    }
];

export const statusList = [
    {
        value: 'active',
        label: 'Active'
    }, {
        value: 'inactive',
        label: 'Inactive'
    }, {
        value: 'blocked',
        label: 'Blocked'
    }
];

export const teamRoleList = [
    {
        value: 'admin',
        label: 'Admin'
    }, {
        value: 'leader',
        label: 'Leader'
    }, {
        value: 'member',
        label: 'Member'
    }
];

export const profileStatusList = [
    {
        value: 'incomplete',
        label: 'Incomplete'
    }, {
        value: 'avatarsetup',
        label: 'Avatar Setup'
    }, {
        value: 'profilesetup',
        label: 'Profile Setup'
    }, {
        value: 'passwordchanged',
        label: 'Password Change'
    }, {
        value: 'metutorialcomplete',
        label: 'Me Tutorial Complete'
    }
];

export const seriesItemCategory = [
    {
        label: "Select Category",
        value: ""
    }, {
        label: "Energy Intelligence",
        value: "Energy Intelligence"
    }, {
        label: "Teamwork",
        value: "Teamwork"
    }, {
        label: "Leadership",
        value: "Leadership"
    }, {
        label: "Self Care",
        value: "Self Care"
    }, {
        label: "INSIGHT",
        value: "INSIGHT"
    }
];

export const seriesItemType = [
    {
        label: "Check-in",
        value: "checkin"
    }, {
        label: "Dialog",
        value: "dialog"
    }, {
        label: "Drainers",
        value: "drainers"
    }, {
        label: "Energizers",
        value: "energizers"
    }, {
        label: "Energy Wheel",
        value: "energywheel"
    }, {
        label: "External Do Later",
        value: "externaldolater"
    }, {
        label: "Info",
        value: "info"
    }, {
        label: "Video",
        value: "video"
    },
];

export const seriesItemDialog = [
    {
        label: "Choose Item",
        value: "chooseitem"
    }, {
        label: "Moz",
        value: "moz"
    }, {
        label: "Standard",
        value: "standard"
    }, {
        label: "Video",
        value: "video"
    },
];

export const seriesItemLayout = [
    {
        label: "Large",
        value: "large"
    }, {
        label: "Text",
        value: "text"
    }
];

export const seriesItemSurveyType = [
    {
        label: "Energy Wheel",
        value: "energy_wheel"
    }, {
        label: "Select Feels",
        value: "selectFeels"
    }, {
        label: "Select Energizers",
        value: "selectEnergizers"
    }, {
        label: "Select Intentions",
        value: "selectIntentions"
    }, {
        label: "Select Practice Three",
        value: "selectPracticeThree"
    }, {
        label: "Select Practice Four",
        value: "selectPracticeFour"
    }, {
        label: "Select Colleague Energizer",
        value: "selectColleagueEnergizer"
    }, {
        label: "Select Gratitude",
        value: "selectGratitude"
    }, {
        label: "Select Self Care",
        value: "selectSelfCare"
    }, {
        label: "Select Pause",
        value: "selectPause"
    }, {
        label: "Select Notice OD",
        value: "selectNoticeOD"
    }, {
        label: "Select Balance OD",
        value: "selectBalanceOD"
    }, {
        label: "Select Move Body",
        value: "selectMoveBody"
    }, {
        label: "Select Possibility",
        value: "selectPossibility"
    }, {
        label: "Select Choice",
        value: "selectChoice"
    }, {
        label: "Select Drainer",
        value: "selectDrainer"
    }, {
        label: "Select Hydrate",
        value: "selectHydrate"
    }, {
        label: "Select Stress",
        value: "selectStress"
    }, {
        label: "Select Better Comm",
        value: "selectBetterComm"
    }, {
        label: "Select Break",
        value: "selectBreak"
    }, {
        label: "Select More Energizers",
        value: "selectMoreEnergizers"
    }, {
        label: "Select Connect Stress",
        value: "selectConnectStress"
    }, {
        label: "Select Healthy Food",
        value: "selectHealthyFood"
    }, {
        label: "Select Team Drainer",
        value: "selectTeamDrainer"
    }, {
        label: "Select Stop Drainer",
        value: "selectStopDrainer"
    }, {
        label: "Select Experiment",
        value: "selectExperiment"
    }, {
        label: "Select Influence",
        value: "selectInfluence"
    }
];

export const weekDays = [
    {
        label: "Select Day",
        value: ''
    }, {
        label: "Monday",
        value: 1
    }, {
        label: "Tuesday",
        value: 2
    }, {
        label: "Wednesday",
        value: 3
    }, {
        label: "Thursday",
        value: 4
    }, {
        label: "Friday",
        value: 5
    }, {
        label: "Saturday",
        value: 6
    }, {
        label: "Sunday",
        value: 7
    }
];

export const seriesViewLayoutType = [
    {
        label: "Select Template",
        value: ""
    }, {
        label: "CheckInRadioView",
        value: "CheckInRadioView"
    }, {
        label: "CheckInRadioAndWheelView",
        value: "CheckInRadioAndWheelView"
    }, {
        label: "PracticeCheckInVideo",
        value: "CheckInVideoView"
    }, {
        label: "CheckInBreatheView",
        value: "CheckInBreatheView"
    }, {
        label: "CheckInColleaguesEnergizerView",
        value: "CheckInColleaguesEnergizerView"
    }, {
        label: "CheckInSelectChoiceView",
        value: "CheckInSelectChoiceView"
    }, {
        label: "VideoView",
        value: "VideoView"
    }, {
        label: "CheckInFeelView",
        value: "CheckInFeelView"
    }, {
        label: "InfoTipOfTheDayView",
        value: "InfoTipOfTheDayView"
    }, {
        label: "InfoLearningJourneyView",
        value: "InfoLearningJourneyView"
    }, {
        label: "InfoQuoteOfTheDayView",
        value: "InfoQuoteOfTheDayView"
    }, {
        label: "DialogVideoLearningJourneyView",
        value: "DialogVideoLearningJourneyView"
    }, {
        label: "DialogMozLearningJourneyView",
        value: "DialogMozLearningJourneyView"
    }, {
        label: "ExternalDoLaterArticleOfTheWeekView",
        value: "ExternalDoLaterArticleOfTheWeekView"
    }, {
        label: "ExternalDoLaterStatsOfTheWeekView",
        value: "ExternalDoLaterStatsOfTheWeekView"
    }, {
        label: "ExternalDoLaterTedOfTheWeekView",
        value: "ExternalDoLaterTedOfTheWeekView"
    }, {
        label: "CheckInHydrateView",
        value: "CheckInHydrateView"
    }, {
        label: "EnergyWheelUploadView",
        value: "EnergyWheelUploadView"
    }, {
        label: "SelectTop7EnergizerView",
        value: "SelectTop7EnergizerView"
    }, {
        label: "SelectBottom2DrainersView",
        value: "SelectBottom2DrainersView"
    }
]

export const seriesItemDataValidation = {
    "CheckInRadioView": {
        "view_postHeading": true,
        "view_title": true,
        "view_heading": true,
        "view_hashtag": true,
        "view_category": true,
        "view_mediaUrl": true,
        "view_practiceUrl": true,
        "view_radioDataList": true,
        "view_secondButton": false,
        "view_postHint": true
    },
    "CheckInRadioAndWheelView": {
        "view_postHeading": true,
        "view_title": true,
        "view_heading": true,
        "view_hashtag": true,
        "view_category": true,
        "view_mediaUrl": true,
        "view_practiceUrl": true,
        "view_radioDataList": true,
        "view_secondButton": false,
        "view_postHintAndTitle": true
    },
    "CheckInVideoView": {
        "view_postHeading": true,
        "view_title": true,
        "view_heading": true,
        "view_hashtag": true,
        "view_category": true,
        "view_mediaUrl": true,
        "view_practiceUrl": false,
        "view_secondButton": false,
        "view_postHintAndTitle": true
    },
    "CheckInBreatheView": {
        "view_postHeading": true,
        "view_title": true,
        "view_heading": true,
        "view_hashtag": true,
        "view_category": true,
        "view_mediaUrl": true,
        "view_practiceUrl": true,
        "view_postHintAndTitle": true
    },
    "CheckInColleaguesEnergizerView": {
        "view_postHeading": true,
        "view_title": true,
        "view_innerTitle": true,
        "view_heading": true,
        "view_hashtag": true,
        "view_category": true,
        "view_mediaUrl": true,
        "view_practiceUrl": true,
        "view_postHint": true
    },
    "CheckInSelectChoiceView": {
        "view_title": true,
        "view_heading": true,
        "view_hashtag": true,
    },
    "VideoView": {
        "view_title": true,
        "view_heading": true,
        "view_mediaUrl": false,
        "view_practiceUrl": true,
        "view_hashtag": false,
        "view_practiceTitle": true,
        "view_practiceSubtitle": false,
        "view_bottomButton": true
    },
    "CheckInFeelView": {
        "view_postHeading": true,
        "view_title": true,
        "view_heading": true,
        "view_hashtag": true,
        "view_mediaUrl": true,
        "view_practiceUrl": true,
        "view_postHintAndTitle": true
    },
    "InfoTipOfTheDayView": {
        "view_title": true,
        "view_heading": true,
        "view_hashtag": false
    },
    "InfoLearningJourneyView": {
        "view_title": true,
        "view_heading": true
    },
    "InfoQuoteOfTheDayView": {
        "view_title": true,
        "view_title2": false,
        "view_heading": true,
        "view_hashtag": false
    },
    "DialogVideoLearningJourneyView": {
        "view_title": true,
        "view_heading": true,
        "view_dialogTitle": true,
        "view_dialogSubTitle": true,
        "view_practiceUrl": true
    },
    "DialogMozLearningJourneyView": {
        "view_title": true,
        "view_heading": true,
        "view_dialogHeading": true,
        "view_dialogTitle": true
    },
    "ExternalDoLaterArticleOfTheWeekView": {
        "view_title": true,
        "view_title2": true,
        "view_heading": true,
        "view_webViewUrl": true,
        "view_mediaUrl": true
    },
    "ExternalDoLaterStatsOfTheWeekView": {
        "view_title": true,
        "view_title2": false,
        "view_title3": true,
        "view_heading": true,
        "view_webViewUrl": true,
        "view_mediaUrl": true,
        "view_hashtag": false
    },
    "ExternalDoLaterTedOfTheWeekView": {
        "view_title": true,
        "view_title2": true,
        "view_title3": true,
        "view_heading": true,
        "view_webViewUrl": true,
        "view_mediaUrl": true
    },
    "CheckInHydrateView": {
        "view_postHeading": true,
        "view_title": true,
        "view_heading": true,
        "view_hashtag": true,
        "view_mediaUrl": false,
        "view_practiceUrl": false,
        "view_postHintAndTitle": false,
        "view_url1": true,
        "view_url2": true
    },
    "VideoViewView": {
        "view_title": true,
        "view_heading": true,
        "view_hashtag": true,
        "view_mediaUrl": true,
        "view_practiceUrl": true,
        "view_next": true,
        "view_practiceTitle": true,
        "view_practiceSubtitle": true,
        "view_calltoaction": true,
    },
    "EnergyWheelUploadView": {
        "view_heading": true,
        "view_title": true
    },
    "SelectTop7EnergizerView": {
        "view_heading": true,
        "view_title": true
    },
    "SelectBottom2DrainersView": {
        "view_heading": true,
        "view_title": true
    }
};

export const seriesItemViewTemplateImages = {
    "CheckInRadioView": [
        "CheckInRadioView-1.png",
        "CheckInRadioView-2.png",
        "CheckInRadioView-3.png"
    ],
    "CheckInRadioAndWheelView": [
        "CheckInRadioAndWheelView-1.png"
    ],
    "CheckInVideoView": [
        "CheckInVideoView-1.png",
        "CheckInVideoView-2.png",
        "CheckInVideoView-3.png"
    ],
    "CheckInBreatheView": [
        "CheckInBreatheView-1.png",
        "CheckInBreatheView-2.png"
    ],
    "CheckInColleaguesEnergizerView": [
        "CheckInColleaguesEnergizerView-1.png",
        "CheckInColleaguesEnergizerView-2.png",
        "CheckInColleaguesEnergizerView-3.png",
        "CheckInColleaguesEnergizerView-4.png"
    ],
    "CheckInSelectChoiceView": [
        "CheckInSelectChoiceView-1.png"
    ],
    "VideoView": [
        "VideoView-1.png",
        "VideoView-2.png"
    ],
    "CheckInFeelView": [
        "CheckInFeelView-1.png",
        "CheckInFeelView-2.png",
        "CheckInFeelView-3.png"
    ],
    "InfoTipOfTheDayView": [
        "InfoTipOfTheDayView-1.png",
        "InfoTipOfTheDayView-2.png"
    ],
    "InfoLearningJourneyView": [
        "InfoLearningJourneyView-1.png",
        "InfoLearningJourneyView-2.png"
    ],
    "InfoQuoteOfTheDayView": [
        "InfoQuoteOfTheDayView-1.png",
        "InfoQuoteOfTheDayView-2.png"
    ],
    "DialogVideoLearningJourneyView": [
        "DialogVideoLearningJourneyView-1.png",
        "DialogVideoLearningJourneyView-2.png"
    ],
    "DialogMozLearningJourneyView": [
        "DialogMozLearningJourneyView-1.png",
        "DialogMozLearningJourneyView-2.png"
    ],
    "ExternalDoLaterArticleOfTheWeekView": [
        "ExternalDoLaterArticleOfTheWeekView-1.png",
        "ExternalDoLaterArticleOfTheWeekView-2.png"
    ],
    "ExternalDoLaterStatsOfTheWeekView": [
        "ExternalDoLaterStatsOfTheWeekView-1.png",
        "ExternalDoLaterStatsOfTheWeekView-2.png"
    ],
    "ExternalDoLaterTedOfTheWeekView": [
        "ExternalDoLaterTedOfTheWeekView-1.png",
        "ExternalDoLaterTedOfTheWeekView-2.png"
    ],
    "CheckInHydrateView": [
        "CheckInHydrateView-1.png",
        "CheckInHydrateView-2.png",
        "CheckInHydrateView-3.png",
        "CheckInHydrateView-4.png",
        "CheckInHydrateView-5.png"
    ],
    "VideoViewView": [],
    "EnergyWheelUploadView": [
        "EnergyWheelUploadView-1.png",
        "EnergyWheelUploadView-2.png",
        "EnergyWheelUploadView-3.png"
    ],
    "SelectTop7EnergizerView": [
        "SelectTop7EnergizerView-1.png",
        "SelectTop7EnergizerView-2.png"
    ],
    "SelectBottom2DrainersView": [
        "SelectBottom2DrainersView-1.png",
        "SelectBottom2DrainersView-2.png"
    ]
};

export const paginationModal = {
    pagination: {
        pageNumber: 1,
        pageSize: 10,
        totalPages: 1,
        totalRecords: 0
    },
    filter: {},
    sort: {}
};

export const notificationStatus = [
    {
        label: "Unread",
        value: "unread"
    },
    {
        label: "Read",
        value: "read"
    },
    {
        label: "Completed",
        value: "completed"
    }
];

export const audienceType = [
    {
        label: "Select Audience",
        value: ""
    },
    {
        label: "All Users",
        value: "all"
    },
    {
        label: "Teams",
        value: "teams"
    },
    {
        label: "Individual Leaders",
        value: "leaders"
    },
    {
        label: "Individual Members",
        value: "users"
    }
];

export const feedType = [
    {
        label: "Practice",
        value: "SERIES"
    },
    {
        label: "Post",
        value: "POST"
    }
]

export const feedStatus = [
    {
        label: "Read",
        value: "READ"
    },
    {
        label: "Unread",
        value: "UNREAD"
    }
]

export const notificationType = [
    {value: false, label: 'Notification'},
    {value: true, label: 'Adhoc Me Item'}
];

// export const weFeedType = {
//     POST: "POST",
//     ENCOURAGEMENT: "ENCOURAGEMENT"
// }

export const weFeedType = [
    {
        value: 'POST',
        label: 'Post'
    }, {
        value: 'ENCOURAGEMENT',
        label: 'Encouragement'
    }
];