import React, {useEffect, useRef, useState} from "react";
import {connect} from "react-redux";
import {Badge, Card, CardBody, CardHeader, Col, Nav, NavItem, NavLink, Row, TabContent, TabPane} from "reactstrap";
import {useHistory, useParams} from "react-router-dom";
import Loader from "../../components/Loader/Loader";
import {fetchUser} from "../../redux/user/user.actions";
import {checkAuth, checkAuthWithCallback, decodeRole, getBadgeColor, getStaticDataValue} from "../../config/helper";
import classnames from "classnames";
import UserDetail from "./view/UserDetail";
import OtherOperation from "./view/OtherOperation";
import MyTeams from "./view/MyTeams";
import MEScreen from "./view/MEScreen";
import ImageModal from "../../components/Modals/ImageModal";
import Create from "./Create";
import WeScreen from "./view/WeScreen";
import {fetchSeriesList} from "../../redux/series/series.actions";
import {fetchGroup, fetchGroups} from "../../redux/group/group.actions";

const View = (props) => {
    const {id} = useParams();
    const history = useHistory();
    const {fetchUser, fetchSeriesList, fetchGroups,fetchGroup} = props;
    const mounted = useRef(false);
    const [user, setUser] = useState({});
    const [activeTab, setActiveTab] = useState('tab1');
    const [role, setRole] = useState('advisor');
    const [imageModalShow, setImageModalShow] = useState(false);

    useEffect(() => {
        mounted.current = true;
        checkAuth(history);
        setRole(decodeRole());
        if (props.groupList === undefined || props.groupList.length === 0) {
            checkAuthWithCallback(history, fetchGroups);
        }
        return () => {
            mounted.current = false;
            setUser({});
            setActiveTab('tab1');
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (mounted.current) {
            if (props.seriesList === undefined || props.seriesList.length === 0) {
                checkAuthWithCallback(history, fetchSeriesList);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.seriesList]);

    useEffect(() => {
        if (mounted.current) {
            if (props.user === undefined || props.user.id === undefined || props.user.id !== id) {
                checkAuthWithCallback(history, fetchUser, id);
            }
            if (props.user.id !== undefined && props.user.id === id) {
                checkAuthWithCallback(history, fetchGroup, props.user.groupId);
                setUser(props.user);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.user, user]);

    const showImageModal = () => {
        if (!imageModalShow) {
            setImageModalShow(true);
        }
    }

    const handleCloseImageModal = () => {
        if (imageModalShow) {
            setImageModalShow(false);
        }
    }

    if (user.id !== undefined) {
        return (
            <>
                <div className={"mt--7"}>
                    <Row>
                        <Col xl="4">
                            <Card className="card-profile shadow mt-6">
                                <Row className="justify-content-center">
                                    <Col lg="3">
                                        <div className="card-profile-image">
                                            <div className="rounded-circle mt--4">
                                                <img
                                                    className="cursor-pointer"
                                                    alt={(user.firstName ?? user.emailId) + ' ' + (user.lastName ?? '')}
                                                    onClick={showImageModal}
                                                    src={user.profilePicThumbnailId || ("https://ui-avatars.com/api/?name=" + ([(user.firstName ?? ''), (user.lastName ?? '')].join(' ').trim() || user.emailId))}/>
                                                <ImageModal
                                                    image={user.profilePicId || ("https://ui-avatars.com/api/?name=" + ([(user.firstName ?? ''), (user.lastName ?? '')].join(' ').trim() || user.emailId))}
                                                    isModalShow={imageModalShow}
                                                    handleCloseModal={handleCloseImageModal}
                                                    alt={(user.firstName ?? user.emailId) + ' ' + (user.lastName ?? '')}/>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                                <CardHeader className="text-center border-0 pt-8 pt-md-4 pb-0 pb-md-4">
                                </CardHeader>
                                <CardBody className="pt-0 pt-md-4">
                                    <Row>
                                        <Col sm="12">
                                            <div className="text-center">
                                                <h3>{(user.firstName ?? '') + ' ' + (user.lastName ?? '')}</h3>
                                                <a className="text-muted h4 mb-2"
                                                   href={"mailto:" + user.emailId}>{user.emailId ?? ''}</a>
                                                <div className="h5 font-weight-300">{user.city}</div>
                                                <p>
                                                    <Badge color={getBadgeColor(user.userRole)}
                                                           className="mr-2">{getStaticDataValue(user.userRole, 'permission')}</Badge>
                                                    <Badge
                                                        color={getBadgeColor(user.userStatus)}>{getStaticDataValue(user.userStatus, 'status')}</Badge>
                                                </p>
                                            </div>
                                        </Col>
                                        <Col sm="12">
                                            <Nav pills fill vertical role="tablist">
                                                <NavItem>
                                                    <NavLink
                                                        role='tab'
                                                        className={classnames({
                                                            active: activeTab === 'tab1',
                                                            'cursor-pointer': true
                                                        })}
                                                        onClick={() => {
                                                            setActiveTab('tab1');
                                                        }}
                                                    >
                                                        User Details
                                                    </NavLink>
                                                </NavItem>
                                                {
                                                    role === 'admin'
                                                        ?
                                                        <>
                                                            <NavItem>
                                                                <NavLink
                                                                    role='tab'
                                                                    className={classnames({
                                                                        active: activeTab === 'tab2',
                                                                        'cursor-pointer': true
                                                                    })}
                                                                    onClick={() => {
                                                                        setActiveTab('tab2');
                                                                    }}
                                                                >
                                                                    Update User
                                                                </NavLink>
                                                            </NavItem>
                                                            {/*<NavItem>*/}
                                                            {/*    <NavLink*/}
                                                            {/*        role='tab'*/}
                                                            {/*        className={classnames({*/}
                                                            {/*            active: activeTab === 'tab3',*/}
                                                            {/*            'cursor-pointer': true*/}
                                                            {/*        })}*/}
                                                            {/*        onClick={() => {*/}
                                                            {/*            setActiveTab('tab3');*/}
                                                            {/*        }}*/}
                                                            {/*    >*/}
                                                            {/*        Other Operations*/}
                                                            {/*    </NavLink>*/}
                                                            {/*</NavItem>*/}
                                                        </>
                                                        : ''}
                                                {
                                                    role === 'admin'
                                                        ?
                                                        <>
                                                            <NavItem>
                                                                <NavLink
                                                                    role='tab'
                                                                    className={classnames({
                                                                        active: activeTab === 'tab4',
                                                                        'cursor-pointer': true
                                                                    })}
                                                                    onClick={() => {
                                                                        setActiveTab('tab4');
                                                                    }}
                                                                >
                                                                    ME Screen
                                                                </NavLink>
                                                            </NavItem>
                                                        </>
                                                        : ''
                                                }
                                                {
                                                    role !== "admin"
                                                        ?
                                                        <NavItem>
                                                            <NavLink
                                                                role='tab'
                                                                className={classnames({
                                                                    active: activeTab === 'tab5',
                                                                    'cursor-pointer': true
                                                                })}
                                                                onClick={() => {
                                                                    setActiveTab('tab5');
                                                                }}
                                                            >
                                                                We Screen
                                                            </NavLink>
                                                        </NavItem>
                                                        : ""
                                                }
                                                {
                                                    role === 'admin'
                                                        ?
                                                        <>
                                                            <NavItem>
                                                                <NavLink
                                                                    role='tab'
                                                                    className={classnames({
                                                                        active: activeTab === 'tab6',
                                                                        'cursor-pointer': true
                                                                    })}
                                                                    onClick={() => {
                                                                        setActiveTab('tab6');
                                                                    }}
                                                                >
                                                                    My Teams
                                                                </NavLink>
                                                            </NavItem>
                                                        </>
                                                        : ''
                                                }
                                            </Nav>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col xl="8" className="mt-4 mt-lg-0">
                            <TabContent activeTab={activeTab}>
                                <TabPane tabId="tab1">
                                    <Row>
                                        <Col sm="12">
                                            <UserDetail userData={user} viewerRole={role}/>
                                        </Col>
                                    </Row>
                                </TabPane>
                                {
                                    role === 'admin'
                                        ?
                                        <>
                                            {activeTab === 'tab2' ?
                                                <TabPane tabId="tab2">
                                                    <Row>
                                                        <Col sm="12">
                                                            <Create viewerRole={role}/>
                                                        </Col>
                                                    </Row>
                                                </TabPane>: ''}
                                            <TabPane tabId="tab3">
                                                <Row>
                                                    <Col sm="12">
                                                        <OtherOperation userData={user} viewerRole={role}/>
                                                    </Col>
                                                </Row>
                                            </TabPane>
                                            <TabPane tabId="tab4">
                                                <Row>
                                                    <Col sm="12">
                                                        <MEScreen viewerRole={role} userData={user}/>
                                                    </Col>
                                                </Row>
                                            </TabPane>
                                        </>
                                        : ''}
                                <TabPane tabId="tab5">
                                    <Row>
                                        <Col sm="12">
                                            <WeScreen viewerRole={role} userData={user}/>
                                        </Col>
                                    </Row>
                                </TabPane>
                                {
                                    role === 'admin'
                                        ?
                                        <>
                                            <TabPane tabId="tab6">
                                                <Row>
                                                    <Col sm="12">
                                                        <MyTeams userData={user} viewerRole={role}/>
                                                    </Col>
                                                </Row>
                                            </TabPane>
                                        </>
                                        : ''}
                            </TabContent>
                        </Col>
                    </Row>
                </div>
            </>
        );
    } else {
        return (
            <>
                <div className="mt--7">
                    <Row>
                        <Col sm="12" className="text-center">
                            <Card>
                                <CardBody className="min-vh-60 lds-parent">
                                    <Loader className="mt-7 mb-5"/>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.user.user ?? {},
        seriesList: state.series.seriesSelect ?? []
    }
}

const mapDispatchToProps = {
    fetchUser,
    fetchSeriesList,
    fetchGroups,
    fetchGroup
}

export default connect(mapStateToProps, mapDispatchToProps)(View);