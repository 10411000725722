import React, {useEffect, useRef, useState} from "react";
import {connect} from "react-redux";
import {Button, ButtonGroup, Tooltip} from "reactstrap";
import {checkAuth, displayMessage, showConfirmDialog, switchToRole} from "../../config/helper";
import {deleteAdvisor, directAdvisorLogin} from "../../redux/advisor/advisor.actions";
import {useHistory} from "react-router-dom";

const loaderStatus = {
    deleteAdvisor: false,
    viewDashboard: false
};

const TableAction = (props) => {
    const {deleteAdvisor, directAdvisorLogin} = props;
    const [deleteTooltipOpen, setDeleteTooltipOpen] = useState(false);
    const [viewDashboardTooltipOpen, setViewDashboardTooltipOpen] = useState(false);
    const [loadingBtn, setLoadingBtn] = useState(loaderStatus);
    const mounted = useRef(false);
    const history = useHistory();

    useEffect(() => {
        checkAuth(history);
        mounted.current = true;
        return () => {
            mounted.current = false;
            setLoadingBtn(loaderStatus);
        };
    }, []);

    const deleteToggleTooltip = () => setDeleteTooltipOpen(!deleteTooltipOpen);
    const viewDashboardToggleTooltip = () => setViewDashboardTooltipOpen(!viewDashboardTooltipOpen);

    const handleDelete = (row, status) => {
        if (mounted.current) {
            if (status) {
                deleteAdvisor(row).then((data) => {
                    if (!('payload' in data && data.payload === undefined)) {
                        displayMessage("Coach deleted");
                    }
                    updateLoaderData('deleteAdvisor', false);
                });
            }
        }
    }

    const handleViewAsAdvisor = () => {
        if(props.row && props.row.user && props.row.user.id) {
            updateLoaderData('viewDashboard',true);
            directAdvisorLogin({id: props.row.user.id}).then((data) => {
                if(data.payload && data.payload.accessToken){
                    switchToRole(history,'admin','advisor',data.payload);
                }
                updateLoaderData('viewDashboard',false);
            });
        }
    }

    const updateLoaderData = (key, val) => {
        let loading = loadingBtn;
        if (key in loading) {
            loading[key] = val;
            setLoadingBtn(loading);
        }
    }

    return (
        <>
            <div className='d-flex'>
                <ButtonGroup>
                    <Button
                        color="danger"
                        size="sm"
                        type="button"
                        onClick={() => showConfirmDialog(props.row, handleDelete, 'Are you sure!')}
                        id={'deleteTooltip-' + props.row.id}
                    >
                        {loadingBtn.deleteAdvisor ? <i className='fa fa-spinner fa-spin'/> :
                            <i className={'fa fa-trash'}/>}
                    </Button>
                    <Button
                        color="info"
                        size="sm"
                        type="button"
                        onClick={() => handleViewAsAdvisor()}
                        id={'viewDashboardTooltip-' + props.row.id}
                    >
                        {loadingBtn.viewDashboard ? <i className='fa fa-spinner fa-spin'/> :
                            <i className={'fa fa-desktop'}/>}
                    </Button>
                </ButtonGroup>
                <Tooltip placement="top" isOpen={deleteTooltipOpen} autohide={false}
                         target={'deleteTooltip-' + props.row.id} toggle={deleteToggleTooltip}>
                    Delete
                </Tooltip>
                <Tooltip placement="top" isOpen={viewDashboardTooltipOpen} autohide={false}
                         target={'viewDashboardTooltip-' + props.row.id} toggle={viewDashboardToggleTooltip}>
                    View Dashboard
                </Tooltip>
            </div>
        </>
    );
}

const mapStateToProps = () => {
    return {}
}

const mapDispatchToProps = {
    deleteAdvisor,
    directAdvisorLogin
}

export default connect(mapStateToProps, mapDispatchToProps)(TableAction);