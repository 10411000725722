import React, {useEffect, useRef, useState} from 'react'
import {connect} from "react-redux"
import {Field, FieldArray, reduxForm} from 'redux-form'
import {Button, Col, Row} from "reactstrap";
import {renderSelectField, renderTextField} from "../../../config/formElement";
import Loader from "../../../components/Loader/Loader";
import {checkAuth, deepEqual, stringRestyle} from "../../../config/helper";
import {useHistory} from "react-router-dom";
import {fetchNotificationSetting} from "../../../redux/cron-setting/cronSetting.actions";
import {cronSettingSchema} from "../../../config/schema";

const validate = (values) => {
    let errors = {};
    if (values.value && values.value.length > 0) {
        let notificationArrayErrors = [];
        values.value.forEach((notification, notificationIndex) => {
            const notificationErrors = {notification:{}}
            if (!notification || !notification.hour) {
                notificationErrors.hour = 'Hour is required';
                notificationArrayErrors[notificationIndex] = notificationErrors;
            }
            if (!notification || !notification.minute) {
                notificationErrors.minute = 'Minute is required';
                notificationArrayErrors[notificationIndex] = notificationErrors;
            }
            if (!notification || !notification.notification.title) {
                notificationErrors.notification.title = 'Title is required';
                notificationArrayErrors[notificationIndex] = notificationErrors;
            }
            if (!notification || !notification.notification.body) {
                notificationErrors.notification.body = 'Message is required';
                notificationArrayErrors[notificationIndex] = notificationErrors;
            }
        });
        if (notificationArrayErrors.length) {
            errors.value = notificationArrayErrors;
        }
    }
    return errors;
}

const warn = () => {
    return {};
}

let NotificationSettingFormCreate = (props) => {
    const {handleSubmit, fetchNotificationSetting} = props;
    const mounted = useRef(false);
    const [isSubmitting, setIsSubmitting] = useState({text: 'Save', icon: ''});
    const [isLoading, setIsLoading] = useState(true);
    const history = useHistory();
    const [cronNotifications, setCronNotifications] = useState(<></>);
    const [fields,setFields] = useState(cronSettingSchema());

    const hourList = Array.from({length: 24}, (v, i) => {
        return {value: i, label: i};
    })

    const minuteList = Array.from({length: 59}, (v, i) => {
        return {value: i, label: i};
    })

    useEffect(() => {
        checkAuth(history);
        mounted.current = true;
        fetchNotificationSetting({key: "CRON_NOTIFICATION"});

        return () => {
            mounted.current = false;
            setIsSubmitting({text: 'Save', icon: ''});
        };
    }, []);

    useEffect(() => {
        if (props.submitBtnText !== isSubmitting.text) {
            setIsSubmitting({text: props.submitBtnText, icon: props.submitBtnIcon});
        } else {
            if (isLoading) {
                setIsSubmitting({text: 'Wait', icon: ''});
            } else {
                setIsSubmitting({text: 'Save', icon: ''});
            }
        }
    }, [props.submitBtnText, props.submitBtnIcon, isLoading]);

    useEffect(() => {
        setIsLoading(false);
        if (props.cronSetting && props.cronSetting.value.length > 0 && !deepEqual(props.cronSetting,fields)) {
            setFields(props.cronSetting);
            updateFields();
        }
    }, [props.cronSetting]);

    const renderNotificationFields = ({fields, meta: {touched, error, submitFailed}}) => {
        return (
            <>
                {fields.map((notification, index) =>
                    <Row key={"notification-" + index + "-row"}>
                        {index !== 0 ? <Col sm={12}>
                            <hr/>
                        </Col> : ''}
                        <Col xl={12}>
                            <h3>{stringRestyle((props.cronSetting.value[index].key || '').toLowerCase(), 'firstUpperCase')}</h3>
                            <Field
                                name={`${notification}.key`}
                                type="hidden"
                                label=""
                                component={renderTextField}
                            />
                        </Col>
                        <Col xl={6}>
                            <Field
                                name={`${notification}.hour`}
                                type="select"
                                component={renderSelectField}
                                hasDefaultValue={props.cronSetting.value[index].hour}
                                label="Hour"
                                options={hourList ?? []}
                                isRequired={true}
                            />
                        </Col>
                        <Col xl={6}>
                            <Field
                                name={`${notification}.minute`}
                                type="select"
                                component={renderSelectField}
                                hasDefaultValue={props.cronSetting.value[index].minute}
                                label="Minute"
                                options={minuteList ?? []}
                                isRequired={true}
                            />
                        </Col>
                        <Col xl={12}>
                            <Field
                                name={`${notification}.notification.title`}
                                type="text"
                                component={renderTextField}
                                hasDefaultValue={props.cronSetting.value[index].notification.title}
                                label="Title"
                                isRequired={true}
                            />
                        </Col>
                        <Col xl={12}>
                            <Field
                                name={`${notification}.notification.body`}
                                type="textarea"
                                component={renderTextField}
                                hasDefaultValue={props.cronSetting.value[index].notification.body}
                                label="Message"
                                rows={4}
                                isRequired={true}
                            />
                        </Col>
                    </Row>
                )}
            </>
        );
    }

    const updateFields = () => {
        if (props.cronSetting && props.cronSetting.value && props.cronSetting.value.length > 0) {
            setCronNotifications(<FieldArray name="value" defaultValues={props.cronSetting.value}
                                             component={renderNotificationFields}/>);
        }
    }

    return (
        <>
            {
                isLoading ?
                    <div className="text-center"><Loader/></div>
                    :
                    <form onSubmit={(values) => handleSubmit(values)}>
                        <Row>
                            <Col lg="12">
                                {cronNotifications}
                            </Col>
                            <Col lg="12">
                                <Button color="primary" type="submit" disabled={isSubmitting.text !== 'Save'}>
                                    {isSubmitting.icon !== '' ?
                                        <i className={isSubmitting.icon}/> : ''} {isSubmitting.text}
                                </Button>
                            </Col>
                        </Row>
                    </form>
            }
        </>)
}

const mapStateToProps = (state) => {
    return {
        cronSetting: state.cronSetting.cronSetting ? cronSettingSchema(state.cronSetting.cronSetting) : cronSettingSchema(),
        initialValues: state.cronSetting.cronSetting ? cronSettingSchema(state.cronSetting.cronSetting) : cronSettingSchema()
    };
}

const mapDispatchToProps = {
    fetchNotificationSetting
}

NotificationSettingFormCreate = reduxForm({
    form: 'notificationSettingFormCreate',
    enableReinitialize: true,
    validate,
    warn
})(NotificationSettingFormCreate)

export default connect(mapStateToProps, mapDispatchToProps)(NotificationSettingFormCreate)