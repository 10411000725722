import {SIGNOUT} from "./auth.types";

const INITIAL_STATE = {
};

const reducer = (state = INITIAL_STATE, action) => {
  try {
    switch (action.type) {
      case SIGNOUT:
      default:
        return state;
    }
  } catch (e) {
    // console.log({e});
  }
  return state;
};

export default reducer;