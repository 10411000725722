/*!

=========================================================
* Argon Dashboard React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, {useEffect, useRef} from "react";
import {useLocation, Route, Switch, Redirect, useHistory} from "react-router-dom";
// reactstrap components
import { Container, Row, Col } from "reactstrap";

// core components

// import routes from "../routes";
import Register from "../views/examples/Register";
import Login from "../views/examples/Login";
import {checkRole, validateToken} from "../config/helper";

const Auth = () => {
  const mainContent = useRef(null);
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    document.body.classList.add("bg-white");
    const tokenStatus = validateToken();
    if(tokenStatus) {
      checkRole('', history);
    }
    return () => {
      document.body.classList.remove("bg-white");
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    mainContent.current.scrollTop = 0;
  }, [location]);

  /*const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.layout === "/auth") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };*/

  return (
    <>
      <div className="main-content" ref={mainContent}>
        {/*<AuthNavbar />*/}
        <div className="header bg-gradient-primary py-7 py-lg-8">
          <Container>
            <div className="header-body text-center mb-7">
              <Row className="justify-content-center">
                <Col lg="5" md="6">
                  <img
                      alt="..."
                      src={
                        require("../assets/img/brand/logo.png").default
                      }
                      style={{maxHeight:'5rem'}}
                  />
                  <h1 className="text-white">Welcome to Lovework!</h1>
                </Col>
              </Row>
            </div>
          </Container>
          <div className="separator separator-bottom separator-skew zindex-100">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
              version="1.1"
              viewBox="0 0 2560 100"
              x="0"
              y="0"
            >
              <polygon
                className="fill-white"
                points="2560 0 2560 100 0 100"
              />
            </svg>
          </div>
        </div>
        {/* Page content */}
        <Container className="mt--8 pb-5">
          <Row className="justify-content-center">
            <Switch>
              <Route
                  path="/auth/login/:role?"
                  component={Login}
              />
              <Route
                path="/auth/register"
                component={Register}
              />
              <Redirect from="*" to="/auth/login/:role?" />
            </Switch>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Auth;
