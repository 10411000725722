import {FETCH, POST} from './appSetting.types';

const INITIAL_STATE = {
  appSetting: {},
};

const reducer = (state = INITIAL_STATE, action) => {
  let appSetting = [];
  try {
    switch (action.type) {
      case FETCH:
      case POST:
        appSetting = action.payload.setting ?? {};
        return {
          ...state,
          appSetting: appSetting
        };
      default:
        return state;
    }
  } catch (e) {
    // console.log({e});
  }
  return state;
};

export default reducer;