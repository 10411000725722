import {FormGroup, FormText, Input, Label} from "reactstrap";
import React from "react";
import AsyncSelect from "react-select/async";
import {reactSelectStyle} from "./helper";
import Select from "react-select";

export const renderTextField = ({
                                    input,
                                    label,
                                    type,
                                    isRequired = false,
                                    isDisabled = false,
                                    hasDefaultValue,
                                    className = '',
                                    meta
                                }) => {
    if (hasDefaultValue !== undefined) {
        delete input.value;
        return (
            <>
                <FormGroup>
                    {label.length > 0 ?
                        <Label>{label} {isRequired ? <span className="text-danger">*</span> : ''}</Label> : ''}
                    <Input {...input} className={className} placeholder={label} type={type}
                           defaultValue={hasDefaultValue || ''}
                           disabled={isDisabled || false}/>
                    {meta.touched && meta.error && <span className="text-danger">{meta.error}</span>}
                </FormGroup>
            </>
        );
    } else {
        return (
            <>
                <FormGroup>
                    {label.length > 0 ?
                        <Label>{label} {isRequired ? <span className="text-danger">*</span> : ''}</Label> : ''}
                    <Input {...input} className={className} placeholder={label} type={type} value={input.value || ''}
                           disabled={isDisabled || false}/>
                    {meta.touched && meta.error && <span className="text-danger">{meta.error}</span>}
                </FormGroup>
            </>
        );
    }
}

export const renderRadioField = ({
                                     input,
                                     label,
                                     type,
                                     isRequired = false,
                                     isDisabled = false,
                                     hasDefaultValue,
                                     meta,
                                     hasClassName = 'ml-3'
                                 }) => {
    if (hasDefaultValue !== undefined) {
        delete input.value;
        return (
            <>
                <FormGroup>
                    <Label check className={hasClassName ? hasClassName : " ml-3 pt-5"}>
                        <Input {...input} type={type} defaultValue={hasDefaultValue || ''}
                               disabled={isDisabled || false} checked={hasDefaultValue || false}/>
                        {label}
                    </Label>
                    {meta.touched && meta.error && <span className="text-danger">{meta.error}</span>}
                </FormGroup>
            </>
        );
    } else {
        return (
            <>
                <FormGroup>
                    <Label check className={hasClassName ? hasClassName : " ml-3 pt-5"}>
                        <Input {...input} type={type} value={input.value || ''}
                               disabled={isDisabled || false}/>
                        {label}
                    </Label>
                    {meta.touched && meta.error && <span className="text-danger">{meta.error}</span>}
                </FormGroup>
            </>
        );
    }
}

export const renderSelectField = ({
                                      input,
                                      label,
                                      type,
                                      isRequired = false,
                                      isMultiple = false,
                                      isDisabled = false,
                                      hasDefaultValue,
                                      options,
                                      meta
                                  }) => {
    delete input.value;
    let optionList = [];
    if (options.length > 0) {
        for (const key in options) {
            optionList.push(<option value={options[key].value} key={key}
                                    disabled={options[key].disable || false}>{options[key].label}</option>);
        }
    }
    return (
        <>
            <FormGroup>
                <Label>{label} {isRequired ? <span className="text-danger">*</span> : ''}</Label>
                <Input {...input} placeholder={label} type={type}
                       defaultValue={isMultiple ? (hasDefaultValue || []) : (hasDefaultValue || '')}
                       disabled={isDisabled || false} multiple={isMultiple || false}>
                    {optionList}
                </Input>
                {meta.touched && meta.error && <span className="text-danger">{meta.error}</span>}
                {options.length === 0 ?
                    <FormText className="text-muted"><span className="text-danger">Warning : </span> No item
                        found.</FormText> : ''}
            </FormGroup>
        </>
    );
}

export const renderSelectFieldWithValue = ({
                                               input,
                                               label,
                                               type,
                                               isRequired = false,
                                               isMultiple = false,
                                               isDisabled = false,
                                               options,
                                               meta
                                           }) => {
    let optionList = [];
    if (options.length > 0) {
        for (const key in options) {
            optionList.push(<option value={options[key].value} key={key}
                                    disabled={options[key].disable || false}>{options[key].label}</option>);
        }
    }
    return (
        <>
            <FormGroup>
                <Label>{label} {isRequired ? <span className="text-danger">*</span> : ''}</Label>
                <Input {...input} placeholder={label} type={type}
                       disabled={isDisabled || false} multiple={isMultiple || false}>
                    {optionList}
                </Input>
                {meta.touched && meta.error && <span className="text-danger">{meta.error}</span>}
                {options.length === 0 ?
                    <FormText className="text-muted"><span className="text-danger">Warning : </span> No item
                        found.</FormText> : ''}
            </FormGroup>
        </>
    );
}

export const renderSelectSearchableField = ({
                                                input,
                                                label,
                                                isRequired = false,
                                                isMultiple = false,
                                                meta,
                                                options,
                                                hasDefaultValue = [],
                                                changeEvent,
                                                refEle
                                            }) => {

    return (<>
        <FormGroup>
            <Label>{label} {isRequired ? <span className="text-danger">*</span> : ''}</Label>
            <Select
                {...input}
                name={input.name}
                aria-required={isRequired}
                isMulti={isMultiple}
                styles={reactSelectStyle}
                // defaultValue={hasDefaultValue}
                options={options}
                ref={refEle}
                onChange={(value) => {
                    input.onChange(value);
                    changeEvent(value);
                }}
                onBlur={() => {
                }}
            />
            {meta.touched && meta.error && <span className="text-danger">{meta.error}</span>}
        </FormGroup>
    </>)
}

export const renderSelectUserAsyncField = ({
                                               input,
                                               label,
                                               isRequired = false,
                                               isMultiple = false,
                                               meta,
                                               loadOptions,
                                               hasDefaultValue,
                                               changeEvent
                                           }) => {
    return (<>
        <FormGroup>
            <Label>{label} {isRequired ? <span className="text-danger">*</span> : ''}</Label>
            <AsyncSelect
                {...input}
                name={input.name}
                getOptionLabel={(option) => `${[(option.firstName || ''), (option.lastName || ''), ':'].join(' ').trim()} ${option.emailId}`}
                noOptionsMessage={() => "Type 3 or more letters to search"}
                getOptionValue={e => e.id}
                loadOptions={loadOptions}
                isMulti={isMultiple}
                styles={reactSelectStyle}
                defaultValue={hasDefaultValue}
                aria-required={isRequired}
                onChange={(value) => {
                    input.onChange(value);
                    changeEvent(value);
                }}
                onBlur={() => {
                }}
            />
            {meta.touched && meta.error && <span className="text-danger">{meta.error}</span>}
        </FormGroup>
    </>)
}

export const renderFileField = ({
                                    input,
                                    label,
                                    isRequired = false,
                                    isDisabled = false,
                                    className = '',
                                    accept = '*',
                                    meta
                                }) => {
    delete input.value;
    return (
        <>
            <FormGroup>
                {label.length > 0 ?
                    <Label>{label} {isRequired ? <span className="text-danger">*</span> : ''}</Label> : ''}
                <Input {...input} className={className} placeholder={label} type="file"
                       disabled={isDisabled || false} accept={accept}
                />
                {meta.touched && meta.error && <span className="text-danger">{meta.error}</span>}
            </FormGroup>
        </>
    );
}