/*!

=========================================================
* Argon Dashboard React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, {useEffect, useRef} from "react";
import {useLocation, Route, Switch, Redirect, useHistory} from "react-router-dom";
// reactstrap components
import { Container } from "reactstrap";
// core components
import AdminNavbar from "components/Navbars/AdminNavbar.js";
import AdminFooter from "components/Footers/AdminFooter.js";
import Sidebar from "components/Sidebar/Sidebar.js";

import routes from "../routes";
import {checkAuth, checkRole} from "../config/helper";
import Header from "../components/Headers/Header";

import UserCreate from "../views/user/Create";
import UserUpload from "../views/user/Upload";
import TeamCreate from "../views/team/Create";
import SeriesCreate from "../views/series/Create";
import SeriesItemCreate from "../views/series-items/Create";
import AdvisorCreate from "../views/advisor/Create";
import HuddleCreate from "../views/huddle/Create";

import GroupView from "../views/group/View";
import UserView from "../views/user/View";
import CreateNotification from "../views/notification/CreateNotification";

const Admin = (props) => {
  const mounted = useRef(false);
  const history = useHistory();
  const mainContent = React.useRef(null);
  const location = useLocation();

  useEffect(() => {
    mounted.current = true;
    checkAuth(history);
    checkRole('admin',history);
    return () => {
      mounted.current = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    mainContent.current.scrollTop = 0;
  }, [location]);

  const getRoutes = (routes) => {
    return routes.sort(function(a, b){return a.order - b.order}).map((prop, key) => {
      if (prop.layout === "/admin") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };

  const getBrandText = () => {
    let routesList = routes.sort((a,b) => b.order - a.order);
    for (let i = 0; i < routesList.length; i++) {
      if (
        props.location.pathname.indexOf(routesList[i].layout + routesList[i].path) !==
        -1
      ) {
        return routesList[i].name;
      }
    }
    return "Lovework";
  };

  return (
    <>
      <Sidebar
        {...props}
        routes={routes}
        logo={{
          innerLink: "/admin/dashboard",
          imgSrc: require("../assets/img/brand/logo.png").default,
          imgAlt: "...",
        }}
      />
      <div className="main-content" ref={mainContent}>
        <AdminNavbar
          {...props}
          brandText={getBrandText()}
        />
        <Header/>

        <Container className="mt--7" style={{
          minHeight: "65vh"
        }} fluid>
          <Switch>
            <Route path="/admin/user/view/:id" component={UserView} />
            <Route path="/admin/user/create" component={UserCreate} />
            <Route path="/admin/user/update/:id" component={UserCreate} />
            <Route path="/admin/user/upload" component={UserUpload} />
            <Route path="/admin/team/create" component={TeamCreate} />
            <Route path="/admin/team/update/:id" component={TeamCreate} />
            <Route path="/admin/practice/create" component={SeriesCreate} />
            <Route path="/admin/practice/clone/:duplicateId" component={SeriesCreate} />
            <Route path="/admin/practice/update/:id" component={SeriesCreate} />
            <Route path="/admin/content/create" component={SeriesItemCreate} />
            <Route path="/admin/content/update/:id" component={SeriesItemCreate} />
            <Route path="/admin/organization/view/:id" component={GroupView} />
            <Route path="/admin/coach/create" component={AdvisorCreate} />
            <Route path="/admin/coach/update/:id" component={AdvisorCreate} />
            <Route path="/admin/team-hub/create" component={HuddleCreate} />
            <Route path="/admin/team-hub/update/:id" component={HuddleCreate} />
            {/*<Route path="/admin/important-info/create" component={NotificationCreate} />*/}
            {/*<Route path="/admin/important-info/create-adhoc" component={AdhocMeItemCreate} />*/}
            <Route path="/admin/important-info/create-important-info" component={CreateNotification} />
            {getRoutes(routes)}
            <Redirect from="*" to="/admin/dashboard" />
          </Switch>
        </Container>

        <Container fluid>
          <AdminFooter />
        </Container>
      </div>
    </>
  );
};

export default Admin;
