import React, {useEffect, useMemo, useRef, useState} from "react";
import {
    Card,
    CardBody,
    CardHeader,
    Col,
    Row
} from "reactstrap";
import DataTable from "react-data-table-component";
import Loader from "../../components/Loader/Loader";
import {connect} from "react-redux";
import {fetchGroups} from "../../redux/group/group.actions";
import CreateModal from "./CreateModal";
import {useHistory} from "react-router-dom";
import Filter from "../../components/filters/Filter";
import {checkAuth, checkAuthWithCallback, checkRole} from "../../config/helper";
import TableAction from "./TableAction";

const Index = (props) => {
    const {fetchGroups} = props;
    const mounted = useRef(false);
    const history = useHistory();
    const [listLoader,setListLoader] = useState(true);
    const [filterText, setFilterText] = useState('');
    const [filteredItems,setFilteredItems] = useState([]);

    const subHeaderComponentMemo = useMemo(() => {
        return (
            <Filter onFilter={val => {
                setFilterText(val)
            }} filterText={filterText} />
        );
    }, [filterText]);

    const columns = [
        {
            name: 'Organization',
            selector: row => row.groupName,
            sortable: true
        },
        {
            name: 'Impact Survey Link',
            selector: row => row.impactSurveyLink,
            sortable: true
        },
        {
            name: 'Action',
            center: true,
            cell: (row) => {
                return (
                    <TableAction row={row} onDataUpdated={updateGroupList}/>
                )
            },
            maxWidth: '150px'
        },
    ];

    useEffect(() => {
        checkAuth(history);
        checkRole('admin',history);
        mounted.current = true;
        setListLoader(true);
        checkAuthWithCallback(history,fetchGroups);
        setListLoader(false);
        return () => {
            mounted.current = false;
            setFilteredItems([]);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if(mounted.current) {
            if (props.groupList.length > 0) {
                let filteredData = props.groupList.filter(
                    item => (item.groupName && item.groupName.toLowerCase().includes(filterText.toLowerCase()))
                );
                setFilteredItems(filteredData);
            }
            setListLoader(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.groupList,filterText,fetchGroups]);

    const updateGroupList = () => {
        if(mounted.current) {
            setListLoader(true);
            checkAuthWithCallback(history,fetchGroups);
            setFilteredItems([]);
        }
    }

    return (
        <>
            {/* Page content */}
            <Row className="justify-content-center">
                <Col xl="12" lg="12" md="12">
                    <Card className="shadow border-0">
                        <CardHeader>
                            <Row>
                                <Col lg="12" md="12">
                                    <span className={"mb-0 h3"}>Organization list</span>
                                    <CreateModal
                                        modalBtnText='Create Organization'
                                        modalBtnIcon="ni ni-fat-add mr-2"
                                        modalTitleText="Create Organization"
                                        updateGroupList={updateGroupList}
                                    />
                                </Col>
                            </Row>
                        </CardHeader>
                        <CardBody>
                            <Row>
                                <Col lg="12">
                                    <DataTable
                                        columns={columns}
                                        progressPending={listLoader}
                                        progressComponent={<Loader/>}
                                        className={"react-dataTable"}
                                        data={filteredItems}
                                        pagination
                                        responsive
                                        subHeaderWrap
                                        subHeader
                                        subHeaderComponent={subHeaderComponentMemo}
                                    />
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </>
    )
};

const mapStateToProps = (state) => {
    return {
        groupList: state.group.groupList ?? [],
    }
}

const mapDispatchToProps = {
    fetchGroups
}

export default connect(mapStateToProps, mapDispatchToProps)(Index);
