import {FETCH} from './member.types';
import {now} from "moment";

const INITIAL_STATE = {
    memberList: [],
    timestamp: ''
};

export default function reducer(state = INITIAL_STATE, action) {
    let memberList = [];
    try {
        switch (action.type) {
            case FETCH:
                memberList = action.payload.memberList ?? [];
                return {
                    ...state,
                    memberList: memberList,
                    timestamp: now()
                };
            default:
                return state;
        }
    } catch (e) {
        // console.log({e})
    }
    return state;
}