import {connect} from "react-redux";
import {Col, Row} from "reactstrap";
import MailTemplateCreateForm from "./form/Update";
import {useEffect, useState} from "react";
import {fetchMails, updateMail} from "../../redux/mail/mail.actions";

const Create = (props) => {
    const {updateMail} = props;
    const [submitBtnText, setSubmitBtnText] = useState('Save');
    const [submitBtnIcon, setSubmitBtnIcon] = useState('');

    const handleSubmitBtn = (val) => {
        if (val) {
            setSubmitBtnText('Saving');
            setSubmitBtnIcon('fa fa-spinner fa-spin');
        } else {
            setSubmitBtnText('Save');
            setSubmitBtnIcon('');
        }
    }

    const handleSubmit = (values) => {
        handleSubmitBtn(false);
        updateMail(values).then(() => {
            // fetchMails();
            handleSubmitBtn(true);
        });
    }

    return (
        <>
            <Row>
                <Col lg={12}>
                    <MailTemplateCreateForm mailTemplate={props.mailTemplate} saveMail={handleSubmit} submitBtnText={submitBtnText}
                                            submitBtnIcon={submitBtnIcon} />
                </Col>
            </Row>
        </>
    )
}

const mapStateToProps = () => {
    return {
    };
}

const mapDispatchToProps = {
    updateMail,
    fetchMails
}

export default connect(mapStateToProps, mapDispatchToProps)(Create);