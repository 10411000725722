import React, {useEffect, useRef, useState} from "react";
import {
    ButtonGroup,
    Card,
    CardBody,
    CardHeader,
    Col, Form, FormGroup, Label,
    Row
} from "reactstrap";
import DataTable from "react-data-table-component";
import {connect} from "react-redux";
import {Link, useHistory} from "react-router-dom";
import Loader from "../../components/Loader/Loader";
import {fetchSeriesItems, fetchSeriesList} from "../../redux/series/series.actions";
import {checkAuth, checkAuthWithCallback, reactSelectStyle} from "../../config/helper";
import {
    seriesViewLayoutType
} from "../../config/staticData";
import Select from "react-select";
import {paginationSchema} from "../../config/schema";
import ImageModal from "../../components/Modals/ImageModal";

const defaultFeedTypeList = [
    { label:"Me Feed",value:"meFeed"},
    { label:"Go Deeper",value:"goDeeper"}

]
const Index = (props) => {

    const {fetchSeriesList, fetchSeriesItems} = props;
    const mounted = useRef(false);
    const selectViewTemplateRef = useRef();
    const selectSeriesRef = useRef();
    const history = useHistory();
    const [listLoader, setListLoader] = useState(true);
    const [tableInfo, setTableInfo] = useState(paginationSchema());
    const [seriesList, setSeriesList] = useState([]);
    const [feedTypeList, setFeedTypeList] = useState(defaultFeedTypeList);
    const [timestamp, setTimestamp] = useState('');
    const [imageModalShow, setImageModalShow] = useState(false);
    const [image, setImage] = useState("");

    const showImageModal = (img) => {
        if (!imageModalShow) {
            setImage(img)
            setImageModalShow(true);
        }
    }
    const handleCloseImageModal = () => {
        if (imageModalShow) {
            setImageModalShow(false);
        }
    }


    const columns = [
        {
            name: 'BG Image',
            center: true,
            cell: (row) => {
                return (<div className={"d-table-cell"}>
                    <img
                        onClick={() => showImageModal(row.seriesItem.bgImg)}
                        alt="Card cap"
                        src={row.seriesItem.bgImg}
                        height={'50px'}
                        className="cursor-pointer"
                    />
                </div>);
            },
            maxWidth: "10%"
        },
        {
            name: 'Practice Name',
            selector: row => row.series.seriesName ?? '',
            fieldName: 'seriesName',
            maxWidth: "10%"
        },
        {
            name: 'Title',
            selector: row => {
                if ('title' in row.seriesItem.view) {
                    return row.seriesItem.view.title ?? '';
                }
                return '';
            },
            fieldName: 'view.title',
            sortable: true,
            maxWidth: "80%"
        },
        {
            name: 'Heading',
            selector: (row) => row.series && row.series.seriesHeader ? row.series.seriesHeader : '',
            sortable: false,
            maxWidth: "12%"
        },
        // {
        //     name: 'Template',
        //     selector: row => {
        //         if ('seriesItem' in row && 'viewTemplateName' in row.seriesItem) {
        //             return getStaticDataValue(row.seriesItem.viewTemplateName, 'seriesViewLayoutType');
        //         } else {
        //             return '';
        //         }
        //     },
        //     fieldName: 'viewTemplateName',
        //     sortable: false,
        //     maxWidth: "20%"
        // },
        // {
        //     name: 'Appear Day',
        //     selector: row => {
        //         if ('seriesItem' in row && 'appearDay' in row.seriesItem) {
        //             return getStaticDataValue(row.seriesItem.appearDay, 'weekDays');
        //         } else {
        //             return '';
        //         }
        //     },
        //     fieldName: 'appearDay',
        //     sortable: true
        // },
        // {
        //     name: 'Expire Day',
        //     selector: row => {
        //         if ('seriesItem' in row && 'appearExpire' in row.seriesItem) {
        //             return getStaticDataValue(row.seriesItem.appearExpire, 'weekDays');
        //         } else {
        //             return '';
        //         }
        //     },
        //     fieldName: 'appearExpire',
        //     sortable: true
        // },
        {
            name: 'Action',
            cell: (row) => {
                return (
                    <div className='d-flex'>
                        <ButtonGroup>
                            {/*<Button color="danger" outline size="sm" type="button" onClick={() => handleDelete(row)} disabled={true}><i*/}
                            {/*    className={"fa fa-trash"}/></Button>*/}
                            <Link
                                to={'/admin/content/update/' + row.seriesItem.id}
                                className={"btn btn-sm btn-primary"}
                            >
                                <i className="fa fa-edit"/>
                            </Link>
                        </ButtonGroup>
                    </div>
                )
            },
            maxWidth: '150px'
        },
    ];

    useEffect(() => {
        mounted.current = true;
        checkAuth(history);
        getSeriesItems();
        return () => {
            mounted.current = false;
            setTableInfo({
                pagination: {
                    pageNumber: 1,
                    pageSize: 10,
                    totalPages: 1,
                    totalRecords: 0
                },
                filter: {},
                sort: {}
            });
        };
    }, []);
    console.log(seriesList);
    useEffect(() => {
        if (props.timestamp !== timestamp) {
            setListLoader(false);
            setTimestamp(props.timestamp);
        }
    }, [props.timestamp]);

    useEffect(() => {
        if (props.seriesItemList === undefined) {
            getSeriesItems();
        }
    }, [props.seriesItemList]);

    useEffect(() => {
        if (props.seriesList === undefined || props.seriesList.length === 0) {
            fetchSeriesList();
        } else {
            updateSeriesList();
        }
    }, [props.seriesList, fetchSeriesList]);

    useEffect(() => {
        if (props.paginationResult !== undefined) {
            let page = tableInfo;
            page.pagination = {...page.pagination, ...props.paginationResult}
            setTableInfo(page);
        }
    }, [props.paginationResult]);

    const handlePageChange = pageNum => {
        let page = tableInfo;
        page.pagination.pageNumber = pageNum;
        setListLoader(true);
        setTableInfo(page);
        getSeriesItems();
    };

    const handlePerRowsChange = async (newPerPage, currentPage) => {
        let page = tableInfo;
        page.pagination.pageSize = newPerPage;
        page.pagination.pageNumber = currentPage;
        setListLoader(true);
        setTableInfo(page);
        getSeriesItems();
    };

    const getSeriesItems = () => {
        if (mounted.current) {
            setListLoader(true);
            checkAuthWithCallback(history, fetchSeriesItems, tableInfo);
        }
    }

    const handleSort = async (column, sortDirection) => {
        let page = tableInfo;
        page.sort = {};
        page.sort[column.fieldName] = sortDirection === 'asc' ? 1 : -1;
        setTableInfo(page);
        getSeriesItems();
    };

    const resetFilter = () => {
        let filterData = tableInfo;
        filterData.filter = {};
        setTableInfo(filterData);
        getSeriesItems();
    }

    const handleSelectFilter = (key, val) => {
        let filterData = tableInfo;
        if (val && val.value !== '') {
            filterData.filter[key] = val.value;
            setTableInfo(filterData);
            getSeriesItems();
        } else {
            delete filterData.filter[key];
            setTableInfo(filterData);
            getSeriesItems();
        }
    }

    const updateSeriesList = () => {
        let seriesOptions = props.seriesList;
        setSeriesList(seriesOptions);
    }

    return (
        <>
            {/* Page content */}
            <Row>
                <Col lg="12" md="12">
                    <Card className="shadow border-0">
                        <CardHeader>
                            <Row>
                                <Col lg="12" md="12">
                                    <span className={"mb-0 h3"}>Content List</span>

                                    <Link
                                        to='/admin/content/create'
                                        className={"d-flex float-right btn btn-sm btn-primary align-items-center"}
                                    >
                                        <i className="ni ni-fat-add mr-2"/>
                                        Add Content
                                    </Link>
                                </Col>
                            </Row>
                        </CardHeader>
                        <CardBody>
                            <Row>
                                <Col sm="12">
                                    <Form>
                                        <Card className="">
                                            {/*<CardHeader className="py-2">*/}
                                            {/*    <Button color="danger" type="reset" size="sm"*/}
                                            {/*            className="d-flex float-right align-items-center"*/}
                                            {/*            onClick={resetFilter}><i className="fa fa-undo mr-2"/> Reset</Button>*/}
                                            {/*</CardHeader>*/}
                                            <CardBody className="py-2">
                                                <Row>
                                                    <Col md="4">
                                                        <FormGroup>
                                                            <Label for="seriesId">
                                                                Practice
                                                            </Label>
                                                            <Select
                                                                id={"seriesId"}
                                                                ref={selectSeriesRef}
                                                                className="basic-single"
                                                                classNamePrefix="select"
                                                                isSearchable={true}
                                                                options={seriesList}
                                                                name="seriesId"
                                                                isClearable
                                                                onChange={(val) => handleSelectFilter('seriesId', val ?? {})}
                                                                styles={reactSelectStyle}
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                    {/*<Col lg="3" md="6">*/}
                                                    {/*    <FormGroup>*/}
                                                    {/*        <Label for="Title">*/}
                                                    {/*            Title*/}
                                                    {/*        </Label>*/}
                                                    {/*        <Input*/}
                                                    {/*            id="Title"*/}
                                                    {/*            name="title"*/}
                                                    {/*            placeholder="Search Title"*/}
                                                    {/*            type="text"*/}
                                                    {/*            onChange={handleFilter}*/}
                                                    {/*        />*/}
                                                    {/*    </FormGroup>*/}
                                                    {/*</Col>*/}
                                                    <Col md="4">
                                                        <FormGroup>
                                                            <Label for="viewTemplateName">
                                                                View Template
                                                            </Label>
                                                            <Select
                                                                id={"viewTemplateName"}
                                                                ref={selectViewTemplateRef}
                                                                className="basic-single"
                                                                classNamePrefix="select"
                                                                isSearchable={true}
                                                                options={seriesViewLayoutType.filter((val) => val.value)}
                                                                name="viewTemplateName"
                                                                isClearable
                                                                onChange={(val) => handleSelectFilter('viewTemplateName', val ?? {})}
                                                                styles={reactSelectStyle}
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                    <Col md="4">
                                                        <FormGroup>
                                                            <Label for="seriesId">
                                                                Feed Type
                                                            </Label>
                                                            <Select
                                                                id={"feedType"}
                                                                ref={selectSeriesRef}
                                                                className="basic-single"
                                                                classNamePrefix="select"
                                                                isSearchable={true}
                                                                options={feedTypeList}
                                                                name="feedType"
                                                                isClearable
                                                                onChange={(val) => handleSelectFilter('feedType', val ?? {})}
                                                                styles={reactSelectStyle}
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                            </CardBody>
                                        </Card>
                                    </Form>
                                </Col>
                                <Col sm="12">
                                    <ImageModal
                                        image={image}
                                        isModalShow={imageModalShow}
                                        handleCloseModal={handleCloseImageModal}
                                        alt=""/>
                                    <DataTable
                                        columns={columns}
                                        progressPending={listLoader}
                                        progressComponent={<Loader/>}
                                        className={"react-dataTable"}
                                        data={props.seriesItemList}
                                        pagination
                                        responsive
                                        paginationServer
                                        paginationTotalRows={tableInfo.pagination.totalRecords}
                                        onChangeRowsPerPage={handlePerRowsChange}
                                        onChangePage={handlePageChange}
                                        onSort={handleSort}
                                        sortServer
                                        subHeaderWrap
                                    />
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </>
    )
};

const mapStateToProps = (state) => {
    return {
        seriesList: state.series.seriesSelect,
        seriesItemList: state.series.seriesItemList,
        paginationResult: state.series.paginationResult,
        timestamp: state.series.timestamp ?? ''
    }
}

const mapDispatchToProps = {
    fetchSeriesList,
    fetchSeriesItems
}

export default connect(mapStateToProps, mapDispatchToProps)(Index);
