import React, {useEffect, useRef, useState} from "react";
import {useHistory} from "react-router-dom";
import {checkAuth} from "../../../config/helper";
import {connect} from "react-redux";
import {Button, Modal, ModalBody, ModalHeader, Tooltip} from "reactstrap";
import {createNotification} from "../../../redux/notification/notification.actions";
import NotificationCombineCreateForm from "../../notification/form/CreateNotification";

const CreateNotificationModal = (props) => {
    const {createNotification} = props;
    const mounted = useRef(false);
    const history = useHistory();
    const [show, setShow] = useState(false);
    const [submitBtnText, setSubmitBtnText] = useState('Save');
    const [submitBtnIcon, setSubmitBtnIcon] = useState('');
    const [updateTooltipOpen, setUpdateTooltipOpen] = useState(false);
    useEffect(() => {
        checkAuth(history);
        mounted.current = true;
        return () => {
            mounted.current = false;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const updateToggleTooltip = () => setUpdateTooltipOpen(!updateTooltipOpen);

    const handleSubmitBtn = (val) => {
        if (val) {
            setSubmitBtnText('Saving');
            setSubmitBtnIcon('fa fa-spinner fa-spin');
        } else {
            setSubmitBtnText('Save');
            setSubmitBtnIcon('');
        }
    }
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const handleSubmit = (values) => {
        if (mounted.current) {
            let postData = values;
            if ('audienceType' in postData) {
                postData.audienceType = postData.audienceType.value || '';
            }
            if ('user_list' in postData) {
                postData.user_list.map((val) => {
                    postData.users.push(val.id);
                    return val;
                });
                delete postData.user_list;
            }
            if ('team_list' in postData) {
                postData.team_list.map((val) => {
                    postData.teams.push(val.value);
                    return val;
                });
                delete postData.team_list;
            }
            handleSubmitBtn(true);

            createNotification(postData).then((data) => {
                handleSubmitBtn(false);
                if (!('payload' in data && data.payload === undefined)) {
                    handleClose();
                }
            });
        }
    }

    return (
        <>
            <Button color="primary" size="sm" className={"d-flex align-items-center float-right"} onClick={handleShow}
                    id={'updateTooltip-' + props.id}>
                {props.modalBtnIcon && <i className={props.modalBtnIcon}/>}{props.modalBtnText}
            </Button>
            <Tooltip placement="top" isOpen={updateTooltipOpen} autohide={false}
                     target={'updateTooltip-' + props.id} toggle={updateToggleTooltip}>
                {props.modalTitleText}
            </Tooltip>
            <Modal centered={true} isOpen={show} backdrop={true}>
                <ModalHeader tag="h3" toggle={handleClose}>{props.modalTitleText}</ModalHeader>
                <ModalBody className="pt-0">
                    <NotificationCombineCreateForm onSubmit={handleSubmit} submitBtnText={submitBtnText}
                                                   submitBtnIcon={submitBtnIcon}/>
                </ModalBody>
            </Modal>
        </>
    );

}

const mapStateToProps = (state) => {
    return {}
}

const mapDispatchToProps = {
    createNotification
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateNotificationModal);