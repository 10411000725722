/*!

=========================================================
* Argon Dashboard React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import {useLocation, Route, Switch, Redirect, useHistory} from "react-router-dom";
import { Container } from "reactstrap";

import advisorRoutes from "../advisorRoutes";
import {decodeRole, validateToken} from "../config/helper";
import AdvisorNavbar from "../components/Navbars/AdvisorNavbar";
import AdvisorHeader from "../components/Headers/AdvisorHeader";
import UserView from "../views/user/View";
import AdvisorFooter from "../components/Footers/AdvisorFooter";

const Advisor = (props) => {
  const history = useHistory();
  const mainContent = React.useRef(null);
  const location = useLocation();

  const tokenStatus = validateToken();

  if (!tokenStatus) {
    let role = decodeRole();
    history.push('/auth/login/'+(role === 'admin' ? 'admin' : ''));
  }


  React.useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    mainContent.current.scrollTop = 0;
  }, [location]);

  const getBrandText = () => {
    let routesList = advisorRoutes.sort((a,b) => b.order - a.order);
    for (let i = 0; i < routesList.length; i++) {
      if (
        props.location.pathname.indexOf(routesList[i].layout + routesList[i].path) !==
        -1
      ) {
        return routesList[i].name;
      }
    }
    return "Lovework";
  };

  const getRoutes = (routes) => {
    return routes.sort(function(a, b){return a.order - b.order}).map((prop, key) => {
      if (prop.layout === "/coach") {
        return (
            <Route
                path={prop.layout + prop.path}
                component={prop.component}
                key={key}
            />
        );
      } else {
        return null;
      }
    });
  };

  return (
    <>
      <div className="main-content" ref={mainContent}>
        <AdvisorNavbar
          {...props}
          brandText={getBrandText()}
        />
        <AdvisorHeader/>

        <Container className="mt--7" style={{
          minHeight: "65vh"
        }} fluid>
          <Switch>
            {getRoutes(advisorRoutes)}
            <Route path="/coach/user/view/:id" component={UserView} />
            <Redirect from="*" to="/coach/dashboard" />
          </Switch>
        </Container>

        <Container fluid>
          <AdvisorFooter />
        </Container>
      </div>
    </>
  );
};

export default Advisor;
