import React, {useEffect, useRef, useState} from 'react'
import {connect} from "react-redux"
import {Field, FieldArray, reduxForm} from 'redux-form'
import {Button, Col, Row} from "reactstrap";
import {renderRadioField, renderSelectField, renderTextField} from "../../../config/formElement";
import Loader from "../../../components/Loader/Loader";
import {fetchSeriesItem, fetchSeriesList} from "../../../redux/series/series.actions";
import {
    seriesItemCategory,
    seriesItemDataValidation,
    seriesItemViewTemplateImages,
    seriesViewLayoutType
} from "../../../config/staticData";
import {checkAuth, checkAuthWithCallback} from "../../../config/helper";
import {useHistory} from "react-router-dom";
import TemplatePreview from "../templatePreview";
import {seriesItemDataSchema} from "../../../config/schema";

const validate = (values) => {
    let errors = {};
    // if (!values.seriesItemName) {
    //     errors.seriesItemName = 'Practice item name is required.';
    // }
    if (!values.seriesId) {
        errors.seriesId = 'Practice selection is required.';
    }
    if (!values.viewTemplateName) {
        errors.viewTemplateName = 'Template is required';
    } else {
        let urlTemplate = /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/g;
        let template = seriesItemDataValidation[values.viewTemplateName];
        let availableFields = Object.keys(template);
        // if (availableFields.includes('view_postHeading') && template.view_postHeading && !values.view_postHeading) {
        //     errors.view_postHeading = "Post heading is required";
        // }
        if (availableFields.includes('view_title') && template.view_title && !values.view_title) {
            errors.view_title = "Title is required";
        }
        // if (availableFields.includes('view_heading') && template.view_heading && !values.view_heading) {
        //     errors.view_heading = "Heading is required";
        // }
        if (availableFields.includes('view_hashtag') && template.view_hashtag && !values.view_hashtag) {
            errors.view_hashtag = "Hashtag is required";
        }
        if (availableFields.includes('view_category') && template.view_category && !values.view_category) {
            errors.view_category = "Category is required";
        }
        if (availableFields.includes('view_mediaUrl')) {
            let ref_mediaUrl = (values.view_mediaUrl || '').match(urlTemplate)
            if (template.view_mediaUrl && !values.view_mediaUrl) {
                errors.view_mediaUrl = "Media url is required";
            } else if (values.view_mediaUrl && ref_mediaUrl == null) {
                errors.view_mediaUrl = "Please enter valid URL";
            }
        }
        if (availableFields.includes('view_practiceUrl') && template.view_practiceUrl && !values.view_practiceUrl) {
            errors.view_practiceUrl = "Practice video url is required";
        }
        // if (availableFields.includes('view_secondButton')) {
        //     let ref_secondUrl = (values.view_secondButton_url ? values.view_secondButton_url : '').match(urlTemplate)
        //     if (template.view_secondButton && !values.view_secondButton_text) {
        //         errors.view_secondButton_text = "Second button text is required";
        //     }
        //     if (template.view_secondButton && !values.view_secondButton_url) {
        //         errors.view_secondButton_url = "Second button url is required";
        //     } else if (values.view_secondButton_url && ref_secondUrl == null) {
        //         errors.view_secondButton_url = "Please enter valid URL";
        //     }
        //
        //     if (values.view_secondButton_text || values.view_secondButton_url) {
        //         if (!values.view_secondButton_text) {
        //             errors.view_secondButton_text = "Bottom button text is required";
        //         }
        //         if (!values.view_secondButton_url) {
        //             errors.view_secondButton_url = "Bottom button url is required";
        //         } else if (values.view_secondButton_url && ref_secondUrl == null) {
        //             errors.view_secondButton_url = "Please enter valid URL";
        //         }
        //     }
        // }
        if (availableFields.includes('view_postHint') && template.view_postHint && !values.view_postHint) {
            errors.view_postHint = "Post hint is required";
        }
        // if (availableFields.includes('view_postHintAndTitle') && template.view_postHintAndTitle && !values.view_postHintAndTitle) {
        //     errors.view_postHintAndTitle = "Post hint and title is required";
        // }
        if (availableFields.includes('view_innerTitle') && template.view_innerTitle && !values.view_innerTitle) {
            errors.view_innerTitle = "Inner title is required";
        }
        if (availableFields.includes('view_practiceTitle') && template.view_practiceTitle && !values.view_practiceTitle) {
            errors.view_practiceTitle = "Practice title is required";
        }
        if (availableFields.includes('view_practiceSubtitle') && template.view_practiceSubtitle && !values.view_practiceSubtitle) {
            errors.view_practiceSubtitle = "Practice subtitle is required";
        }
        if (availableFields.includes('view_bottomButton')) {
            let ref_bottomUrl = (values.view_bottomButton_url ? values.view_bottomButton_url : '').match(urlTemplate);
            if (template.view_bottomButton && !values.view_bottomButton_text) {
                errors.view_bottomButton_text = "Bottom button text is required";
            }
            if (template.view_bottomButton && !values.view_bottomButton_url) {
                errors.view_bottomButton_url = "Bottom button url is required";
            } else if (values.view_bottomButton_url && ref_bottomUrl == null) {
                errors.view_bottomButton_url = "Please enter valid URL";
            }

            if (values.view_bottomButton_text || values.view_bottomButton_url) {
                if (!values.view_bottomButton_text) {
                    errors.view_bottomButton_text = "Bottom button text is required";
                }
                if (!values.view_bottomButton_url) {
                    errors.view_bottomButton_url = "Bottom button url is required";
                } else if (values.view_bottomButton_url && ref_bottomUrl == null) {
                    errors.view_bottomButton_url = "Please enter valid URL";
                }
            }
        }
        if (availableFields.includes('view_title2') && template.view_title2 && !values.view_title2) {
            errors.view_title2 = "Title 2 is required";
        }
        if (availableFields.includes('view_dialogTitle') && template.view_dialogTitle && !values.view_dialogTitle) {
            errors.view_dialogTitle = "Dialog title is required";
        }
        if (availableFields.includes('view_dialogSubTitle') && template.view_dialogSubTitle && !values.view_dialogSubTitle) {
            errors.view_dialogSubTitle = "Dialog subtitle is required";
        }
        if (availableFields.includes('view_dialogHeading') && template.view_dialogHeading && !values.view_dialogHeading) {
            errors.view_dialogHeading = "Dialog heading is required";
        }
        if (availableFields.includes('view_webViewUrl')) {
            let ref_wevViewUrl = (values.view_webViewUrl || '').match(urlTemplate);
            if (template.view_webViewUrl && !values.view_webViewUrl) {
                errors.view_webViewUrl = "Web view url is required";
            } else if (values.view_webViewUrl && ref_wevViewUrl == null) {
                errors.view_webViewUrl = "Please enter valid URL";
            }
        }
        if (availableFields.includes('view_title3') && template.view_title3 && !values.view_title3) {
            errors.view_title3 = "Title 3 is required";
        }
        if (availableFields.includes('view_url1')) {
            let ref_url1 = (values.view_url1 || '').match(urlTemplate);
            if (template.view_url1 && !values.view_url1) {
                errors.view_url1 = "URL 1 is required";
            } else if (values.view_url1 && ref_url1 == null) {
                errors.view_url1 = "Please enter valid URL";
            }
        }
        if (availableFields.includes('view_url2')) {
            let ref_url2 = (values.view_url2 || '').match(urlTemplate);
            if (template.view_url2 && !values.view_url2) {
                errors.view_url2 = "URL 2 is required";
            } else if (values.view_url2 && ref_url2 == null) {
                errors.view_url2 = "Please enter valid URL";
            }
        }
        if (availableFields.includes('view_next') && template.view_next && !values.view_next) {
            errors.view_next = "Next is required";
        }
        if (availableFields.includes('view_calltoaction') && template.view_calltoaction && !values.view_calltoaction) {
            errors.view_calltoaction = "Call-to-action is required";
        }
        if (availableFields.includes('view_radioDataList')) {
            if (template.view_radioDataList && (!values.view_radioDataList || !values.view_radioDataList.length)) {
                errors.view_radioDataList = {_error: "At least one radio option must be entered"};
            } else {
                const radioArrayErrors = [];
                values.view_radioDataList.forEach((radio, radioIndex) => {
                    const radioErrors = {}
                    if (!radio || !radio.text) {
                        radioErrors.text = 'Text is required';
                        radioArrayErrors[radioIndex] = radioErrors;
                    }
                });
                if (radioArrayErrors.length) {
                    errors.view_radioDataList = radioArrayErrors;
                }
            }
        }
    }
    return errors;
}

const warn = () => {
    return {};
}

let SeriesItemCreateForm = (props) => {
    const {handleSubmit, fetchSeriesItem, fetchSeriesList} = props;
    const mounted = useRef(false);
    const [fields, setFields] = useState(seriesItemDataSchema());
    const [isSubmitting, setIsSubmitting] = useState({text: 'Save', icon: ''});
    const [seriesList, setSeriesList] = useState([{label: "Select Practice", value: ""}]);
    const [isLoading, setIsLoading] = useState(true);
    const [viewTemplate,setViewTemplate] = useState('');
    const history = useHistory();
    const [templateFields, setTemplateFields] = useState([]);
    const [templateImages, setTemplateImages] = useState([]);
    const [templateChangeLoader, setTemplateChangeLoader] = useState(false);

    useEffect(() => {
        checkAuth(history);
        if (props.id !== undefined) {
            setIsLoading(true);
            checkAuthWithCallback(history, fetchSeriesItem, props.id);
        }
        setTimeout(() => {
            mounted.current = true;
        }, 100);
        return () => {
            mounted.current = false;
            setFields(seriesItemDataSchema());
            setIsSubmitting({text: 'Save', icon: ''});
            setSeriesList([{label: "Select Practice", value: ""}]);
        };
    }, []);

    useEffect(() => {
        if (mounted.current) {
            if (props.submitBtnText !== isSubmitting.text) {
                setIsSubmitting({text: props.submitBtnText, icon: props.submitBtnIcon});
            } else {
                if (isLoading) {
                    setIsSubmitting({text: 'Wait', icon: ''});
                } else {
                    setIsSubmitting({text: 'Save', icon: ''});
                }
            }
        }
    }, [props.submitBtnText, props.submitBtnIcon, isLoading]);

    useEffect(() => {
        if (props.id === undefined) {
            setIsLoading(false);
        } else {
            if (props.seriesItem !== undefined && props.seriesItem.id !== undefined && props.seriesItem.id === props.id) {
                let data = {...fields, ...props.seriesItem};
                setFields(data);
                if ('viewTemplateName' in data && viewTemplate === '') {
                    setViewTemplate(data.viewTemplateName);
                }
                setTimeout(() => {
                    setIsLoading(false);
                }, 100);
            }
        }
    }, [props.seriesItem]);

    useEffect(() => {
        if (props.seriesList === undefined || props.seriesList.length === 0) {
            checkAuthWithCallback(history, fetchSeriesList);
        }
        if (props.seriesList !== undefined && props.seriesList.length > 0) {
            setSeriesList([{label: "Select Practice", value: ""}].concat(props.seriesList));
        }
    }, [props.seriesList, fetchSeriesList]);

    useEffect(() => {
        updateTemplateFields();
    },[viewTemplate]);

    const handleSelect = (key, val) => {
        let value = val.target.value;
        if (key === 'viewTemplateName' && value !== viewTemplate) {
            setViewTemplate(value);
        }
    }

    const renderRadioList = ({fields, meta: {touched, error, submitFailed}}) => {
        return (
            <Row>
                <Col sm={12} className="mb-2">
                    <div className="border rounded p-3 w-100">
                        <span className="h4 pt-2 pb-2">Radio Data List</span>
                        <Button
                            size="sm"
                            type="button"
                            color="primary"
                            className="float-right"
                            onClick={() => fields.push({})}>
                            <i className="fa fa-plus-circle"></i>
                        </Button>
                        {error && <><br/><span className="text-danger">{error}</span></>}
                    </div>
                </Col>
                <Col sm={12}>
                    <div className="border rounded p-2 mb-3">
                        {fields.map((radioText, index) =>
                            <Row key={index}>
                                <Col xl={4} md={6}>
                                    <Field
                                        name={`${radioText}.text`}
                                        type="text"
                                        label="Text"
                                        component={renderTextField}
                                        isRequired={true}
                                    />
                                </Col>
                                <Col xl={4} md={6}>
                                    <Field
                                        name={`${radioText}.highlight`}
                                        type="text"
                                        label="Highlight"
                                        component={renderTextField}
                                        isRequired={false}
                                    />
                                </Col>
                                <Col xl={3} md={6}>
                                    <Field
                                        name={`${radioText}.isDefault`}
                                        type="checkbox"
                                        label="Is Default"
                                        component={renderRadioField}
                                        isRequired={false}
                                    />
                                </Col>
                                <Col xl={1} md={6}>
                                    <div className="d-flex justify-content-end">
                                        <Button
                                            size="sm"
                                            type="button"
                                            color="danger"
                                            onClick={() => fields.remove(index)}>
                                            <i className="fa fa-minus-circle"></i>
                                        </Button>
                                    </div>
                                </Col>
                            </Row>
                        )}
                    </div>
                </Col>
            </Row>
        )
    }

    const updateTemplateFields = () => {
        setTemplateChangeLoader(true);
        let fieldList = [];
        let imageList = [];
        if (viewTemplate !== '') {
            let template = seriesItemDataValidation[viewTemplate];
            let availableFields = Object.keys(template);
            if (availableFields.includes('view_title')) {
                fieldList.push(<Col xl="4" md="6" key={'form_view_title'}>
                    <Field
                        name="view_title"
                        type="text"
                        label="Title"
                        hasDefaultValue={fields.view_title}
                        component={renderTextField}
                        isRequired={template.view_title || false}
                    />
                </Col>);
            }
            if (availableFields.includes('view_title2')) {
                fieldList.push(<Col xl="4" md="6" key={'form_view_title2'}>
                    <Field
                        name="view_title2"
                        type="text"
                        label="Title 2"
                        hasDefaultValue={fields.view_title2}
                        component={renderTextField}
                        isRequired={template.view_title2 || false}
                    />
                </Col>);
            }
            if (availableFields.includes('view_title3')) {
                fieldList.push(<Col xl="4" md="6" key={'form_view_title3'}>
                    <Field
                        name="view_title3"
                        type="text"
                        label="Title 3"
                        hasDefaultValue={fields.view_title3}
                        component={renderTextField}
                        isRequired={template.view_title3 || false}
                    />
                </Col>);
            }
            // if (availableFields.includes('view_heading')) {
            //     fieldList.push(<Col xl="4" md="6" key={'form_view_heading'}>
            //         <Field
            //             name="view_heading"
            //             type="text"
            //             label="Heading"
            //             hasDefaultValue={fields.view_heading}
            //             component={renderTextField}
            //             isRequired={template.view_heading || false}
            //         />
            //     </Col>);
            // }
            if (availableFields.includes('view_hashtag')) {
                fieldList.push(<Col xl="4" md="6" key={'form_view_hashtag'}>
                    <Field
                        name="view_hashtag"
                        type="text"
                        label="Hashtag"
                        hasDefaultValue={fields.view_hashtag}
                        component={renderTextField}
                        isRequired={template.view_hashtag || false}
                    />
                </Col>);
            }
            if (availableFields.includes('view_category')) {
                fieldList.push(<Col xl="4" md="6" key={'form_view_category'}>
                    <Field
                        name="view_category"
                        type="select"
                        label="Category"
                        hasDefaultValue={fields.view_category}
                        component={renderSelectField}
                        options={seriesItemCategory ?? []}
                        isRequired={template.view_category || false}
                    />
                </Col>);
            }
            if (availableFields.includes('view_mediaUrl')) {
                fieldList.push(<Col xl="4" md="6" key={'form_view_mediaUrl'}>
                    <Field
                        name="view_mediaUrl"
                        type="text"
                        label="Media URL"
                        hasDefaultValue={fields.view_mediaUrl}
                        component={renderTextField}
                        isRequired={template.view_mediaUrl || false}
                    />
                </Col>);
            }
            // if (availableFields.includes('view_secondButton')) {
            //     fieldList.push(<Col xl="4" md="6" key={'form_view_secondButton_text'}>
            //         <Field
            //             name="view_secondButton_text"
            //             type="text"
            //             label="Second Button Text"
            //             hasDefaultValue={fields.view_secondButton_text}
            //             component={renderTextField}
            //             isRequired={template.view_secondButton || false}
            //         />
            //     </Col>);
            //     fieldList.push(<Col xl="4" md="6" key={'form_view_secondButton_url'}>
            //         <Field
            //             name="view_secondButton_url"
            //             type="text"
            //             label="Second Button URL"
            //             hasDefaultValue={fields.view_secondButton_url}
            //             component={renderTextField}
            //             isRequired={template.view_secondButton || false}
            //         />
            //     </Col>);
            // }
            // if (availableFields.includes('view_postHeading')) {
            //     fieldList.push(<Col xl="4" md="6" key={'form_view_postHeading'}>
            //         <Field
            //             name="view_postHeading"
            //             type="text"
            //             label="Post Heading"
            //             hasDefaultValue={fields.view_postHeading}
            //             component={renderTextField}
            //             isRequired={template.view_postHeading || false}
            //         />
            //     </Col>);
            // }
            if (availableFields.includes('view_postHint')) {
                fieldList.push(<Col xl="4" md="6" key={'form_view_postHint'}>
                    <Field
                        name="view_postHint"
                        type="text"
                        label="Post Hint"
                        hasDefaultValue={fields.view_postHint}
                        component={renderTextField}
                        isRequired={template.view_postHint || false}
                    />
                </Col>);
            }
            // if (availableFields.includes('view_postHintAndTitle')) {
            //     fieldList.push(<Col xl="4" md="6" key={'form_view_postHintAndTitle'}>
            //         <Field
            //             name="view_postHintAndTitle"
            //             type="text"
            //             label="Post Hint And Title"
            //             hasDefaultValue={fields.view_postHintAndTitle}
            //             component={renderTextField}
            //             isRequired={template.view_postHintAndTitle || false}
            //         />
            //     </Col>);
            // }
            if (availableFields.includes('view_innerTitle')) {
                fieldList.push(<Col xl="4" md="6" key={'form_view_innerTitle'}>
                    <Field
                        name="view_innerTitle"
                        type="text"
                        label="Inner Title"
                        hasDefaultValue={fields.view_innerTitle}
                        component={renderTextField}
                        isRequired={template.view_innerTitle || false}
                    />
                </Col>);
            }
            if (availableFields.includes('view_practiceTitle')) {
                fieldList.push(<Col xl="4" md="6" key={'form_view_practiceTitle'}>
                    <Field
                        name="view_practiceTitle"
                        type="text"
                        label="Practice Title"
                        hasDefaultValue={fields.view_practiceTitle}
                        component={renderTextField}
                        isRequired={template.view_practiceTitle || false}
                    />
                </Col>);
            }
            if (availableFields.includes('view_practiceSubtitle')) {
                fieldList.push(<Col xl="4" md="6" key={'form_view_practiceSubtitle'}>
                    <Field
                        name="view_practiceSubtitle"
                        type="text"
                        label="Practice Subtitle"
                        hasDefaultValue={fields.view_practiceSubtitle}
                        component={renderTextField}
                        isRequired={template.view_practiceSubtitle || false}
                    />
                </Col>);
            }
            if (availableFields.includes('view_practiceUrl')) {
                fieldList.push(<Col xl="4" md="6" key={'form_view_practiceUrl'}>
                    <Field
                        name="view_practiceUrl"
                        type="text"
                        label="Practice Video URL"
                        hasDefaultValue={fields.view_practiceUrl}
                        component={renderTextField}
                        isRequired={template.view_practiceUrl || false}
                    />
                </Col>);
            }
            if (availableFields.includes('view_dialogTitle')) {
                fieldList.push(<Col xl="4" md="6" key={'form_view_dialogTitle'}>
                    <Field
                        name="view_dialogTitle"
                        type="text"
                        label="Dialog Title"
                        hasDefaultValue={fields.view_dialogTitle}
                        component={renderTextField}
                        isRequired={template.view_dialogTitle || false}
                    />
                </Col>);
            }
            if (availableFields.includes('view_dialogSubTitle')) {
                fieldList.push(<Col xl="4" md="6" key={'form_view_dialogSubTitle'}>
                    <Field
                        name="view_dialogSubTitle"
                        type="text"
                        label="Dialog Subtitle"
                        hasDefaultValue={fields.view_dialogSubTitle}
                        component={renderTextField}
                        isRequired={template.view_dialogSubTitle || false}
                    />
                </Col>);
            }
            if (availableFields.includes('view_dialogHeading')) {
                fieldList.push(<Col xl="4" md="6" key={'form_view_dialogHeading'}>
                    <Field
                        name="view_dialogHeading"
                        type="text"
                        label="Dialog Heading"
                        hasDefaultValue={fields.view_dialogHeading}
                        component={renderTextField}
                        isRequired={template.view_dialogHeading || false}
                    />
                </Col>);
            }
            if (availableFields.includes('view_webViewUrl')) {
                fieldList.push(<Col xl="4" md="6" key={'form_view_webViewUrl'}>
                    <Field
                        name="view_webViewUrl"
                        type="text"
                        label="Web View URL"
                        hasDefaultValue={fields.view_webViewUrl}
                        component={renderTextField}
                        isRequired={template.view_webViewUrl || false}
                    />
                </Col>);
            }
            if (availableFields.includes('view_url1')) {
                fieldList.push(<Col xl="4" md="6" key={'form_view_url1'}>
                    <Field
                        name="view_url1"
                        type="text"
                        label="URL 1"
                        hasDefaultValue={fields.view_url1}
                        component={renderTextField}
                        isRequired={template.view_url1 || false}
                    />
                </Col>);
            }
            if (availableFields.includes('view_url2')) {
                fieldList.push(<Col xl="4" md="6" key={'form_view_url2'}>
                    <Field
                        name="view_url2"
                        type="text"
                        label="URL 2"
                        hasDefaultValue={fields.view_url2}
                        component={renderTextField}
                        isRequired={template.view_url2 || false}
                    />
                </Col>);
            }
            if (availableFields.includes('view_bottomButton')) {
                fieldList.push(<Col xl="4" md="6" key={'form_view_bottomButton_text'}>
                    <Field
                        name="view_bottomButton_text"
                        type="text"
                        label="Bottom Button Text"
                        hasDefaultValue={fields.view_bottomButton_text}
                        component={renderTextField}
                        isRequired={template.view_bottomButton || false}
                    />
                </Col>);
                fieldList.push(<Col xl="4" md="6" key={'form_view_bottomButton_url'}>
                    <Field
                        name="view_bottomButton_url"
                        type="text"
                        label="Bottom Button URL"
                        hasDefaultValue={fields.view_bottomButton_url}
                        component={renderTextField}
                        isRequired={template.view_bottomButton || false}
                    />
                </Col>);
            }
            if (availableFields.includes('view_next')) {
                fieldList.push(<Col xl="4" md="6" key={'form_view_next'}>
                    <Field
                        name="view_next"
                        type="text"
                        label="Next"
                        hasDefaultValue={fields.view_next}
                        component={renderTextField}
                        isRequired={template.view_next || false}
                    />
                </Col>);
            }
            if (availableFields.includes('view_calltoaction')) {
                fieldList.push(<Col xl="4" md="6" key={'form_view_calltoaction'}>
                    <Field
                        name="view_calltoaction"
                        type="text"
                        label="Call-to-action"
                        hasDefaultValue={fields.view_calltoaction}
                        component={renderTextField}
                        isRequired={template.view_calltoaction || false}
                    />
                </Col>);
            }
            if (availableFields.includes('view_radioDataList')) {
                fieldList.push(<Col sm={12} key={'form_view_radioDataList'}>
                    <FieldArray name="view_radioDataList" defaultValues={fields.view_radioDataList}
                                component={renderRadioList}/>
                </Col>);
            }
            let images = seriesItemViewTemplateImages[viewTemplate];
            if(images.length > 0){
                for (let image of images){
                    imageList.push(<Col lg={6} sm={4} className="mb-3" key={image}>
                        <TemplatePreview image={"/templates/"+image} alt={image} imgClass={"w-100"} />
                    </Col>);
                }
            }
        }
        setTemplateFields(fieldList);
        setTemplateImages(imageList);
        setTemplateChangeLoader(false);
    }

    const validatePostData = (val) => {
        val.preventDefault();
        handleSubmit(val);
    }

    return (
        <>
            {
                isLoading ?
                    <div className="text-center"><Loader/></div>
                    :
                    <div className="row">
                        <div className="col-lg-8 order-1 order-lg-0">
                            <form onSubmit={(values) => validatePostData(values)}>
                                <Row>
                                    {/*<Col xl="4" md="6">*/}
                                    {/*    <Field*/}
                                    {/*        name="seriesItemName"*/}
                                    {/*        type="text"*/}
                                    {/*        label="Name"*/}
                                    {/*        hasDefaultValue={fields.seriesItemName}*/}
                                    {/*        component={renderTextField}*/}
                                    {/*        isRequired={true}*/}
                                    {/*    />*/}
                                    {/*</Col>*/}
                                    <Col xl="4" md="6">
                                        <Field
                                            name="seriesId"
                                            type="select"
                                            component={renderSelectField}
                                            label="Practice"
                                            options={seriesList ?? []}
                                            hasDefaultValue={fields.seriesId}
                                            isRequired={true}
                                        />
                                    </Col>
                                    {/*<Col xl="4" md="6">*/}
                                    {/*    <Field*/}
                                    {/*        name="orderPriority"*/}
                                    {/*        type="number"*/}
                                    {/*        label="Order Priority"*/}
                                    {/*        hasDefaultValue={fields.orderPriority}*/}
                                    {/*        component={renderTextField}*/}
                                    {/*        min={0}*/}
                                    {/*    />*/}
                                    {/*</Col>*/}
                                    {/*<Col xl="4" md="6">*/}
                                    {/*    <Field*/}
                                    {/*        name="appearDay"*/}
                                    {/*        type="select"*/}
                                    {/*        component={renderSelectField}*/}
                                    {/*        label="Appear Day"*/}
                                    {/*        options={weekDays ?? []}*/}
                                    {/*        hasDefaultValue={fields.appearDay}*/}
                                    {/*    />*/}
                                    {/*</Col>*/}
                                    {/*<Col xl="4" md="6">*/}
                                    {/*    <Field*/}
                                    {/*        name="appearTime"*/}
                                    {/*        type="time"*/}
                                    {/*        component={renderTextField}*/}
                                    {/*        label="Appear Time"*/}
                                    {/*        hasDefaultValue={fields.appearTime}*/}
                                    {/*    />*/}
                                    {/*</Col>*/}
                                    {/*<Col xl="4" md="6">*/}
                                    {/*    <Field*/}
                                    {/*        name="appearExpire"*/}
                                    {/*        type="select"*/}
                                    {/*        component={renderSelectField}*/}
                                    {/*        label="Expire Day"*/}
                                    {/*        options={weekDays ?? []}*/}
                                    {/*        hasDefaultValue={fields.appearExpire}*/}
                                    {/*    />*/}
                                    {/*</Col>*/}
                                    {/*<Col xl="4" md="6">*/}
                                    {/*    <Field*/}
                                    {/*        name="pushTitle"*/}
                                    {/*        type="text"*/}
                                    {/*        label="Notification Title"*/}
                                    {/*        hasDefaultValue={fields.pushTitle}*/}
                                    {/*        component={renderTextField}*/}
                                    {/*    />*/}
                                    {/*</Col>*/}
                                    {/*<Col xl="4" md="6">*/}
                                    {/*    <Field*/}
                                    {/*        name="pushMessage"*/}
                                    {/*        type="text"*/}
                                    {/*        label="Notification Message"*/}
                                    {/*        hasDefaultValue={fields.pushMessage}*/}
                                    {/*        component={renderTextField}*/}
                                    {/*    />*/}
                                    {/*</Col>*/}
                                    {/*<Col xl="4" md="6">*/}
                                    {/*    <Field*/}
                                    {/*        name="pushTime"*/}
                                    {/*        type="time"*/}
                                    {/*        label="Notification Time"*/}
                                    {/*        hasDefaultValue={fields.pushTime}*/}
                                    {/*        component={renderTextField}*/}
                                    {/*    />*/}
                                    {/*</Col>*/}
                                    <Col xl="4" md="6">
                                        <Field
                                            name="viewTemplateName"
                                            type="select"
                                            component={renderSelectField}
                                            label="View Template"
                                            options={seriesViewLayoutType ?? []}
                                            hasDefaultValue={fields.viewTemplateName}
                                            isRequired={true}
                                            onChange={(value) => {
                                                value.preventDefault();
                                                handleSelect('viewTemplateName', value);
                                            }}
                                        />
                                    </Col>
                                    {templateFields}
                                    <Col lg="12">
                                        <Button color="primary" type="submit" disabled={isSubmitting.text !== 'Save' || templateChangeLoader}>
                                            {isSubmitting.icon !== '' ? <i className={isSubmitting.icon}/> : ''}
                                            {isSubmitting.text}
                                        </Button>
                                    </Col>
                                </Row>
                            </form>
                        </div>
                        <div className="col-lg-4 add-separator-border order-0 order-lg-1">
                            {/*TODO : Image Preview*/}
                            <Row className="justify-content-center justify-content-md-start mb-4 mb-lg-0">
                                {templateImages}
                            </Row>
                        </div>
                    </div>
            }
        </>);
}

const mapStateToProps = (state) => {
    let seriesItem = seriesItemDataSchema();
    if (state.series !== undefined && state.series.seriesItem !== undefined && state.series.seriesItem.id !== undefined) {
        seriesItem = {...seriesItem, ...state.series.seriesItem};
        if ('view' in seriesItem) {
            let keys = Object.keys(seriesItem.view);
            keys.forEach((val) => {
                seriesItem['view_' + val] = seriesItem.view[val];
            });
            delete seriesItem.view;
        }
        seriesItem = seriesItemDataSchema(seriesItem);
    }
    return {
        initialValues: seriesItem,
        seriesItem: seriesItem,
        seriesList: state.series.seriesSelect
    };
}

const mapDispatchToProps = {
    fetchSeriesItem,
    fetchSeriesList
}

SeriesItemCreateForm = reduxForm(
    {
        form: 'seriesItemCreateForm',
        enableReinitialize: true,
        validate,
        warn
    }
)(SeriesItemCreateForm)

export default connect(mapStateToProps, mapDispatchToProps)(SeriesItemCreateForm)