const storedEnv = sessionStorage.getItem('env');
let BASE_URL;
if(storedEnv == null || storedEnv === "development"){
    BASE_URL      = "https://dev.api.lovework.team";
}else{
    BASE_URL      = "https://api.lovework.team";
}


export const REACT_APP_AWS_BUCKET           = process.env.REACT_APP_AWS_BUCKET || ''

export const LOGIN_ADMIN_API                = BASE_URL + '/api/admin/login'
export const CHANGE_PASSWORD_API            = BASE_URL + '/api/admin/change-password'
export const UPDATE_PROFILE_API             = BASE_URL + '/api/admin/update-profile'

export const LOGIN_ADVISOR_API              = BASE_URL + '/api/advisor/login'
export const USER_LIST_API                  = BASE_URL + '/api/user/list'
export const USER_DELETE_API                = BASE_URL + '/api/user/delete'
export const USER_PERMANENT_DELETE_API      = BASE_URL + '/api/user/permanent-delete'
export const USER_DATA_API                  = BASE_URL + '/api/user/'
export const USER_PROFILE_UPDATE_API        = BASE_URL + '/api/user/update-profile-by-admin'
export const USER_REGISTER_API              = BASE_URL + '/api/user/register'
export const USER_RESET_API                 = BASE_URL + '/api/user/reset'
export const USER_RESEND_PASSWORD_API       = BASE_URL + '/api/user/resend-password'
export const USER_BULK_REGISTER_API         = BASE_URL + '/api/user/register-bulk'

export const USER_TEAM_MEMBER_LIST_API      = BASE_URL + '/api/team/members'
export const S3_SIGNED_URL                  = BASE_URL + '/api/s3/signedUrl'
export const S3_FILE_UPLOAD_URL             = 'https://s3.amazonaws.com/' + REACT_APP_AWS_BUCKET
export const TEAM_DATA_API                  = BASE_URL + '/api/team/'
export const TEAM_DELETE_API                = BASE_URL + '/api/team/'
export const TEAM_LIST_API                  = BASE_URL + '/api/team'
export const TEAM_CREATE_API                = BASE_URL + '/api/team/create'
export const TEAM_UPDATE_API                = BASE_URL + '/api/team'

export const GROUP_LIST_API                 = BASE_URL + '/api/group'
export const GROUP_DATA_API                 = BASE_URL + '/api/group/'
export const GROUP_UPDATE_API               = BASE_URL + '/api/group'
export const GROUP_DELETE_API               = BASE_URL + '/api/group/'
export const GROUP_CREATE_API               = BASE_URL + '/api/group/create'
export const GROUP_TEAM_API                 = BASE_URL + '/api/group/team/'

export const SERIES_LIST_API                = BASE_URL + '/api/series'
export const SERIES_DATA_API                = BASE_URL + '/api/series/'
export const SERIES_UPDATE_API              = BASE_URL + '/api/series'
export const SERIES_DELETE_API              = BASE_URL + '/api/series/'
export const SERIES_CREATE_API              = BASE_URL + '/api/series'
export const SERIES_ITEM_LIST_API           = BASE_URL + '/api/series/items/list'
export const SERIES_ITEM_CREATE_API         = BASE_URL + '/api/series/item'
export const SERIES_ITEM_UPDATE_API         = BASE_URL + '/api/series/item'
export const SERIES_ITEM_VIEW_API           = BASE_URL + '/api/series/item/'

export const ADVISOR_DELETE_API             = BASE_URL + '/api/advisor'
export const ADVISOR_LIST_API               = BASE_URL + '/api/advisor/list'
export const ADVISOR_CREATE_API             = BASE_URL + '/api/advisor'
export const ADVISOR_DIRECT_LOGIN_API       = BASE_URL + '/api/advisor/direct-login'

export const MEMBER_LIST_API                = BASE_URL + '/api/analytics/teamMember'

export const HUDDLE_LIST_API                = BASE_URL + '/api/huddle'
export const HUDDLE_CREATE_API              = BASE_URL + '/api/huddle'
export const HUDDLE_UPDATE_API              = BASE_URL + '/api/huddle'
export const HUDDLE_DATA_API                = BASE_URL + '/api/huddle/'

export const NOTIFICATION_LIST_API          = BASE_URL + '/api/notification/push/list'
export const ADHOC_NOTIFICATION_LIST_API    = BASE_URL + '/api/notification/adhoc/list'
export const NOTIFICATION_CREATE_API        = BASE_URL + '/api/notification/push'
export const ADHOC_NOTIFICATION_CREATE_API  = BASE_URL + '/api/notification/adhoc'


export const ME_FEED_LIST_API               = BASE_URL + '/api/mefeeds/list'

export const WE_SCREEN_LIST_API             = BASE_URL + '/api/wescreen/wefeed/list'
export const POST_FETCH_API                 = BASE_URL + '/api/posts/'

export const APP_SETTING_FETCH_API          = BASE_URL + '/api/setting/get-setting'
export const APP_SETTING_POST_API           = BASE_URL + '/api/setting'

export const CRON_SETTING_FETCH_API          = BASE_URL + '/api/setting/get-setting'
export const CRON_SETTING_POST_API           = BASE_URL + '/api/setting'

export const MAIL_TEMPLATE_GET_API          = BASE_URL + '/api/template'
export const MAIL_TEMPLATE_PUT_API          = BASE_URL + '/api/template'