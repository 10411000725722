import {connect} from "react-redux";
import {Card, CardBody, CardHeader, Col, Row} from "reactstrap";
import React, {useEffect, useRef, useState} from "react";
import {Link, useHistory, useParams} from "react-router-dom";
import {createSeriesItem, updateSeriesItem} from "../../redux/series/series.actions";
import SeriesItemCreateForm from "./form/Create";
import {checkAuth, displayErrorMessage, keepKeyInObject} from "../../config/helper";
import {seriesItemDataValidation} from "../../config/staticData";

const numberFields = ['orderPriority', 'appearDay', 'appearExpire'];

const Create = (props) => {
    const {createSeriesItem, updateSeriesItem} = props;
    const history = useHistory();
    const mounted = useRef(false);
    const [submitBtnText, setSubmitBtnText] = useState('Save');
    const [submitBtnIcon, setSubmitBtnIcon] = useState('');
    const {id} = useParams();

    useEffect(() => {
        mounted.current = true;
        checkAuth(history);
        return () => {
            mounted.current = false;
            handleSubmitBtn(false);
        };
    }, []);

    const handleSubmitBtn = (val) => {
        if (val) {
            setSubmitBtnText('Saving');
            setSubmitBtnIcon('fa fa-spinner fa-spin');
        } else {
            setSubmitBtnText('Save');
            setSubmitBtnIcon('');
        }
    }
    const handleSubmit = (values) => {
        let postData = values;
        numberFields.map(val => {
            let number = parseInt(postData[val] ?? '0')
            postData[val] = number > 0 ? number : null;
            return val;
        });
        let template = seriesItemDataValidation[postData.viewTemplateName];
        let availableFields = Object.keys(template);
        if (!('view' in postData)) {
            postData.view = {};
        }
        if ('view_secondButton_text' in postData) {
            if(postData.view_secondButton_text) {
                postData.view_secondButton = {
                    "text": postData.view_secondButton_text,
                    "url": postData.view_secondButton_url
                }
            }else{
                postData.view_secondButton = null;
            }
            delete postData.view_secondButton_text;
            delete postData.view_secondButton_url;
        }
        if ('view_bottomButton_text' in postData) {
            if(postData.view_bottomButton_text) {
                postData.view_bottomButton = {
                    "text": postData.view_bottomButton_text,
                    "url": postData.view_bottomButton_url
                }
            }else{
                postData.view_bottomButton = null;
            }
            delete postData.view_bottomButton_text;
            delete postData.view_bottomButton_url;
        }
        let keys = Object.keys(postData);
        keys.forEach((val) => {
            if (val.indexOf('view_') === 0) {
                postData.view[val.slice(5)] = postData[val];
                delete postData[val];
            }
        });
        availableFields = availableFields.map((val) => val.slice(5));
        postData.view = keepKeyInObject(availableFields,postData.view);
        if (!(postData.id === '' || postData.id === undefined) && id !== postData.id) {
            handleSubmitBtn(false);
            displayErrorMessage("Please refresh the page and try again.");
        } else {
            handleSubmitBtn(true);
            if (postData.id !== undefined && postData.id.length > 0) {
                updateSeriesItem(postData).then((data) => {
                    handleSubmitBtn(false);
                    if (!('payload' in data && data.payload === undefined)) {
                        history.push('/admin/contents');
                    }
                });
            } else {
                createSeriesItem(postData).then((data) => {
                    handleSubmitBtn(false);
                    if (!('payload' in data && data.payload === undefined)) {
                        history.push('/admin/contents');
                    }
                });
            }
        }
    }
    return (
        <>
            <Row className={"justify-content-center"}>
                <Col xl="12">
                    <Card className="shadow border-0">
                        <CardHeader>
                            <Row>
                                <Col lg="12">
                                    <span
                                        className={"mb-0 h3"}>{id === undefined ? "Create" : "Update"} Content</span>
                                    <Link
                                        to={'/admin/content'}
                                        className={"d-flex float-right btn btn-sm btn-primary align-items-center"}
                                    >
                                        <i className="fa fa-arrow-left mr-2"/>
                                        Back
                                    </Link>
                                </Col>
                            </Row>
                        </CardHeader>
                        <CardBody>
                            <SeriesItemCreateForm id={id} onSubmit={handleSubmit} submitBtnText={submitBtnText}
                                                  submitBtnIcon={submitBtnIcon}/>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </>
    )
}

const mapStateToProps = () => {
    return {};
}

const mapDispatchToProps = {
    updateSeriesItem,
    createSeriesItem
}

export default connect(mapStateToProps, mapDispatchToProps)(Create);