import React, {useEffect, useRef, useState} from 'react'
import {connect} from "react-redux"
import {Field, reduxForm} from 'redux-form'
import {Button, Col, Row} from "reactstrap";
import {checkAuth, getExtension} from "../../../config/helper";
import {useHistory} from "react-router-dom";
import {renderFileField} from "../../../config/formElement";

const validate = (values) => {
    let errors = {};
    if (!values.userCsv || values.userCsv.length === 0) {
        errors.userCsv = 'File is required.';
    } else if (values.userCsv.length > 0) {
        let status = false;
        for (const file of values.userCsv) {
            if (getExtension(file.name) !== 'csv') {
                status = true;
            }
        }
        if (status) {
            errors.userCsv = 'Only CSV file allow.';
        }
    }

    return errors;
}

const warn = () => {
    return {};
}

let UserUploadForm = (props) => {
    const {handleSubmit} = props;
    const mounted = useRef(false);
    const [isSubmitting, setIsSubmitting] = useState({text: "Save", icon: ""});
    const history = useHistory();

    useEffect(() => {
        checkAuth(history);
        mounted.current = true;
        return () => {
            mounted.current = false;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (props.submitBtnText !== isSubmitting.text) {
            setIsSubmitting({text: props.submitBtnText, icon: props.submitBtnIcon});
        } else {
            setIsSubmitting({text: 'Save', icon: ''});
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.submitBtnText, props.submitBtnIcon]);

    return (
        <>
            <form onSubmit={(values) => handleSubmit(values)}>
                <Row>
                    <Col lg="6">
                        <Field
                            name="userCsv"
                            type="file"
                            className="form-file-input"
                            component={renderFileField}
                            label="Upload CSV"
                            isRequired={true}
                            accept=".csv"
                        />
                    </Col>
                    <Col lg="12">
                        <Button color="primary" type="submit"
                                disabled={isSubmitting.text !== 'Save'}>{isSubmitting.icon !== '' ?
                            <i className={isSubmitting.icon}/> : ''} {isSubmitting.text}</Button>
                        <a target={"_blank"} href={'/sample/User-List.csv'} className={"btn btn-info"} rel="noreferrer">Download Sample</a>
                    </Col>
                </Row>
            </form>
        </>);
};

const mapStateToProps = () => {
    return {}
}

UserUploadForm = reduxForm({
    form: 'userUploadForm',
    validate,
    warn
})(UserUploadForm)

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(UserUploadForm)