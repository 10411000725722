import {FETCH, VIEW, POST, PUT, TEAM_FETCH, POST_BULK} from './user.types';
import {now} from "moment";
import {displayErrorMessage, displayMessage} from "../../config/helper";

const INITIAL_STATE = {
    userList: [],
    userSelect: [],
    memberList: [],
    failUpload: [],
    timestamp: ''
};

const reducer = (state = INITIAL_STATE, action) => {
    let user = state.user ?? {};
    let secondaryTeams = [];
    let members = [];
    let users = state.userList;
    try {
        switch (action.type) {
            case FETCH:
                let data = (action.payload.userList || []).map((value) => {
                    if (value['individualKickOffDate'] !== undefined && value['individualKickOffDate'].length > 0) {
                        value['individualKickOffDate'] = window.moment(value['individualKickOffDate']).format('YYYY-MM-DD');
                    }
                    if (value['kickOffDate'] !== undefined && value['kickOffDate'].length > 0) {
                        value['kickOffDate'] = window.moment(value['kickOffDate']).format('YYYY-MM-DD');
                    }
                    if (value['createdAt'] !== undefined && value['createdAt'].length > 0) {
                        value['createdAt'] = window.moment(value['createdAt']).format('YYYY-MM-DD h:mm A');
                    }
                    return value;
                });
                return {
                    ...state,
                    userList: data,
                    paginationResult: action.payload.paginationResult,
                    timestamp: now(),
                    failUpload: []
                };
            case VIEW:
                user = action.payload && action.payload.user ? action.payload.user : {};
                secondaryTeams = action.payload && action.payload.teamList ? action.payload.teamList.map((t) => t.id) : [];
                if ('individualKickOffDate' in user) {
                    user.individualKickOffDate = window.moment(user.individualKickOffDate).format('YYYY-MM-DD');
                }
                if ('kickOffDate' in user) {
                    user.kickOffDate = window.moment(user.kickOffDate).format('YYYY-MM-DD');
                }
                if ('createdAt' in user) {
                    user.createdAt = window.moment(user.createdAt).format('YYYY-MM-DD h:mm A');
                }
                user.secondaryTeams = secondaryTeams;
                return {
                    ...state,
                    user: user,
                    timestamp: now(),
                    failUpload: []
                };
            case POST:
            case PUT:
                user = action.payload && action.payload.user ? action.payload.user : (state.user ?? {});
                if('teamList' in action.payload){
                    secondaryTeams = action.payload && 'teamList' in action.payload ? action.payload.teamList.map((t) => t.id) : (state.user.secondaryTeams ?? []);
                    user.secondaryTeams = secondaryTeams;
                }
                if ('individualKickOffDate' in user) {
                    user.individualKickOffDate = window.moment(user.individualKickOffDate).format('YYYY-MM-DD');
                }
                if ('kickOffDate' in user) {
                    user.kickOffDate = window.moment(user.kickOffDate).format('YYYY-MM-DD');
                }
                if ('createdAt' in user) {
                    user.createdAt = window.moment(user.createdAt).format('YYYY-MM-DD h:mm A');
                }
                if (action.payload && action.payload.user) {
                    let index = users.findIndex(val => val.id === user.id);
                    if (index >= 0) {
                        users[index] = user;
                    } else {
                        users.unshift(user);
                        users.pop();
                    }
                }
                return {
                    ...state,
                    user: user,
                    userList: users,
                    timestamp: now(),
                    failUpload: []
                }
            case POST_BULK:
                let failedData = action.payload && action.payload.failedEmailIds ? action.payload.failedEmailIds : [];
                failedData = failedData.map((val) => {
                    if ('userRole' in val) {
                        delete val.userRole;
                    }
                    return val;
                });
                if (failedData.length > 0) {
                    if (action.payload.successEmailIds.length === 0) {
                        displayErrorMessage('Fail to upload users');
                    } else {
                        displayMessage('Users has been partially uploaded');
                    }
                } else {
                    displayMessage('Users uploaded successfully');
                }
                return {
                    ...state,
                    failUpload: failedData,
                    timestamp: now()
                }
            case TEAM_FETCH:
                if (action.payload && action.payload.membersList) {
                    members = action.payload.membersList ?? [];
                    members = members.map((value) => {
                        if (value['individualKickOffDate'] !== undefined && value['individualKickOffDate'].length > 0) {
                            value['individualKickOffDate'] = window.moment(value['individualKickOffDate']).format('YYYY-MM-DD');
                        }
                        if (value['kickOffDate'] !== undefined && value['kickOffDate'].length > 0) {
                            value['kickOffDate'] = window.moment(value['kickOffDate']).format('YYYY-MM-DD');
                        }
                        if (value['createdAt'] !== undefined && value['createdAt'].length > 0) {
                            value['createdAt'] = window.moment(value['createdAt']).format('YYYY-MM-DD h:mm A');
                        }
                        return value;
                    });
                }
                return {
                    ...state,
                    memberList: members ?? [],
                    failUpload: []
                };
            default:
                return {...state, timestamp: now(), failUpload: []};
        }
    } catch (e) {
        // console.log({e});
    }
    return state;
};

export default reducer;