import React, {useEffect, useRef, useState} from 'react'
import {connect} from "react-redux"
import {reduxForm} from 'redux-form'
import {Button, Col, FormGroup, Input, Label, Row} from "reactstrap";
import Loader from "../../../components/Loader/Loader";
import {fetchUsers} from "../../../redux/user/user.actions";
import AsyncSelect from "react-select/async";
import {checkAuth, checkAuthWithCallback, reactSelectStyle} from "../../../config/helper";
import {useHistory} from "react-router-dom";
import Select from "react-select";
import {fetchGroup, fetchGroups} from "../../../redux/group/group.actions";

const advisorData = {
    userId:'',
    teamId:''
}

const validate = (values) => {
    let errors = {};
    if (!values.userId) {
        errors.userId = 'Coach is required.';
    }
    if (!values.teamId) {
        errors.teamId = 'Team is required.';
    }
    return errors;
}

const warn = () => {
    return {};
}

let AdvisorCreateForm = props => {
    const {fetchUsers, onSubmit, fetchGroup, fetchGroups} = props;
    const mounted = useRef(false);
    const selectTeamInputRef = useRef();
    const history = useHistory();
    const [fields, setFields] = useState(advisorData);
    const [isSubmitting,setIsSubmitting] = useState({text:'Save',icon: ''});
    const [teamList,setTeamList] = useState([]);
    const [selectedTeams,setSelectedTeams] = useState([]);
    const [selectAllTeamsVal, setSelectAllTeamsVal] = useState(false);
    const [isLoading,setIsLoading] = useState(true);
    const [errorInfo,setErrorInfo] = useState({});
    const [selectedGroups, setSelectedGroups] = useState(null);
    const [tableInfo,setTableInfo] = useState({
        pagination: {
            pageNumber: 1,
            pageSize: 100,
            totalPages:1,
            totalRecords: 0
        },
        filter: {},
        sort: {}
    });

    useEffect(() => {
        checkAuth(history);
        mounted.current = true;
        setIsLoading(false);
        return () => {
            mounted.current = false;
            setFields(advisorData);
            setIsSubmitting({text:'Save',icon: ''});
            setErrorInfo({});
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if(props.submitBtnText !== isSubmitting.text) {
            setIsSubmitting({text:props.submitBtnText,icon: props.submitBtnIcon});
        }else{
            if(isLoading){
                setIsSubmitting({text:'Wait',icon: ''});
            }else{
                setIsSubmitting({text:'Save',icon: ''});
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.submitBtnText,props.submitBtnIcon,isLoading]);

    useEffect(() => {
        if (mounted.current) {
            if (props.groupList === undefined || props.groupList.length <= 1) {
                checkAuthWithCallback(history, fetchGroups);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.groupList, fetchGroups]);

    useEffect(() => {
        if(mounted.current) {
            if (selectedGroups && selectedGroups !== props.group.id) {
                selectTeamInputRef.current.clearValue();
                fetchGroup(selectedGroups.value);
            } else {
                setTeamList([]);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[selectedGroups]);

    useEffect(() => {
        setTeamList(props.teamList);
        if(selectAllTeamsVal){
            setSelectedTeams(props.teamList);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.teamList]);

    useEffect(() => {
        if(selectAllTeamsVal){
            setSelectedTeams(props.teamList);
        }else{
            setSelectedTeams([]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[selectAllTeamsVal]);

    useEffect(() => {
        if(selectedTeams.length > 0) {
            let data = fields;
            data['teamId'] = selectedTeams.map(v => v.value);
            setFields(data);
        }
        removeError('teamId');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[selectedTeams]);

    const loadOptions = (val) => {
        if(mounted.current) {
            removeError('userId');
            if (val.length > 2) {
                let table = tableInfo;
                table.filter.fullName = val;
                setTableInfo(table);
                return fetchUsers(table).then((data) => {
                    if (data.payload && data.payload.userList) {
                        return data.payload.userList;
                    } else {
                        return [];
                    }
                });
            } else {
                return [];
            }
        }else{
            return [];
        }
    }

    const changeTeam = (val) => {
        setSelectedTeams(val);
    }

    const changeGroup = (val) => {
        setSelectedGroups(val);
    }

    const selectAllTeams = (val) => {
        setSelectAllTeamsVal(val.target.checked);
    }

    const handleAsyncSelect = (key,val) => {
        let data = fields;
        data[key] = val.id;
        setFields(data);
    }

    const removeError = (key) => {
        let errorData = errorInfo;
        if(key in errorData){
            delete errorData[key];
            setErrorInfo(errorData);
        }
    }

    const handleForm = async (val) => {
        let fail = false;
        val.preventDefault();
        let errorData = errorInfo;
        if(fields.userId === ''){
            errorData.userId = 'Coach selection is required';
            fail = true;
        }else{
            delete errorData.userId;
        }
        if(fields.teamId.length <= 0){
            errorData.teamId = 'Team selection is required';
            fail = true;
        }else{
            delete errorData.teamId;
        }
        setErrorInfo(errorData);
        if(!fail){
            setIsSubmitting({text:'Saving',icon: 'fa fa-spinner fa-spin'})
        }else{
            setIsSubmitting({text:'Save',icon: ''});
        }
        return fail;
    }

    return (
        <>
            {
                isLoading ?
                    <div className="text-center"><Loader /></div>
                    :
                    <form onSubmit={(values) => handleForm(values).then((data) => {
                        if(!data){
                            onSubmit(fields);
                        }
                    })}>
                        <Row>
                            <Col lg="12">
                                <FormGroup>
                                    <Label>Coach <span className="text-danger">*</span></Label>
                                    <AsyncSelect
                                        getOptionLabel={(option) => `${[(option.firstName || ''),(option.lastName || ''),':'].join(' ').trim()} ${option.emailId}`}
                                        noOptionsMessage={() => "Type 3 or more letters to search"}
                                        getOptionValue={e => e.id}
                                        loadOptions={loadOptions}
                                        name="userId"
                                        required={true}
                                        onChange={(val) => handleAsyncSelect('userId',val)}
                                        styles={reactSelectStyle}
                                    />
                                    {errorInfo.userId && <span className="text-danger">{errorInfo.userId}</span>}
                                </FormGroup>
                            </Col>
                            <Col lg="12">
                                <FormGroup>
                                    <Label for="group">
                                        Organization
                                    </Label>
                                    <Select
                                        id={"group"}
                                        className="basic-select"
                                        classNamePrefix="select"
                                        isSearchable={true}
                                        options={props.groupSelect}
                                        name="groupId"
                                        isClearable
                                        onChange={changeGroup}
                                        styles={reactSelectStyle}
                                    />
                                </FormGroup>
                            </Col>
                            <Col lg="12">
                                <FormGroup>
                                    <Label for="team">
                                        Team <span className="text-danger">*</span>
                                    </Label>
                                    <Select
                                        id="team"
                                        name="teamId"
                                        placeholder="Select Team"
                                        options={teamList}
                                        className="basic-multi-select"
                                        classNamePrefix="select"
                                        isMulti={true}
                                        isClearable
                                        onChange={changeTeam}
                                        styles={reactSelectStyle}
                                        ref={selectTeamInputRef}
                                        value={selectedTeams}
                                    >
                                    </Select>
                                    {errorInfo.teamId && <span className="text-danger">{errorInfo.teamId}</span>}
                                </FormGroup>
                                <FormGroup className="mr-5">
                                    <Input id="allTeams" className="position-relative ml-0 mr-1" type="checkbox" onChange={selectAllTeams} />
                                    <Label for="allTeams">
                                        All Teams
                                    </Label>
                                </FormGroup>
                            </Col>
                            <Col lg="12">
                                <Button color="primary" type="submit" disabled={isSubmitting.text !== 'Save'}>{isSubmitting.icon !== '' ? <i className={isSubmitting.icon} /> : ''} {isSubmitting.text}</Button>
                            </Col>
                        </Row>
                    </form>
            }
        </>);
}

const mapStateToProps = (state) => {
    return {
        group: state.group.group ?? null,
        teamList: state.group.teamSelect ?? [],
        advisorList: state.user.userList ?? [],
        groupSelect: state.group.groupSelect ?? [],
    };
};

const mapDispatchToProps = {
    fetchGroup,
    fetchGroups,
    fetchUsers
};

AdvisorCreateForm = reduxForm({
    form: 'advisorCreateForm',
    // enableReinitialize:true,
    validate,
    warn
})(AdvisorCreateForm);

export default connect(mapStateToProps,mapDispatchToProps)(AdvisorCreateForm)