/*!

=========================================================
* Argon Dashboard React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import {Link, useHistory} from "react-router-dom";
// reactstrap components
import {
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Form,
  Navbar,
  Nav,
  Container,
  Media,
} from "reactstrap";
import {removeToken, auth, decodeRole, switchToRole} from "../../config/helper";
import {connect} from "react-redux";
import {signOutUser} from "../../redux/auth/auth.actions";
import {useEffect, useState} from "react";

const AdvisorNavbar = (props) => {
  const {signOutUser} = props;
  const user = auth();
  const history = useHistory();
  const [allowAdmin,setAllowAdmin] = useState(false);
  const [env, setEnv] = useState('development');

  useEffect(() => {
    if (localStorage.getItem("_randUidAs") !== null) {
      setAllowAdmin(true)
    }
    function storageEventHandler(event) {
      if (event.key === "_randUidAs" && event.newValue !== null) {
        setAllowAdmin(true)
      }
    }
    window.addEventListener("storage", storageEventHandler);
    return () => {
      window.removeEventListener("storage", storageEventHandler);
    };
  }, []);

  useEffect(() => {
    const storedEnv = sessionStorage.getItem('env');
    if (storedEnv) {
      setEnv(storedEnv);
    }
  }, []);

  const goToAdmin = () =>{
    signOutUser();
    switchToRole(history,'advisor','admin');
  }

  function handleLogout () {
    removeToken()
    signOutUser();
    let role = decodeRole();
    history.push('/auth/login/'+(role === 'admin' ? 'admin' : ''));
  }

  function toggleEnv() {
    const newEnv = env === 'development' ? 'production' : 'development';
    setEnv(newEnv);
    sessionStorage.setItem('env', newEnv);
    window.location.reload();
  }

  return (
    <>
      <Navbar className="navbar-top navbar-dark" expand="md" id="navbar-main">
        <Container fluid>
          <Link
            className="h4 mb-0 text-white text-uppercase d-none d-lg-inline-block"
            to="/"
          >
            {props.brandText}
          </Link>
          <Form className="navbar-search navbar-search-dark form-inline mr-3 d-none d-lg-flex ml-xl-auto">
            <div className="custom-control custom-switch">
              <input type="checkbox" className="custom-control-input" id="customSwitch1"
                     onChange={toggleEnv} checked={env === "development"} />
              <label className="custom-control-label" htmlFor="customSwitch1">
                <h3 className="text-secondary">Use Dev Environment</h3>
              </label>
            </div>
          </Form>
          <Nav className="align-items-center d-none d-md-flex" navbar>
            <UncontrolledDropdown nav>
              <DropdownToggle className="pr-0" nav>
                <Media className="align-items-center">
                  <span className="avatar avatar-sm rounded-circle">
                    <img
                      alt="..."
                      src={"https://ui-avatars.com/api/?name=" + ((user !== null && user.name) || "Coach") + "&color=7F9CF5&background=EBF4FF"}
                    />
                  </span>
                  <Media className="ml-2 d-none d-lg-block">
                    <span className="mb-0 text-sm font-weight-bold">
                      {(user !== null && user.name) || ""}
                    </span>
                  </Media>
                </Media>
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-arrow" right>
                <DropdownItem className="noti-title" header tag="div">
                  <h6 className="text-overflow m-0">Welcome!</h6>
                </DropdownItem>
                <DropdownItem divider />
                {allowAdmin ?
                    <DropdownItem onClick={goToAdmin}>
                      <i className="ni ni-tv-2" />
                      <span>Switch To Admin</span>
                    </DropdownItem>
                    : ''}
                {/*<DropdownItem to="/admin/my-profile" tag={Link}>*/}
                {/*  <i className="ni ni-single-02" />*/}
                {/*  <span>My profile</span>*/}
                {/*</DropdownItem>*/}
                <DropdownItem onClick={handleLogout}>
                  <i className="ni ni-user-run" />
                  <span>Logout</span>
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </Nav>
        </Container>
      </Navbar>
    </>
  );
};

const mapStateToProps = () => {
  return {
  }
}

const mapDispatchToProps = {
  signOutUser
}

export default connect(mapStateToProps, mapDispatchToProps)(AdvisorNavbar);
