
const Loader = () => {
  return (
    <>
      <div className={"p-4"}>
        <div className="lds-roller">
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
        </div>
      </div>
    </>
  )
}

export default Loader;