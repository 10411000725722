import React, {useEffect, useRef, useState} from "react";
import {connect} from "react-redux";
import {fetchNotifications} from "../../redux/notification/notification.actions";
import {useHistory} from "react-router-dom";
import {paginationSchema} from "../../config/schema";
import {checkAuth, checkAuthWithCallback, getStaticDataValue} from "../../config/helper";
import {Card, CardBody, CardHeader, Col, Row} from "reactstrap";
import DataTable from "react-data-table-component";
import Loader from "../../components/Loader/Loader";
import CreateNotificationModal from "./Modal/CreateNotificationModal";

const NotificationSection = (props) => {
    const mounted = useRef(false);
    const history = useHistory();
    const {fetchNotifications} = props;
    const [tableInfo, setTableInfo] = useState(paginationSchema());
    const [listLoader, setListLoader] = useState(true);
    const [timestamp, setTimestamp] = useState('');

    useEffect(() => {
        mounted.current = true;
        checkAuth(history);
        getNotificationData();
        return () => {
            mounted.current = false;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const columns = [
        {
            name: 'Title',
            selector: row => row.title,
            fieldName: 'title'
        },
        {
            name: "Link",
            fieldName: 'link',
            cell: row => {
                if(row.link) {
                    return (
                        <a target="_blank" href={row.link} rel="noreferrer">{row.link}</a>
                    );
                }else{
                    return "N/A";
                }
            },
        },
        {
            name: 'Audience',
            fieldName: 'audienceType',
            selector: row => getStaticDataValue(row.audienceType, 'audienceType'),
        },
        {
            name: 'Device Reached',
            selector: row => row.deviceCount ?? 0,
            fieldName: 'deviceCount'
        },
        {
            name: 'Created At',
            selector: row => row.createdAt || 'N/A',
            fieldName: 'createdAt'
        }
    ];

    useEffect(() => {
        if (props.timestamp !== timestamp) {
            setListLoader(false);
            setTimestamp(props.timestamp);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.timestamp]);

    useEffect(() => {
        if (props.paginationResult !== undefined) {
            let page = tableInfo;
            page.pagination = {...page.pagination, ...props.paginationResult}
            setTableInfo(page)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.paginationResult]);

    const handlePageChange = pageNum => {
        let page = tableInfo;
        page.pagination.pageNumber = pageNum;
        setListLoader(true);
        setTableInfo(page);
        getNotificationData();
    };

    const handlePerRowsChange = async (newPerPage, currentPage) => {
        let page = tableInfo;
        page.pagination.pageSize = newPerPage;
        page.pagination.pageNumber = currentPage;
        setListLoader(true);
        setTableInfo(page);
        getNotificationData();
    };

    const getNotificationData = () => {
        if (mounted.current) {
            setListLoader(true);
            checkAuthWithCallback(history,fetchNotifications, tableInfo);
        }
    }

    const handleSort = async (column, sortDirection) => {
        let page = tableInfo;
        page.sort = {};
        page.sort[column.fieldName] = sortDirection === 'asc' ? 1 : -1;
        setTableInfo(page);
        getNotificationData();
    };

    return (
        <>
            {/* Page content */}
            <Card className="shadow border-0">
                <CardHeader>
                    <Row>
                        <Col lg="12" md="12">
                            <span className={"mb-0 h3"}>Notification List</span>
                            <CreateNotificationModal
                                modalBtnText='Send Notification'
                                modalBtnIcon="ni ni-send mr-2"
                                modalTitleText="Send Notification"
                            />
                        </Col>
                    </Row>
                </CardHeader>
                <CardBody>
                    <Row>
                        <Col sm="12">
                            <DataTable
                                columns={columns}
                                progressPending={listLoader}
                                progressComponent={<Loader/>}
                                className={"react-dataTable"}
                                data={props.notificationList || []}
                                pagination
                                responsive
                                paginationServer
                                paginationTotalRows={tableInfo.pagination.totalRecords}
                                onChangeRowsPerPage={handlePerRowsChange}
                                onChangePage={handlePageChange}
                                onSort={handleSort}
                                sortServer
                                subHeaderWrap
                            />
                        </Col>
                    </Row>
                </CardBody>
            </Card>
        </>
    )

}

const mapStateToProps = (state) => {
    return {
        notificationList: state.notification.adhocNotificationList,
        paginationResult: state.notification.paginationResult,
        timestamp: state.notification.timestamp ?? ''
    }
}

const mapDispatchToProps = {
    fetchNotifications
}

export default connect(mapStateToProps, mapDispatchToProps)(NotificationSection);