import React, {useEffect, useRef, useState} from "react";
import {connect} from "react-redux";
import {Button, ButtonGroup, Tooltip} from "reactstrap";
import {checkAuth, showConfirmDialog} from "../../config/helper";
import {Link, useHistory} from "react-router-dom";
import CreateModal from "../group/CreateModal";
import {deleteGroup} from "../../redux/group/group.actions";

const loaderStatus = {
    deleteGroup: false
};

const TableAction = (props) => {
    const {deleteGroup} = props;
    const [deleteTooltipOpen, setDeleteTooltipOpen] = useState(false);
    const [viewTooltipOpen, setViewTooltipOpen] = useState(false);
    const [loadingBtn, setLoadingBtn] = useState(loaderStatus);
    const mounted = useRef(false);
    const history = useHistory();

    useEffect(() => {
        checkAuth(history);
        mounted.current = true;
        return () => {
            mounted.current = false;
            setLoadingBtn(loaderStatus);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const deleteToggleTooltip = () => setDeleteTooltipOpen(!deleteTooltipOpen);
    const viewToggleTooltip = () => setViewTooltipOpen(!viewTooltipOpen);

    const handleDelete = (row, status) => {
        if (mounted.current) {
            if (status) {
                deleteGroup(row).then(() => {
                    updateLoaderData('deleteGroup', false);
                });
            }
        }
    }

    const updateLoaderData = (key, val) => {
        let loading = loadingBtn;
        if (key in loading) {
            loading[key] = val;
            setLoadingBtn(loading);
        }
    }

    return (
        <>
            <div className='d-flex'>
                <ButtonGroup>
                    <CreateModal
                        formData={props.row}
                        modalBtnText=''
                        modalBtnIcon="fa fa-edit"
                        modalTitleText="Update Organization"
                        updateGroupList={props.updateGroupList}
                        tooltipText={"Update"}
                    />
                    <Link
                        to={'/admin/organization/view/'+props.row.id}
                        className={"btn btn-sm btn-info align-items-center"}
                        id={'viewTooltip-' + props.row.id}
                    >
                        <i className="fa fa-eye"/>
                    </Link>
                    <Button
                        color="danger"
                        size="sm"
                        type="button"
                        onClick={() => showConfirmDialog(props.row, handleDelete, 'Are you sure!')}
                        id={'deleteTooltip-' + props.row.id}
                    >
                        {loadingBtn.deleteGroup ? <i className='fa fa-spinner fa-spin'/> :
                            <i className={'fa fa-trash'}/>}
                    </Button>
                </ButtonGroup>
                <Tooltip placement="top" isOpen={deleteTooltipOpen} autohide={false}
                         target={'deleteTooltip-' + props.row.id} toggle={deleteToggleTooltip}>
                    Delete
                </Tooltip>
                <Tooltip placement="top" isOpen={viewTooltipOpen} autohide={false}
                         target={'viewTooltip-' + props.row.id} toggle={viewToggleTooltip}>
                    View
                </Tooltip>
            </div>
        </>
    );
}

const mapStateToProps = () => {
    return {}
}

const mapDispatchToProps = {
    deleteGroup
}

export default connect(mapStateToProps, mapDispatchToProps)(TableAction);