import React, {useEffect, useRef, useState} from "react";
import {connect} from "react-redux";
import {Button, ButtonGroup, Tooltip} from "reactstrap";
import {Link, useHistory} from "react-router-dom";
import ChangeStatusModal from "./Modals/ChangeStatusModal";
import {checkAuth, displayMessage, showConfirmDialog} from "../../config/helper";
import {resendUserPassword, resetUser, updateUserProfile} from "../../redux/user/user.actions";

const loaderStatus = {
    updatePassword: false,
    updateUserStatus: false,
    removedFromTeam: false,
    resetUser: false
};

const TableAction = (props) => {
    const {updateUserProfile, resetUser, resendUserPassword} = props;
    const [updateUserTooltipOpen, setUpdateUserTooltipOpen] = useState(false);
    const [detailTooltipOpen, setDetailTooltipOpen] = useState(false);
    const [changeStatusTooltipOpen, setChangeStatusTooltipOpen] = useState(false);
    const [updatePasswordTooltipOpen, setUpdatePasswordTooltipOpen] = useState(false);
    const [resetUserTooltipOpen, setResetUserTooltipOpen] = useState(false);
    const [showStatusModal, setShowStatusModal] = useState(false);
    const [loadingBtn, setLoadingBtn] = useState(loaderStatus);
    const [updatePasswordLoadingBtn, setUpdatePasswordLoadingBtn] = useState(false);
    const [updateUserStatusLoadingBtn, setUpdateUserStatusLoadingBtn] = useState(false);
    const mounted = useRef(false);
    const history = useHistory();

    useEffect(() => {
        checkAuth(history);
        mounted.current = true;
        return () => {
            mounted.current = false;
            setLoadingBtn(loaderStatus);
        };
    }, []);

    const detailToggleTooltip = () => {
        setDetailTooltipOpen(!detailTooltipOpen);
    }

    const changeStatusToggleTooltip = () => {
        setChangeStatusTooltipOpen(!changeStatusTooltipOpen);
    }
    const updatePasswordToggleTooltip = () => {
        setUpdatePasswordTooltipOpen(!updatePasswordTooltipOpen);
    }
    const resetUserToggleTooltip = () => {
        setResetUserTooltipOpen(!resetUserTooltipOpen);
    }

    const updateUserStatus = (status) => {
        setUpdateUserStatusLoadingBtn(true);
        showConfirmDialog({
            profileInfo: {userId: props.row.id, userStatus: status},
            loaderKey: 'updateUserStatus'
        }, updateProfileCallback, 'Are you sure!', 'you are going to change status of <strong>' + props.row.emailId + '</strong>');
    }

    const updatePassword = () => {
        updateLoaderData('updatePassword', true);
        showConfirmDialog({loaderKey: 'updatePassword'}, resendPasswordCallback, 'Are you sure!');
    }

    const updateProfileInfo = (profileInfo) => {
        if (mounted.current) {
            updateUserProfile(profileInfo).then((data) => {
                if (!('payload' in data && data.payload === undefined)) {
                    if ("userStatus" in profileInfo) {
                        displayMessage("User Status has been updated");
                        setShowStatusModal(false);
                    }
                    setUpdatePasswordLoadingBtn(false);
                    setUpdateUserStatusLoadingBtn(false);
                }
            });
        }
    }

    const resetUserData = () => {
        updateLoaderData('resetUser', true);
        showConfirmDialog(null, resetUserCallback, 'Are you sure!');
    }

    const updateProfileCallback = (data, status) => {
        if (status) {
            updateProfileInfo(data.profileInfo, data.loaderKey);
        } else {
            updateLoaderData(data.loaderKey, false);
            setUpdatePasswordLoadingBtn(false);
            setUpdateUserStatusLoadingBtn(false);
        }
    }

    const resendPasswordCallback = (data, status) => {
        if (status) {
            resendUserPassword({userId: props.row.id}).then(() => {
                updateLoaderData(data.loaderKey, false);
            });
        } else {
            updateLoaderData(data.loaderKey, false);
        }
    }

    const resetUserCallback = (data, status) => {
        if (mounted.current) {
            if (status) {
                resetUser({userId: props.row.id}).then((data) => {
                    if (!('payload' in data && data.payload === undefined)) {
                        displayMessage("User reset successfully");
                    }
                    updateLoaderData('resetUser', false);
                });
            } else {
                updateLoaderData('resetUser', false);
            }
        }
    }

    const updateLoaderData = (key, val) => {
        let loading = loadingBtn;
        if (key in loading) {
            loading[key] = val;
            setLoadingBtn(loading);
        }
    }

    return (
        <>
            <div className='d-flex'>
                <ButtonGroup>
                    {/*<Button color="danger" outline size="sm" type="button" onClick={() => handleDelete(row)} disabled={true}><i*/}
                    {/*    className={"fa fa-trash"}/></Button>*/}
                    {/*<Button color="primary" size="sm" type="button" disabled={true}><i*/}
                    {/*    className={"fa fa-edit"}/></Button>*/}
                    {/*<Link*/}
                    {/*    to={'/admin/user/update/' + props.row.id}*/}
                    {/*    className={"btn btn-sm btn-primary align-items-center"}*/}
                    {/*    id={'updateUserTooltip-' + props.row.id}*/}
                    {/*>*/}
                    {/*    <i className="fa fa-edit"/>*/}
                    {/*</Link>*/}
                    <Link
                        to={'/admin/user/view/' + props.row.id}
                        className={"btn btn-sm btn-info align-items-center"}
                        id={'detailTooltip-' + props.row.id}
                    >
                        <i className="fa fa-eye"/>
                    </Link>
                    <Button
                        size={'sm'}
                        color="primary"
                        onClick={() => setShowStatusModal(true)}
                        id={'statusTooltip-' + props.row.id}
                    >
                        <i className={'fas fa-universal-access'}/>
                    </Button>
                    <Button
                        size={'sm'}
                        color="success"
                        onClick={updatePassword}
                        id={'passwordTooltip-' + props.row.id}
                    >
                        {loadingBtn.updatePassword ? <i className='fa fa-spinner fa-spin'/> :
                            <i className={'fas fa-key'}/>}
                    </Button>
                    <Button
                        size={'sm'}
                        color="danger"
                        onClick={resetUserData}
                        disabled={loadingBtn.resetUser}
                        id={'resetUserTooltip-' + props.row.id}
                    >
                        {loadingBtn.resetUser ? <i className='fa fa-spinner fa-spin'/> : <i className={'fas fa-undo'}/>}
                    </Button>
                </ButtonGroup>
                {/*<Tooltip placement="top" isOpen={updateUserTooltipOpen} autohide={false}*/}
                {/*         target={'updateUserTooltip-' + props.row.id} toggle={updateUserToggleTooltip}>*/}
                {/*    Update User*/}
                {/*</Tooltip>*/}
                <Tooltip placement="top" isOpen={detailTooltipOpen} autohide={false}
                         target={'detailTooltip-' + props.row.id} toggle={detailToggleTooltip}>
                    Details
                </Tooltip>
                <Tooltip placement="top" isOpen={changeStatusTooltipOpen} autohide={false}
                         target={'statusTooltip-' + props.row.id} toggle={changeStatusToggleTooltip}>
                    Change Status
                </Tooltip>
                <Tooltip placement="top" isOpen={updatePasswordTooltipOpen} autohide={false}
                         target={'passwordTooltip-' + props.row.id} toggle={updatePasswordToggleTooltip}>
                    Resend Password
                </Tooltip>
                <Tooltip placement="top" isOpen={resetUserTooltipOpen} autohide={false}
                         target={'resetUserTooltip-' + props.row.id} toggle={resetUserToggleTooltip}>
                    Reset User
                </Tooltip>
                <ChangeStatusModal
                    isModalOpen={showStatusModal}
                    userStatus={props.row.userStatus}
                    handleSubmit={updateUserStatus}
                    handleClose={(val) => setShowStatusModal(val)}
                    loadingBtn={updateUserStatusLoadingBtn}
                >
                </ChangeStatusModal>
                {/*<UpdatePasswordModal*/}
                {/*    isModalOpen={showPasswordChangeModal}*/}
                {/*    handleSubmit={updatePassword}*/}
                {/*    handleClose={(val) => setShowPasswordChangeModal(val)}*/}
                {/*    loadingBtn={updatePasswordLoadingBtn}*/}
                {/*>*/}
                {/*</UpdatePasswordModal>*/}
            </div>
        </>
    );
}

const mapStateToProps = () => {
    return {}
}

const mapDispatchToProps = {
    updateUserProfile,
    resetUser,
    resendUserPassword
}

export default connect(mapStateToProps, mapDispatchToProps)(TableAction);