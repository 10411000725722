import React, {useEffect, useRef, useState} from 'react'
import {connect} from "react-redux"
import {Field, reduxForm} from 'redux-form'
import {Button, Col, Row} from "reactstrap";
import {
    renderSelectSearchableField,
    renderSelectUserAsyncField,
    renderTextField
} from "../../../config/formElement";
import Loader from "../../../components/Loader/Loader";
import {checkAuth, checkAuthWithCallback} from "../../../config/helper";
import {useHistory} from "react-router-dom";
import {paginationSchema, updateNotificationSchema} from "../../../config/schema";
import {audienceType} from "../../../config/staticData";
import {fetchTeams} from "../../../redux/team/team.actions";
import {fetchUsers} from "../../../redux/user/user.actions";

const validate = (values) => {
    let errors = {};
    if (!values.audienceType) {
        errors.audienceType = 'Audience selection is required';
    } else {
        if (values.audienceType.value === 'teams') {
            if (values.team_list.length === 0) {
                errors.team_list = 'Please select minimum one team.';
            }
        }
        if (values.audienceType.value === 'users') {
            if (values.user_list.length === 0) {
                errors.user_list = 'Please select minimum one user.';
            }
        }
    }
    if (!values.title) {
        errors.title = 'Title is required';
    }
    // if (!values.is_adhoc && !values.message) {
    //     errors.message = 'Message is required';
    // }
    let urlTemplate = /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&/=]*)/g;
    let ref_mediaUrl = (values.link || '').match(urlTemplate)
    if (values.link && ref_mediaUrl == null) {
        errors.link = "Please enter valid URL";
    }
    return errors;
}

const warn = () => {
    return {};
}

let NotificationCombineCreateForm = (props) => {
    const {handleSubmit, fetchTeams, fetchUsers} = props;
    const mounted = useRef(false);
    const history = useHistory();
    const [fields, setFields] = useState(updateNotificationSchema({}));
    const [isSubmitting, setIsSubmitting] = useState({text: 'Save', icon: ''});
    const [isLoading, setIsLoading] = useState(true);
    const [audienceField, setAudienceField] = useState([]);
    const [notificationFields, setNotificationFields] = useState([]);
    const [teamList, setTeamList] = useState([]);
    const [tableInfo, setTableInfo] = useState(paginationSchema);
    const [audienceChangeLoader, setAudienceChangeLoader] = useState(false);

    useEffect(() => {
        checkAuth(history);
        mounted.current = true;
        return () => {
            mounted.current = false;
            setFields(updateNotificationSchema({}));
            setIsSubmitting({text: 'Save', icon: ''});
        };
    }, []);

    useEffect(() => {
        if (props.submitBtnText !== isSubmitting.text) {
            setIsSubmitting({text: props.submitBtnText, icon: props.submitBtnIcon});
        } else {
            if (isLoading) {
                setIsSubmitting({text: 'Wait', icon: ''});
            } else {
                setIsSubmitting({text: 'Save', icon: ''});
            }
        }
    }, [props.submitBtnText, props.submitBtnIcon, isLoading]);

    useEffect(() => {
        if (mounted.current) {
            if (props.id !== undefined) {
                if (props.notification === {} || props.notification.id !== props.id) {
                    // checkAuthWithCallback(history,fetchNotification,props.id);
                }
                if (props.notification !== {} && props.notification.id === props.id) {
                    setFields(props.notification);
                    setIsLoading(false);
                }
            } else {
                setIsLoading(false);
            }
        }
    }, [props.id, props.notification]);

    useEffect(() => {
        if (mounted.current) {
            if (props.teamList === undefined || props.teamList.length === 0) {
                setIsLoading(true);
                checkAuthWithCallback(history, fetchTeams);
            } else {
                let teams = [...new Map(props.teamList.map(item => [item.value, item])).values()];
                setTeamList(teams);
                setIsLoading(false);
            }
        }
    }, [props.teamList, fetchTeams]);

    useEffect(() => {
        changeNotificationFields(fields.is_adhoc.value);
    },[fields.is_adhoc]);

    const handleSelect = (key, value) => {
        let data = fields;
        data[key] = value;
        setFields(data);
        if ('audienceType' === key) {
            changeAudienceType(value.value);
        }
        if ('is_adhoc' === key) {
            changeNotificationFields(value.value);
        }
    }

    const handleInput = (event) => {
        fields[event.target.name] = event.target.value;
        setFields(fields);
    };

    const changeNotificationFields = (val) => {
        let field = [];
        if (val) {
            field.push(<Col lg={12} key="form_message">
                <Field
                    name="message"
                    type="text"
                    label={"Message"}
                    hasDefaultValue={fields.message}
                    component={renderTextField}
                    isRequired={true}
                    onChange={handleInput}
                />
            </Col>);
            field.push(<Col lg={12} key="form_link">
                <Field
                    name="link"
                    type="text"
                    label={"Link"}
                    hasDefaultValue={fields.link}
                    component={renderTextField}
                    onChange={handleInput}
                />
            </Col>);
        } else {
            field.push(<Col lg={12} key="form_title">
                <Field
                    name="title"
                    type="text"
                    label={"Title"}
                    hasDefaultValue={fields.title}
                    isRequired={true}
                    component={renderTextField}
                    onChange={handleInput}
                />
            </Col>);
            field.push(<Col lg={12} key="form_link">
                <Field
                    name="link"
                    type="text"
                    label={"Link"}
                    hasDefaultValue={fields.link}
                    component={renderTextField}
                    onChange={handleInput}
                />
            </Col>);
        }
        setNotificationFields(field);
    };

    const loadOptions = (val) => {
        if (mounted.current) {
            if (val.length > 2) {
                let table = tableInfo;
                table.filter.fullName = val;
                if(fields.audienceType?.value === 'leaders') {
                    table.filter.userRole = 'leader';
                }else if(fields.audienceType?.value === 'users') {
                    table.filter.userRole = 'member';
                }else{
                    if('userRole' in table.filter){
                        delete table.filter.userRole;
                    }
                }
                setTableInfo(table);
                return fetchUsers(table).then((data) => {
                    if (data.payload && data.payload.userList) {
                        return data.payload.userList;
                    } else {
                        return [];
                    }
                });
            } else {
                return [];
            }
        } else {
            return [];
        }
    }

    const changeAudienceType = (val) => {
        let field = [];
        setAudienceChangeLoader(true);
        switch (val) {
            case 'teams':
                field.push(<Col lg={12} key="form_team_list">
                    <Field
                        name="team_list"
                        component={renderSelectSearchableField}
                        isMultiple={true}
                        label="Teams"
                        options={teamList ?? []}
                        hasDefaultValue={fields.team_list}
                        isRequired={true}
                        changeEvent={(val) => handleSelect('team_list', val)}
                    />
                </Col>)
                break;
            case 'leaders':
                field.push(<Col lg={12} key="form_user_list">
                    <Field
                        name="user_list"
                        component={renderSelectUserAsyncField}
                        isMultiple={true}
                        label="Leaders"
                        isRequired={true}
                        loadOptions={loadOptions}
                        hasDefaultValue={fields.user_list}
                        changeEvent={(val) => handleSelect('user_list', val)}
                    />
                </Col>)
                break;
            case 'users':
                field.push(<Col lg={12} key="form_user_list">
                    <Field
                        name="user_list"
                        component={renderSelectUserAsyncField}
                        isMultiple={true}
                        label="Members"
                        isRequired={true}
                        loadOptions={loadOptions}
                        hasDefaultValue={fields.user_list}
                        changeEvent={(val) => handleSelect('user_list', val)}
                    />
                </Col>)
                break;
            default:
                break;
        }
        setAudienceField(field);
        setAudienceChangeLoader(false);
    }

    return (
        <>
            {
                isLoading ?
                    <div className="text-center"><Loader/></div>
                    :
                    <form onSubmit={(values) => handleSubmit(values)}>
                        <Row>
                            {/*<Col xl="6">*/}
                            {/*    <Field*/}
                            {/*        name="is_adhoc"*/}
                            {/*        type="select"*/}
                            {/*        component={renderSelectSearchableField}*/}
                            {/*        label="Notification Type"*/}
                            {/*        options={notificationType ?? []}*/}
                            {/*        hasDefaultValue={fields.is_adhoc}*/}
                            {/*        isRequired={true}*/}
                            {/*        changeEvent={(val) => handleSelect('is_adhoc', val)}*/}
                            {/*    />*/}
                            {/*</Col>*/}
                            <Col lg={12}>
                                <Field
                                    name="audienceType"
                                    type="select"
                                    component={renderSelectSearchableField}
                                    label="Audience"
                                    options={audienceType ?? []}
                                    hasDefaultValue={fields.audienceType}
                                    isRequired={true}
                                    changeEvent={(val) => handleSelect('audienceType', val)}
                                />
                            </Col>
                            {audienceField}
                            {notificationFields}
                            <Col lg="12">
                                <Button color="primary" type="submit"
                                        disabled={isSubmitting.text !== 'Save' || audienceChangeLoader}>{isSubmitting.icon !== '' ?
                                    <i className={isSubmitting.icon}/> : ''} {isSubmitting.text}</Button>
                            </Col>
                        </Row>
                    </form>
            }
        </>
    );
}

const mapStateToProps = (state, ownProps) => {
        let notification = updateNotificationSchema({});
        if (ownProps.id !== undefined && state.notification !== undefined && state.notification.notification !== undefined && state.notification.notification.id !== undefined && ownProps.id === state.notification.notification.id) {
            notification = updateNotificationSchema(state.notification.notification);
        }
        return {
            initialValues: notification,
            notification: notification,
            teamList: state.team.teamSelect ?? []
        };
    }
;

const mapDispatchToProps = {
    fetchTeams,
    fetchUsers
};

NotificationCombineCreateForm = reduxForm(
    {
        form: 'notificationCombineCreateForm',
        // enableReinitialize: true,
        validate,
        warn
    }
)(NotificationCombineCreateForm);

export default connect(mapStateToProps, mapDispatchToProps)(NotificationCombineCreateForm)