import React, {useEffect, useState} from "react";
import {Modal} from "reactstrap";
import {connect} from "react-redux";

const ImageModal = (props) => {
    const {handleCloseModal} = props;
    const [show, setShow] = useState(false);

    useEffect(() => {
        if(props.isModalShow !== show && props.image !==''){
            setShow(props.isModalShow);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[props.isModalShow,props.image]);

    const handleClose = () => {
        setShow(false);
        handleCloseModal();
    }

    return (
        <>
            <Modal
                size="lg"
                isOpen={show}
                centered={true}
                backdrop={true}
                className={"modal-image-preview justify-content-center"}
            >
                <button className="close close-btn" onClick={handleClose} style={{position: 'absolute', right: '15px', top: '15px'}}>×</button>
                <img src={props.image} className="modal-image-size" alt={props.alt || 'Modal Image'}/>
            </Modal>
        </>
    );
}

const mapStateToProps = (state) => {
    return {
    }
}

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(ImageModal);