import React, {useEffect, useMemo, useRef, useState} from "react";
import {
    Card,
    CardBody,
    CardHeader,
    Col,
    Row
} from "reactstrap";
import DataTable from "react-data-table-component";
import Loader from "../../components/Loader/Loader";
import {connect} from "react-redux";
import {Link, useHistory} from "react-router-dom";
import Filter from "../../components/filters/Filter";
import {fetchSeriesList} from "../../redux/series/series.actions";
import {checkAuth, checkAuthWithCallback} from "../../config/helper";
import TableAction from "./TableAction";

const Index = (props) => {

    const {fetchSeriesList} = props;
    const mounted = useRef(false);
    const history = useHistory();
    const [listLoader,setListLoader] = useState(true);
    const [filterText, setFilterText] = useState('');
    const [filteredItems,setFilteredItems] = useState([]);

    const subHeaderComponentMemo = useMemo(() => {
        return (
            <Filter onFilter={val => {
                setFilterText(val)
            }} filterText={filterText} />
        );
    }, [filterText]);

    const columns = [
        {
            name: 'Name',
            selector: row => row.seriesName,
            sortable: true
        },
        {
            name: 'Practice Title',
            selector: row => row.seriesHeader,
            sortable: true
        },
        {
            name: 'Practice Category',
            selector: row => row.seriesCategory,
            sortable: true
        },
        {
            name: 'Practice Type',
            selector: row => row.seriesType,
            sortable: true
        },
        {
            name: 'Next Practice',
            selector: row => row.nextSeries && row.nextSeries.seriesName ? row.nextSeries.seriesName : '',
            sortable: true
        },
        // {
        //     name: 'Created at',
        //     selector: row => row.createdAt,
        //     sortable: true
        // },
        {
            name: 'Action',
            cell: (row) => {
                return (
                    <TableAction row={row} />
                )
            },
            maxWidth: '150px'
        },
    ];

    useEffect(() => {
        mounted.current = true;
        checkAuth(history);
        return () => {
            mounted.current = false;
            setFilteredItems([]);
        };
    }, []);

    useEffect(() => {
        if(mounted.current) {
            if (props.seriesList === undefined || props.seriesList.length === 0) {
                checkAuthWithCallback(history,fetchSeriesList);
            }
            if (props.seriesList.length > 0) {
                let filteredData = props.seriesList.filter(
                    item => (item.seriesName && item.seriesName.toLowerCase().includes(filterText.toLowerCase()))
                        || (item.seriesType && item.seriesType.toLowerCase().includes(filterText.toLowerCase()))
                );
                setFilteredItems(filteredData);
                setListLoader(false);
            }
        }
    }, [props.seriesList,filterText,fetchSeriesList]);

    return (
        <>
            {/* Page content */}
            <Row>
                <Col lg="12" md="12">
                    <Card className="shadow border-0">
                        <CardHeader>
                            <Row>
                                <Col lg="12" md="12">
                                    <span className={"mb-0 h3"}>Practice list</span>
                                    {/*<Link*/}
                                    {/*    to='/admin/practice/create'*/}
                                    {/*    className={"d-flex float-right btn btn-sm btn-primary align-items-center"}*/}
                                    {/*>*/}
                                    {/*    <i className="ni ni-fat-add mr-2"/>*/}
                                    {/*    Add Practice*/}
                                    {/*</Link>*/}
                                </Col>
                            </Row>
                        </CardHeader>
                        <CardBody>
                            <DataTable
                                columns={columns}
                                progressPending={listLoader}
                                progressComponent={<Loader/>}
                                className={"react-dataTable"}
                                data={filteredItems}
                                pagination
                                responsive
                                subHeaderWrap
                                subHeader
                                subHeaderComponent={subHeaderComponentMemo}
                            />
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </>
    )
};

const mapStateToProps = (state) => {
    return {
        seriesList: state.series.seriesList ?? []
    }
}

const mapDispatchToProps = {
    fetchSeriesList
}

export default connect(mapStateToProps, mapDispatchToProps)(Index);
