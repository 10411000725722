import {FETCH, DELETE, VIEW, PUT, POST, VIEW_ITEM, FETCH_ITEM, PUT_ITEM, POST_ITEM} from './series.types';
import collect from "collect.js";
import {now} from "moment";

const INITIAL_STATE = {
    series: {},
    seriesItem: {},
    seriesList: [],
    seriesItemList: undefined,
    seriesSelect: [],
    timestamp: ''
};

const reducer = (state = INITIAL_STATE, action) => {
    let seriesSelect = [];
    let seriesList = [];
    let series = {};
    let seriesItemList = [];
    let seriesItem = {};
    try {
        switch (action.type) {
            case FETCH:
                seriesList = action.payload.seriesList ?? [];
                seriesList = seriesList.map(val => {
                    val.createdAt = window.moment(val.createdAt).format('YYYY-MM-DD h:mm A');
                    return val;
                });
                seriesSelect = seriesList.map((val) => {
                    return {value: val.id, label: val.seriesName}
                });
                return {
                    ...state, seriesList: seriesList, seriesSelect: seriesSelect
                };
            case VIEW:
                if (action.payload.series && action.payload.series.id !== undefined) {
                    series = action.payload.series ?? {};
                    seriesItem = action.payload.seriesItem ?? {};
                    if ('createdAt' in series) {
                        series.createdAt = window.moment(series.createdAt).format('YYYY-MM-DD h:mm A');
                    }
                }
                seriesItemList = action.payload.seriesItemList ?? [];
                seriesItemList = seriesItemList.map(val => {
                    val.createdAt = window.moment(val.createdAt).format('YYYY-MM-DD h:mm A');
                    return val;
                });
                return {
                    ...state, series: series, seriesItemList: seriesItemList, seriesItem: seriesItem
                };
            case DELETE:
                return {
                    ...state,
                    seriesList: state.seriesList.filter(item => item.id !== action.payload.id),
                    seriesSelect: state.seriesSelect.filter(item => item.value !== action.payload.id)
                };
            case POST:
            case PUT:
                seriesList = state.seriesList ?? [];
                seriesItemList = action.payload.seriesItemList ?? [];
                if (action.payload.series && action.payload.series.id !== undefined) {
                    series = action.payload.series ?? {};
                    if ('createdAt' in series) {
                        series.createdAt = window.moment(series.createdAt).format('YYYY-MM-DD h:mm A');
                    }
                    seriesList = [series].concat(seriesList);
                    seriesList = collect(seriesList).unique('id').all();
                    seriesSelect = seriesList.map((val) => {
                        return {value: val.id, label: val.seriesName}
                    });
                }
                seriesItemList = seriesItemList.map(val => {
                    val.createdAt = window.moment(val.createdAt).format('YYYY-MM-DD h:mm A');
                    return val;
                });
                return {...state, series: series, seriesList: seriesList,seriesSelect: seriesSelect, seriesItemList: seriesItemList}

// -----------------------------------------------------------------------------------------------------------------
// ------------------------------------------ Series Item Operation ------------------------------------------------
// -----------------------------------------------------------------------------------------------------------------

            case FETCH_ITEM:
                seriesItemList = action.payload.seriesItemList ?? [];
                seriesItemList = seriesItemList.map(val => {
                    if ('series' in val && 'createdAt' in val.series) {
                        val.series.createdAt = window.moment(val.series.createdAt).format('YYYY-MM-DD h:mm A');
                    }
                    if ('seriesItem' in val && 'createdAt' in val.seriesItem) {
                        val.seriesItem.createdAt = window.moment(val.seriesItem.createdAt).format('YYYY-MM-DD h:mm A');
                    }
                    return val;
                });

                return {
                    ...state,
                    seriesItemList: seriesItemList,
                    paginationResult: action.payload.paginationResult,
                    timestamp: now()
                };
            case PUT_ITEM:
            case POST_ITEM:
                // seriesItemList = state.seriesItemList ?? [];
                // if (
                //     action.payload.seriesItem &&
                //     action.payload.seriesItem.id !== undefined
                // ) {
                //     seriesItemList = [action.payload.seriesItem].concat(seriesItemList);
                // }
                return {
                    ...state,
                    seriesItem: action.payload.seriesItem ?? {}
                }
            case VIEW_ITEM:
                series = action.payload.series ?? {};
                seriesItem = action.payload.seriesItem ?? {};
                if (action.payload.series && action.payload.series.id !== undefined) {
                    if ('createdAt' in series) {
                        series.createdAt = window.moment(series.createdAt).format('YYYY-MM-DD h:mm A');
                    }
                }
                if (action.payload.seriesItem && action.payload.seriesItem.id !== undefined) {
                    if ('createdAt' in seriesItem) {
                        seriesItem.createdAt = window.moment(seriesItem.createdAt).format('YYYY-MM-DD h:mm A');
                    }
                }
                return {
                    ...state, series: series, seriesItem: seriesItem
                };
            default:
                return state;
        }
    } catch (e) {
        // console.log({e});
    }
    return state;
};

export default reducer;