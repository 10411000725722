export const seriesItemDataSchema = (data = {}) => {
    return {
        id: data.id || "",
        // seriesItemName: data.seriesItemName || "",
        seriesId: data.seriesId || "",
        orderPriority: data.orderPriority || 0,
        viewTemplateName: data.viewTemplateName || "",
        appearDay: data.appearDay || 0,
        appearExpire: data.appearExpire || 0,
        appearTime: data.appearTime || "",
        pushMessage: data.pushMessage || "",
        pushTime: data.pushTime || "",
        pushTitle: data.pushTitle || "",
        // view_postHeading: data.view_postHeading || "",
        view_title: data.view_title || "",
        // view_heading: data.view_heading || "",
        view_hashtag: data.view_hashtag || "",
        view_category: data.view_category || "",
        view_mediaUrl: data.view_mediaUrl || "",
        view_practiceUrl: data.view_practiceUrl || "",
        view_radioDataList: data.view_radioDataList || [{text: "", highlight: ""}],
        // view_secondButton_text: (data.view_secondButton && data.view_secondButton.text) || "",
        // view_secondButton_url: (data.view_secondButton && data.view_secondButton.url) || "",
        view_postHint: data.view_postHint || "",
        // view_postHintAndTitle: data.view_postHintAndTitle || "",
        view_innerTitle: data.view_innerTitle || "",
        view_practiceTitle: data.view_practiceTitle || "",
        view_practiceSubtitle: data.view_practiceSubtitle || "",
        view_bottomButton_text: (data.view_bottomButton && data.view_bottomButton.text) || "",
        view_bottomButton_url: (data.view_bottomButton && data.view_bottomButton.url) || "",
        view_title2: data.view_title2 || "",
        view_dialogTitle: data.view_dialogTitle || "",
        view_dialogSubTitle: data.view_dialogSubTitle || "",
        view_dialogHeading: data.view_dialogHeading || "",
        view_webViewUrl: data.view_webViewUrl || "",
        view_title3: data.view_title3 || "",
        view_url1: data.view_url1 || "",
        view_url2: data.view_url2 || "",
        view_next: data.view_next || "",
        view_calltoaction: data.view_calltoaction || ""
    };
}

export const paginationSchema = () => {
    return {
        pagination: {
            pageNumber: 1,
            pageSize: 10,
            totalPages: 1,
            totalRecords: 0
        },
        filter: {},
        sort: {}
    };
}

export const updateUserSchema = (data = {}) => {
    return {
        id: data.id || '',
        emailId: data.emailId || '',
        firstName: data.firstName || '',
        lastName: data.lastName || '',
        groupId: data.groupId || '',
        kickOffDate: data.kickOffDate || '',
        teamId: data.teamId || '',
        secondaryTeams: data.secondaryTeams || [],
        userRole: data.userRole || 'member',
        currentSeriesId: data.currentSeriesId || ''
    }
}

export const updateSeriesSchema = (data = {}, seriesItemData = {}) => {
    return {
        id: data.id || '',
        seriesHeader: data.seriesHeader || '',
        seriesName: data.seriesName || '',
        seriesType: data.seriesType || '',
        seriesCategory: data.seriesCategory || '',
        seriesIcon: data.seriesIcon || '',
        prevSeriesId: data.previousSeries && data.previousSeries.id ? data.previousSeries.id : '',
        view: {
            title: seriesItemData.view?.title || '',
            bgImg: seriesItemData.view?.bgImg || '',
            questionText: seriesItemData.view?.questionText || '',
            videoButtonText: seriesItemData.view?.videoButtonText || '',
            postHint: seriesItemData.view?.postHint || '',
            practiceUrl: seriesItemData.view?.practiceUrl || '',
        },
        pushTitle: seriesItemData.pushTitle || '',
        pushMessage: seriesItemData.pushMessage || '',

    }
}

export const updateHuddleSchema = (data = {}) => {
    return {
        id: data.id || '',
        seriesId: data.series && data.series.id ? data.series.id : '',
        heading: data.heading || "",
        title: data.title || "",
        videoUrl: data.videoUrl || "",
        practiceTitle: data.practiceTitle || "",
        hashtag: data.hashtag || "",
        practiceSubTitle: data.practiceSubTitle || ""
    }
}

export const updateNotificationSchema = (data = {}) => {
    return {
        // id: data.id || '',
        // is_adhoc: notificationType[0],
        is_adhoc: false,
        // simple
        title: data.title || '',
        // adHoc
        message: data.message || '',
        link: data.link || '',
        // audience
        audienceType: data.audienceType || null,
        teams: data.teams || [],
        users: data.users || [],
        team_list: data.team_list || [],
        user_list: data.user_list || [],
    }
}

export const updateAdhocMeItemSchema = (data = {}) => {
    return {
        // id: data.id || '',
        audienceType: data.audienceType || null,
        teams: data.teams || [],
        users: data.users || [],
        message: data.message || '',
        link: data.link || '',
        team_list: data.team_list || [],
        user_list: data.user_list || [],
    }
}

export const appSettingSchema = (data = {IOS: {}, ANDROID: {}}) => {
    return {
        ios_app_build: (data && data.IOS && data.IOS.buildNumber ? data.IOS.buildNumber : ''),
        android_app_build: (data && data.ANDROID && data.ANDROID.buildNumber ? data.ANDROID.buildNumber : ''),
        is_force_update: (data && data.IOS && data.IOS.isMandatory ? data.IOS.isMandatory : false),
        description: (data && data.IOS && data.IOS.description ? data.IOS.description : ''),
    }
}

export const cronSettingSchema = (data = {}) => {
    return {
        key: 'CRON_NOTIFICATION',
        value: data.value || []
    }
}

export const mailTemplateSchema = (data = {}) => {
    return {
        id: data.id || null,
        name: data.name || '',
        subject: data.subject || '',
        message: data.message || ''
    }
}