import React, {useEffect, useMemo, useRef, useState} from "react";
import {
    Badge,
    Button,
    Card,
    CardBody,
    CardHeader,
    Col, Form, FormGroup, Input, Label,
    Row, Tooltip, UncontrolledCollapse
} from "reactstrap";
import DataTable from "react-data-table-component";
import Loader from "../../components/Loader/Loader";
import {connect} from "react-redux"
import {fetchUsers, deleteUser, downloadUsers ,permanentDeleteUser} from "../../redux/user/user.actions";
import {Link, useHistory} from "react-router-dom";
import {
    checkAuth,
    checkAuthWithCallback,
    displayErrorMessage,
    exportCSVFile,
    getBadgeColor,
    getStaticDataValue,
    reactSelectStyle,
    showConfirmDialog,
    stringRestyle,
    uiAvatarName
} from "../../config/helper";
import {fetchTeams} from "../../redux/team/team.actions";
import {fetchGroups} from "../../redux/group/group.actions";
import {statusList, teamRoleList} from "../../config/staticData";
import TableAction from "./TableAction";
import Select from "react-select";
import {paginationSchema} from "../../config/schema";

const Index = (props) => {
    const mounted = useRef(false);
    const history = useHistory();
    const {fetchUsers, fetchTeams, fetchGroups, downloadUsers, deleteUser, permanentDeleteUser} = props;
    const [tableInfo, setTableInfo] = useState(paginationSchema());
    const selectTeamInputRef = useRef();
    const selectGroupInputRef = useRef();
    const selectRoleInputRef = useRef();
    const selectStatusInputRef = useRef();
    const [listLoader, setListLoader] = useState(true);
    const [showFilter, setShowFilter] = useState('close');
    const [timestamp, setTimestamp] = useState('');
    const [exportLoader, setExportLoader] = useState(false);
    const [bulkRemoveLoader, setBulkRemoveLoader] = useState(false);
    const [permanentRemoveLoader, setPermanentRemoveLoader] = useState(false);
    const [groupList, setGroupList] = useState([]);
    const [teamList, setTeamList] = useState([]);
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [selectAllUsers, setSelectAllUsers] = useState(false)
    const [selectedGroups, setSelectedGroups] = useState([]);
    const [bulkRemoveTooltip, setBulkRemoveTooltip] = useState(false);
    const [exportUserTooltip, setExportUserTooltip] = useState(false);
    const [bulkUploadTooltip, setBulkUploadTooltip] = useState(false);
    const [permanentRemoveTooltip, setPermanentRemoveTooltip] = useState(false);
    const [bulkRemoveTitle, setBulkRemoveTitle] = useState('Block User');
    const [permanentRemoveTitle, setPermanentRemoveTitle] = useState('Permanent Delete');

    useEffect(() => {
        document.title = 'User List - ' + process.env.REACT_APP_NAME;
        mounted.current = true;
        checkAuth(history);
        getUserData();
        checkAuthWithCallback(history, fetchTeams);
        checkAuthWithCallback(history, fetchGroups);

        return () => {
            mounted.current = false;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        // if (props.userList === undefined || props.userList.length === 0) {
        //     getUserData();
        // }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.userList]);

    useEffect(() => {
        if (props.timestamp !== timestamp) {
            setListLoader(false);
            setTimestamp(props.timestamp);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.timestamp]);

    useEffect(() => {
        if (mounted.current) {
            if (props.teamList === undefined || props.teamList.length === 0) {
                // checkAuthWithCallback(history, fetchTeams);
            } else {
                let groupID = selectedGroups.map(val => val.value);
                let teams = props.teamList;
                if (groupID.length > 0) {
                    teams = teams.filter(val => groupID.includes(val.groupData.id));
                    selectTeamInputRef.current.clearValue();
                }
                setTeamList(teams.map(val => {
                    return {value: val.id, label: val.teamName}
                }));
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.teamList, selectedGroups]);

    useEffect(() => {
        if (mounted.current) {
            if (props.groupSelect === undefined || props.groupSelect.length === 0) {
                // checkAuthWithCallback(history, fetchGroups);
            }else{
                let groups = props.groupSelect;
                groups.push({label:'Select All',value:''});
                setGroupList(groups);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.groupSelect]);

    useEffect(() => {
        if (props.paginationResult !== undefined) {
            let page = tableInfo;
            page.pagination = {...page.pagination, ...props.paginationResult}
            setTableInfo(page)
            setSelectedUsers([]);
            setSelectAllUsers(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.paginationResult]);

    useEffect(() => {
        let title = "Block User/s";
        let title1 = "Permanent Delete User/s";
        if (tableInfo.filter.groupId && tableInfo.filter.groupId.length > 0) {
            title = "Block Selected Group's Users";
            title1 = "Permanently Delete Selected Group's Users";
        }
        if (tableInfo.filter.teamId && tableInfo.filter.teamId.length > 0) {
            title = "Block Selected Team's Users";
            title1 = "Permanently Delete Selected Team's Users";
        }
        if (selectAllUsers) {
            title = "Block All Listed Users";
            title1 = "Permanently Delete All Listed Users";
        }
        if (selectedUsers.length > 0) {
            title = "Block Selected Users";
            title1 = "Permanently Delete Selected Users";
        }
        setBulkRemoveTitle(title);
        setPermanentRemoveTitle(title1);
    }, [selectedUsers, selectAllUsers, tableInfo.filter.teamId, tableInfo.filter.groupId]);

    const updateBulkRemoveTooltip = () => setBulkRemoveTooltip(!bulkRemoveTooltip);

    const updatePermanentRemoveTooltip = () => setPermanentRemoveTooltip(!permanentRemoveTooltip);

    const updateExportUserTooltip = () => setExportUserTooltip(!exportUserTooltip);

    const updateBulkUploadTooltip = () => setBulkUploadTooltip(!bulkUploadTooltip);

    const userSelected = (event) => {
        if (event.target.checked) {
            setSelectedUsers([...selectedUsers, event.target.value]);
        } else {
            setSelectedUsers(selectedUsers.filter((val) => val !== event.target.value));
        }
    }

    const selectedAllUser = (event) => {
        if (event.target.checked) {
            setSelectAllUsers(true)
        } else {
            setSelectAllUsers(false)
        }
    }

    const exportUsers = () => {
        if (mounted.current) {
            setExportLoader(true);
            let page = tableInfo;
            page.downloadCsv = true;

            downloadUsers(page).then(data => {
                if (data.payload) {
                    exportCSVFile(data.payload, 'users');
                }
                setExportLoader(false);
            });
        }
    }

    const handlePageChange = pageNum => {
        let page = tableInfo;
        page.pagination.pageNumber = pageNum;
        setListLoader(true);
        setTableInfo(page);
        getUserData();
    };

    const handlePerRowsChange = async (newPerPage, currentPage) => {
        let page = tableInfo;
        page.pagination.pageSize = newPerPage;
        page.pagination.pageNumber = currentPage;
        setListLoader(true);
        setTableInfo(page);
        getUserData();
    };

    const getUserData = () => {
        if (mounted.current) {
            setListLoader(true);
            if ('downloadCsv' in tableInfo) {
                delete tableInfo.downloadCsv;
                setTableInfo(tableInfo);
            }
            checkAuthWithCallback(history, fetchUsers, tableInfo);
        }
    }

    const bulkRemoveUser = () => {
        let payload = {
            userIdList: [],
            teamIdList: [],
            groupIdList: []
        };
        if (selectAllUsers) {
            if (props.userList.length > 0) {
                payload.userIdList = props.userList.map(val => val.id);
                if (payload.userIdList.length > 0) {
                    setBulkRemoveLoader(true);
                    showConfirmDialog(
                        payload,
                        bulkRemoveUserCallback,
                        'Are you sure!',
                        'All users on this pages will be soft deleted'
                    );
                } else {
                    displayErrorMessage("Please select users first.")
                }
            } else if ('teamId' in tableInfo.filter && tableInfo.filter.teamId.length > 0) {
                payload.teamIdList = tableInfo.filter.teamId ?? [];
                setBulkRemoveLoader(true);
                showConfirmDialog(
                    payload,
                    bulkRemoveUserCallback,
                    'Are you sure!',
                    'You have selected some teams, If you proceed further all the users from that teams will be soft deleted'
                );
            } else if ('groupId' in tableInfo.filter && tableInfo.filter.groupId.length > 0) {
                payload.groupIdList = tableInfo.filter.groupId ?? [];
                setBulkRemoveLoader(true);
                showConfirmDialog(
                    payload,
                    bulkRemoveUserCallback,
                    'Are you sure!',
                    'You have selected some organizations, If you proceed further all the users from that organizations will be soft deleted'
                );
            }
        } else {
            if (selectedUsers.length > 0) {
                payload.userIdList = selectedUsers;
                setBulkRemoveLoader(true);
                showConfirmDialog(payload, bulkRemoveUserCallback, 'Are you sure!');
            } else {
                displayErrorMessage("Please select users first.")
            }
        }
    }

    const permanentRemoveUser = () => {
        let payload = {
            userIdList: [],
            teamIdList: [],
            groupIdList: []
        };
        if (selectAllUsers) {
            if (props.userList.length > 0) {
                payload.userIdList = props.userList.map(val => val.id);
                if (payload.userIdList.length > 0) {
                    setPermanentRemoveLoader(true);
                    showConfirmDialog(
                        payload,
                        permanentRemoveUserCallback,
                        'Are you sure!',
                        'All users on this pages will be deleted permanently'
                    );
                } else {
                    displayErrorMessage("Please select users first.")
                }
            } else if ('teamId' in tableInfo.filter && tableInfo.filter.teamId.length > 0) {
                payload.teamIdList = tableInfo.filter.teamId ?? [];
                setPermanentRemoveLoader(true);
                showConfirmDialog(
                    payload,
                    permanentRemoveUserCallback,
                    'Are you sure!',
                    'You have selected some teams, If you proceed further all the users from that teams will be deleted permanently'
                );
            } else if ('groupId' in tableInfo.filter && tableInfo.filter.groupId.length > 0) {
                payload.groupIdList = tableInfo.filter.groupId ?? [];
                setPermanentRemoveLoader(true);
                showConfirmDialog(
                    payload,
                    permanentRemoveUserCallback,
                    'Are you sure!',
                    'You have selected some organizations, If you proceed further all the users from that organizations will be deleted permanently'
                );
            }
        } else {
            if (selectedUsers.length > 0) {
                payload.userIdList = selectedUsers;
                setPermanentRemoveLoader(true);
                showConfirmDialog(
                    payload,
                    permanentRemoveUserCallback,
                    'Are you sure!',
                    'Selected users will be deleted permanently');
            } else {
                displayErrorMessage("Please select users first.")
            }
        }
    }

    const permanentRemoveUserCallback = (data, status) => {
        if (status) {
            permanentDeleteUser(data).then((val) => {
                if (!('payload' in val && val.payload === undefined)) {
                    window.location.reload();
                }
                setPermanentRemoveLoader(false);
            });
        } else {
            setPermanentRemoveLoader(false);
        }
    }

    const bulkRemoveUserCallback = (data, status) => {
        if (status) {
            deleteUser(data).then((val) => {
                if (!('payload' in val && val.payload === undefined)) {
                    window.location.reload();
                }
                setBulkRemoveLoader(false);
            });
        } else {
            setBulkRemoveLoader(false);
        }
    }

    const handleSort = async (column, sortDirection) => {
        let page = tableInfo;
        page.sort = {};
        page.sort[column.fieldName] = sortDirection === 'asc' ? 1 : -1;
        setTableInfo(page);
        getUserData();
    };

    const noRefCheck = (refData) => {
        setShowFilter(refData);
    }

    const resetFilter = () => {
        selectTeamInputRef.current.clearValue();
        selectGroupInputRef.current.clearValue();
        selectRoleInputRef.current.clearValue();
        selectStatusInputRef.current.clearValue();
        let filterData = tableInfo;
        filterData.filter = {};
        setTableInfo(filterData);
        getUserData();
    }

    const handleSelectFilter = (key, val) => {
        let filterData = tableInfo;
        if (val && val.value !== '') {
            filterData.filter[key] = val.value;
            setTableInfo(filterData);
            getUserData();
        } else {
            delete filterData.filter[key];
            setTableInfo(filterData);
            getUserData();
        }
    }

    const changeTeam = (val) => {
        let filterData = tableInfo;
        if (val.length > 0) {
            filterData.filter.teamId = val.map(v => v.value);
        } else {
            delete filterData.filter.teamId;
        }
        setTableInfo(filterData);
        getUserData();
    }

    const handleFilter = (refData) => {
        let value = refData.target.value;
        let label = refData.target.name;
        let filterData = tableInfo;
        if (value.length < 3) {
            if (label in filterData.filter) {
                delete filterData.filter[label];
                setTableInfo(filterData);
                getUserData();
            }
        } else {
            filterData.filter[label] = value;
            setTableInfo(filterData);
            getUserData();
        }
    }

    const changeGroup = (val) => {
        val = val.filter(v => v.value);
        setSelectedGroups(val);
        let filterData = tableInfo;
        if (val.length > 0) {
            filterData.filter.groupId = val.map(v => v.value);
        } else {
            delete filterData.filter.groupId;
        }
        setTableInfo(filterData);
        getUserData();
    }

    const subHeaderComponentMemo = useMemo(() => {
        return (
            <>
                <Button size="sm" color="danger" disabled={bulkRemoveLoader} onClick={bulkRemoveUser}
                        id={'bulkRemoveTooltip'}>
                    {bulkRemoveLoader ? <i className="fa fa-spinner fa-spin"/> :
                        <i className="fa fa-trash-alt"/>} {bulkRemoveTitle}
                </Button>
                <Button size="sm" color="danger" disabled={permanentRemoveLoader} onClick={permanentRemoveUser}
                        id={'permanentRemoveTooltip'}>
                    {permanentRemoveLoader ? <i className="fa fa-spinner fa-spin"/> :
                        <i className="fa fa-trash-alt"/>} {permanentRemoveTitle}
                </Button>
                <Button size="sm" color="primary" disabled={exportLoader} onClick={exportUsers}
                        id={"exportUserTooltip"}>
                    {exportLoader ? <i className="fa fa-spinner fa-spin"/> : <i className="fa fa-file-export"/>} Export
                    User
                </Button>
                <Link
                    to={'/admin/user/upload'}
                    className={"btn btn-sm btn-info align-items-center"}
                    id={'bulkUploadTooltip'}

                >
                    <i className="fa fa-upload"/> Bulk Upload
                </Link>

                <Tooltip placement="top" isOpen={bulkRemoveTooltip} autohide={false}
                         target={'bulkRemoveTooltip'} toggle={updateBulkRemoveTooltip}>
                    Bulk Block Users
                </Tooltip>
                <Tooltip placement="top" isOpen={exportUserTooltip} autohide={false}
                         target={'exportUserTooltip'} toggle={updateExportUserTooltip}>
                    Export User
                </Tooltip>
                <Tooltip placement="top" isOpen={bulkUploadTooltip} autohide={false}
                         target={'bulkUploadTooltip'} toggle={updateBulkUploadTooltip}>
                    Bulk Upload
                </Tooltip>
                <Tooltip placement="top" isOpen={permanentRemoveTooltip} autohide={false}
                         target={'permanentRemoveTooltip'} toggle={updatePermanentRemoveTooltip}>
                    Permanent Remove
                </Tooltip>
            </>
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [bulkRemoveLoader, exportLoader, bulkRemoveTooltip, exportUserTooltip, bulkUploadTooltip, bulkRemoveTitle, permanentRemoveTitle]);

    const columns = [
        {
            name: <Input type="checkbox" onChange={selectedAllUser}/>,
            fieldName: '',
            center: true,
            cell: (row) => {
                return (<div className={"d-table-cell"}>
                    <Input type="checkbox" value={row.id} onChange={userSelected}/>
                </div>);
            },
            maxWidth: '5%'
        },
        {
            name: 'Profile',
            fieldName: 'profilePicId',
            center: true,
            cell: (row) => {
                let url = "https://ui-avatars.com/api/?name=" + uiAvatarName([row.firstName ?? '', row.lastName ?? ''].join(' ').trim() || row.emailId);
                return (<div className={"d-table-cell"}>
                    <img
                        alt="Card cap"
                        src={row.profilePicThumbnailId || url}
                        height={'50px'}
                    />
                </div>);
            },
            style: () => ({minHeight: '80px', maxWidth: '50px'}),
            maxWidth: '8%'
        },
        {
            name: 'Email',
            selector: row => row.emailId,
            fieldName: 'emailId',
            maxWidth: '15%'
        },
        {
            name: 'Name',
            selector: row => {
                if (row.firstName && row.firstName.trim() !== '' && row.lastName && row.lastName.trim() !== '') {
                    return [row.firstName.trim() ?? '', row.lastName.trim() ?? ''].join(' ')
                } else {
                    return 'N/A';
                }
            },
            fieldName: 'firstName',
            maxWidth: '10%'

        },
        {
            name: 'Organization',
            selector: row => row.team && row.team.groupData && row.team.groupData.groupName ? row.team.groupData.groupName : 'N/A',
            fieldName: 'groupName',
            sortable: true,
            maxWidth: '10%'
        },
        {
            name: 'Team',
            selector: row => row.team && row.team.teamName ? row.team.teamName : 'N/A',
            fieldName: 'teamName',
            sortable: true,
            maxWidth: '10%'
        },
        {
            name: 'Role',
            selector: row => stringRestyle(row.userRole, 'firstUpperCase'),
            fieldName: 'userRole',
            maxWidth: '8%'
        },
        {
            name: 'Current On',
            selector: row => row.currentSeries?.seriesName || 'N/A',
            fieldName: 'currentSeries.seriesName',
            maxWidth: '8%'
        },
        {
            name: 'Individual Kickoff Date',
            selector: row => row.kickOffDate || 'N/A',
            fieldName: 'kickOffDate',
            maxWidth: '8%'
        },
        {
            name: 'Status',
            cell: row => {
                return (
                    <Badge color={getBadgeColor(row.userStatus)}>{getStaticDataValue(row.userStatus, 'status')}</Badge>
                );
            },
            fieldName: 'userStatus',
            center: true,
            maxWidth: '8%'
        },
        {
            name: 'Action',
            center: true,
            cell: (row) => {
                return (
                    <TableAction row={row} onDataUpdated={getUserData}/>
                )
            },
            maxWidth: '16%'

        },
    ];

    return (
        <>
            {/* Page content */}
            <Row>
                <Col lg="12" md="12">
                    <Card className="shadow border-0">
                        <CardHeader>
                            <Row>
                                <Col lg="12" md="12">
                                    <span className={"mb-0 h3"}>User list</span>

                                    <div className={"d-flex float-left float-md-right mt-2 mt-md-0"}>
                                        <Link
                                            to='/admin/user/create'
                                            className={"d-flex float-right btn btn-sm btn-primary align-items-center"}
                                        >
                                            <i className="ni ni-fat-add mr-2"/>
                                            Add User
                                        </Link>

                                        <Button
                                            color="primary"
                                            id="userFilters"
                                            className={"d-flex float-right btn btn-sm btn-primary align-items-center"}
                                        >
                                            {showFilter === 'open' ?
                                                <><i className="fa fa-eye-slash mr-2"/> Hide</> :
                                                <><i className="fa fa-eye mr-2"/> Show</>
                                            } Filters
                                        </Button>
                                    </div>
                                </Col>
                            </Row>
                        </CardHeader>
                        <CardBody>
                            <Row>
                                <Col sm="12">
                                    <UncontrolledCollapse toggler="#userFilters" onEntered={() => noRefCheck('open')}
                                                          onExited={() => noRefCheck('close')}>
                                        <Form>
                                            <Card className="mb-4">
                                                <CardHeader className="py-2">
                                                    <span className="h4">Advance Filters</span>
                                                    <Button color="danger" type="reset" size="sm"
                                                            className="d-flex float-right align-items-center"
                                                            onClick={resetFilter}><i className="fa fa-undo mr-2"/> Reset</Button>
                                                </CardHeader>
                                                <CardBody className="py-2">
                                                    <Row>
                                                        <Col lg="3" md="6">
                                                            <FormGroup>
                                                                <Label for="emailId">
                                                                    Email
                                                                </Label>
                                                                <Input
                                                                    id="emailId"
                                                                    name="emailId"
                                                                    placeholder="Search Email"
                                                                    type="text"
                                                                    onChange={handleFilter}
                                                                />
                                                            </FormGroup>
                                                        </Col>
                                                        <Col lg="3" md="6">
                                                            <FormGroup>
                                                                <Label for="fullName">
                                                                    Name
                                                                </Label>
                                                                <Input
                                                                    id="fullName"
                                                                    name="fullName"
                                                                    placeholder="Search Name"
                                                                    type="text"
                                                                    onChange={handleFilter}
                                                                />
                                                            </FormGroup>
                                                        </Col>
                                                        <Col lg="3" md="6">
                                                            <FormGroup>
                                                                <Label for="userRole">
                                                                    Role
                                                                </Label>
                                                                <Select
                                                                    id={"userRole"}
                                                                    ref={selectRoleInputRef}
                                                                    className="basic-single"
                                                                    classNamePrefix="select"
                                                                    isSearchable={true}
                                                                    options={teamRoleList}
                                                                    name="userRole"
                                                                    isClearable
                                                                    onChange={(val) => handleSelectFilter('userRole', val ?? {})}
                                                                    styles={reactSelectStyle}
                                                                />
                                                            </FormGroup>
                                                        </Col>
                                                        <Col lg="3" md="6">
                                                            <FormGroup>
                                                                <Label for="userStatus">
                                                                    Status
                                                                </Label>
                                                                <Select
                                                                    id={"userStatus"}
                                                                    ref={selectStatusInputRef}
                                                                    className="basic-single"
                                                                    classNamePrefix="select"
                                                                    isSearchable={true}
                                                                    options={statusList}
                                                                    name="userStatus"
                                                                    isClearable
                                                                    onChange={(val) => handleSelectFilter('userStatus', val ?? {})}
                                                                    styles={reactSelectStyle}
                                                                />
                                                            </FormGroup>
                                                        </Col>
                                                        <Col md="6">
                                                            <FormGroup>
                                                                <Label for="group">
                                                                    Organization
                                                                </Label>
                                                                <Select
                                                                    id={"group"}
                                                                    ref={selectGroupInputRef}
                                                                    className="basic-multi-select"
                                                                    classNamePrefix="select"
                                                                    isSearchable={true}
                                                                    isMulti={true}
                                                                    options={groupList}
                                                                    name="groupId"
                                                                    isClearable
                                                                    onChange={changeGroup}
                                                                    styles={reactSelectStyle}
                                                                />
                                                            </FormGroup>
                                                        </Col>
                                                        <Col md="6">
                                                            <FormGroup>
                                                                <Label for="team">
                                                                    Team
                                                                </Label>
                                                                <Select
                                                                    id={"team"}
                                                                    ref={selectTeamInputRef}
                                                                    className="basic-multi-select"
                                                                    classNamePrefix="select"
                                                                    isSearchable={true}
                                                                    isMulti={true}
                                                                    options={teamList}
                                                                    name="teamId"
                                                                    isClearable
                                                                    onChange={changeTeam}
                                                                    styles={reactSelectStyle}
                                                                />
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                </CardBody>
                                            </Card>
                                        </Form>
                                    </UncontrolledCollapse>
                                </Col>
                                <Col sm="12">
                                    <DataTable
                                        subHeader
                                        subHeaderComponent={subHeaderComponentMemo}
                                        columns={columns}
                                        progressPending={listLoader}
                                        progressComponent={<Loader/>}
                                        className={"react-dataTable"}
                                        data={props.userList || []}
                                        pagination
                                        responsive
                                        paginationServer
                                        paginationTotalRows={tableInfo.pagination.totalRecords}
                                        onChangeRowsPerPage={handlePerRowsChange}
                                        onChangePage={handlePageChange}
                                        onSort={handleSort}
                                        sortServer
                                        subHeaderWrap
                                    />
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </>
    )
};

const mapStateToProps = (state) => {
    return {
        userList: state.user.userList,
        paginationResult: state.user.paginationResult,
        teamList: state.team.teamList,
        groupSelect: state.group.groupSelect,
        timestamp: state.user.timestamp ?? ''
    }
}

const mapDispatchToProps = {
    fetchUsers,
    deleteUser,
    fetchTeams,
    fetchGroups,
    downloadUsers,
    permanentDeleteUser
}

export default connect(mapStateToProps, mapDispatchToProps)(Index);
