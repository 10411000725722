/*!

=========================================================
* Argon Dashboard React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import Dashboard from "./views/admin-dashboard/Index";
import UserIndex from "./views/user/Index";
import TeamIndex from "./views/team/Index";
import GroupIndex from "./views/group/Index";
import SeriesIndex from "./views/series/Index";
import SeriesItemIndex from "./views/series-items/Index";
import AdvisorIndex from "./views/advisor/Index";
import HuddleIndex from "./views/huddle/Index";
import AdhocNotificationIndex from "./views/notification/AdhocIndex";
import SettingIndex from "./views/setting/Index";
import LeaderHubIndex from "./views/leader-hub/Index";
import MailTemplate from "./views/mail-template/Index";

var routes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: "ni ni-tv-2 text-primary",
    component: Dashboard,
    layout: "/admin",
    order:0
  },
  {
    path: "/organization",
    name: "Organizations",
    icon: "ni ni-building text-primary",
    component: GroupIndex,
    layout: "/admin",
    order: 1
  },
  {
    path: "/team",
    name: "Teams",
    icon: "fa fa-users text-primary",
    component: TeamIndex,
    layout: "/admin",
    order: 2
  },
  {
    path: "/user",
    name: "Users",
    icon: "fa fa-user text-primary",
    component: UserIndex,
    layout: "/admin",
    order: 3
  },
  {
    path: "/content",
    name: "Content",
    icon: "fa fa-list-ul text-primary",
    component: SeriesItemIndex,
    layout: "/admin",
    order: 5
  },
  {
    path: "/practice",
    name: "Practice",
    icon: "fa fa-th-list text-primary",
    component: SeriesIndex,
    layout: "/admin",
    order: 4
  },
  {
    path: "/leader-hub",
    name: "Leader Hub",
    icon: "fa fa-file-video text-primary",
    component: LeaderHubIndex,
    layout: "/admin",
    order: 6
  },
  {
    path: "/team-hub",
    name: "Team Hub",
    icon: "fa fa-file-video text-primary",
    component: HuddleIndex,
    layout: "/admin",
    order: 7
  },
  // {
  //   path: "/notification",
  //   name: "Notification",
  //   icon: "fa fa-bell text-primary",
  //   component: NotificationIndex,
  //   layout: "/admin",
  //   order: 7
  // },
  {
    path: "/important-info",
    name: "Important Info",
    icon: "fa fa-bell text-primary",
    component: AdhocNotificationIndex,
    layout: "/admin",
    order: 8
  },
  {
    path: "/mail",
    name: "Mail Template",
    icon: "fa fa-envelope text-primary",
    component: MailTemplate,
    layout: "/admin",
    order: 9
  },
  {
    path: "/coach",
    name: "Coach",
    icon: "ni ni-circle-08 text-primary",
    component: AdvisorIndex,
    layout: "/admin",
    order: 10
  },
  {
    path: "/setting",
    name: "Settings",
    icon: "fa fa-cog text-primary",
    component: SettingIndex,
    layout: "/admin",
    order: 11
  }
];
export default routes;