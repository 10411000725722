import {FETCH, FETCH_POST} from './weScreen.types';
import {now} from "moment";

const INITIAL_STATE = {
  weScreenList: [],
  timestamp: '',
  postComments:[],
  commentsTimestamp: '',
  post: {}
};

const reducer = (state = INITIAL_STATE, action) => {
  let weScreens = [];
  let comments = [];
  try {
    switch (action.type) {
      case FETCH:
        weScreens = action.payload.weFeedList ?? [];
        return {
          ...state,
          weScreenList: weScreens,
          paginationResult: action.payload.paginationResult,
          timestamp: now(),
        };
      case FETCH_POST:
        comments = action.payload.post?.comments ?? [];
        return {
          ...state,
          post: action.payload.post ?? {},
          postComments: comments,
          commentsTimestamp: now()
        };
      default:
        return state;
    }
  } catch (e) {
    // console.log({e});
  }
  return state;
};

export default reducer;