import {displayErrorMessage, displayMessage, postRequest} from "../../config/helper";
import {ADHOC_FETCH, ADHOC_POST, FETCH, POST} from "./notification.types";
import {
    ADHOC_NOTIFICATION_CREATE_API,
    ADHOC_NOTIFICATION_LIST_API,
    NOTIFICATION_CREATE_API,
    NOTIFICATION_LIST_API
} from "../../config/URLConstant";

export const fetchNotifications = (postData) => {
    return function (dispatch) {
        return postRequest(NOTIFICATION_LIST_API, postData)
            .then(
                response => response.data,
                error => {
                    if (error && error.response !== undefined) {
                        displayErrorMessage(error.response.data.message || "Internal server error")
                    } else {
                        displayErrorMessage("Internal server error")
                    }
                }
            )
            .then((data) => {
                    return dispatch({
                        type: FETCH,
                        payload: data
                    })
                },
            );
    };
};

export const createNotification = (postData) => {
    return function (dispatch) {
        return postRequest(NOTIFICATION_CREATE_API, postData)
            .then(
                response => {
                    displayMessage('Important Info Sent.');
                    return response.data;
                },
                error => {
                    if (error && error.response !== undefined) {
                        displayErrorMessage(error.response.data.message || "Internal server error")
                    } else {
                        displayErrorMessage("Internal server error")
                    }
                }
            ).then((data) => {
                    return dispatch({
                        type: POST,
                        payload: data
                    })
                }
            )
    }
};

export const fetchAdhocNotifications = (postData) => {
    return function (dispatch) {
        return postRequest(ADHOC_NOTIFICATION_LIST_API, postData)
            .then(
                response => response.data,
                error => {
                    if (error && error.response !== undefined) {
                        displayErrorMessage(error.response.data.message || "Internal server error")
                    } else {
                        displayErrorMessage("Internal server error")
                    }
                }
            )
            .then((data) => {
                    return dispatch({
                        type: ADHOC_FETCH,
                        payload: data
                    })
                },
            );
    };
};

export const createAdhocNotification = (postData) => {
    return function (dispatch) {
        return postRequest(ADHOC_NOTIFICATION_CREATE_API, postData)
            .then(
                response => {
                    displayMessage('Adhoc Important Info Sent.');
                    return response.data;
                },
                error => {
                    if (error && error.response !== undefined) {
                        displayErrorMessage(error.response.data.message || "Internal server error")
                    } else {
                        displayErrorMessage("Internal server error")
                    }
                }
            ).then((data) => {
                    return dispatch({
                        type: ADHOC_POST,
                        payload: data
                    })
                }
            )
    }
};