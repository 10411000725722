import React, {useEffect, useRef} from "react";
import {connect} from "react-redux"
import {Col, Row} from "reactstrap";
import TeamSection from "./TeamSection";
import {checkAuth, checkRole} from "../../config/helper";
import {useHistory} from "react-router-dom";

const Index = () => {
    const mounted = useRef(false);
    const history = useHistory();

    useEffect(() => {
        mounted.current = true;
        checkAuth(history);
        checkRole('advisor',history);
        return () => {
            mounted.current = false;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <Row>
                <Col sm="12">
                    <TeamSection />
                </Col>
            </Row>
        </>
    )
};

const mapStateToProps = () => {
    return {
    }
}

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(Index);
