import {FETCH, DOWNLOAD} from './member.types';
import {
    displayErrorMessage, postRequest,
} from "../../config/helper";
import {
    MEMBER_LIST_API
} from "../../config/URLConstant";

export const fetchMembers = (postData) => {
    return function (dispatch) {
        return postRequest(MEMBER_LIST_API, postData)
            .then(
                response => response.data,
                error => {
                    if (error && error.response !== undefined) {
                        displayErrorMessage(error.response.data.message || "Internal server error")
                    } else {
                        displayErrorMessage("Internal server error")
                    }
                }
            )
            .then((data) => {
                    return dispatch({
                        type: FETCH,
                        payload: data
                    })
                },
            );
    };
};

export const downloadMembers = (postData) => {
    return function (dispatch) {
        return postRequest(MEMBER_LIST_API, postData)
            .then(
                response => response,
                error => {
                    if (error && error.response !== undefined) {
                        displayErrorMessage(error.response.data.message || "Internal server error")
                    } else {
                        displayErrorMessage("Internal server error")
                    }
                }
            )
            .then((data) => {
                    return dispatch({
                        type: DOWNLOAD,
                        payload: data
                    })
                },
            );
    };
};