import React, {useEffect, useRef, useState} from 'react'
import {connect} from "react-redux"
import {Field, reduxForm} from 'redux-form'
import {Button, Col, Row} from "reactstrap";
import {renderRadioField, renderTextField} from "../../../config/formElement";
import Loader from "../../../components/Loader/Loader";
import {checkAuth} from "../../../config/helper";
import {useHistory} from "react-router-dom";
import {appSettingSchema} from "../../../config/schema";
import {fetchAppSetting} from "../../../redux/app-setting/appSetting.actions";

const validate = (values) => {
    let errors = {};
    if (!values.ios_app_build) {
        errors.ios_app_build = 'IOS app build is required.';
    }
    if (!values.android_app_build) {
        errors.android_app_build = 'Android app build is required.';
    }
    return errors;
}

const warn = () => {
    return {};
}

let AppSettingFormCreate = (props) => {
    const {handleSubmit, fetchAppSetting} = props;
    const mounted = useRef(false);
    const [isSubmitting, setIsSubmitting] = useState({text: 'Save', icon: ''});
    const [isLoading, setIsLoading] = useState(true);
    const history = useHistory();

    useEffect(() => {
        checkAuth(history);
        mounted.current = true;
        fetchAppSetting({key: "APP_UPDATE_DATA"});

        return () => {
            mounted.current = false;
            setIsSubmitting({text: 'Save', icon: ''});
        };
    }, []);

    useEffect(() => {
        if (props.submitBtnText !== isSubmitting.text) {
            setIsSubmitting({text: props.submitBtnText, icon: props.submitBtnIcon});
        } else {
            if (isLoading) {
                setIsSubmitting({text: 'Wait', icon: ''});
            } else {
                setIsSubmitting({text: 'Save', icon: ''});
            }
        }
    }, [props.submitBtnText, props.submitBtnIcon, isLoading]);

    useEffect(() => {
        setIsLoading(false);
    }, [props.appSetting]);

    return (
        <>
            {
                isLoading ?
                    <div className="text-center"><Loader/></div>
                    :
                    <form onSubmit={(values) => handleSubmit(values)}>
                        <Row>
                            <Col lg="6">
                                <Field
                                    name="ios_app_build"
                                    type="text"
                                    label={"IOS Build Number"}
                                    component={renderTextField}
                                    isRequired={true}
                                />
                            </Col>
                            <Col lg="6">
                                <Field
                                    name="android_app_build"
                                    type="text"
                                    label={"Android Build Number"}
                                    component={renderTextField}
                                    isRequired={true}
                                />
                            </Col>
                            <Col lg="12">
                                <Field
                                    name="is_force_update"
                                    type="checkbox"
                                    label={"Is Force Update"}
                                    component={renderRadioField}
                                    hasClassName={"ml-4"}
                                />
                            </Col>
                            <Col lg="12">
                                <Field
                                    name="description"
                                    type="textarea"
                                    label={"Description"}
                                    component={renderTextField}
                                />
                            </Col>
                            <Col lg="12">
                                <Button color="primary" type="submit" disabled={isSubmitting.text !== 'Save'}>
                                    {isSubmitting.icon !== '' ?
                                        <i className={isSubmitting.icon}/> : ''} {isSubmitting.text}
                                </Button>
                            </Col>
                        </Row>
                    </form>
            }
        </>)
}

const mapStateToProps = (state) => {
    return {
        appSetting: state.appSetting.appSetting && state.appSetting.appSetting.value ? appSettingSchema(state.appSetting.appSetting.value) : appSettingSchema(),
        initialValues: state.appSetting.appSetting && state.appSetting.appSetting.value ? appSettingSchema(state.appSetting.appSetting.value) : appSettingSchema()
    };
}

const mapDispatchToProps = {
    fetchAppSetting
}

AppSettingFormCreate = reduxForm({
    form: 'appAppSettingFormCreate',
    enableReinitialize: true,
    validate,
    warn
})(AppSettingFormCreate)

export default connect(mapStateToProps, mapDispatchToProps)(AppSettingFormCreate)