import {connect} from "react-redux";
import React, {useEffect, useRef, useState} from "react";
import {useHistory} from "react-router-dom";
import {checkAuth} from "../../config/helper";
import {Card, CardBody, CardHeader, Col, Nav, NavItem, NavLink, Row} from "reactstrap";
import App from "./App";
import Notification from "./Notification";

const Index = () => {
    const mounted = useRef(false);
    const history = useHistory();
    const [selectedTabId, setSelectedTabId] = useState('');
    const [tabContent, sebTabContent] = useState('');

    useEffect(() => {
        mounted.current = true;
        checkAuth(history);
        setSelectedTabId('app-setting');
        return () => {
            mounted.current = false;
        };
    }, []);

    useEffect(() => {
        let content = "";
        switch (selectedTabId) {
            case 'app-setting':
                content = <App />;
                break;
            case "notification-setting":
                content = <Notification />;
                break;
        }
        sebTabContent(content);
    },[selectedTabId]);

    return (
        <>
            <Row className={"justify-content-center"}>
                <Col xl="8">
                    <Card className="shadow border-0">
                        <CardHeader>
                            <Row>
                                <Col lg="12">
                                    <h3 className={"mb-0"}>Settings</h3>
                                </Col>
                            </Row>
                        </CardHeader>
                        <CardBody>
                            <Row>
                                <Col lg={12}>
                                    <Nav
                                        card
                                        fill
                                        justified
                                        pills
                                        tabs
                                    >
                                        <NavItem>
                                            <NavLink
                                                className={"cursor-pointer "+ (selectedTabId === "app-setting" ? 'active' : '')}
                                                onClick={() => setSelectedTabId("app-setting")}>
                                                App
                                            </NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink
                                                className={"cursor-pointer "+ (selectedTabId === "notification-setting" ? 'active' : '')}
                                                onClick={() => setSelectedTabId("notification-setting")}>
                                                Notification
                                            </NavLink>
                                        </NavItem>
                                    </Nav>
                                </Col>
                                <Col lg={12} className={"mt-5"}>
                                    {tabContent}
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </>
    )
}

const mapStateToProps = (state) => {
    return state;
}

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(Index);