import {connect} from "react-redux";
import {Card, CardBody, CardHeader, Col, Row} from "reactstrap";
import React, {useEffect, useRef, useState} from "react";
import {Link, useHistory, useParams} from "react-router-dom";
import {checkAuth} from "../../config/helper";
import {createAdhocNotification, createNotification} from "../../redux/notification/notification.actions";
import NotificationCombineCreateForm from "./form/CreateNotification";

const CreateNotification = (props) => {
    const {createAdhocNotification,createNotification} = props;
    const history = useHistory();
    const mounted = useRef(false);
    const [submitBtnText, setSubmitBtnText] = useState('Save');
    const [submitBtnIcon, setSubmitBtnIcon] = useState('');
    const {id} = useParams();

    useEffect(() => {
        mounted.current = true;
        checkAuth(history);
        return () => {
            mounted.current = false;
            handleSubmitBtn(false);
        };
    }, []);

    const handleSubmitBtn = (val) => {
        if (val) {
            setSubmitBtnText('Saving');
            setSubmitBtnIcon('fa fa-spinner fa-spin');
        } else {
            setSubmitBtnText('Save');
            setSubmitBtnIcon('');
        }
    }

    const handleSubmit = (values) => {
        if (mounted.current) {
            let postData = values;
            if ('audienceType' in postData) {
                postData.audienceType = postData.audienceType.value || '';
            }
            if ('user_list' in postData) {
                postData.user_list.map((val) => {
                    postData.users.push(val.id);
                    return val;
                });
                delete postData.user_list;
            }
            if ('team_list' in postData) {
                postData.team_list.map((val) => {
                    postData.teams.push(val.value);
                    return val;
                });
                delete postData.team_list;
            }
            handleSubmitBtn(true);
            if(postData.is_adhoc && postData.is_adhoc.value) {
                createAdhocNotification(postData).then((data) => {
                    handleSubmitBtn(false);
                    if (!('payload' in data && data.payload === undefined)) {
                        history.push('/admin/important-info');
                    }
                });
            }else{
                createNotification(postData).then((data) => {
                    handleSubmitBtn(false);
                    if (!('payload' in data && data.payload === undefined)) {
                        history.push('/admin/important-info');
                    }
                });
            }
        }
    }

    return (
        <>
            <Row className={"justify-content-center"}>
                <Col xl="8">
                    <Card className="shadow border-0">
                        <CardHeader>
                            <Row>
                                <Col lg="12">
                                    <span
                                        className={"mb-0 h3"}>{id === undefined ? "Create" : "Update"} Important Info</span>
                                    <Link
                                        to='/admin/important-info'
                                        className={"d-flex float-right btn btn-sm btn-primary align-items-center"}
                                    >
                                        <i className="fa fa-arrow-left mr-2"/>
                                        Back
                                    </Link>
                                </Col>
                            </Row>
                        </CardHeader>
                        <CardBody>
                            <NotificationCombineCreateForm id={id} onSubmit={handleSubmit} submitBtnText={submitBtnText}
                                                   submitBtnIcon={submitBtnIcon}/>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </>
    )
}

const mapStateToProps = () => {
    return {};
}

const mapDispatchToProps = {
    createAdhocNotification,
    createNotification
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateNotification);