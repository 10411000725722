import React, {useEffect, useMemo, useRef, useState} from "react";
import {
    Badge,
    Card,
    CardBody,
    CardHeader,
    Col,
    Row
} from "reactstrap";
import DataTable from "react-data-table-component";
import Loader from "../../../components/Loader/Loader";
import {connect} from "react-redux";
import Filter from "../../../components/filters/Filter";
import {fetchTeamMembers} from "../../../redux/user/user.actions";
import {
    checkAuth,
    checkAuthWithCallback,
    getBadgeColor,
    getStaticDataValue,
    uiAvatarName
} from "../../../config/helper";
import {Link, useHistory} from "react-router-dom";

const MyTeams = (props) => {
    const {fetchTeamMembers} = props;
    const mounted = useRef(false);
    const [listLoader,setListLoader] = useState(true);
    const [filterText, setFilterText] = useState('');
    const [filteredItems,setFilteredItems] = useState([]);
    const history = useHistory();

    const subHeaderComponentMemo = useMemo(() => {
        return (
            <Filter onFilter={val => {
                setFilterText(val)
            }} filterText={filterText} />
        );
    }, [filterText]);

    const columns = [
        {
            name: 'Profile',
            fieldName: 'profilePicId',
            cell: (row) => {
                let url = "https://ui-avatars.com/api/?name=" + uiAvatarName([row.firstName ?? '',row.lastName ?? ''].join(' ').trim() || row.emailId);
                return (<div className={"d-table-cell"}><img
                    alt="Card cap"
                    src={row.profilePicThumbnailId || url}
                    height={'50px'}
                /></div>);
            },
            style: () => ({minHeight:'80px'})
        },
        {
            name: 'Name',
            selector: row => {
                if(row.firstName && row.firstName.trim() !== '' && row.lastName && row.lastName.trim() !== '') {
                    return [row.firstName.trim() ?? '', row.lastName.trim() ?? ''].join(' ')
                }else{
                    return 'N/A';
                }
            },
            fieldName: 'firstName'
        },
        {
            name: 'Email',
            selector: row => row.emailId,
            fieldName: 'emailId'
        },
        {
            name: 'Status',
            cell: row => {
                return (
                    <Badge color={getBadgeColor(row.userStatus)}>{getStaticDataValue(row.userStatus,'status')}</Badge>
                ) ;
            },
            fieldName: 'userStatus'
        }
    ];

    useEffect(() => {
        checkAuth(history);
        mounted.current = true;
        getTeam();
        return () => {
            mounted.current = false;
            setFilteredItems([]);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if(props.memberList.length > 0) {
            if(props.memberList[0].teamId !== props.userData.teamId){
                getTeam();
            }
        }
        let filteredData = props.memberList.filter(
            item => item.firstName && item.firstName.toLowerCase().includes(filterText.toLowerCase())
        );
        setFilteredItems(filteredData);
        setListLoader(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.userData,filterText]);

    const getTeam = () => {
        if(mounted.current) {
            setListLoader(true);
            setFilteredItems([]);
            if (props.userData !== undefined && props.userData.id !== undefined && props.userData.id !== '') {
                checkAuthWithCallback(history,fetchTeamMembers,props.userData.id);
            }
        }
    }

    return (
        <>
            {/* Page content */}
            <Row>
                <Col lg="12" md="12">
                    <Card className="shadow border-0">
                        <CardHeader>
                            <Row>
                                <Col lg="12" md="12">
                                    <span className={"mb-0 h3"}>My Team</span>
                                    <Link
                                        to={props.viewerRole ==='advisor' ? '/coach/dashboard' :'/admin/user'}
                                        className={"d-flex float-right btn btn-sm btn-primary align-items-center"}
                                    >
                                        <i className="fa fa-arrow-left mr-2"/>
                                        Back
                                    </Link>
                                </Col>
                            </Row>
                        </CardHeader>
                        <CardBody>
                            <Row>
                                <Col lg="12">
                                    <DataTable
                                        columns={columns}
                                        progressPending={listLoader}
                                        progressComponent={<Loader/>}
                                        className={"react-dataTable"}
                                        data={filteredItems}
                                        pagination
                                        responsive
                                        subHeaderWrap
                                        subHeader
                                        subHeaderComponent={subHeaderComponentMemo}
                                    />
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </>
    )
};

const mapStateToProps = (state) => {
    // console.log({state});
    return {
        memberList: state.user.memberList ?? [],
    }
}

const mapDispatchToProps = {
    fetchTeamMembers
}

export default connect(mapStateToProps, mapDispatchToProps)(MyTeams);