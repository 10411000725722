import {FETCH, POST, ADHOC_FETCH, ADHOC_POST} from './notification.types';
import {now} from "moment";
import {paginationSchema} from "../../config/schema";

const INITIAL_STATE = {
    notificationList: [],
    notification: {},
    adhocNotificationList: [],
    adhocNotification: {},
    paginationResult: paginationSchema(),
    adhocPaginationResult: paginationSchema(),
    timestamp: ''
};

const reducer = (state = INITIAL_STATE, action) => {
    let notification = {};
    let notifications = [];
    let adhocNotification = {};
    let adhocNotifications = [];
    try {
        switch (action.type) {
            case FETCH:
                notifications = (action.payload.notificationList || []).map((value) => {
                    if (value['createdAt'] !== undefined && value['createdAt'].length > 0) {
                        value['createdAt'] = window.moment(value['createdAt']).format('YYYY-MM-DD h:mm A');
                    }
                    return value;
                });
                return {
                    ...state,
                    notificationList: notifications,
                    paginationResult: action.payload.paginationResult,
                    timestamp: now()
                };
            case POST:
                notifications = state.notificationList;
                notification = action.payload && action.payload.notification ? action.payload.notification : (state.notification ?? {});
                if ('createdAt' in notification) {
                    notification.createdAt = window.moment(notification.createdAt).format('YYYY-MM-DD h:mm A');
                }
                if (action.payload && action.payload.notification) {
                    let index = notifications.findIndex(val => val.id === notification.id);
                    if (index >= 0) {
                        notifications[index] = notification;
                    } else {
                        notifications.pop();
                        notifications.reverse().push(notification)
                        notifications.reverse();
                    }
                }
                return {
                    ...state,
                    notification: notification,
                    notificationList: notifications,
                    timestamp: now()
                }
            case ADHOC_FETCH:
                adhocNotification = (action.payload.notificationList || []).map((value) => {
                    if (value['createdAt'] !== undefined && value['createdAt'].length > 0) {
                        value['createdAt'] = window.moment(value['createdAt']).format('YYYY-MM-DD h:mm A');
                    }
                    return value;
                });
                return {
                    ...state,
                    adhocNotificationList: adhocNotification,
                    adhocPaginationResult: action.payload.paginationResult,
                    timestamp: now()
                };
            case ADHOC_POST:
                adhocNotifications = state.adhocNotificationList;
                adhocNotification = action.payload && action.payload.notification ? action.payload.notification : (state.adhocNotification ?? {});
                if ('createdAt' in adhocNotification) {
                    adhocNotification.createdAt = window.moment(adhocNotification.createdAt).format('YYYY-MM-DD h:mm A');
                }
                if (action.payload && action.payload.notification) {
                    let index = adhocNotifications.findIndex(val => val.id === adhocNotification.id);
                    if (index >= 0) {
                        adhocNotifications[index] = adhocNotification;
                    } else {
                        adhocNotifications.pop();
                        adhocNotifications.reverse().push(adhocNotification)
                        adhocNotifications.reverse();
                    }
                }
                return {
                    ...state,
                    adhocNotification: adhocNotification,
                    adhocNotificationList: adhocNotifications,
                    timestamp: now()
                }
            default:
                return {...state, timestamp: now()};
        }
    } catch (e) {
        // console.log({e});
    }
    return state;
};

export default reducer;