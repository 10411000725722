import React, {useEffect, useRef, useState} from 'react'
import {connect} from "react-redux"
import {Field, reduxForm} from 'redux-form'
import {Button, Col, Row} from "reactstrap";
import {
    renderSelectField,
    renderSelectSearchableField,
    renderTextField
} from "../../../config/formElement";
import {permissionList} from "../../../config/staticData";
import {fetchGroup, fetchGroups} from "../../../redux/group/group.actions";
import Loader from "../../../components/Loader/Loader";
import {checkAuth, checkAuthWithCallback} from "../../../config/helper";
import {useHistory} from "react-router-dom";
import {updateUserSchema} from "../../../config/schema";
import {now} from "moment";

const validate = (values, props) => {
    let errors = {};
    if (props.id === undefined) {
        const mailFormat = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,6})+$/;
        if (!values.emailId) {
            errors.emailId = 'Email is required.';
        } else if (!values.emailId.match(mailFormat)) {
            errors.emailId = 'Invalid email address.';
        }
    }
    if (props.id !== undefined) {
        if (!values.firstName) {
            errors.firstName = 'First name is required.';
        }
        if (!values.lastName) {
            errors.lastName = 'Last name is required.';
        }
    }
    if (!values.groupId) {
        errors.groupId = 'Organization is required.';
    }
    if (!values.teamId) {
        errors.teamId = 'Team is required.';
    }
    if (!values.userRole) {
        errors.userRole = 'Permission is required.';
    }
    if (props.id !== undefined) {
        if (!values.currentSeriesId) {
            errors.currentSeriesId = 'Current practice is required.';
        }
    }

    return errors;
}

const warn = () => {
    return {};
}

let UserCreateForm = (props) => {
    const {handleSubmit, fetchGroups, fetchGroup} = props;
    const mounted = useRef(false);
    const history = useHistory();
    const selectTeamInputRef = useRef();
    const [id, setId] = useState('');
    const [fields, setFields] = useState(updateUserSchema({}));
    const [teamList, setTeamList] = useState([{value: '', label: 'Select Team'}]);
    const [secondaryTeam, setSecondaryTeam] = useState(null);
    const [groupList, setGroupList] = useState([{value: '', label: 'Select Organization'}]);
    const [isSubmitting, setIsSubmitting] = useState({text: "Save", icon: ""});
    const [isLoading, setIsLoading] = useState(true);
    const [seriesList, setSeriesList] = useState([]);
    const [updateSecondaryTeams, setUpdateSecondaryTeams] = useState(0);

    useEffect(() => {
        checkAuth(history);
        mounted.current = true;
        return () => {
            mounted.current = false;
            setId('');
            setIsLoading(true);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (props.submitBtnText !== isSubmitting.text) {
            setIsSubmitting({text: props.submitBtnText, icon: props.submitBtnIcon});
        } else {
            if (isLoading) {
                setIsSubmitting({text: 'Wait', icon: ''});
            } else {
                setIsSubmitting({text: 'Save', icon: ''});
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.submitBtnText, props.submitBtnIcon, isLoading]);

    useEffect(() => {
        if (mounted.current) {
            if (props.groupList !== undefined) {
                let group = props.groupList;
                if (props.id === undefined) {
                    group = [{value: '', label: 'Select Organization'}].concat(group || []);
                }
                setGroupList(group);
            }
        }
    }, [props.groupList, fetchGroups]);

    useEffect(() => {
        if (props.teamList !== undefined) {
            let team = props.teamList;
            let secondaryTeams = props.teamList;
            if (props.id === undefined) {
                team = [{value: '', label: 'Select Team'}].concat(team);
            }
            setTeamList(team);
            let selectSecondaryTeams = [];
            if (fields.teamId) {
                secondaryTeams = secondaryTeams.filter(v => v.value !== fields.teamId);
                selectSecondaryTeams = fields.secondaryTeams.filter(v => v.value !== fields.teamId);
                if(fields.secondaryTeams.length !== selectSecondaryTeams.length){
                    selectTeamInputRef.current.clearValue();
                }
                setFields({...fields, secondaryTeams: selectSecondaryTeams});
            }
            if (secondaryTeams.length === 0) {
                secondaryTeams = [{value: '', label: 'No Team Available', disable: true}].concat(secondaryTeams);
            }

            if(secondaryTeams) {
                setSecondaryTeam(<Col lg="6">
                    <Field
                        name="secondaryTeams"
                        component={renderSelectSearchableField}
                        isMultiple={true}
                        label="Secondary Teams"
                        options={secondaryTeams ?? []}
                        value={fields.secondaryTeams}
                        changeEvent={(val) => handleSelect('secondaryTeams', val)}
                        refEle={selectTeamInputRef}
                    />
                    {/*<Field*/}
                    {/*    name="secondaryTeams"*/}
                    {/*    type="select"*/}
                    {/*    component={renderSelectFieldWithValue}*/}
                    {/*    label="Secondary Teams"*/}
                    {/*    options={secondaryTeams}*/}
                    {/*    value={fields.secondaryTeams}*/}
                    {/*    isMultiple={true}*/}
                    {/*    onChange={handleMultipleInput}*/}
                    {/*/>*/}
                </Col>);
            }
        }
    }, [props.teamList, fields.teamId, updateSecondaryTeams]);

    useEffect(() => {
        if (mounted.current) {

            if (props.id !== undefined) {
                if (props.id !== id) {
                    setId(props.id);
                }
                if (props.user !== {} && props.user.id === props.id) {
                    setFields(props.user);
                    // checkAuthWithCallback(history, fetchGroup, props.user.groupId);
                    setIsLoading(false);
                }
            } else {
                setIsLoading(false);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.user, props.id]);

    useEffect(() => {
        setSeriesList(props.seriesList);
    }, [props.seriesList]);

    const handleInput = (event) => {
        if (mounted.current) {
            setFields({...fields, [event.target.name]: event.target.value});
            if (event.target.name === 'groupId') {
                selectTeamInputRef.current.clearValue();
                checkAuthWithCallback(history, fetchGroup, event.target.value);
            }
            if (event.target.name === 'teamId') {
                setUpdateSecondaryTeams(now());
            }
        }
    };

    const handleSelect = (key, value) => {
        setFields({...fields,[key]:value});
    }

    return (
        <>
            {
                isLoading ?
                    <div className="text-center"><Loader/></div> :
                    <form onSubmit={(values) => handleSubmit(values)}>
                        <Row>
                            <Col lg="6">
                                <Field
                                    name="emailId"
                                    type="email"
                                    component={renderTextField}
                                    label="Email"
                                    hasDefaultValue={fields.emailId}
                                    isRequired={true}
                                    onChange={handleInput}
                                    isDisabled={id}
                                />
                            </Col>
                            {id !== undefined ?
                                <>
                                    <Col lg="6">
                                        <Field
                                            name="firstName"
                                            type="text"
                                            component={renderTextField}
                                            label="First Name"
                                            hasDefaultValue={fields.firstName}
                                            isRequired={true}
                                            onChange={handleInput}
                                        />
                                    </Col>
                                    <Col lg="6">
                                        <Field
                                            name="lastName"
                                            type="text"
                                            component={renderTextField}
                                            label="Last Name"
                                            hasDefaultValue={fields.lastName}
                                            isRequired={true}
                                            onChange={handleInput}
                                        />
                                    </Col>
                                </>
                                : ''}
                            <Col lg="6">
                                <Field
                                    name="groupId"
                                    type="select"
                                    component={renderSelectField}
                                    label="Organization"
                                    options={groupList}
                                    hasDefaultValue={fields.groupId}
                                    isRequired={true}
                                    onChange={handleInput}
                                />
                            </Col>
                            <Col lg="6">
                                <Field
                                    name="teamId"
                                    type="select"
                                    component={renderSelectField}
                                    label="Team"
                                    options={teamList}
                                    hasDefaultValue={fields.teamId}
                                    isRequired={true}
                                    onChange={handleInput}
                                />
                            </Col>
                            {secondaryTeam}
                            <Col lg="6">
                                <Field
                                    name="userRole"
                                    type="select"
                                    component={renderSelectField}
                                    label="Permission"
                                    options={permissionList}
                                    hasDefaultValue={fields.userRole}
                                    isRequired={true}
                                    onChange={handleInput}
                                />
                            </Col>
                            {
                                id !== undefined && seriesList.length > 0 ?
                                    <Col lg="6">
                                        <Field
                                            name="currentSeriesId"
                                            type="select"
                                            component={renderSelectField}
                                            label="Current Practice"
                                            options={seriesList}
                                            hasDefaultValue={fields.currentSeriesId}
                                            isRequired={true}
                                            onChange={handleInput}
                                        />
                                    </Col>
                                    : ''
                            }
                            <Col lg="6">
                                <Field
                                    name="kickOffDate"
                                    type="date"
                                    component={renderTextField}
                                    label="Individual Kickoff Date"
                                    hasDefaultValue={fields.kickOffDate}
                                />
                            </Col>
                            <Col lg="12">
                                <Button color="primary" type="submit"
                                        disabled={isSubmitting.text !== 'Save'}>{isSubmitting.icon !== '' ?
                                    <i className={isSubmitting.icon}/> : ''} {isSubmitting.text}</Button>
                            </Col>
                        </Row>
                    </form>
            }
        </>);
};

const mapStateToProps = (state, ownProps) => {
    let user = updateUserSchema({});
    if (
        state.group.teamSelect &&
        // (state.series !== undefined && state.series.seriesSelect) &&
        (ownProps.id !== undefined && state.user !== undefined && state.user.user !== undefined && Object.keys(state.user.user).length > 0 && ownProps.id === state.user.user.id)) {

        let teamList = state.group.teamSelect ?? [];
        let selectedTeams = teamList.filter(val => (state.user?.user?.secondaryTeams ?? []).includes(val.value));
        user = updateUserSchema({...state.user.user, secondaryTeams: selectedTeams});
    }
    return {
        initialValues: user,
        user: user,
        groupList: state.group.groupSelect ?? [],
        teamList: state.group.teamSelect ?? [],
        seriesList: state.series.seriesSelect ?? []
    }
}

UserCreateForm = reduxForm({
    form: 'userCreateForm',
    enableReinitialize: true,
    validate,
    warn
})(UserCreateForm)

const mapDispatchToProps = {
    fetchGroups,
    fetchGroup
}

export default connect(mapStateToProps, mapDispatchToProps)(UserCreateForm)