import React, {useEffect, useRef, useState} from "react";
import {connect} from "react-redux";
import {fetchGroup} from "../../redux/group/group.actions";
import {Card, CardBody, CardHeader, Col, Row, Table} from "reactstrap";
import {Link, useHistory, useParams} from "react-router-dom";
import Loader from "../../components/Loader/Loader";
import {checkAuth, checkAuthWithCallback} from "../../config/helper";

const View = (props) => {
    const { id } = useParams();
    const history = useHistory();
    const { fetchGroup } = props;
    const [rows,setRows] = useState([]);
    const [group,setGroup] = useState({});
    const [teamList, setTeamList] = useState([]);
    const mounted = useRef(false);
    useEffect(() => {
        checkAuth(history);
        mounted.current = true;
        return () => {
            mounted.current = false;
        };
    }, []);

    useEffect(() => {
        if(mounted.current) {
            if (props.group === undefined || props.group.id === undefined || props.group.id !== id) {
                checkAuthWithCallback(history,fetchGroup,id);
            }
            if (props.group.id !== undefined && props.group.id === id) {
                setGroup(props.group);
                setTeamList(props.teamList);
            }
            if (teamList.length > 0) {
                let rowItems = [];
                for (let item of teamList) {
                    let createdAt = window.moment(item.createdAt).format('YYYY MMM DD h:mm A');
                    rowItems.push(<tr key={item.id}>
                        <td>{item.teamName}</td>
                        <td>{createdAt}</td>
                    </tr>);
                }
                setRows(rowItems);
            }
        }
    }, [props.teamList,props.group,group,teamList]);

    if(group.id !== undefined) {
        return (
            <>
                <Row className="justify-content-center">
                    <Col lg="6" md="12">
                        <Card className="shadow border-0">
                            <CardHeader>
                                <Row>
                                    <Col lg="12" md="12">
                                        <span className={"mb-0 h3"}>{group.groupName}</span>
                                        <Link
                                            to='/admin/organization'
                                            className={"d-flex float-right btn btn-sm btn-primary align-items-center"}
                                        >
                                            <i className="fa fa-arrow-left mr-2"/>
                                            Back
                                        </Link>
                                    </Col>
                                </Row>
                            </CardHeader>
                            <CardBody>
                                <Row>
                                    <Col lg="12">
                                        <Table responsive striped>
                                            <thead>
                                            <tr>
                                                <th>Team</th>
                                                <th>Created At</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {rows.length === 0 ? <tr>
                                                <td colSpan="2" className="text-center text-muted">No Records found</td>
                                            </tr> : rows}
                                            </tbody>
                                        </Table>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </>
        );
    }else{
        return (
            <>
                <Row className="justify-content-center">
                    <Col lg="6" md="12">
                        <Card className="shadow border-0">
                            <CardBody className="text-center">
                                <Loader className="mt-5 mb-5" />
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        teamList: state.group.teamList ?? [],
        group: state.group.group ?? {}
    }
}

const mapDispatchToProps = {
    fetchGroup
}

export default connect(mapStateToProps, mapDispatchToProps)(View);