import React, {useEffect, useState} from 'react'
import {connect} from "react-redux"
import {Field, reduxForm} from 'redux-form'
import {Button, Col, Row} from "reactstrap";
import {renderTextField} from "../../../config/formElement";

const validate = (values) => {
    let errors = {};
    if (!values.groupName) {
        errors.groupName = 'Organization Name is required.';
    }
    return errors;
}

const warn = () => {
    return {};
}

let GroupCreateForm = (props) => {
    const {handleSubmit, onCancel} = props
    const [id, setId] = useState(0);
    const [fields, setFields] = useState({});
    const [first,setFirst] = useState(0);
    const [isSubmitting,setIsSubmitting] = useState({text:"Saving",icon:"fa fa-spinner fa-spin"});
    if(first === 0) {
        setId(props.id);
        setFields(fields);
        setFirst(first+1);
    }

    if(props.formData && props.formData.id !== id){
        setId(props.formData.id);
        setFields(props.formData);
    }

    const handleInput = (event) => {
        fields[event.target.name] = event.target.value;
        setFields(fields);
    };

    useEffect(() => {
        if(props.submitBtnText !== isSubmitting.text || props.submitBtnIcon !== isSubmitting.icon) {
            setIsSubmitting({text:props.submitBtnText,icon: props.submitBtnIcon});
        }
    }, [props.submitBtnText,props.submitBtnIcon]);

    return (
        <>
            <form onSubmit={(values) => handleSubmit(values)}>
                <Row>
                    <Col lg="12">
                        <Field
                            name="groupName"
                            type="text"
                            label={"Name"}
                            // hasDefaultValue={fields.groupName}
                            component={renderTextField}
                            isRequired={true}
                            onChange={handleInput}
                        />
                    </Col>
                    <Col lg="12">
                        <Field
                            name="impactSurveyLink"
                            type="text"
                            label={"Impact Survey Link"}
                            // hasDefaultValue={fields.impactSurveyLink}
                            component={renderTextField}
                            isRequired={false}
                            onChange={handleInput}
                        />
                    </Col>
                    <Col lg="12">
                        <Button color="primary" type="submit" disabled={isSubmitting.text !== 'Save'}>{isSubmitting.icon !== '' ? <i className={isSubmitting.icon} /> : ''} {isSubmitting.text}</Button>
                        {props.showCancleBtn && <Button color="secondary" onClick={onCancel}>Close</Button>}
                    </Col>
                </Row>
            </form>
        </>)
}

const mapStateToProps = (state,ownProps) => {
    return {
        initialValues: ownProps.formData
    };
}

GroupCreateForm = reduxForm({
    form: 'groupCreateForm',
    enableReinitialize : true,
    validate,
    warn
})(GroupCreateForm);

export default connect(mapStateToProps)(GroupCreateForm)