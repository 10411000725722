import React from "react";
import axios from "axios";
import {
    audienceType,
    feedStatus,
    feedType,
    notificationStatus,
    permissionList,
    profileStatusList,
    seriesItemCategory,
    seriesItemDialog,
    seriesItemLayout,
    seriesItemSurveyType,
    seriesItemType,
    seriesViewLayoutType,
    statusList,
    weekDays
} from "./staticData";
import collect from 'collect.js';
import Swal from "sweetalert2";

const Snackbar = require('node-snackbar');
const jwt = require("jsonwebtoken");

export function displayMessage(message) {
    Snackbar.show({text: message, pos: 'top-right', duration: 6000});
}

export function displayErrorMessage(message) {
    Snackbar.show({
        text: message,
        pos: 'top-right',
        backgroundColor: '#f5365c',
        actionTextColor: '#fff',
        duration: 6000
    });
}

export function useFormFields(initialValues) {
    const [formFields, setFormFields] = React.useState(initialValues);
    const createChangeHandler = (key) => (e) => {
        const value = e.target.value;
        setFormFields((prev) => (Object.assign(Object.assign({}, prev), {[key]: value})));
    };
    return {formFields, createChangeHandler};
}

export const reverseString = (str) => {
    return str.split("").reverse().join("");
}

export const setSessionData = (data, role) => {
    localStorage.setItem('_randUid', encodeToken(data.accessToken));
    localStorage.setItem('_role', encodeToken(role ?? 'advisor'));
    return localStorage.getItem("_randUid");
}

export const encodeToken = (token) => {
    const salt = process.env.REACT_APP_SECRET_SALT || '';
    return btoa(reverseString(reverseString(salt) + token));
}

export const removeToken = () => {
    localStorage.removeItem("_randUid");
}

export const validateToken = () => {
    const token = decodeToken();
    if (token !== "" && token !== undefined) {
        const userObj = jwt.decode(token);
        let currentDate = new Date();
        if (userObj.exp * 1000 > currentDate.getTime()) {
            return true;
        } else {
            localStorage.removeItem('_randUid');
            localStorage.removeItem('_role');
            return false;
        }
    } else {
        localStorage.removeItem('_randUid');
        localStorage.removeItem('_role');
        return false;
    }
}

export const decodeToken = () => {
    try {
        const salt = process.env.REACT_APP_SECRET_SALT || '';
        let decodedToken = (reverseString(atob(localStorage.getItem('_randUid'))));
        return decodedToken.split(reverseString(salt))[1];
    } catch (e) {
        localStorage.removeItem('_randUid');
        localStorage.removeItem('_role');
        return "";
    }
}

export const decodeRole = () => {
    try {
        const salt = process.env.REACT_APP_SECRET_SALT || '';
        let decodedToken = (reverseString(atob(localStorage.getItem('_role'))));
        return decodedToken.split(reverseString(salt))[1];
    } catch (e) {
        localStorage.removeItem('_role');
        return "";
    }
}

export const checkAuth = (history) => {
    const tokenStatus = validateToken();
    if (!tokenStatus) {
        let role = decodeRole();
        history.push('/auth/login/' + (role === 'admin' ? 'admin' : ''));
    }

}

export const checkAuthWithCallback = (history, callback, data = null) => {
    const tokenStatus = validateToken();
    if (!tokenStatus) {
        let role = decodeRole();
        history.push('/auth/login/' + (role === 'admin' ? 'admin' : ''));
    } else {
        if (data !== null) {
            callback(data);
        } else {
            callback();
        }
    }
}

export const checkRole = (role, history) => {
    const roleStatus = decodeRole();
    if (role !== roleStatus) {
        if (validateToken()) {
            switch (roleStatus) {
                case 'admin' :
                    history.push('/admin/dashboard');
                    break;
                case 'advisor' :
                    history.push('/coach/dashboard');
                    break;
                default:
                    break;
            }
        }
    }
}

export const auth = () => {
    if (!validateToken()) {
        return null;
    } else {
        const token = decodeToken();
        if (token !== "" && token !== undefined) {
            let user = jwt.decode(token);
            user.token = token;
            return user;
        } else {
            return null;
        }
    }
}

export const authRole = () => {
    if (!validateToken()) {
        return null;
    } else {
        const role = decodeRole();
        if (role !== "" && role !== undefined) {
            return role;
        } else {
            return null;
        }
    }
}

/******************************************
 *  Switch to second role
 * @param history
 * @param first
 * @param second
 * @param data
 * @returns {boolean}
 */
export const switchToRole = (history, first = "admin", second = "advisor", data = {token: ''}) => {
    let status = false;
    try {
        switch (first) {
            case 'admin':
                localStorage.setItem('_randUidAs', localStorage.getItem('_randUid'));
                setSessionData(data, second);
                history.push('/coach/dashboard');
                status = true;
                break;
            case 'advisor':
                localStorage.setItem('_randUid', localStorage.getItem('_randUidAs'));
                localStorage.setItem('_role', encodeToken(second || ''));
                localStorage.removeItem('_randUidAs');
                displayMessage('Switching To Admin.');
                history.push('/admin/coach');
                status = true;
                break;
            default:
                displayErrorMessage('Fail to switch role');
                break;
        }
    } catch (error) {
        displayErrorMessage('Fail to switch role');
        status = false;
    }
    return status;
}

export const getRequest = (url) => {
    return new Promise((myResolve, myReject) => {
        const user = auth();
        if (user !== null) {
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + user.token;
            axios.get(url)
                .then((response) => {
                    myResolve(response.data);
                }).catch((error) => {
                myReject(error);
            });
        } else {
            myReject(null);
        }

    })
}

export const postRequest = (url, data) => {
    return new Promise((myResolve, myReject) => {
        const user = auth();
        if (user !== null) {
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + user.token;
            axios.post(url, data)
                .then((response) => {
                    myResolve(response.data);
                }).catch((error) => {
                myReject(error);
            });
        } else {
            myReject(null);
        }

    })
}

export const postFormRequest = (url, data) => {
    return new Promise((myResolve, myReject) => {
        const user = auth();
        if (user !== null) {
            axios.defaults.headers.common['Content-Type'] = 'multipart/form-data';
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + user.token;
            axios.post(url, data)
                .then((response) => {
                    myResolve(response.data);
                }).catch((error) => {
                    myReject(error);
                });
        } else {
            myReject(null);
        }

    })
}

export const postRequest1 = (url, data) => {
    return new Promise((myResolve, myReject) => {
        if (axios.defaults.headers.common['Authorization'] !== undefined) {
            delete axios.defaults.headers.common['Authorization'];
        }
        axios.post(url, data)
            .then((response) => {
                myResolve(response.data);
            }).catch((error) => {
            myReject(error);
        });
    })
}

export const putRequest = (url, data) => {
    return new Promise((myResolve, myReject) => {
        const user = auth();

        if (user !== null) {
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + user.token;
            axios.put(url, data)
                .then((response) => {
                    myResolve(response.data);
                }).catch((error) => {
                myReject(error);
            });
        } else {
            myReject(null);
        }

    })
}
export const deleteRequest = (url) => {
    return new Promise((myResolve, myReject) => {
        const user = auth();

        if (user !== null) {
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + user.token;
            axios.delete(url)
                .then((response) => {
                    myResolve(response.data);
                }).catch((error) => {
                myReject(error);
            });
        } else {
            myReject(null);
        }

    })
}

export const stringRestyle = (value, type) => {
    if (value !== undefined) {
        switch (type) {
            case 'firstUpperCase' :
                value = value.charAt(0).toUpperCase() + value.slice(1);
                break;
            default:
                break;
        }
    }
    return value;
}

export const getBadgeColor = (value) => {
    let color = 'secondary';
    if (value !== undefined) {
        switch (value.toLowerCase()) {
            case 'member':
            case 'metutorialcomplete' :
            case 'active':
            case 'completed':
            case 'read':
                color = 'primary';
                break;
            case 'inactive' :
            case 'incomplete' :
            case 'admin' :
            case 'unread':
                color = 'info';
                break;
            case 'passwordchanged' :
                color = 'success';
                break;
            case 'profilesetup' :
                color = 'dark';
                break;
            case 'blocked' :
                color = 'danger';
                break;
            default:
                break;
        }
    }
    return color;
}

export const getStaticDataValue = (key, type) => {
    let data = [];
    let value = key;
    try {
        switch (type) {
            case 'permission' :
                data = permissionList;
                break;
            case 'status' :
                data = statusList;
                break;
            case 'profileStatus' :
                data = profileStatusList;
                break;
            case 'seriesItemCategory' :
                data = seriesItemCategory;
                break;
            case 'seriesItemType' :
                data = seriesItemType;
                break;
            case 'seriesItemDialog' :
                data = seriesItemDialog;
                break;
            case 'seriesItemLayout' :
                data = seriesItemLayout;
                break;
            case 'seriesItemSurveyType':
                data = seriesItemSurveyType;
                break;
            case 'weekDays':
                data = weekDays;
                break;
            case "seriesViewLayoutType":
                data = seriesViewLayoutType;
                break;
            case "notificationStatus":
                data = notificationStatus;
                break;
            case "audienceType":
                data = audienceType;
                break;
            case "feedType":
                data = feedType;
                break;
            case "feedStatus":
                data = feedStatus;
                break;
            default:
                break;
        }
        if (data.length > 0) {
            let obj = collect(data).first(item => item.value === key);
            if (obj.value !== undefined) {
                value = obj.label;
            }
        }
    } catch (e) {
        // console.log({e});
    }
    return value;
};

export const getIcon = (val) => {
    let value = 'fas fa-universal-access';
    // switch (val){
    // }
    return value;
};

export const deepEqual = (object1, object2) => {
    const keys1 = Object.keys(object1);
    const keys2 = Object.keys(object2);
    if (keys1.length !== keys2.length) {
        return false;
    }
    for (const key of keys1) {
        const val1 = object1[key];
        const val2 = object2[key];
        const areObjects = isObject(val1) && isObject(val2);
        if (
            (areObjects && !deepEqual(val1, val2)) ||
            (!areObjects && val1 !== val2)
        ) {
            return false;
        }
    }
    return true;
}
export const isObject = (object) => {
    return object != null && typeof object === 'object';
}

export const showConfirmDialog = (data, callback, title = 'Are you sure!', description = '') => {
    const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
            confirmButton: 'btn btn-success',
            cancelButton: 'btn btn-danger'
        },
        buttonsStyling: false
    })
    swalWithBootstrapButtons.fire({
        icon: 'warning',
        title: title ?? '',
        html: description ?? '',
        showCancelButton: true,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No'
    }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
            callback(data, true);
        } else {
            callback(data, false);
        }
    })
}

export const uiAvatarName = (val = 'N/A') => {
    return val.replace('#', '').replace('$', '').trim();
}

export const keepKeyInObject = (arr = [], mainObj = {}) => {
    const l = new Set(arr);
    for (let prop of Object.keys(mainObj)) {
        if (!l.has(prop)) {
            delete mainObj[prop];
        }
    }
    return mainObj;
}

export const reactSelectStyle = {
    control: (base, state) => ({
        ...base,
        outline: 0,
        lineHeight: 1.5,
        backgroundColor: '#fff',
        boxShadow: 'none',
        border: state.isFocused ? "1px solid #c0dff1" : '1px solid #cad1d7',
        "&:hover": {
            border: "1px solid #c0dff1",
        }
    }),
    option: (base, state) => ({
        ...base,
        backgroundColor: state.isSelected ? "#f47e57" : '#fff',
        color: state.isSelected ? "#fff" : 'inherit',
        "&:hover": {
            backgroundColor: "#f47e57",
            color: "#fff"
        },
        "&:active": {
            backgroundColor: "#f47e57",
            color: "#fff"
        }
    })
}

export const exportCSVFile = (data, filename = "export") => {
    if (data && typeof data === 'object' && data.length > 0) {
        data = data.join('\n');
    }
    if (data && typeof data === 'string') {
        const blob = new Blob([data], { type:  "application/octet-stream" });
        const blobUrl = URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.download = (process.env.REACT_APP_NAME || "My") + "-" + filename + ".csv";
        a.href = blobUrl;
        a.click();
        URL.revokeObjectURL(blob);
    }
}

export const getExtension = (filename = '') => {
    let parts = filename.split('.');
    return parts[parts.length - 1];
}