import React, {useEffect, useRef, useState} from "react";
import {connect} from "react-redux";
import {Card, CardBody, CardHeader, CardText, CardTitle, Col, ListGroup, ListGroupItem, Row, Table} from "reactstrap";
import {checkAuth, getStaticDataValue} from "../../../config/helper";
import ImageModal from "../../../components/Modals/ImageModal";
import {Link, useHistory} from "react-router-dom";

const UserDetail = (props) => {
    const mounted = useRef(false);
    const [image, setImage] = useState(false);
    const [drainerList, setDrainerList] = useState([]);
    const [energizerList, setEnergizerList] = useState([]);
    const [imageModalShow, setImageModalShow] = useState(false);
    const history = useHistory();

    useEffect(() => {
        checkAuth(history);
        mounted.current = true;
        return () => {
            mounted.current = false;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const showImageModal = () => {
        if (image && !imageModalShow) {
            setImageModalShow(true);
        }
    }

    const handleCloseImageModal = () => {
        if (imageModalShow) {
            setImageModalShow(false);
        }
    }

    useEffect(() => {
        if (props.userData !== undefined) {
            if (!image && props.userData.strengthScopeWheelPicThumbnailId !== undefined && props.userData.strengthScopeWheelPicThumbnailId !== null && props.userData.strengthScopeWheelPicThumbnailId !== '') {
                setImage(true);
            }
            if (props.userData.drainers) {
                let drainers = [];
                let keys = Object.keys(props.userData.drainers);
                if (keys.length > 0) {
                    for (let key of keys) {
                        if (props.userData.drainers[key]) {
                            drainers.push(<ListGroupItem key={key}>{key}</ListGroupItem>);
                        }
                    }
                }
                setDrainerList(drainers);
            }
            if (props.userData.energizers) {
                let energizers = [];
                let keys = Object.keys(props.userData.energizers);
                if (keys.length > 0) {
                    for (let key of keys) {
                        if (props.userData.energizers[key]) {
                            energizers.push(<ListGroupItem key={key}>{key}</ListGroupItem>);
                        }
                    }
                }
                setEnergizerList(energizers);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.userData]);

    return (
        <>
            <Card>
                <CardHeader>
                    <span className="h3">User Details</span>
                    <Link
                        to={props.viewerRole === 'advisor' ? '/coach/dashboard' : '/admin/user'}
                        className={"d-flex float-right btn btn-sm btn-primary align-items-center"}
                    >
                        <i className="fa fa-arrow-left mr-2"/>
                        Back
                    </Link>
                </CardHeader>
                <CardBody>
                    {
                        props.viewerRole === 'advisor'
                            ?
                            <>
                                <Row className="dashboard-cards row-eq-height">
                                    <Col xl={3} md={6}>
                                        <Card outline color="primary" className="mb-3">
                                            <CardBody>
                                                <h3>CURRENT PRACTICE</h3>
                                                <CardTitle>{
                                                    'currentSeries' in props.userData && 'seriesName' in props.userData.currentSeries
                                                    && props.userData.currentSeries.seriesName ? props.userData.currentSeries.seriesName : 'N/A'
                                                }</CardTitle>
                                                <CardText>User Current Practice</CardText>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                    <Col xl={3} md={6}>
                                        <Card outline color="primary" className="mb-3">
                                            <CardBody>
                                                <h3>WEEKLY CHECKIN</h3>
                                                <CardTitle>{props.userData.weeklyCheckIn || 'N/A'}</CardTitle>
                                                <CardText>Total number of check ins by week</CardText>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                    <Col xl={3} md={6}>
                                        <Card outline color="primary" className="mb-3">
                                            <CardBody>
                                                <h3>ALLTIME CHECKIN</h3>
                                                <CardTitle>{props.userData.totalCheckIn || 'N/A'}</CardTitle>
                                                <CardText>Total cumulative number of practice check ins</CardText>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                    <Col xl={3} md={6}>
                                        <Card outline color="primary" className="mb-3">
                                            <CardBody>
                                                <h3>ENERGY WHEEL</h3>
                                                <CardTitle>{image ? 'Yes' : 'No'}</CardTitle>
                                                <CardText>User Current Practice</CardText>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>
                                <Row className="dashboard-cards row-eq-height">
                                    <Col md={6}>
                                        <Card outline color="primary" className="mb-3">
                                            <CardBody>
                                                <h3>Energizers</h3>
                                                <CardText tag={'div'}>
                                                    {energizerList.length > 0 ?
                                                        <ListGroup>{energizerList}</ListGroup> : 'N/A'}
                                                </CardText>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                    <Col md={6}>
                                        <Card outline color="primary" className="mb-3">
                                            <CardBody>
                                                <h3>Drainers</h3>
                                                <CardText tag={'div'}>
                                                    {drainerList.length > 0 ?
                                                        <ListGroup>{drainerList}</ListGroup> : 'N/A'}
                                                </CardText>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                    <Col md={6}>
                                        <Card outline color="primary" className="mb-3">
                                            <CardBody>
                                                <h3>Strength Scope Wheel</h3>
                                                <CardText tag={'div'}>
                                                    {image ?
                                                        <>
                                                            <img className="cursor-pointer border h-200"
                                                                 src={props.userData.strengthScopeWheelPicThumbnailId}
                                                                 onClick={showImageModal} alt={'Strength Scope Wheel'}/>
                                                            <ImageModal
                                                                image={props.userData.strengthScopeWheelPicId ?? ''}
                                                                isModalShow={imageModalShow}
                                                                handleCloseModal={handleCloseImageModal}
                                                                alt={'Strength Scope Wheel'}
                                                            />
                                                        </>
                                                        : 'N/A'}
                                                </CardText>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>
                            </>
                            :
                            <Table striped borderless responsive>
                                <tbody>
                                <tr>
                                    <th>User Name</th>
                                    <th>:</th>
                                    <td>{props.userData.userName && props.userData.userName.trim() !== '' ? props.userData.userName.trim() : 'N/A'}</td>
                                </tr>
                                <tr>
                                    <th>Profile Status</th>
                                    <th>:</th>
                                    <td>{getStaticDataValue(props.userData.profileStatus ?? 'N/A', 'profileStatus')}</td>
                                </tr>
                                <tr>
                                    <th>Call For</th>
                                    <th>:</th>
                                    <td className="text-wrap">{props.userData.callFor ?? 'N/A'}</td>
                                </tr>
                                <tr>
                                    <th>Team</th>
                                    <th>:</th>
                                    <td>{props.userData.team && props.userData.team.teamName ? props.userData.team.teamName : 'N/A'}</td>
                                </tr>
                                <tr>
                                    <th>Organization</th>
                                    <th>:</th>
                                    <td>{props.userData.team && props.userData.team.groupData && props.userData.team.groupData.groupName ? props.userData.team.groupData.groupName : 'N/A'}</td>
                                </tr>
                                <tr>
                                    <th>Team KickOff Date</th>
                                    <th>:</th>
                                    <td>{props.userData.team.kickOffDate.substring(0, 10) ?? 'N/A'}</td>
                                </tr>
                                <tr>
                                    <th>Individual KickOff Date</th>
                                    <th>:</th>
                                    <td>{props.userData.kickOffDate ?? 'N/A'}</td>
                                </tr>
                                <tr>
                                    <th>Current Practice</th>
                                    <th>:</th>
                                    <td>
                                        {
                                            'currentSeries' in props.userData && 'seriesName' in props.userData.currentSeries
                                            && props.userData.currentSeries.seriesName ? props.userData.currentSeries.seriesName : 'N/A'
                                        }
                                    </td>
                                </tr>
                                <tr>
                                    <th>Drainers</th>
                                    <th>:</th>
                                    <td>
                                        {drainerList.length > 0 ? <ListGroup>{drainerList}</ListGroup> : 'N/A'}
                                    </td>
                                </tr>
                                <tr>
                                    <th>Energizers</th>
                                    <th>:</th>
                                    <td>
                                        {energizerList.length > 0 ? <ListGroup>{energizerList}</ListGroup> : 'N/A'}
                                    </td>
                                </tr>
                                <tr>
                                    <th>Strength Scope Wheel</th>
                                    <th>:</th>
                                    <td>
                                        {image ?
                                            <>
                                                <img className="cursor-pointer border h-200"
                                                     src={props.userData.strengthScopeWheelPicThumbnailId}
                                                     onClick={showImageModal} alt={"Strength Scope Wheel"}/>
                                                <ImageModal image={props.userData.strengthScopeWheelPicId ?? ''}
                                                            isModalShow={imageModalShow}
                                                            handleCloseModal={handleCloseImageModal}
                                                            alt={"Strength Scope Wheel"}
                                                />
                                            </>
                                            : 'N/A'}
                                    </td>
                                </tr>
                                <tr>
                                    <th>User Last Login</th>
                                    <th>:</th>
                                    <td>
                                        <Table>
                                            <tbody>
                                            <tr>
                                                <th>IP Address</th>
                                                <th>:</th>
                                                <td>
                                                    {props.userData && props.userData.metaData && props.userData.metaData.ipAddress ? props.userData.metaData.ipAddress : 'N/A'}
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>Is Active</th>
                                                <th>:</th>
                                                <td>
                                                    {props.userData && props.userData.metaData && props.userData.metaData.isActive ? "Yes" : "No"}
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>Device</th>
                                                <th>:</th>
                                                <td>
                                                    {props.userData && props.userData.metaData && props.userData.metaData.device ? props.userData.metaData.device : 'N/A'}
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>OS Version</th>
                                                <th>:</th>
                                                <td>
                                                    {props.userData && props.userData.metaData && props.userData.metaData.osVersion ? props.userData.metaData.osVersion : 'N/A'}
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>Build Number</th>
                                                <th>:</th>
                                                <td>
                                                    {props.userData && props.userData.metaData && props.userData.metaData.buildNumber ? props.userData.metaData.buildNumber : 'N/A'}
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>Last Opened Time</th>
                                                <th>:</th>
                                                <td>
                                                    {props.userData && props.userData.metaData && props.userData.metaData.lastOpenedTime ? window.moment(props.userData.metaData.lastOpenedTime).format('YYYY-MM-DD h:mm A') : 'N/A'}
                                                </td>
                                            </tr>
                                            </tbody>
                                        </Table>
                                    </td>
                                </tr>
                                </tbody>
                            </Table>
                    }
                </CardBody>
            </Card>
        </>
    );
}

const mapStateToProps = () => {
    return {}
}

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(UserDetail);