import React, {forwardRef, useImperativeHandle, useRef, useState} from "react";
import {Button, Col, Input, InputGroup, InputGroupAddon, Row, Tooltip} from "reactstrap";

const Filter = forwardRef((props, ref) => {
    const {onFilter} = props;
    const filterRef = useRef('');
    const [tooltipOpen, setTooltipOpen] = useState(false)
    const toggleTooltip = () => {
        setTooltipOpen(!tooltipOpen);
    }
    const clearFilter = () => {
        filterRef.current = ''
        onFilter(filterRef.current);
    }

    useImperativeHandle(ref, () => ({
        clearFilter() {
            filterRef.current = ''
            onFilter(filterRef.current);
        }
    }));
    return (
        <>
            <Row className="justify-content-end">
                <Col sm="12">
                    <InputGroup>
                        <Input value={filterRef.current} placeholder="Search"
                               onKeyUp={(value) => {
                                   filterRef.current = value.target.value;
                                   onFilter(filterRef.current);
                               }} onChange={(value) => {
                            filterRef.current = value.target.value;
                            onFilter(filterRef.current);
                        }}
                        />
                        <InputGroupAddon addonType="append">
                            <Button color="danger" outline id="clearFilterTooltip" onClick={clearFilter}>
                                <i className="fa fa-times"/>
                            </Button>
                            <Tooltip placement="top" isOpen={tooltipOpen} autohide={false} target="clearFilterTooltip"
                                     toggle={toggleTooltip}>
                                Clear Filter
                            </Tooltip>
                        </InputGroupAddon>
                    </InputGroup>
                </Col>
            </Row>
        </>
    );
});

export default Filter;