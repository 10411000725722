import {FETCH, POST} from './cronSetting.types';

const INITIAL_STATE = {
  cronSetting: {},
};

const reducer = (state = INITIAL_STATE, action) => {
  let cronSetting = [];
  try {
    switch (action.type) {
      case FETCH:
      case POST:
        cronSetting = action.payload.setting ?? {};
        return {
          ...state,
          cronSetting: cronSetting
        };
      default:
        return state;
    }
  } catch (e) {
    // console.log({e});
  }
  return state;
};

export default reducer;