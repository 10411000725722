import {combineReducers} from 'redux';
import authReducer from './auth/auth.reducer';
import userReducer from './user/user.reducer';
import groupReducer from './group/group.reducer';
import teamReducer from './team/team.reducer';
import seriesReducer from './series/series.reducer';
import advisorReducer from './advisor/advisor.reducer';
import memberReducer from './member/member.reducer';
import huddleReducer from './huddle/huddle.reducer';
import notificationReducer from './notification/notification.reducer';
import meFeedReducer from './me-feed/meFeed.reducer';
import weScreenReducer from './we-screen/weScreen.reducer';
import appSettingReducer from './app-setting/appSetting.reducer';
import cronSettingReducer from './cron-setting/cronSetting.reducer';
import mailReducer from './mail/mail.reducer';
import {reducer as formReducer} from 'redux-form';
import {SIGNOUT} from "./auth/auth.types";
import {LOGIN} from "./advisor/advisor.types";


const appReducer = combineReducers({
    auth: authReducer,
    user: userReducer,
    group: groupReducer,
    team: teamReducer,
    series: seriesReducer,
    advisor: advisorReducer,
    member: memberReducer,
    huddle: huddleReducer,
    notification: notificationReducer,
    meFeed: meFeedReducer,
    weScreen: weScreenReducer,
    appSetting: appSettingReducer,
    cronSetting: cronSettingReducer,
    mail: mailReducer,
    form: formReducer
});

const rootReducer = (state, action) => {
    if (action.type === SIGNOUT || action.type === LOGIN) {
        return appReducer(undefined, action);
    }
    return appReducer(state, action)
}

export default rootReducer;