import {FETCH, DELETE, VIEW, PUT, POST} from './group.types';
import collect from 'collect.js';

const INITIAL_STATE = {
    group:{},
    groupList: [],
    groupSelect:[],
    teamList:[],
    teamSelect:[]
};

export default function reducer(state = INITIAL_STATE, action) {
    let groupList = [];
    let groupSelect = [];
    let teamSelect = [];
    let group = {};
    try {
        switch (action.type) {
            case FETCH:
                groupList = action.payload.groupsList ?? [];
                groupList = groupList.map(val => {
                    val.createdAt = window.moment(val.createdAt).format('YYYY-MM-DD h:mm A');
                    return val;
                });
                groupSelect = groupList.map((val) => {
                    return {value: val.id, label: val.groupName}
                });
                return {
                    ...state, groupList: groupList, groupSelect: groupSelect
                };
            case VIEW:
                teamSelect = (action.payload.teamsList ?? []).map((val) => {
                    return {value: val.id, label: val.teamName}
                });
                return {
                    ...state,
                    group: action.payload.group ?? {},
                    teamList: action.payload.teamsList ?? [],
                    teamSelect: teamSelect
                };
            case DELETE:
                return {
                    ...state,
                    groupList: state.groupList.filter(item => item.id !== action.payload.id),
                    groupSelect: state.groupSelect.filter(item => item.value !== action.payload.id)
                };
            case POST:
            case PUT:
                groupList = state.groupList ?? [];
                if (action.payload.group && action.payload.group.id !== undefined) {
                    group = action.payload.group ?? {};
                    if ('createdAt' in group) {
                        group.createdAt = window.moment(group.createdAt).format('YYYY-MM-DD h:mm A');
                    }
                    groupList = [group].concat(groupList);
                    groupList = collect(groupList).unique('id').all();
                    groupSelect = groupList.map((val) => {
                        return {value: val.id, label: val.groupName}
                    });
                }
                return {...state, groupList: groupList, group: group, groupSelect: groupSelect}
            default:
                return state;
        }
    } catch (e) {
        // console.log({e})
    }
    return state;
}