import {FETCH, DELETE, VIEW, PUT, POST, SELECTED} from './team.types';
import {
    deleteRequest,
    displayErrorMessage,
    displayMessage,
    getRequest,
    postRequest,
    putRequest
} from "../../config/helper";
import {
    TEAM_CREATE_API,
    TEAM_DATA_API,
    TEAM_DELETE_API,
    TEAM_LIST_API,
    TEAM_UPDATE_API
} from "../../config/URLConstant";

export const fetchTeams = () => {
    return function (dispatch) {
        return getRequest(TEAM_LIST_API)
            .then(
                response => response.data,
                error => {
                    if (error && error.response !== undefined) {
                        displayErrorMessage(error.response.data.message || "Internal server error")
                    } else {
                        displayErrorMessage("Internal server error")
                    }
                }
            )
            .then((data) => {
                    return dispatch({
                        type: FETCH,
                        payload: data
                    })
                },
            );
    };
};

export const createTeam = (postData) => {
    return function (dispatch) {
        return postRequest(TEAM_CREATE_API, postData)
            .then(
                response => {
                    displayMessage('Team Created.');
                    return response.data;
                },
                error => {
                    if (error && error.response !== undefined) {
                        displayErrorMessage(error.response.data.message || "Internal server error")
                    } else {
                        displayErrorMessage("Internal server error")
                    }
                }
            ).then((data) => {
                    return dispatch({
                        type: POST,
                        payload: data
                    })
                }
            )
    }
};

export const updateTeam = (postData) => {
    return function (dispatch) {
        return putRequest(TEAM_UPDATE_API, postData)
            .then(
                response => {
                    displayMessage('Team Updated.');
                    return response.data;
                },
                error => {
                    if (error && error.response !== undefined) {
                        displayErrorMessage(error.response.data.message || "Internal server error")
                    } else {
                        displayErrorMessage("Internal server error")
                    }
                }
            ).then((data) => {
                    return dispatch({
                        type: PUT,
                        payload: data
                    })
                }
            )
    }
};

export const fetchTeam = (id) => {
    return function (dispatch) {
        return getRequest(TEAM_DATA_API + id)
            .then(
                response => response.data,
                error => {
                    if (error && error.response !== undefined) {
                        displayErrorMessage(error.response.data.message || "Internal server error")
                    } else {
                        displayErrorMessage("Internal server error")
                    }
                }
            )
            .then((data) => {
                    return dispatch({
                        type: VIEW,
                        payload: data
                    })
                },
            );
    };
};

export const deleteTeam = (data) => {
    return function (dispatch) {
        return deleteRequest(TEAM_DELETE_API + data.id)
            .then(
                response => {
                    displayMessage(response.message);
                    return response.data;
                },
                error => {
                    if (error && error.response !== undefined) {
                        displayErrorMessage(error.response.data.message || "Internal server error")
                    } else {
                        displayErrorMessage("Internal server error")
                    }
                }
            )
            .then((response) => {
                    return dispatch({
                        type: DELETE,
                        payload: response !== undefined ? data : response
                    })
                },
            );
    };
};

export const selectedTeamData = (data) => {
    return function (dispatch) {
        return dispatch({
            type: SELECTED,
            payload: data
        });
    };
};