import {FETCH, PUT, VIEW} from './mail.types';
import {
    displayErrorMessage,
    displayMessage,
    getRequest,
    putRequest
} from "../../config/helper";
import {MAIL_TEMPLATE_GET_API, MAIL_TEMPLATE_PUT_API} from "../../config/URLConstant";

export const fetchMails = () => {
    return function (dispatch) {
        return getRequest(MAIL_TEMPLATE_GET_API)
            .then(
                response => response.data,
                error => {
                    if (error && error.response !== undefined) {
                        displayErrorMessage(error.response.data.message || "Internal server error")
                    } else {
                        displayErrorMessage("Internal server error")
                    }
                }
            )
            .then((data) => {
                    return dispatch({
                        type: FETCH,
                        payload: data
                    })
                },
            );
    };
};

export const updateMail = (postData) => {
    return function (dispatch) {
        return putRequest(MAIL_TEMPLATE_PUT_API, postData)
            .then(
                response => {
                    displayMessage('Mail Updated.');
                    return response.data;
                },
                error => {
                    if (error && error.response !== undefined) {
                        displayErrorMessage(error.response.data.message || "Internal server error")
                    } else {
                        displayErrorMessage("Internal server error")
                    }
                }
            ).then((data) => {
                    return dispatch({
                        type: PUT,
                        payload: postData
                    })
                }
            )
    }
};

export const viewMailData = (data) => {
    return function (dispatch) {
        return dispatch({
            type: VIEW,
            payload: data
        });
    };
};