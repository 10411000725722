import {FETCH} from './meFeed.types';
import {displayErrorMessage, postRequest} from "../../config/helper";
import {ME_FEED_LIST_API} from "../../config/URLConstant";

export const fetchMeFeeds= (formData) => {
    return function (dispatch) {
        return postRequest(ME_FEED_LIST_API,formData)
            .then(
                response => response.data,
                error => {
                    if (error && error.response !== undefined) {
                        displayErrorMessage(error.response.data.message || "Internal server error")
                    } else {
                        displayErrorMessage("Internal server error")
                    }
                }
            )
            .then((data) => {
                    return dispatch({
                        type: FETCH,
                        payload: data
                    })
                },
            );
    };
};