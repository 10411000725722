import React, {useEffect, useRef, useState} from 'react'
import {connect} from "react-redux"
import {Field, reduxForm} from 'redux-form'
import {Button, Col, Row} from "reactstrap";
import {renderSelectField, renderTextField} from "../../../config/formElement";
import Loader from "../../../components/Loader/Loader";
import {fetchSeries, fetchSeriesList} from "../../../redux/series/series.actions";
import {seriesTypeList} from "../../../config/staticData";
import {checkAuth, checkAuthWithCallback} from "../../../config/helper";
import {useHistory} from "react-router-dom";
import {updateSeriesSchema} from "../../../config/schema";

const validate = (values) => {
    let errors = {};
    if (!values.seriesName) {
        errors.seriesName = 'Practice name is required.';
    }
    if (!values.seriesType) {
        errors.seriesType = 'Practice type is required.';
    }
    if (!values.seriesHeader) {
        errors.seriesHeader = 'Practice Header is required.';
    }
    if (!values.seriesCategory) {
        errors.seriesCategory = 'Practice Category is required.';
    }
    return errors;
}

const warn = () => {
    return {};
}

let SeriesCreateForm = (props) => {
    const {handleSubmit, fetchSeries, fetchSeriesList} = props;
    const mounted = useRef(false);
    const [fields, setFields] = useState(updateSeriesSchema({}));
    const [isSubmitting, setIsSubmitting] = useState({text: 'Save', icon: ''});
    const [isLoading, setIsLoading] = useState(true);
    const [seriesList, setSeriesList] = useState([]);
    const history = useHistory();

    useEffect(() => {
        checkAuth(history);
        mounted.current = true;
        return () => {
            mounted.current = false;
            setFields(updateSeriesSchema({}));
            setIsSubmitting({text: 'Save', icon: ''});
        };
    }, []);

    useEffect(() => {
        if (props.submitBtnText !== isSubmitting.text) {
            setIsSubmitting({text: props.submitBtnText, icon: props.submitBtnIcon});
        } else {
            if (isLoading) {
                setIsSubmitting({text: 'Wait', icon: ''});
            } else {
                setIsSubmitting({text: 'Save', icon: ''});
            }
        }
    }, [props.submitBtnText, props.submitBtnIcon, isLoading]);

    useEffect(() => {
        if(mounted.current) {
            if (props.seriesList === undefined || props.seriesList.length === 0) {
                setIsLoading(true);
                checkAuthWithCallback(history, fetchSeriesList);
            }
            if (props.seriesList !== undefined && props.seriesList.length > 0) {
                let series = [...new Map(props.seriesList.map(item => [item.value, item])).values()];
                setSeriesList([{value: null, label: 'Select Practice'}].concat(series));
                setIsLoading(false);
            }
        }
    }, [props.seriesList, fetchSeriesList]);

    useEffect(() => {
        if(mounted.current) {
            if (props.id !== undefined) {
                // if (props.series === undefined || props.series.id !== props.id) {
                    setIsLoading(true);
                    checkAuthWithCallback(history,fetchSeries,props.id);
                // }
                if (props.series !== undefined && props.series.id === props.id) {
                    let data = {...fields, ...props.series}
                    setFields(data);
                    setTimeout(() => {
                        setIsLoading(false);
                    }, 500);
                }
            }
            if (props.duplicateId !== undefined) {
                if (props.series === undefined || props.series.id !== props.duplicateId) {
                    setIsLoading(true);
                    checkAuthWithCallback(history,fetchSeries,props.duplicateId);
                }
                if (props.series !== undefined && props.series.id === props.duplicateId) {
                    let data = {...fields, ...props.series}
                    setFields(data);
                    setIsLoading(false);
                }
            }
        }
    }, [props.series, fetchSeries]);

    const handleSelect = (val) => {
        let value = val.target.value;
        let data = fields;
        data.prevSeriesId = value;
        setFields(data);
    }

    return (
        <>
            {
                isLoading ?
                    <div className="text-center"><Loader /></div>
                    :
                    <form onSubmit={(values) => handleSubmit(values)}>
                        <Row>
                            <Col lg="4">
                                <Field
                                    name="seriesName"
                                    type="text"
                                    label={"Name"}
                                    hasDefaultValue={fields.seriesName}
                                    component={renderTextField}
                                    isRequired={true}
                                />
                            </Col>
                            <Col lg="4">
                                <Field
                                    name="seriesCategory"
                                    type="text"
                                    label={"Category"}
                                    hasDefaultValue={fields.seriesCategory}
                                    component={renderTextField}
                                    isRequired={true}
                                />
                            </Col>
                            {/*<Col lg="4">*/}
                            {/*    <Field*/}
                            {/*        name="seriesType"*/}
                            {/*        type="select"*/}
                            {/*        component={renderSelectField}*/}
                            {/*        label="Type"*/}
                            {/*        options={seriesTypeList ?? []}*/}
                            {/*        hasDefaultValue={fields.seriesType}*/}
                            {/*        isRequired={true}*/}
                            {/*    />*/}
                            {/*</Col>*/}
                            <Col lg="4">
                                <Field
                                    name="prevSeriesId"
                                    type="select"
                                    component={renderSelectField}
                                    label="Previous Practice"
                                    options={seriesList ?? []}
                                    hasDefaultValue={fields.prevSeriesId}
                                    isRequired={false}
                                    onChange={handleSelect}
                                />
                            </Col>
                            <Col lg="12">
                                <hr className="mt-0 mb-3"/>
                            </Col>

                            <Col lg="4">
                                <Field
                                    name="seriesHeader"
                                    type="text"
                                    label={"Card Title"}
                                    hasDefaultValue={fields.seriesHeader}
                                    component={renderTextField}
                                    isRequired={true}
                                />
                            </Col>
                            <Col lg="4">
                                <Field
                                    name="view.bgImg"
                                    type="text"
                                    label={"Card BG Image"}
                                    hasDefaultValue={fields.view.bgImg}
                                    component={renderTextField}
                                    isRequired={true}
                                />
                            </Col>
                            <Col lg="4">
                                <Field
                                    name="view.title"
                                    type="textarea"
                                    label={"Explainer Text"}
                                    hasDefaultValue={fields.view.title}
                                    component={renderTextField}
                                    isRequired={true}
                                />
                            </Col>

                            <Col lg="12">
                                <hr className="mt-0 mb-3"/>
                            </Col>

                            <Col lg="4">
                                <Field
                                    name="view.questionText"
                                    type="text"
                                    label={"Question Text"}
                                    hasDefaultValue={fields.view.questionText}
                                    component={renderTextField}
                                    isRequired={true}
                                />
                            </Col>
                            <Col lg="4">
                                <Field
                                    name="view.videoButtonText"
                                    type="text"
                                    label={"Video Button Text"}
                                    hasDefaultValue={fields.view.videoButtonText}
                                    component={renderTextField}
                                    isRequired={true}
                                />
                            </Col>
                            <Col lg="4">
                                <Field
                                    name="view.postHint"
                                    type="text"
                                    label={"Comment field Text"}
                                    hasDefaultValue={fields.view.postHint}
                                    component={renderTextField}
                                    isRequired={true}
                                />
                            </Col>
                            <Col lg="4">
                                <Field
                                    name="seriesIcon"
                                    type="text"
                                    label={"Icon"}
                                    hasDefaultValue={fields.seriesIcon}
                                    component={renderTextField}
                                    isRequired={true}
                                />
                            </Col>
                            <Col lg="4">
                                <Field
                                    name="view.practiceUrl"
                                    type="text"
                                    label={"Practice Video Link"}
                                    hasDefaultValue={fields.view.practiceUrl}
                                    component={renderTextField}
                                    isRequired={true}
                                />
                            </Col>
                            <Col lg="12">
                                <hr className="mt-0 mb-3"/>
                            </Col>

                            <Col lg="6">
                                <Field
                                    name="pushTitle"
                                    type="text"
                                    label={"Notification Title"}
                                    hasDefaultValue={fields.pushTitle}
                                    component={renderTextField}
                                    isRequired={true}
                                />
                            </Col>
                            <Col lg="6">
                                <Field
                                    name="pushMessage"
                                    type="text"
                                    label={"Notification Message"}
                                    hasDefaultValue={fields.pushMessage}
                                    component={renderTextField}
                                    isRequired={true}
                                />
                            </Col>

                            <Col lg="12">
                                <Button color="primary" type="submit"
                                        disabled={isSubmitting.text !== 'Save'}>{isSubmitting.icon !== '' ?
                                    <i className={isSubmitting.icon} /> : ''} {isSubmitting.text}</Button>
                            </Col>
                        </Row>
                    </form>
            }
        </>);
}

const mapStateToProps = (state,ownProps) => {
    let series = updateSeriesSchema({});
    if ((ownProps.id !== undefined || ownProps.duplicateId !== undefined) && state.series !== undefined && state.series.series !== undefined && state.series.series.id !== undefined && (ownProps.id === state.series.series.id || ownProps.duplicateId === state.series.series.id)) {
        series = updateSeriesSchema(state.series.series,state.series.seriesItem);
    }
    return {
        initialValues: series,
        series: series,
        seriesItem: state.series.seriesItem,
        seriesList: state.series.seriesSelect || []
    };
};

const mapDispatchToProps = {
    fetchSeries,
    fetchSeriesList
};

SeriesCreateForm = reduxForm({
    form: 'seriesCreateForm',
    enableReinitialize: true,
    validate,
    warn
})(SeriesCreateForm);

export default connect(mapStateToProps, mapDispatchToProps)(SeriesCreateForm)