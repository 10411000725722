import {DELETE, FETCH, VIEW, POST, PUT} from './group.types';
import {
    deleteRequest,
    displayErrorMessage,
    displayMessage,
    getRequest,
    postRequest,
    putRequest
} from "../../config/helper";
import {
    GROUP_CREATE_API,
    GROUP_DATA_API,
    GROUP_DELETE_API,
    GROUP_LIST_API,
    GROUP_TEAM_API,
    GROUP_UPDATE_API
} from "../../config/URLConstant";

export const fetchGroups = () => {
    return function (dispatch) {
        return getRequest(GROUP_LIST_API)
            .then(
                response => response.data,
                error => {
                    if (error && error.response !== undefined) {
                        displayErrorMessage(error.response.data.message || "Internal server error")
                    } else {
                        displayErrorMessage("Internal server error")
                    }
                }
            )
            .then((data) => {
                    return dispatch({
                        type: FETCH,
                        payload: data
                    })
                },
            );
    };
};

export const createGroup = (postData) => {
    return function (dispatch) {
    return postRequest(GROUP_CREATE_API, postData)
        .then(
            response => {
                displayMessage('Organization Created.');
                return response.data;
            },
            error => {
                if (error && error.response !== undefined) {
                    displayErrorMessage(error.response.data.message || "Internal server error")
                } else {
                    displayErrorMessage("Internal server error")
                }
            }
        ).then((data) => {
                return dispatch({
                    type: POST,
                    payload: data
                })
            }
        )
    }
};

export const fetchGroup = (id) => {
    return function (dispatch) {
        return getRequest(GROUP_DATA_API + id)
            .then(
                response => response.data,
                error => {
                    if (error && error.response !== undefined) {
                        displayErrorMessage(error.response.data.message || "Internal server error")
                    } else {
                        displayErrorMessage("Internal server error")
                    }
                }
            )
            .then((data) => {
                    return dispatch({
                        type: VIEW,
                        payload: data
                    })
                },
            );
    };
};

export const updateGroup = (postData) => {
    return function (dispatch) {
    return putRequest(GROUP_UPDATE_API, postData)
        .then(
            response => {
                displayMessage('Organization Updated.');
                return response.data;
            },
            error => {
                if (error && error.response !== undefined) {
                    displayErrorMessage(error.response.data.message || "Internal server error")
                } else {
                    displayErrorMessage("Internal server error")
                }
            }
        ).then((data) => {
                return dispatch({
                    type: PUT,
                    payload: data
                })
            }
        );
    }
};

export const fetchGroupTeams = () => {
    return function (dispatch) {
        return getRequest(GROUP_TEAM_API)
            .then(
                response => response.data,
                error => {
                    if (error && error.response !== undefined) {
                        displayErrorMessage(error.response.data.message || "Internal server error")
                    } else {
                        displayErrorMessage("Internal server error")
                    }
                }
            )
            .then((data) => {
                    return dispatch({
                        type: FETCH,
                        payload: data
                    })
                },
            );
    };
};

export const deleteGroup = (data) => {
    return function (dispatch) {
        return deleteRequest(GROUP_DELETE_API + data.id)
            .then(
                response => {
                    displayMessage(response.message);
                    return response.data
                },
                error => {
                    if (error && error.response !== undefined) {
                        displayErrorMessage(error.response.data.message || "Internal server error")
                    } else {
                        displayErrorMessage("Internal server error")
                    }
                }
            )
            .then((response) => {
                    return dispatch({
                        type: DELETE,
                        payload: response !== undefined ? data : response
                    })
                },
            );
    };
};
