import {
    Switch,
    Route,
    BrowserRouter as Router,
    Redirect
} from "react-router-dom";
import AdminLayout from "./layouts/Admin";
import AuthLayout from "./layouts/Auth";
import AdvisorLayout from "./layouts/Advisor";

function App() {
    return (
        <Router>
            <Switch>
                <Route path="/coach" render={(props) => <AdvisorLayout {...props} />}/>
                <Route path="/admin" render={(props) => <AdminLayout {...props} />}/>
                <Route path="/auth" render={(props) => <AuthLayout {...props} />}/>
                <Redirect from="/" to="/admin/dashboard"/>
            </Switch>
        </Router>
    );
}

export default App;
