import {connect} from "react-redux";
import {Col, Row} from "reactstrap";
import {fetchNotificationSetting, postNotificationSetting} from "../../redux/cron-setting/cronSetting.actions";
import {useHistory} from "react-router-dom";
import {useEffect, useRef, useState} from "react";
import {checkAuth} from "../../config/helper";
import NotificationSettingFormCreate from "./form/NotificationCreate";


const Notification = (props) => {
    const {postNotificationSetting, fetchNotificationSetting} = props;
    const history = useHistory();
    const mounted = useRef(false);
    const [submitBtnText, setSubmitBtnText] = useState('Save');
    const [submitBtnIcon, setSubmitBtnIcon] = useState('');

    useEffect(() => {
        mounted.current = true;
        checkAuth(history);
        return () => {
            mounted.current = false;
            handleSubmitBtn(false);
        };
    }, []);

    const handleSubmitBtn = (val) => {
        if (val) {
            setSubmitBtnText('Saving');
            setSubmitBtnIcon('fa fa-spinner fa-spin');
        } else {
            setSubmitBtnText('Save');
            setSubmitBtnIcon('');
        }
    }

    const handleSubmit = (values) => {
        postNotificationSetting(values).then(() => {
            fetchNotificationSetting({key: "CRON_NOTIFICATION"}).then(() => {
                handleSubmitBtn(false);
            });
        });
    }

    return (
        <>
            <Row>
                <Col sm={12}>
                    <NotificationSettingFormCreate onSubmit={handleSubmit} submitBtnText={submitBtnText}
                                                   submitBtnIcon={submitBtnIcon}/>
                </Col>
            </Row>
        </>
    )
}

const mapStateToProps = (state) => {
    return state;
}

const mapDispatchToProps = {
    postNotificationSetting,
    fetchNotificationSetting
}

export default connect(mapStateToProps, mapDispatchToProps)(Notification);