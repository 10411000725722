import ImageModal from "../../components/Modals/ImageModal";
import React, {useState} from "react";
import {connect} from "react-redux";

let TemplatePreview = (props) => {
    const [imageModalShow,setImageModalShow] = useState(false);

    const showImageModal = () => {
        if(!imageModalShow) {
            setImageModalShow(true);
        }
    }

    const handleCloseImageModal = () => {
        if(imageModalShow) {
            setImageModalShow(false);
        }
    }

    return (
        <>
            <img className={"cursor-pointer border "+props.imgClass} src={props.image} onClick={showImageModal} alt={props.alt ?? "preview image"}/>
            <ImageModal image={props.image ?? ''} isModalShow={imageModalShow} handleCloseModal={handleCloseImageModal} alt={props.alt ?? "preview image"} />
        </>
    );
}

const mapStateToProps = (state) => {
    return {
    }
}

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(TemplatePreview);
