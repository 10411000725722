import {FETCH, DELETE, POST, LOGIN} from './advisor.types';
import {
    deleteRequest,
    displayErrorMessage,
    displayMessage,
    postRequest,
} from "../../config/helper";
import {
    ADVISOR_CREATE_API,
    ADVISOR_DELETE_API,
    ADVISOR_DIRECT_LOGIN_API,
    ADVISOR_LIST_API,
} from "../../config/URLConstant";

export const fetchAdvisors = (data) => {
  return function (dispatch) {
    return postRequest(ADVISOR_LIST_API,data)
      .then(
        response => response.data,
        error => {
          if (error && error.response !== undefined) {
            displayErrorMessage(error.response.data.message || "Internal server error")
          } else {
            displayErrorMessage("Internal server error")
          }
        }
      )
        .then((data) => {
          return dispatch({
            type: FETCH,
            payload: data
          })
        },
      );
  };
};

export const createAdvisor = (postData) => {
    return function (dispatch) {
        return postRequest(ADVISOR_CREATE_API, postData)
            .then(
                response => {
                    displayMessage('Coach Created.');
                    return response.data || true;
                },
                error => {
                    if (error && error.response !== undefined) {
                        displayErrorMessage(error.response.data.message || "Internal server error")
                    } else {
                        displayErrorMessage("Internal server error")
                    }
                }
            ).then((data) => {
                    return dispatch({
                        type: POST,
                        payload: data
                    })
                }
            )
    }
};

export const deleteAdvisor = (data) => {
  return function (dispatch) {
    return deleteRequest(ADVISOR_DELETE_API + '/' + data.id)
      .then(
        response => {
          displayMessage(response.message);
          return response.data
        },
        error => {
          if (error && error.response !== undefined) {
            displayErrorMessage(error.response.data.message || "Internal server error")
          } else {
            displayErrorMessage("Internal server error")
          }
        }
      )
      .then(() => {
          return dispatch({
            type: DELETE,
            payload: data
          })
        },
      );
  };
};

/***********************************************
 *              Coach Direct Login
 **********************************************/

export const directAdvisorLogin = (data) => {
    return function (dispatch) {
        return postRequest(ADVISOR_DIRECT_LOGIN_API,data)
            .then(
                response => {
                    displayMessage('Switching To Coach.');
                    return response.data;
                },
                error => {
                    if (error && error.response !== undefined) {
                        displayErrorMessage(error.response.data.message || "Internal server error")
                    } else {
                        displayErrorMessage("Internal server error")
                    }
                }
            ).then((data) => {
                    return dispatch({
                        type: LOGIN,
                        payload: data
                    })
                }
            )
    }
}