import {FETCH} from './meFeed.types';
import {now} from "moment";

const INITIAL_STATE = {
  meFeedList: [],
  timestamp: ''
};

const reducer = (state = INITIAL_STATE, action) => {
  let meFeeds = [];
  try {
    switch (action.type) {
      case FETCH:
        meFeeds = action.payload.meFeedList ?? [];
        return {
          ...state,
          meFeedList: meFeeds,
          paginationResult: action.payload.paginationResult,
          timestamp: now(),
        };
      default:
        return state;
    }
  } catch (e) {
    // console.log({e});
  }
  return state;
};

export default reducer;