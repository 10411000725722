import React, {useEffect, useRef, useState} from "react";
import {
    Button,
    Card,
    CardBody,
    CardHeader, Col, Form, FormGroup, Label,
    Row, UncontrolledCollapse
} from "reactstrap";
import DataTable from "react-data-table-component";
import Loader from "../../components/Loader/Loader";
import {connect} from "react-redux";
import {Link, useHistory} from "react-router-dom";
import {fetchAdvisors} from "../../redux/advisor/advisor.actions";
import TableAction from "./TableAction";
import {fetchGroups} from "../../redux/group/group.actions";
import {fetchTeams} from "../../redux/team/team.actions";
import AsyncSelect from "react-select/async";
import {fetchUsers} from "../../redux/user/user.actions";
import Select from "react-select";
import {checkAuth, checkAuthWithCallback, checkRole, reactSelectStyle} from "../../config/helper";

const Index = (props) => {

    const {fetchTeams,fetchAdvisors,fetchGroups,fetchUsers} = props;
    const mounted = useRef(false);
    const history = useHistory();
    const selectAdvisorInputRef = useRef();
    const selectTeamInputRef = useRef();
    const selectGroupInputRef = useRef();
    const [listLoader,setListLoader] = useState(true);
    const [showFilter,setShowFilter] = useState('close');
    const [timestamp, setTimestamp] = useState('');
    const [tableInfo,setTableInfo] = useState({
        pagination: {
            pageNumber: 1,
            pageSize: 10,
            totalPages:1,
            totalRecords: 0
        },
        filter: {},
        sort: {}
    });

    const columns = [
        {
            name: 'Name',
            selector: row => [row.user.firstName ?? '',row.user.lastName ?? ''].join(' '),
            fieldName: 'firstName'
        },
        {
            name: 'Email',
            selector: row => row.user.emailId ?? '',
            fieldName: 'email'
        },
        {
            name: 'Organization',
            selector: row => row.mappedTeam.groupData.groupName || '',
            fieldName: 'groupName'
        },
        {
            name: 'Team',
            selector: row => row.mappedTeam.teamName || '',
            fieldName: 'teamName'
        },
        {
            name: 'Action',
            cell: (row) => {
                return (
                    <TableAction row={row} onDataUpdated={getAdvisorData}/>
                )
            },
            maxWidth: '150px'
        },
    ];

    useEffect(() => {
        checkAuth(history);
        checkRole('admin',history);
        mounted.current = true;
        return () => {
            mounted.current = false;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if(mounted.current) {
            if (props.userList === undefined || props.userList.length === 0) {
                checkAuthWithCallback(history,fetchAdvisors,tableInfo);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.userList]);

    useEffect(() => {
        if(props.timestamp !== timestamp){
            setListLoader(false);
            setTimestamp(props.timestamp);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[props.timestamp]);

    useEffect(() => {
        if(mounted.current) {
            if (props.teamSelect === undefined || props.teamSelect.length === 0) {
                checkAuthWithCallback(history,fetchTeams);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[props.teamSelect]);

    useEffect(() => {
        if(mounted.current) {
            if (props.groupSelect === undefined || props.groupSelect.length === 0) {
                checkAuthWithCallback(history,fetchGroups);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[props.groupSelect]);

    const handlePageChange = pageNum => {
        let page = tableInfo;
        page.pagination.pageNumber = pageNum;
        setListLoader(true);
        setTableInfo(page);
        getAdvisorData();
    };

    const handlePerRowsChange = async (newPerPage, currentPage) => {
        let page = tableInfo;
        page.pagination.pageSize = newPerPage;
        page.pagination.pageNumber = currentPage;
        setListLoader(true);
        setTableInfo(page);
        getAdvisorData();
    };

    const getAdvisorData = () => {
        if(mounted.current) {
            setListLoader(true);
            checkAuthWithCallback(history,fetchAdvisors,tableInfo);
        }
    }

    const handleSort = async (column, sortDirection) => {
        let page = tableInfo;
        page.sort = {};
        page.sort[column.fieldName] = sortDirection === 'asc' ? 1 : -1;
        setTableInfo(page);
        getAdvisorData();
    };

    const noRefCheck = (refData) => {
        setShowFilter(refData);
    }

    const resetFilter = () => {
        selectAdvisorInputRef.current.clearValue();
        selectTeamInputRef.current.clearValue();
        selectGroupInputRef.current.clearValue();
        let filterData = tableInfo;
        filterData.filter = {};
        setTableInfo(filterData);
        getAdvisorData();
    }

    const handleFilter = (key,val) => {
        let filterData = tableInfo;
        if(val && val.value !== ''){
            filterData.filter[key] = val.value;
            setTableInfo(filterData);
            getAdvisorData();
        }else{
            delete filterData.filter[key];
            setTableInfo(filterData);
            getAdvisorData();
        }
    }

    const handleAsyncSelect = (key,val) => {
        let filterData = tableInfo;
        if(val && val.id && val.id !== ''){
            filterData.filter[key] = val.id;
            setTableInfo(filterData);
            getAdvisorData();
        }else{
            delete filterData.filter[key];
            setTableInfo(filterData);
            getAdvisorData();
        }
    }

    const loadOptions = (val) => {
        if(mounted.current) {
            if (val.trim().length > 2) {
                let table = tableInfo;
                table.filter.fullName = val.trim();
                setTableInfo(table);
                return fetchUsers(table).then((data) => {
                    if (data.payload && data.payload.userList) {
                        return data.payload.userList;
                    } else {
                        return [];
                    }
                });
            } else {
                return [];
            }
        }else{
            return [];
        }
    }

    return (
        <>
            {/* Page content */}
            <Row>
                <Col lg="12" md="12">
                    <Card className="shadow border-0">
                        <CardHeader>
                            <Row>
                                <Col lg="12" md="12">
                                    <span className={"mb-0 h3"}>Coach list</span>
                                    <Link
                                        to='/admin/coach/create'
                                        className={"d-flex float-right btn btn-sm btn-primary align-items-center"}
                                    >
                                        <i className="ni ni-fat-add mr-2"/>
                                        Add Coach
                                    </Link>
                                    <Button
                                        color="primary"
                                        id="userFilters"
                                        className={"d-flex float-right btn btn-sm btn-primary align-items-center mr-3"}
                                    >
                                        {showFilter === 'open' ? <><i className="fa fa-eye-slash mr-2" /> Hide</> : <><i className="fa fa-eye mr-2" /> Show</>} Filters
                                    </Button>
                                </Col>
                            </Row>
                        </CardHeader>
                        <CardBody>
                            <Row>
                                <Col sm="12">
                                    <UncontrolledCollapse toggler="#userFilters" onEntered={() => noRefCheck('open')} onExited={() => noRefCheck('close')}>
                                        <Form>
                                            <Card className="mb-4">
                                                <CardHeader>
                                                    <span className="h4">Advance Filters</span>
                                                    <Button color="danger" type="reset" size="sm" className="d-flex float-right align-items-center" onClick={resetFilter}><i className="fa fa-undo mr-2" /> Reset</Button>
                                                </CardHeader>
                                                <CardBody>
                                                    <Row>
                                                        <Col lg="3" md="6">
                                                            <FormGroup>
                                                                <Label>Coach <span className="text-danger">*</span></Label>
                                                                <AsyncSelect
                                                                    ref={selectAdvisorInputRef}
                                                                    cacheOptions
                                                                    getOptionLabel={(option) => `${[(option.firstName || ''),(option.lastName || ''),':'].join(' ').trim()} ${option.emailId}`}
                                                                    noOptionsMessage={() => "Type 3 or more letters to search"}
                                                                    getOptionValue={e => e.id}
                                                                    loadOptions={loadOptions}
                                                                    name="userId"
                                                                    required={true}
                                                                    isClearable
                                                                    onChange={(val) => handleAsyncSelect('userId',val)}
                                                                    styles={reactSelectStyle}
                                                                />
                                                            </FormGroup>
                                                        </Col>
                                                        <Col lg="3" md="6">
                                                            <FormGroup>
                                                                <Label for="group">
                                                                    Organization
                                                                </Label>
                                                                <Select
                                                                    ref={selectGroupInputRef}
                                                                    className="basic-single"
                                                                    classNamePrefix="select"
                                                                    isSearchable={true}
                                                                    options={props.groupSelect}
                                                                    name="groupId"
                                                                    isClearable
                                                                    onChange={(val) => handleFilter('groupId',val ?? {})}
                                                                    styles={reactSelectStyle}
                                                                />
                                                            </FormGroup>
                                                        </Col>
                                                        <Col lg="3" md="6">
                                                            <FormGroup>
                                                                <Label for="team">
                                                                    Team
                                                                </Label>
                                                                <Select
                                                                    ref={selectTeamInputRef}
                                                                    className="basic-single"
                                                                    classNamePrefix="select"
                                                                    isSearchable={true}
                                                                    options={props.teamSelect}
                                                                    name="teamId"
                                                                    isClearable
                                                                    onChange={(val) => handleFilter('teamId',val ?? {})}
                                                                    styles={reactSelectStyle}
                                                                />
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                </CardBody>
                                            </Card>
                                        </Form>
                                    </UncontrolledCollapse>
                                </Col>
                                <Col sm="12">
                                    <DataTable
                                        columns={columns}
                                        progressPending={listLoader}
                                        progressComponent={<Loader/>}
                                        className={"react-dataTable"}
                                        data={props.advisorList}
                                        pagination
                                        responsive
                                        paginationServer
                                        paginationTotalRows={tableInfo.pagination.totalRecords}
                                        onChangeRowsPerPage={handlePerRowsChange}
                                        onChangePage={handlePageChange}
                                        onSort={handleSort}
                                        sortServer
                                        subHeaderWrap
                                    />
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </>
    )
};

const mapStateToProps = (state) => {
    return {
        advisorList: state.advisor.advisorList,
        teamSelect: state.team.teamSelect,
        groupSelect: state.group.groupSelect,
        timestamp: state.advisor.timestamp ?? ''
    }
}

const mapDispatchToProps = {
    fetchAdvisors,
    fetchGroups,
    fetchTeams,
    fetchUsers
}

export default connect(mapStateToProps, mapDispatchToProps)(Index);
