import {
    FETCH,
    DELETE,
    VIEW,
    POST,
    FETCH_S3_SIGNED,
    POST_S3_FILE,
    TEAM_FETCH,
    POST_USER,
    DOWNLOAD,
    POST_BULK, RESEND_PASSWORD
} from './user.types';
import {
    displayErrorMessage,
    displayMessage,
    getRequest, postFormRequest,
    postRequest, postRequest1,
    putRequest
} from "../../config/helper";
import {
    S3_FILE_UPLOAD_URL,
    S3_SIGNED_URL, USER_BULK_REGISTER_API,
    USER_DATA_API,
    USER_DELETE_API,
    USER_LIST_API, USER_PERMANENT_DELETE_API,
    USER_PROFILE_UPDATE_API,
    USER_REGISTER_API, USER_RESEND_PASSWORD_API, USER_RESET_API, USER_TEAM_MEMBER_LIST_API
} from "../../config/URLConstant";

export const fetchUsers = (postData) => {
    return function (dispatch) {
        return postRequest(USER_LIST_API, postData)
            .then(
                response => response.data,
                error => {
                    if (error && error.response !== undefined) {
                        displayErrorMessage(error.response.data.message || "Internal server error")
                    } else {
                        displayErrorMessage("Internal server error")
                    }
                }
            )
            .then((data) => {
                    return dispatch({
                        type: FETCH,
                        payload: data
                    })
                },
            );
    };
};

export const downloadUsers = (postData) => {
    return function (dispatch) {
        return postRequest(USER_LIST_API, postData)
            .then(
                response => response,
                error => {
                    if (error && error.response !== undefined) {
                        displayErrorMessage(error.response.data.message || "Internal server error")
                    } else {
                        displayErrorMessage("Internal server error")
                    }
                }
            )
            .then((data) => {
                    return dispatch({
                        type: DOWNLOAD,
                        payload: data
                    })
                },
            );
    };
};

export const fetchUser = (id) => {
    return function (dispatch) {
        return getRequest(USER_DATA_API + id)
            .then(
                response => response.data,
                error => {
                    if (error && error.response !== undefined) {
                        displayErrorMessage(error.response.data.message || "Internal server error")
                    } else {
                        displayErrorMessage("Internal server error")
                    }
                }
            )
            .then((data) => {
                    return dispatch({
                        type: VIEW,
                        payload: data
                    })
                },
            );
    };
};

export const deleteUser = (data) => {
    return function (dispatch) {
        return postRequest(USER_DELETE_API, data)
            .then(
                response => {
                    displayMessage('Users blocked successfully');
                    return response.data
                },
                error => {
                    if (error && error.response !== undefined) {
                        displayErrorMessage(error.response.data.message || "Internal server error")
                    } else {
                        displayErrorMessage("Internal server error")
                    }
                }
            )
            .then(() => {
                    return dispatch({
                        type: DELETE,
                        payload: data
                    })
                },
            );
    };
};

export const permanentDeleteUser = (data) => {
    return function (dispatch) {
        return postRequest(USER_PERMANENT_DELETE_API, data)
            .then(
                response => {
                    displayMessage('Users has been permanently deleted');
                    return response.data
                },
                error => {
                    if (error && error.response !== undefined) {
                        displayErrorMessage(error.response.data.message || "Internal server error")
                    } else {
                        displayErrorMessage("Internal server error")
                    }
                }
            )
            .then(() => {
                    return dispatch({
                        type: DELETE,
                        payload: data
                    })
                },
            );
    };
};

export const updateUserProfile = (data) => {
    return function (dispatch) {
        return putRequest(USER_PROFILE_UPDATE_API, data)
            .then(
                response => {
                    return response.data;
                },
                error => {
                    if (error && error.response !== undefined) {
                        displayErrorMessage(error.response.data.message || "Internal server error")
                    } else {
                        displayErrorMessage("Internal server error")
                    }
                }
            )
            .then((data) => {
                    return dispatch({
                        type: POST,
                        payload: data
                    })
                },
            );
    };
};

export const getS3SignedUrl = (data) => {
    return function (dispatch) {
        return postRequest(S3_SIGNED_URL, data)
            .then(
                response => response.data,
                error => {
                    if (error && error.response !== undefined) {
                        displayErrorMessage(error.response.data.message || "Internal server error")
                    } else {
                        displayErrorMessage("Internal server error")
                    }
                }
            )
            .then((data) => {
                    return dispatch({
                        type: FETCH_S3_SIGNED,
                        payload: data
                    })
                },
            );
    };
};

export const postS3FileUpload = (data) => {
    return function (dispatch) {
        return postRequest1(S3_FILE_UPLOAD_URL, data)
            .then(
                response => response.data ?? true,
                error => {
                    if (error && error.response !== undefined) {
                        displayErrorMessage(error.response.data.message || "Internal server error")
                    } else {
                        displayErrorMessage("Internal server error")
                    }
                }
            )
            .then((data) => {
                    return dispatch({
                        type: POST_S3_FILE,
                        payload: data
                    })
                },
            );
    };
};

export const registerUser = (data) => {
    return function (dispatch) {
        return postRequest(USER_REGISTER_API, data)
            .then(
                response => response.data,
                error => {
                    if (error && error.response !== undefined) {
                        displayErrorMessage(error.response.data.message || "Internal server error")
                    } else {
                        displayErrorMessage("Internal server error")
                    }
                }
            ).then((data) => {
                    return dispatch({
                        type: POST,
                        payload: data
                    })
                },
            );
    };
}

export const registerBulkUser = (data) => {
    return function (dispatch) {
        return postFormRequest(USER_BULK_REGISTER_API, data)
            .then(
                response => response.data,
                error => {
                    if (error && error.response !== undefined) {
                        displayErrorMessage(error.response.data.message || "Internal server error")
                    } else {
                        displayErrorMessage("Internal server error")
                    }
                }
            ).then((data) => {
                if(data !== undefined) {
                    return dispatch({
                        type: POST_BULK,
                        payload: data
                    })
                }},
            );
    };
}

export const resendUserPassword = (data) => {
    return function (dispatch) {
        return postRequest(USER_RESEND_PASSWORD_API, data)
            .then(
                response => response.data,
                error => {
                    if (error && error.response !== undefined) {
                        displayErrorMessage(error.response.data.message || "Internal server error")
                    } else {
                        displayErrorMessage("Internal server error")
                    }
                }
            ).then((data) => {
                    return dispatch({
                        type: RESEND_PASSWORD,
                        payload: data
                    })
                },
            );
    };
}

export const resetUser = (data) => {
    return function (dispatch) {
        return postRequest(USER_RESET_API, data)
            .then(
                response => response.data,
                error => {
                    if (error && error.response !== undefined) {
                        displayErrorMessage(error.response.data.message || "Internal server error")
                    } else {
                        displayErrorMessage("Internal server error")
                    }
                }
            ).then((data) => {
                    return dispatch({
                        type: POST_USER,
                        payload: data
                    })
                },
            );
    };
}

export const fetchTeamMembers = (id) => {
    return function (dispatch) {
        return getRequest(USER_TEAM_MEMBER_LIST_API + '?userId=' + id)
            .then(
                response => response.data,
                error => {
                    if (error && error.response !== undefined) {
                        displayErrorMessage(error.response.data.message || "Internal server error")
                    } else {
                        displayErrorMessage("Internal server error")
                    }
                }
            )
            .then((data) => {
                    return dispatch({
                        type: TEAM_FETCH,
                        payload: data
                    })
                },
            );
    };
};